import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenMenuButtonComponent } from './open-menu-button/open-menu-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ToggleMenuService } from './toggle-menu.service';


@NgModule({
  declarations: [
    OpenMenuButtonComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
  ],
  providers: [
    ToggleMenuService,
  ],
  exports: [
    OpenMenuButtonComponent,
  ],
})
export class MenuModule {}
