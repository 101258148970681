<mat-card>
  <mat-table
    *ngIf="!!dataSource && !!me"
    [dataSource]="dataSource"
    [trackBy]="trackBy"
    data-testid="mat-table"
    matSort
    matSortActive="lastname"
    matSortDirection="asc"
    matSortDisableClear
  >
    <ng-container matColumnDef="selection">
      <mat-header-cell *matHeaderCellDef disableClear mat-header-cell mat-sort-header>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && areAllSelected()"
          [color]="selection.hasValue() ? 'primary' : 'accent'"
        >
        </mat-checkbox>
        <span i18n style="padding-left: 0.5rem;">{{ checkboxLabel() }}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let collaborator">
        <mat-checkbox
          [disabled]="isMe(collaborator)"
          (change)="$event ? selection.toggle(collaborator) : null"
          (click)="$event.stopPropagation()"
          [aria-label]="collaborator.displayName"
          [checked]="selection.isSelected(collaborator)"
          [color]="selection.hasValue() ? 'primary' : 'accent'">
        </mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="enabled">
      <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Enabled</mat-header-cell>
      <mat-cell *matCellDef="let collaborator">
        <mat-icon *ngIf="collaborator.enabled; else no">done</mat-icon>
        <ng-template #no>
          <mat-icon>highlight_off</mat-icon>
        </ng-template>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="firstname">
      <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Firstname</mat-header-cell>
      <mat-cell *matCellDef="let collaborator" data-testid="firstname">{{ collaborator.firstname }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastname">
      <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Lastname</mat-header-cell>
      <mat-cell *matCellDef="let collaborator" data-testid="lastname">{{ collaborator.lastname }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="isAdmin">
      <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Role</mat-header-cell>
      <mat-cell *matCellDef="let collaborator">
        {{ collaborator | collaboratorRole }}
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="background-accent"></mat-header-row>
    <mat-row
      (click)="!isMe(collaborator) ? selection.toggle(collaborator) : undefined"
      *matRowDef="let collaborator; columns: displayedColumns"
      id="collaborator-{{collaborator.id}}"
      matRipple
    ></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[10, 20, 50]" class="table-footer"></mat-paginator>
</mat-card>
