import { Injectable } from '@angular/core';
import { Material } from '../models/material.models';

@Injectable({
  providedIn: 'root',
})
export class MergeMaterialsService {

  constructor() { }

  merge(newMaterial: Partial<Material>, storedMaterial: Partial<Material>): Material {
    return {
      ...storedMaterial,
      ...newMaterial,
    };
  }
}
