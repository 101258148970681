import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ProjectLock } from './project-lock.model';
import { catchError, map } from 'rxjs/operators';
import { ProjectLockDto } from '../../api/dtos/__generated__/projectLockDto';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ProjectLockMapperService } from './project-lock-mapper.service';
import { ErrorDto } from '../../api/dtos/__generated__/errorDto';

@Injectable({
  providedIn: 'root',
})
export class ProjectLockDataService {
  private RESOURCE_LOCKED = 423;
  PROJECTS_URL = `${environment.apiUrl}/projects`;

  constructor(
    private readonly http: HttpClient,
    private readonly projectLockMapperService: ProjectLockMapperService,
  ) { }

  lock(id: string): Observable<ProjectLock> {
    return this.http.post(`${this.PROJECTS_URL}/${id}/lock`, {}).pipe(
      map((dto: ProjectLockDto) => this.projectLockMapperService.map(dto, true)),
      catchError(this.handleError),
    );
  }

  unlock(id: string): Observable<any> {
    return this.http.post(`${this.PROJECTS_URL}/${id}/unlock`, {}).pipe(
      catchError(this.handleError),
    );
  }

  private handleError = (err) => {
    if (err?.status === this.RESOURCE_LOCKED && err.error) {
      const dto = err.error as ErrorDto;
      return of(this.projectLockMapperService.map(dto.message as ProjectLockDto, false));
    }
    throw Error(err);
  }
}
