import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../../../core/notification.service';
import { catchError, map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DistributionMarketMapperService } from '../mappers/distribution-market-mapper.service';
import { DistributionMarket, Transport, UpdateDistributionMarket } from '../models/distribution-market';
import { DistributionMarketDto } from '../../../api/dtos/__generated__/distributionMarketDto';
import { UpdateDistributionMarketDto } from '../../../api/dtos/__generated__/updateDistributionMarketDto';

@Injectable({
  providedIn: 'root',
})
export class DistributionMarketDataService {
  private readonly BASE_URL = 'distribution-markets';

  constructor(
    private readonly http: HttpClient,
    private readonly notificationService: NotificationService,
    private readonly distributionMarketMapperService: DistributionMarketMapperService
  ) {
  }

  findAll(scenarioId: string): Observable<DistributionMarket[]> {
    return this.http.get(`${environment.apiUrl}/${this.BASE_URL}?scenarioId=${scenarioId}`).pipe(
      map((dto: DistributionMarketDto[]) => this.distributionMarketMapperService.fromList(dto)),
      catchError(err => {
        this.notificationService.warn('Could not retrieve distribution markets');
        throw err;
      }),
    );
  }

  create(scenarioId: string, distributionMarket: UpdateDistributionMarket): Observable<DistributionMarket> {
    const body: UpdateDistributionMarketDto = { ...distributionMarket };
    return this.http.post(`${environment.apiUrl}/${this.BASE_URL}?scenarioId=${scenarioId}`, body).pipe(
      map((dto: DistributionMarketDto) => this.distributionMarketMapperService.from(dto)),
      catchError(err => {
        this.notificationService.warn('Could not create distribution market');
        throw err;
      }),
    );
  }

  update(id: string, distributionMarket: UpdateDistributionMarket): Observable<DistributionMarket> {
    const body: UpdateDistributionMarketDto = { ...distributionMarket };
    return this.http.put(`${environment.apiUrl}/${this.BASE_URL}/${id}`, body).pipe(
      map((dto: DistributionMarketDto) => this.distributionMarketMapperService.from(dto)),
      catchError(err => {
        this.notificationService.warn('Could not update distribution market');
        throw err;
      }),
    );
  }

  delete(id: string): Observable<boolean> {
    return this.http.delete(`${environment.apiUrl}/${this.BASE_URL}/${id}`).pipe(
      map(() => true),
      catchError(err => {
        this.notificationService.warn(`Could not delete distribution market ${id}`);
        throw err;
      }),
    );
  }

  updateTransports(id: string, transports: Transport[]): Observable<DistributionMarket> {
    return this.http.put(`${environment.apiUrl}/${this.BASE_URL}/${id}/transports`, transports).pipe(
      map((dto: DistributionMarketDto) => this.distributionMarketMapperService.from(dto)),
      catchError(err => {
        this.notificationService.warn('Could not update distribution market\'s transports');
        throw err;
      }),
    );
  }
}
