import { createAction, props } from '@ngrx/store';
import { ComponentTransport } from '../../models/component.models';

export const getComponentTransportsSuccess = createAction(
  '[ComponentTransport/API] Get ComponentTransport Success',
  props<{ componentTransports: ComponentTransport[] }>(),
);

export const addComponentTransport = createAction(
  '[ComponentTransport/API] Add ComponentTransport',
  props<{ transport: Partial<ComponentTransport> }>(),
);

export const addComponentTransportSuccess = createAction(
  '[ComponentTransport/API] Add ComponentTransport Success',
  props<{ componentTransport: ComponentTransport }>(),
);

export const updateComponentTransport = createAction(
  '[ComponentTransport/API] Update ComponentTransport',
  props<{ componentTransport: Partial<ComponentTransport> }>(),
);

export const updateComponentTransportSuccess = createAction(
  '[ComponentTransport/API] Update ComponentTransport Success',
  props<{ componentTransport: ComponentTransport }>(),
);

export const deleteComponentTransport = createAction(
  '[ComponentTransport/API] Delete ComponentTransport',
  props<{ id: string }>(),
);

export const deleteComponentTransportSuccess = createAction(
  '[ComponentTransport/API] Delete ComponentTransport Success',
  props<{ id: string }>(),
);

export const componentTransportError = createAction(
  '[ComponentTransport/API] ComponentTransports Error',
);

export const deleteAll = createAction(
  '[ComponentTransport/API] Delete All ComponentTransports Of Given Component',
  props<{ componentId: string }>(),
);
