import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  template: `
    <div id="snackbar-wrapper">
      <span class="message">{{ data }}</span>
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  `,
  styleUrls: [ 'snackbar.component.scss' ],
})
export class SnackbarComponent implements OnInit {

  constructor(
    private readonly ref: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: string,
  ) {
  }

  ngOnInit(): void {
  }

  close(): void {
    this.ref.dismiss();
  }

}
