import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDto } from '../../api/dtos/__generated__/errorDto';
import { LicenseStatusDialogComponent } from './license-status-dialog.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiErrorType } from '../../shared/api-error-type.model';
import { LicenseStatus } from '../../shared/license-status.model';

interface ErrorWithFlag {
  open: boolean;
  error: ErrorDto;
}

function openWith(error: ErrorDto): ErrorWithFlag {
  return {
    open: true,
    error
  };
}

const noError: ErrorWithFlag = {
  open: false,
  error: null,
};

@Injectable({
  providedIn: 'root',
})
export class LicenseStatusDialogService {
  private request$: BehaviorSubject<ErrorWithFlag> = new BehaviorSubject<ErrorWithFlag>(noError);

  constructor(private readonly dialog: MatDialog,
              private readonly authService: AuthService) {
    this.request$
      .pipe(
        filter(value => value.open),
        switchMap(this.openDialog(dialog))
      )
      .subscribe(() => {
        this.request$.next(noError);
        authService.logout();
      });
  }

  accept(error: any): boolean {
    const statusForbidden = 403;
    if (error instanceof HttpErrorResponse && error.status === statusForbidden) {
      const dto = error.error as ErrorDto;
      if (dto.type === ApiErrorType.ERROR_LICENSE_STATUS) {
        return true;
      }
    }
    return false;
  }

  isLicenseNotConfigured(error: any): boolean {
    return this.accept(error) && ((error.error as ErrorDto).code === LicenseStatus.NOT_CONFIGURED);
  }

  handle(error: ErrorDto): void {
    const last = this.request$.getValue();
    if (!last.open) {
      this.request$.next(openWith(error));
    }
  }

  private openDialog(dialog: MatDialog): (value: ErrorWithFlag) => Observable<any> {
    return (value) => {
      return dialog.open(LicenseStatusDialogComponent, {
        data: value.error,
        width: null,
        maxWidth: '50%',
      }).afterClosed();
    };
  }
}
