import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Observable, from } from 'rxjs';
import { LocalStorageService } from '../core/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private readonly authService = Auth;

  constructor(private readonly localStorageService: LocalStorageService) { }

  logout(): Observable<any> {
    this.localStorageService.clear();
    return from(this.authService.signOut());
  }

  async getIdToken(): Promise<string> {
    const session = await this.authService.currentSession();
    return session.getIdToken().getJwtToken();
  }
}
