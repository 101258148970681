import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ScenarioDataService } from '../../services/scenario.data-service';
import {
  addScenario,
  addScenarioSuccess,
  deleteScenario,
  deleteScenarioSuccess,
  duplicateScenario,
  duplicateScenarioSuccess,
  getScenario,
  getScenarioSuccess,
  importScenario,
  importScenarioSuccess,
  scenarioError,
  updateHasSecondaryPackaging,
  updateHasTertiaryPackaging,
  updateHasTertiaryPalletizationPackaging,
  updateScenario,
  updateScenarioSuccess,
} from './scenario.actions';
import { catchError, concatMap, map, mergeMap, pluck, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { Scenario } from '../../models/scenario.model';
import { AppState } from '../../../../store';
import { select, Store } from '@ngrx/store';
import { isLastScenario } from './scenario.selectors';
import * as fromPrimaryPackaging from '../packaging/primary';
import * as fromSecondaryPackaging from '../packaging/secondary';
import * as fromTertiaryPackaging from '../packaging/tertiary';
import * as fromTertiaryPalletizationPackaging from '../packaging/tertiary-palletization';
import * as fromComponent from '../component';
import * as fromFinishingProcess from '../finishing-process';
import * as fromMaterial from '../material';
import * as fromMaterialTransport from '../material-transport';
import { NotificationService } from '../../../../core/notification.service';
import { HttpErrorResponse } from '@angular/common/http';


@Injectable({
  providedIn: 'root',
})
export class ScenarioEffects {

  getScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getScenario),
      map((action: { type: string, id: string }) => action.id),
      concatMap((id: string) => this.scenarioDataService.get(id)
        .pipe(
          concatMap(({
            components,
            finishingProcesses,
            materials,
            materialTransports,
            primaryPackaging,
            secondaryPackaging,
            tertiaryPackaging,
            tertiaryPalletizationPackaging,
            scenario,
          }) => [
            getScenarioSuccess({ scenario }),
            fromPrimaryPackaging.getPrimaryPackagingSuccess({ packaging: primaryPackaging }),
            fromSecondaryPackaging.getSecondaryPackagingSuccess({ packaging: secondaryPackaging }),
            fromTertiaryPackaging.getTertiaryPackagingSuccess({ packaging: tertiaryPackaging }),
            fromTertiaryPalletizationPackaging.getTertiaryPalletizationPackagingSuccess({ packaging: tertiaryPalletizationPackaging }),
            fromComponent.getComponentsSuccess({ components }),
            fromFinishingProcess.getFinishingProcessesSuccess({ finishingProcesses }),
            fromMaterial.getMaterialsSuccess({ materials }),
            fromMaterialTransport.getMaterialTransportsSuccess({ materialTransports }),
          ]),
          catchError(() => of(scenarioError({ message: 'Something went wrong when getting scenario by id' }))),
        ),
      ),
    ),
  );

  addScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addScenario),
      concatMap((action: { type: string, projectId: string }) => this.scenarioDataService.create(action.projectId)
        .pipe(
          concatMap((created) =>
            [
              fromPrimaryPackaging.createFirstPrimaryPackaging({ scenarioId: created.id }),
              addScenarioSuccess({ scenario: created }),
            ],
          ),
          catchError((error: HttpErrorResponse) => this.notificationService.warnAndRethrow({ error })),
        ),
      ),
      catchError(err => of(scenarioError({ message: err?.message }))),
    ),
  );

  importScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importScenario),
      concatMap((action: {
          type: string,
          projectId: string,
          fromPublishedScenarioId: string
        }) => this.scenarioDataService.create(action.projectId, action.fromPublishedScenarioId)
          .pipe(
            pluck('id'),
            concatMap(scenarioId =>
              [
                importScenarioSuccess({ scenarioId }),
                getScenario({ id: scenarioId }),
              ],
            ),
            catchError((error: HttpErrorResponse) => this.notificationService.warnAndRethrow({ error })),
          ),
      ),
      catchError(err => of(scenarioError({ message: err?.message }))),
    ));

  addOrDeleteSecondaryPackaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateHasSecondaryPackaging),
      switchMap((action: { type: string, id: string, scenarioId: string, hasPackaging: boolean }) => {
        const { id, scenarioId, hasPackaging } = action;
        if (hasPackaging) {
          return of(fromSecondaryPackaging.addSecondaryPackaging({ scenarioId }));
        }
        return of(fromSecondaryPackaging.deleteSecondaryPackaging({ id, scenarioId }));
      }),
    ),
  );

  addOrDeleteTertiaryPackaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateHasTertiaryPackaging),
      switchMap((action: { type: string, id: string, scenarioId: string, hasPackaging: boolean }) => {
        const { id, scenarioId, hasPackaging } = action;
        if (hasPackaging) {
          return of(fromTertiaryPackaging.addTertiaryPackaging({ scenarioId }));
        }
        return of(fromTertiaryPackaging.deleteTertiaryPackaging({ id, scenarioId }));
      }),
    ),
  );

  addOrDeleteTertiaryPalletizationPackaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateHasTertiaryPalletizationPackaging),
      switchMap((action: { type: string, id: string, scenarioId: string, hasPackaging: boolean }) => {
        const { id, scenarioId, hasPackaging } = action;
        if (hasPackaging) {
          return of(fromTertiaryPalletizationPackaging.addTertiaryPalletizationPackaging({ scenarioId }));
        }
        return of(fromTertiaryPalletizationPackaging.deleteTertiaryPalletizationPackaging({ id, scenarioId }));
      }),
    ),
  );

  updateScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateScenario),
      map((action: { type: string, scenario: Partial<Scenario> }) => action.scenario),
      switchMap((s: Scenario) => {
          return this.scenarioDataService.update(s)
            .pipe(
              map((scenario: Scenario) => {
                return updateScenarioSuccess({ scenario });
              }),
              catchError(() => {
                this.notificationService.warn('Could not save latest scenario information');
                return of(scenarioError({ message: 'Something went wrong when updating scenario' }));
              }),
            );
        },
      ),
    ),
  );

  deleteScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteScenario),
      switchMap((action: { type: string, id: string, projectId: string }) => {
          const { id, projectId } = action;
          return this.store.pipe(
            select(isLastScenario(projectId)),
            take(1),
            switchMap((isLast: boolean) => {
              if (isLast) {
                this.notificationService.warn('You can\'t delete the last scenario of a project.');
                return of(scenarioError({ message: 'Cannot delete the last scenario of a project' }));
              }
              return this.scenarioDataService.delete(id)
                .pipe(
                  mergeMap(() => {
                    return [
                      deleteScenarioSuccess({ id }),
                      fromPrimaryPackaging.deleteAll({ scenarioId: id }),
                      fromSecondaryPackaging.deleteAll({ scenarioId: id }),
                      fromTertiaryPackaging.deleteAll({ scenarioId: id }),
                      fromTertiaryPalletizationPackaging.deleteAll({ scenarioId: id }),
                    ];
                  }),
                );
            }),
            catchError((error) => {
              return of(scenarioError({ message: error?.message ?? 'Something went wrong when deleting scenario' }));
            }),
          );
        },
      ),
    ),
  );

  duplicateScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(duplicateScenario),
      switchMap((action: { type: string, id: string, name?: string }) => this.scenarioDataService.duplicate(action.id, action.name)
        .pipe(
          mergeMap(({
            components,
            finishingProcesses,
            materials,
            materialTransports,
            primaryPackaging,
            secondaryPackaging,
            tertiaryPackaging,
            tertiaryPalletizationPackaging,
            scenario,
            downstream,
          }) => [
            duplicateScenarioSuccess({ scenario }),
            fromPrimaryPackaging.getPrimaryPackagingSuccess({ packaging: primaryPackaging }),
            fromSecondaryPackaging.getSecondaryPackagingSuccess({ packaging: secondaryPackaging }),
            fromTertiaryPackaging.getTertiaryPackagingSuccess({ packaging: tertiaryPackaging }),
            fromTertiaryPalletizationPackaging.getTertiaryPalletizationPackagingSuccess({ packaging: tertiaryPalletizationPackaging }),
            fromComponent.getComponentsSuccess({ components }),
            fromFinishingProcess.getFinishingProcessesSuccess({ finishingProcesses }),
            fromMaterial.getMaterialsSuccess({ materials }),
            fromMaterialTransport.getMaterialTransportsSuccess({ materialTransports }),
          ]),
          catchError(() => of(scenarioError({ message: 'Something went wrong when duplicating scenario' }))),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly scenarioDataService: ScenarioDataService,
    private readonly store: Store<AppState>,
    private readonly notificationService: NotificationService,
  ) {
  }

}
