import { Injectable } from '@angular/core';
import { AbstractMapper } from '../../project/abstract-mapper';
import { PublishedScenario } from '../models/published-scenario.model';
import { PublishedScenarioTableData } from '../scenario-library-table/published-scenario-table.data';
import { DatePipe } from '@angular/common';
import { FullNamePipe } from '../../collaborator/fullName.pipe';

@Injectable({
  providedIn: 'root',
})
export class PublishedScenarioTableDataMapperService extends AbstractMapper<PublishedScenario, PublishedScenarioTableData> {

  constructor(private readonly datePipe: DatePipe, private readonly fullNamePipe: FullNamePipe) { super(); }

  from(scenario: PublishedScenario): PublishedScenarioTableData {
    const {
      name,
      publicationStatus,
      publishedAt,
      brandType,
      description,
      reference,
      packagingType,
      baseMaterialType,
      productType,
      statusType,
      productionCountry,
      salesCountry,
      publishedBy,
    } = scenario;
    return {
      name: name ?? '',
      brandType: brandType?.value ?? '',
      description: description ?? '',
      publishedAt: this.datePipe.transform(publishedAt.toDateString(), 'shortDate'),
      baseMaterialType: baseMaterialType?.value ?? '',
      productType: productType?.value ?? '',
      packagingType: packagingType?.value ?? '',
      publicationStatus,
      productionCountry: productionCountry?.value ?? '',
      salesCountry: salesCountry?.value ?? '',
      reference: reference ?? '',
      statusType: statusType?.value ?? '',
      publishedBy: this.fullNamePipe.transform(publishedBy),
    } as PublishedScenarioTableData;
  }
}
