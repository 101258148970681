import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-version-page', template: `
    <app-set-default-version-header></app-set-default-version-header>
    <app-set-default-version-table></app-set-default-version-table>`, styleUrls: ['./version-page.component.scss']
})
export class VersionPageComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
