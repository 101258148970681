import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Version, Versions } from './models/versions-data.model';
import { map } from 'rxjs/operators';
import { VersionsDto } from '../../api/dtos/__generated__/versionsDto';
import { VersionDto } from '../../api/dtos/__generated__/versionDto';
import { VERSIONS_DATA_ID } from './store/versions-data.reducer';

@Injectable({
  providedIn: 'root',
})
export class VersionsDataDataService {
  VERSIONS_DATA_URL = `${environment.apiUrl}/versions`;

  constructor(
    private readonly http: HttpClient,
  ) {
  }

  get(): Observable<Versions> {
    return this.http.get(this.VERSIONS_DATA_URL)
      .pipe(map((data: VersionsDto) =>
        ({ id: VERSIONS_DATA_ID, ...data } as Versions)
      ));
  }

  setDefaultVersion(version: Version): Observable<Version> {
    return this.http.put(`${this.VERSIONS_DATA_URL}/default`, version)
      .pipe(map((data: VersionDto) => data as Version));
  }
}
