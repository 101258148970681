import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { catchError, debounceTime, map } from 'rxjs/operators';
import { TokenDto } from '../api/dtos/__generated__/tokenDto';
import { LicenseStatusDialogService } from '../core/license-status-dialog/license-status-dialog.service';
import { ErrorDto } from '../api/dtos/__generated__/errorDto';

@Injectable({
  providedIn: 'root',
})
export class TokenService {

  private http: HttpClient;

  private readonly TOKENS_URL = `${ environment.apiUrl }/tokens`;

  constructor(private readonly httpBackend: HttpBackend,
              private readonly licenseStatusDialogService: LicenseStatusDialogService) {
    this.http = new HttpClient(httpBackend);
  }

  validate(provider: string): Observable<string | null> {
    return this.http.get(`${ this.TOKENS_URL }`, { params: { id: provider } })
      .pipe(
        map((res: TokenDto) => res.provider),
        catchError(err => {
          if (this.licenseStatusDialogService.isLicenseNotConfigured(err)) {
            return of(provider);
          }
          if (this.licenseStatusDialogService.accept(err)) {
            this.licenseStatusDialogService.handle(err.error as ErrorDto);
          }
          return throwError(err);
        })
      );
  }
}
