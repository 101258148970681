import { Injectable } from '@angular/core';
import { ReferenceDataDto } from '../../api/dtos/__generated__/referenceDataDto';
import { ReferenceData } from './models/reference-data.model';
import { INDICATOR_TYPE, IndicatorType } from '../../project/assessment/pages/models/indicator-type.model';
import { LCAIndicator } from '../../project/assessment/models/lca-indicator.model';
import { PackagingDesignIndicator } from '../../project/assessment/models/packaging-design-indicator.model';
import { UnclassifiedIndicator } from '../../project/assessment/models/unclassified-indicator.model';
import { GHGIndicator } from '../../project/assessment/models/ghg-indicator.model';

@Injectable({
  providedIn: 'root',
})
export class ReferenceDataMappingService {

  indicatorTypes = IndicatorType;
  lcaIndicators: string[] = Object.values(LCAIndicator);
  packagingDesignIndicators: string[] = Object.values(PackagingDesignIndicator);
  unclassifiedIndicators = UnclassifiedIndicator;
  ghgIndicators = GHGIndicator;

  constructor() {}

  mapReferenceDataFromReferenceDataDto(dto: ReferenceDataDto): ReferenceData {
    return {
      id: 1,
      countries: dto?.countries.map(this.mapLabels),
      finishingProcessTypes: dto?.finishingProcessTypes.map(this.mapLabels),
      washingProcessTypes: dto?.washingProcessTypes.map(this.mapLabels),
      materialTypes: dto?.materialTypes.map(d => ({
        label: d?.label,
        value: d?.value,
        hasRecycledContent: d?.hasRecycledContent,
        conversionProcessTypes: d?.conversionProcessTypes.map(this.mapLabels),
      })),
      transportTypes: dto?.transportTypes.map(this.mapLabels),
      componentTypes: dto?.componentTypes.map(d => ({
        label: d?.label,
        value: d?.value,
        componentSubtypes: d?.componentSubtypes.map(this.mapLabels),
      })),
      productTypes: dto?.productTypes.map(this.mapLabels),
      statusTypes: dto?.statusTypes.map(this.mapLabels),
      packagingTypes: dto?.packagingTypes.map(this.mapLabels),
      baseMaterialTypes: dto?.baseMaterialTypes.map(this.mapLabels),
      brandTypes: dto?.brandTypes.map(this.mapLabels),
      indicators: dto?.indicators.map(d => ({
        id: d.label,
        unit: d?.unit,
        priority: d?.priority,
        value: d?.value,
        type: this.indicatorType(d.label),
        rank: d?.rank,
      })),
      functionalUnits: dto?.functionalUnits.map(d => ({
        label: d.label,
        value: d.value,
      })),
    };
  }

  private indicatorType(label: string): INDICATOR_TYPE | null {
    if (this.lcaIndicators.includes(label)) {
      return this.indicatorTypes.LCA;
    }
    if (this.packagingDesignIndicators.includes(label)) {
      return this.indicatorTypes.PACKAGING_DESIGN;
    }
    if (this.unclassifiedIndicators.SINGLE_SCORE === label) {
      return this.indicatorTypes.SINGLE_SCORE;
    }
    if (this.unclassifiedIndicators.WASTE_STREAM === label) {
      return this.indicatorTypes.WASTE_STREAM;
    }
    if (this.unclassifiedIndicators.PLASTIC_FOOTPRINT === label) {
      return this.indicatorTypes.PLASTIC_FOOTPRINT;
    }
    if (this.ghgIndicators.CC === label) {
      return this.indicatorTypes.GHG;
    }
    if (this.unclassifiedIndicators.MATERIAL_CIRCULARITY === label) {
      return this.indicatorTypes.MATERIAL_CIRCULARITY;
    }
    return null;
  }

  private mapLabels(input: Partial<{ label: string, value: string }>) {
    return { label: input?.label, value: input?.value };
  }
}
