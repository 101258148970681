import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserManagementPageComponent} from './user-management-page.component';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {UserManagementModule} from '../../user-management/user-management.module';
import {SearchInputModule} from '../../../shared/search-input/search-input.module';


@NgModule({
  declarations: [UserManagementPageComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatListModule,
    UserManagementModule,
    SearchInputModule,
  ],
  exports: [UserManagementPageComponent],
})
export class UserManagementPageModule {
}
