import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminMenuComponent } from './admin-menu.component';
import { TenantModule } from '../../../tenant/tenant.module';
import { SharedModule } from '../../../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import {VersionModule} from "../../../version/version.module";


@NgModule({
  declarations: [ AdminMenuComponent ],
  imports: [
    CommonModule,
    TenantModule,
    SharedModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    RouterModule,
    VersionModule
  ],
  exports: [ AdminMenuComponent ],
})
export class AdminMenuModule {}
