import { Component } from '@angular/core';

@Component({
  selector: 'app-statistics-header',
  template: `
    <div class="col">
      <h2 i18n>Statistics</h2>
    </div>
  `,
  styleUrls: [ 'statistics-header.component.scss' ],
})
export class StatisticsHeaderComponent {
}
