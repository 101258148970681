import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { adapter, primaryPackagingFeatureKey, PrimaryPackagingState } from './primary-packaging.reducer';
import { AppState } from '../../../../../store';
import { Dictionary } from '@ngrx/entity';
import { Packaging } from '../../../models/packaging.models';

export const selectPrimaryPackagingState: MemoizedSelector<AppState, PrimaryPackagingState> =
  createFeatureSelector(primaryPackagingFeatureKey);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectPrimaryPackagingState);

export const selectById = (packagingId: string) => createSelector(
  selectEntities,
  (packaging: Dictionary<Packaging>) => packaging[packagingId],
);

export const selectLastCreated = createSelector(
  selectPrimaryPackagingState,
  (state: PrimaryPackagingState) => !state.loading ? state.lastCreated : undefined,
);

export const isLoading = createSelector(
  selectPrimaryPackagingState,
  (state: PrimaryPackagingState) => state.loading,
);

export const selectByScenarioId = (scenarioId: string) => createSelector(
  selectAll,
  (packaging: Packaging[]) => {
    const [ primary ] = packaging
      .filter((p: Packaging) => p?.scenarioId === scenarioId);
    return primary;
  },
);

export const selectPackagingId = (scenarioId: string) => createSelector(
  selectByScenarioId(scenarioId),
  (p: Packaging) => p?.id,
);

export const selectProductVolume = (scenarioId: string) => createSelector(
  selectByScenarioId(scenarioId),
  (p: Packaging) => p?.productVolume,
);

export const selectProductMass = (scenarioId: string) => createSelector(
  selectByScenarioId(scenarioId),
  (p: Packaging) => p?.productMass,
);

export const selectUsage = (scenarioId: string) => createSelector(
  selectByScenarioId(scenarioId),
  (p: Packaging) => p?.usage,
);
