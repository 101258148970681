import { NgModule } from '@angular/core';
import {PortfolioDetailModule} from '../../../portfolio/components/portfolio-detail/portfolio-detail.module';
import {PortfolioDetailPageComponent} from './portfolio-detail-page.component';

@NgModule({
  declarations: [
    PortfolioDetailPageComponent
  ],
  imports: [
    PortfolioDetailModule
  ],
})
export class PortfolioDetailPageModule {}
