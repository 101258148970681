import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {StatisticsCardComponent} from './statistics-card.component';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [
    StatisticsCardComponent
  ],
  exports: [
    StatisticsCardComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule
  ]
})
export class StatisticsCardModule {
}
