import { Injectable } from '@angular/core';
import { EntityReferenceMapperService } from './entity-reference-mapper.service';
import { AbstractMapper } from '../../abstract-mapper';
import { ScenarioLCAAssessmentDto } from '../../../api/dtos/__generated__/scenarioLCAAssessmentDto';
import { ScenarioLCAAssessment } from '../models/scenario-lca-assessment.model';
import { LCAIndicator } from '../models/lca-indicator.model';
import { DetailItemAssessmentMapperService } from './material-or-finishing-process-assessment-mapper.service';
import { GHGIndicator } from '../models/ghg-indicator.model';

@Injectable({
  providedIn: 'root',
})
export class ScenarioLcaAssessmentMapperService extends AbstractMapper<ScenarioLCAAssessmentDto & {
  overrideIndicator?: GHGIndicator
}, ScenarioLCAAssessment> {

  constructor(
    private readonly materialOrFinishingProcessAssessmentMapper: DetailItemAssessmentMapperService,
    private readonly entityReferenceMapper: EntityReferenceMapperService,
  ) {
    super();
  }

  from(dto: ScenarioLCAAssessmentDto & { overrideIndicator?: GHGIndicator }): ScenarioLCAAssessment | undefined {
    if (!dto) {
      return;
    }

    const {
      scenario,
      indicator,
      functionalUnit,
      endOfLifeImpact,
      downstreamLogisticsImpact,
      processesImpact,
      details,
      primaryPackagingImpact,
      rawMaterialProductionImpact,
      secondaryPackagingImpact,
      tertiaryPackagingBoxImpact,
      tertiaryPackagingPalletizationImpact,
      totalImpact,
      upstreamLogisticsImpact,
      overrideIndicator,
    } = dto;

    return {
      scenario: this.entityReferenceMapper.from(scenario),
      indicator: overrideIndicator ?? indicator as LCAIndicator,
      functionalUnit,
      endOfLifeImpact,
      downstreamLogisticsImpact,
      processesImpact,
      details: this.materialOrFinishingProcessAssessmentMapper.fromList(details?.map(d => ({ ...d, overrideIndicator })) ?? []),
      primaryPackagingImpact,
      rawMaterialProductionImpact,
      secondaryPackagingImpact,
      tertiaryPackagingBoxImpact,
      tertiaryPackagingPalletizationImpact,
      totalImpact,
      upstreamLogisticsImpact,
    } as ScenarioLCAAssessment;
  }
}
