import { createSelector } from '@ngrx/store';
import { REFERENCE_DATA_ID, selectEntities } from './reference-data.reducer';
import { Dictionary } from '@ngrx/entity';
import { MaterialType, ReferenceData } from '../models/reference-data.model';
import { Indicator } from '../../../project/assessment/pages/models/indicators.model';
import { isMajor, isMinor } from '../../../project/assessment/pages/models/indicatorPriority';
import { isSingleScore, UnclassifiedIndicator } from '../../../project/assessment/models/unclassified-indicator.model';
import { isLCAIndicator } from '../../../project/assessment/models/lca-indicator.model';
import { isGHG } from '../../../project/assessment/models/ghg-indicator.model';

export const selectReferenceData = createSelector(
  selectEntities,
  (entities: Dictionary<ReferenceData>) => entities[REFERENCE_DATA_ID],
);

export const selectPackagingTypes = createSelector(
  selectReferenceData,
  (referenceData: ReferenceData) => referenceData?.packagingTypes,
);

export const selectCountries = createSelector(
  selectReferenceData,
  (referenceData: ReferenceData) => referenceData?.countries,
);

export const selectProductTypes = createSelector(
  selectReferenceData,
  (referenceData: ReferenceData) => referenceData?.productTypes,
);

export const selectComponentTypes = createSelector(
  selectReferenceData,
  (referenceData: ReferenceData) => referenceData?.componentTypes,
);

export const selectMaterialTypes = createSelector(
  selectReferenceData,
  (referenceData: ReferenceData) => referenceData?.materialTypes,
);

export const selectBaseMaterialTypes = createSelector(
  selectReferenceData,
  (referenceData: ReferenceData) => referenceData?.baseMaterialTypes,
);

export const selectBrandTypes = createSelector(
  selectReferenceData,
  (referenceData: ReferenceData) => referenceData?.brandTypes,
);

export const selectFunctionalUnits = createSelector(
  selectReferenceData,
  (referenceData: ReferenceData) => {
    return referenceData?.functionalUnits;
  },
);

export const selectTransportTypes = createSelector(
  selectReferenceData,
  (referenceData: ReferenceData) => referenceData?.transportTypes,
);

export const selectFinishingProcessTypes = createSelector(
  selectReferenceData,
  (referenceData: ReferenceData) => referenceData?.finishingProcessTypes,
);

export const selectWashingProcessTypes = createSelector(
    selectReferenceData,
    (referenceData: ReferenceData) => referenceData?.washingProcessTypes,
);

export const selectStatusTypes = createSelector(
  selectReferenceData,
  (referenceData: ReferenceData) => referenceData?.statusTypes,
);

export const selectTransportBoat = createSelector(
  selectReferenceData,
  (referenceData: ReferenceData) => {
    const [ ship ] = referenceData?.transportTypes.filter(t => t.label.includes('boat'));
    return ship;
  },
);

export const selectTransportLorry = createSelector(
  selectReferenceData,
  (referenceData: ReferenceData) => {
    const [ truck ] = referenceData?.transportTypes.filter(t => t.label.includes('lorry'));
    return truck;
  },
);

export const conversionProcessTypes = (materialType: MaterialType) => createSelector(
  selectMaterialTypes,
  (materialTypes: MaterialType[]) => materialTypes?.find(t => t.label === materialType?.label)?.conversionProcessTypes,
);

export const compareByPriorityAndRank = (a: Indicator, b: Indicator): number => {
  const priorityA = isMajor(a.priority) ? 0 : 1;
  const priorityB = isMajor(b.priority) ? 0 : 1;
  if (priorityA === priorityB) {
    return a.rank - b.rank;
  }
  return priorityA - priorityB;
};

export const LcaSingleScoreAndGhgIndicators = createSelector(
  selectReferenceData,
  (referenceData: ReferenceData) => referenceData?.indicators
    .filter(i => isLCAIndicator(i.id) || isSingleScore(i.id) || isGHG(i.id))
    .slice()
    .sort(compareByPriorityAndRank),
);

export const allIndicators = createSelector(
  selectReferenceData,
  (referenceData: ReferenceData) => referenceData?.indicators
    .slice()
    .sort(compareByPriorityAndRank),
);

export const majorIndicators = createSelector(
  selectReferenceData,
  (referenceData: ReferenceData) => {
    return referenceData?.indicators
      .filter(i => isMajor(i.priority))
      .slice()
      .sort(compareByPriorityAndRank);
  },
);

export const indicator = (id: string) => createSelector(
  selectReferenceData,
  (referenceData: ReferenceData) => referenceData?.indicators.find(ind => ind.id === id),
);

export const isIndicatorPresent = (id: string) => createSelector(
  indicator(id),
  (ind: Indicator | undefined) => !!ind,
);

export const isIndicatorMinor = (id: string) => createSelector(
  indicator(id),
  (ind: Indicator) => isMinor(ind.priority),
);

export const indicators = createSelector(
  selectReferenceData,
  (referenceData: ReferenceData) => referenceData?.indicators,
);

export const wasteStream = indicator(UnclassifiedIndicator.WASTE_STREAM);

export const isWasteStreamMinor = isIndicatorMinor(UnclassifiedIndicator.WASTE_STREAM);
export const isWasteStreamPresent = isIndicatorPresent(UnclassifiedIndicator.WASTE_STREAM);

export const isPlasticFootprintMinor = isIndicatorMinor(UnclassifiedIndicator.PLASTIC_FOOTPRINT);

export const isPlasticFootprintPresent = isIndicatorPresent(UnclassifiedIndicator.PLASTIC_FOOTPRINT);

export const isMaterialCircularityMinor = isIndicatorMinor(UnclassifiedIndicator.MATERIAL_CIRCULARITY);
export const isMaterialCircularityPresent = isIndicatorPresent(UnclassifiedIndicator.MATERIAL_CIRCULARITY);
