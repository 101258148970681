import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { positive } from '../../../shared/validators/positive.validator';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store';
import * as fromReferenceData from '../../../store/reference-data/store';
import { arrayNotEmpty } from '../../../shared/rxjs-utils/rxjsFilters';
import { dropdownValidator } from '../../../shared/validators/dropdown-validator';
import { filter, map, take } from 'rxjs/operators';
import { Transport } from '../models/distribution-market';
import { WithLabelAndValue } from '../../../store/reference-data/models/reference-data.model';
import { TransportType } from '../../../project/modeling/models/transport.models';

@Component({
  selector: 'app-transport-form',
  template: `
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <app-string-selector label="Transport type"
                               [value]="form.controls.type?.value?.value"
                               [options]="transportTypeValues$ | async"
                               [disabled]="form.disabled"
                               (changeValue)="handleChangeTransportType($event)"
                               [required]="true"
          ></app-string-selector>
        </div>
        <div class="col">
          <mat-form-field id="distance">
            <mat-label i18n>Distance</mat-label>
            <input
              autocomplete="off"
              formControlName="distance"
              id="distanceInput"
              matInput
              type="number"
            >
            <span matSuffix>km</span>
            <mat-error *ngIf="form.controls?.distance.hasError('required')" i18n>
              Distance is required
            </mat-error>
            <mat-error *ngIf="form.controls?.distance.hasError('positiveError')" i18n>
              Distance should be greater than zero
            </mat-error>
          </mat-form-field>
        </div>
        <button (pointerup)="remove()" *ngIf="form.enabled" color="warn" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </form>
  `,
  styles: [ `
    :host {
      width: 100%;
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-evenly;
    }
  ` ]
})
export class TransportFormComponent implements OnInit, OnDestroy {

  form = this.formBuilder.group({
    type: null,
    distance: [ null, { validators: [ Validators.required, positive ], updateOn: 'blur' } ],
  });
  transportTypes$: Observable<TransportType[]>;
  transportTypeValues$: Observable<string[]>;
  subscription: Subscription = new Subscription();

  @Input()
  set transport(transport: Transport) {
    this.setFormValue(transport);
  }

  @Input()
  set isLockedByMe(isLockedByMe: boolean) {
    if (!isLockedByMe) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
  }

  @Output() changeTransport: EventEmitter<Transport> = new EventEmitter();
  @Output() removeTransport: EventEmitter<void> = new EventEmitter();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store<AppState>,
  ) {
  }

  blur() {
    (document.activeElement as HTMLElement).blur();
  }

  ngOnInit(): void {
    this.subscription.add(this.setFormValidators());
    this.subscription.add(this.formChangeListener());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  remove(): void {
    this.removeTransport.emit();
  }

  private setFormValue(transport: Transport): void {
    this.form.setValue({
      type: transport.type ?? null,
      distance: transport.distance ?? null,
    }, { emitEvent: false });
  }

  private setFormValidators(): Subscription {
    const { type } = this.form.controls;
    this.transportTypes$ = this.store.pipe(select(fromReferenceData.selectTransportTypes), arrayNotEmpty);
    this.transportTypeValues$ = this.transportTypes$.pipe(
      map(types => types.map(it => it.value))
    );
    return this.transportTypes$.subscribe(types => {
      type.setValidators([ dropdownValidator(types), Validators.required ]);
    });
  }

  private formChangeListener(): Subscription {
    return this.form.valueChanges
      .pipe(filter(() => this.form.valid))
      .subscribe((transport) => {
        this.changeTransport.emit(transport);
      });
  }

  handleChangeTransportType(value: string | undefined) {
    const sub = this.transportTypes$.pipe(take(1))
      .subscribe((values: WithLabelAndValue[]) => {
        const newValue = values.filter((v) => v.value === value)[0];
        this.form.controls.type.setValue(newValue, { emitEvent: true });
      });
    sub.unsubscribe();
  }
}
