import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Packaging } from '../models/packaging.models';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PackagingMapperService } from './packaging-mapper.service';
import { map } from 'rxjs/operators';
import { PackagingCompleteDto } from '../../../api/dtos/__generated__/packagingCompleteDto';

@Injectable({
  providedIn: 'root',
})
export class PackagingDataService {

  PACKAGING_URL = `${environment.apiUrl}/packagings`;

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: PackagingMapperService,
  ) { }

  create(packaging: Packaging): Observable<Packaging> {
    const dto = this.mapper.mapPackagingCreationRequestDtoFromPackaging(packaging);
    return this.http.post(this.PACKAGING_URL, dto)
      .pipe(
        map((response: PackagingCompleteDto) => this.mapper.mapPackagingFromPackagingCompleteDto(response)),
      );
  }

  update(packaging: Packaging): Observable<Packaging> {
    const dto = this.mapper.mapPackagingMetadataDtoFromPackaging(packaging);
    return this.http.put(`${this.PACKAGING_URL}/${packaging.id}`, dto)
      .pipe(
        map((response: PackagingCompleteDto) => this.mapper.mapPackagingFromPackagingCompleteDto(response)),
      );
  }

  delete(id: string): Observable<string> {
    return this.http.delete(`${this.PACKAGING_URL}/${id}`).pipe(
      map(() => id)
    );
  }
}
