import { Injectable } from '@angular/core';
import { PortfolioImports } from '../models/portfolio-imports';
import { PortfolioImportScenariosDto } from '../../api/dtos/__generated__/portfolioImportScenariosDto';

@Injectable({
  providedIn: 'root',
})
export class PortfolioImportsMapperService {
  to(object: PortfolioImports): PortfolioImportScenariosDto {
    return {
      imports: Array.from(object.publishedScenarioIds, v => ({
        fromPublishedScenarioId: v
      })),
    };
  }
}
