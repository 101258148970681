export type LCAIndicator =
  'IND_IMPACT_CC'
  | 'IND_IMPACT_OD'
  | 'IND_IMPACT_IR'
  | 'IND_IMPACT_PO'
  | 'IND_IMPACT_PM'
  | 'IND_IMPACT_HC'
  | 'IND_IMPACT_HN'
  | 'IND_IMPACT_AC'
  | 'IND_IMPACT_EF'
  | 'IND_IMPACT_EM'
  | 'IND_IMPACT_ET'
  | 'IND_IMPACT_EC'
  | 'IND_IMPACT_LU'
  | 'IND_IMPACT_RF'
  | 'IND_IMPACT_RM'
  | 'IND_IMPACT_WS';

export const LCAIndicator = {
  CC: 'IND_IMPACT_CC' as LCAIndicator,
  OD: 'IND_IMPACT_OD' as LCAIndicator,
  IR: 'IND_IMPACT_IR' as LCAIndicator,
  PO: 'IND_IMPACT_PO' as LCAIndicator,
  PM: 'IND_IMPACT_PM' as LCAIndicator,
  HC: 'IND_IMPACT_HC' as LCAIndicator,
  HN: 'IND_IMPACT_HN' as LCAIndicator,
  AC: 'IND_IMPACT_AC' as LCAIndicator,
  EF: 'IND_IMPACT_EF' as LCAIndicator,
  EM: 'IND_IMPACT_EM' as LCAIndicator,
  ET: 'IND_IMPACT_ET' as LCAIndicator,
  EC: 'IND_IMPACT_EC' as LCAIndicator,
  LU: 'IND_IMPACT_LU' as LCAIndicator,
  RF: 'IND_IMPACT_RF' as LCAIndicator,
  RM: 'IND_IMPACT_RM' as LCAIndicator,
  WS: 'IND_IMPACT_WS' as LCAIndicator,
};

export const isLCAIndicator = (value: string): boolean => {
  if (typeof value !== 'string') {
    return false;
  }
  return !!Object.values(LCAIndicator).find(ind => ind.localeCompare(value.toUpperCase()) === 0);
};
