import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfDialogComponent } from './pdf-dialog.component';


@NgModule({
  declarations: [
    PdfDialogComponent,
  ],
  imports: [
    CommonModule,
    NgxExtendedPdfViewerModule,
  ],
  exports: [
    PdfDialogComponent,
  ],
})
export class DocumentationModule {}
