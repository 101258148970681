import { Injectable } from '@angular/core';
import { WashingProcess } from '../models/washing-process.models';
import { WashingProcessTypeDto } from '../../../api/dtos/__generated__/washingProcessTypeDto';
import { WashingProcessDto } from '../../../api/dtos/__generated__/washingProcessDto';
import { WashingProcessType } from '../../../store/reference-data/models/reference-data.model';

@Injectable({
  providedIn: 'root',
})
export class WashingProcessMapperService {

  constructor() { }

  mapWashingProcessFromWashingProcessDto(dto: WashingProcessDto): WashingProcess {
    const {
      id,
      componentId,
      electricityType,
      electricityCountry,
      washingProcessType,
      volume,
    } = dto;

    return {
      id,
      volume,
      componentId,
      electricity: electricityType,
      country: {
        label: electricityCountry?.label,
        value: electricityCountry?.value,
      },
      type: this.mapWashingProcessType(washingProcessType),
    };
  }

  mapWashingProcessListFromWashingProcessDtoList(dtos: WashingProcessDto[]): WashingProcess[] {
    return dtos.map((dto: WashingProcessDto) => this.mapWashingProcessFromWashingProcessDto(dto));
  }

  mapWashingProcessDtoFromWashingProcess(fp: WashingProcess): WashingProcessDto {
    const {
      country,
      electricity,
      type,
      volume,
      id,
      componentId,
    } = fp;

    return {
      electricityCountry: country,
      electricityType: electricity,
      washingProcessType: this.mapWashingProcessTypeDto(type),
      volume: volume,
      id,
      componentId,
    } as WashingProcessDto;
  }

  private mapWashingProcessType(dto: WashingProcessTypeDto): WashingProcessType {
    const { label, value } = dto;
    return { label, value } as WashingProcessType;
  }

  private mapWashingProcessTypeDto(type: WashingProcessType): WashingProcessTypeDto {
    const { label, value } = type;
    return { label, value } as WashingProcessTypeDto;
  }
}
