import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { AppState } from '../store';
import { Store } from '@ngrx/store';
import { catchError, map } from 'rxjs/operators';
import { Auth } from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad {

  constructor(private store: Store<AppState>, private router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return from(Auth.currentSession())
      .pipe(map(_ => true),
        catchError(_ => of(this.router.createUrlTree([ 'login' ],
          { queryParams: { redirectUrl: this.router.url } }))));

  }
}
