import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-multiple-selector',
  template: `
    <mat-form-field class="select-autocomplete">
      <mat-label>{{ label }}</mat-label>
      <mat-select [value]="value" [formControl]="selectControl" multiple (selectionChange)="changeValue.emit($event.value)">
        <mat-option>
          <ngx-mat-select-search [formControl]="optionFilter" placeholderLabel="Search"
                                 noEntriesFoundLabel="No results"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let option of filteredOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>
  `,
  styles: [ `
    :host {
      width: 100%
    }

    .mat-form-field {
      margin-top: 0;
    }
  ` ]
})
export class MultipleSelectorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() label!: string;
  @Input() options!: string[];
  @Input() value!: string[];
  @Output() changeValue = new EventEmitter<string | undefined>();
  selectControl = new FormControl();
  optionFilter = new FormControl();
  filteredOptions: string[] = [];
  subscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.subscription.add(this.optionFilter.valueChanges.subscribe(() => this.filterElements()));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filteredOptions = [ ...this.options ];
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private filterElements(): void {
    if (this.options.length === 0) {
      return;
    }
    const search = (this.optionFilter.value as string)?.toLowerCase();
    if (search === undefined) {
      this.filteredOptions = [ ...this.options ];
      return;
    }
    this.filteredOptions = this.options.filter(option => option.toLowerCase().indexOf(search) > -1);
  }
}
