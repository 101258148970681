import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminHomePageComponent } from './admin-home-page.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MenuModule } from '../../../menu/menu.module';
import { AdminMenuModule } from '../../components/admin-menu/admin-menu.module';
import { AdminHeaderModule } from '../../components/admin-header/admin-header.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    AdminHomePageComponent,
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    MenuModule,
    AdminMenuModule,
    AdminHeaderModule,
    RouterModule,
  ],
  exports: [
    AdminHomePageComponent,
  ],
})
export class AdminHomePageModule {}
