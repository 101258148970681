import { createAction, props } from '@ngrx/store';
import { WashingProcess } from '../../models/washing-process.models';
import { Country } from '../../../../store/reference-data/models/reference-data.model';
import { ElectricityType } from '../../models/electricity.model';

const WASHING_PROCESS_SIDE_EFFECT_ACTION = '[WashingProcess/API]';
const WASHING_ACTION = '[WashingProcess]';


export const addWashingProcess = createAction(
  `${WASHING_PROCESS_SIDE_EFFECT_ACTION} Add Washing Process`,
  props<{ washingProcess: WashingProcess }>(),
);

export const addWashingProcessSuccess = createAction(
  `${WASHING_PROCESS_SIDE_EFFECT_ACTION} Add Washing Process Success`,
  props<{ washingProcess: WashingProcess }>(),
);

export const getWashingProcessesSuccess = createAction(
  `${WASHING_PROCESS_SIDE_EFFECT_ACTION} Add Washing Processes Success`,
  props<{ washingProcesses: WashingProcess[] }>(),
);

export const updateWashingProcess = createAction(
  `${WASHING_PROCESS_SIDE_EFFECT_ACTION} Update Washing Process`,
  props<{ washingProcess: WashingProcess }>(),
);

export const updateWashingProcessSuccess = createAction(
  `${WASHING_PROCESS_SIDE_EFFECT_ACTION} Update Washing Process Success`,
  props<{ washingProcess: WashingProcess }>(),
);

export const deleteWashingProcess = createAction(
  `${WASHING_PROCESS_SIDE_EFFECT_ACTION} Delete Washing Process`,
  props<{ id: string }>(),
);

export const deleteWashingProcessSuccess = createAction(
  `${WASHING_PROCESS_SIDE_EFFECT_ACTION} Delete Washing Process Success`,
  props<{ id: string }>(),
);

export const washingProcessError = createAction(
  `${WASHING_PROCESS_SIDE_EFFECT_ACTION} Washing Process Error`,
  props<{ message: string }>(),
);

export const updateCountry = createAction(
  `${WASHING_ACTION} Update Country`,
  props<{ id: string, country: Country }>(),
);

export const updateElectricityType = createAction(
  `${WASHING_ACTION} Update Electricity Type`,
  props<{ id: string, electricityType: ElectricityType }>(),
);

export const deleteAll = createAction(
  `${WASHING_ACTION} Delete All Washing Processes of Given Component`,
  props<{ componentId: string }>(),
);
