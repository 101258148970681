import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PublishedScenario } from '../models/published-scenario.model';
import { Subscription } from "rxjs";

interface ScenarioFilters {
  search?: string;
  brand?: string[];
  packagingType?: string[];
  baseMaterial?: string[];
  productType?: string[];
  status?: string[];
  productionCountry?: string[];
}

@Component({
  selector: 'app-scenario-filters',
  template: `
    <div id="scenario-filter-search">
      <app-string-input label="Search" (changeValue)="handleChangeValue('search', $event)"></app-string-input>
    </div>
    <div id="scenario-filter-selectors">
      <ng-container *ngFor="let selector of scenarioFiltersSelectors">
        <app-multiple-selector label="{{selector.label}}"
                               [options]="selector.options"
                               [value]="filters[selector.field]"
                               (changeValue)="handleChangeValue(selector.field, $event)"></app-multiple-selector>
      </ng-container>
    </div>
    <mat-chip-list>
      <ng-container *ngFor="let selector of scenarioFiltersSelectors">
        <mat-chip *ngIf="filters[selector.field]" [removable]="true" [selectable]="false" class="custom-chip-size"
                  (removed)="handleChangeValue(selector.field, undefined)">
          {{filters[selector.field]}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </ng-container>
    </mat-chip-list>
  `,
  styles: [ `
    :host {
      display: block;
      margin-bottom: 2rem;
    }

    #scenario-filter-selectors {
      display: flex
    }

    #scenario-filter-search {
      padding: 0 1rem;
    }

    app-multiple-selector {
      padding: 0 1rem;
    }

    .custom-chip-size {
      padding: 0 8px;
      min-height: 20px;
      font-size: 12px;
    }
  ` ],
})
export class ScenarioFiltersComponent implements OnInit, OnDestroy {

  scenarioFiltersSelectors: { field: string; label: string; options: string[] }[] = [];
  filters: ScenarioFilters = {};
  @Input() dataSource: MatTableDataSource<PublishedScenario>;
  subscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.dataSource.filterPredicate = (record: PublishedScenario, filter: string) => {
      const filters = new Object(JSON.parse(filter)) as ScenarioFilters;
      return (!filters.search || (filters.search && (
          record.name.toLowerCase().includes(filters.search.toLowerCase()) ||
          record.reference?.toLowerCase().includes(filters.search.toLowerCase())
        ))) &&
        (filters.brand === undefined || (filters.brand && filters.brand.includes(record.brandType?.value))) &&
        (filters.packagingType === undefined || (filters.packagingType && filters.packagingType.includes(record.packagingType?.value))) &&
        (filters.baseMaterial === undefined || (filters.baseMaterial && filters.baseMaterial.includes(record.baseMaterialType?.value))) &&
        (filters.productType === undefined || (filters.productType && filters.productType.includes(record.productType?.value))) &&
        (filters.status === undefined || (filters.status && filters.status.includes(record.statusType?.value))) &&
        (filters.productionCountry === undefined || (filters.productionCountry && filters.productionCountry.includes(record.productionCountry?.value)));
    };
    this.subscription.add(this.dataSource.connect().subscribe(() => this.setScenarioFiltersSelectors()));
  }

  handleChangeValue(field: string, value: string | undefined) {
    this.filters[field] = value;
    this.dataSource.filter = JSON.stringify(this.filters);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setScenarioFiltersSelectors() {
    this.scenarioFiltersSelectors = [
      { field: 'brand', label: 'Brand', options: this.getValues('brandType') },
      { field: 'packagingType', label: 'Packaging type', options: this.getValues('packagingType') },
      { field: 'baseMaterial', label: 'Base material', options: this.getValues('baseMaterialType') },
      { field: 'productType', label: 'Product type', options: this.getValues('productType') },
      { field: 'status', label: 'Progress status', options: this.getValues('statusType') },
      { field: 'productionCountry', label: 'Production country', options: this.getValues('productionCountry') },
    ];
  }

  getValues(field: string): string[] {
    const values = this.dataSource.data.map(scenario => scenario[field]?.value).filter(v => v !== undefined);
    return [ ...new Set(values) ].sort();
  }
}
