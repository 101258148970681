import { Component, ViewChild } from '@angular/core';
import { ScenarioLibraryTableComponent } from '../scenario-library-table/scenario-library-table.component';
import { PublishedScenarioColumnKeys } from '../scenario-library-table/published-scenario-table.data';

@Component({
  template: `
    <div mat-dialog-title>
      <h2 data-testid="title" i18n>Import scenarios from the library</h2>
    </div>
    <mat-dialog-content>
      <app-scenario-library-table
        dialog="true"
        [displayedColumns]="displayedColumns"
        data-testid="scenario-library-table"
      ></app-scenario-library-table>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        data-testid="cancel-button"
        i18n
        mat-dialog-close
        mat-stroked-button
        type="button">Cancel
      </button>
      <button
        [disabled]="scenarioTable?.selection?.isEmpty()"
        [mat-dialog-close]="scenarioTable?.selection?.selected"
        class="border-primary"
        color="primary"
        data-testid="import-button"
        i18n
        mat-stroked-button
        type="submit">Import
      </button>
    </mat-dialog-actions>
  `
})
export class ImportScenarioDialogComponent {

  readonly displayedColumns: PublishedScenarioColumnKeys[] = [
    'selected',
    'name',
    'publishedBy',
    'publishedAt',
    'brandType',
    'reference',
    'packagingType',
    'baseMaterialType',
    'productType',
    'statusType',
    'productionCountry',
  ];

  @ViewChild(ScenarioLibraryTableComponent)
  scenarioTable: ScenarioLibraryTableComponent;
}
