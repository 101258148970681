<app-scenario-filters *ngIf="dataSource" [dataSource]="dataSource"></app-scenario-filters>

<mat-table
  *ngIf="dataSource"
  [dataSource]="dataSource"
  [trackBy]="trackBy"
  data-testid="mat-table"
  matSort
  matSortActive="publishedAt"
  matSortDirection="desc"
  matSortDisableClear
>
  <ng-container matColumnDef="selected">
    <mat-header-cell *matHeaderCellDef>
      <mat-checkbox
        (change)="$event ? masterToggle() : null"
        [aria-label]="checkboxLabel()"
        [checked]="selection.hasValue() && isAllSelected()"
        [color]="selection.hasValue() ? 'primary' : 'accent'"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
        id="select-all"
      >
        <span style="display: none">{{checkboxLabel()}}</span>
      </mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let scenario">
      <mat-checkbox
        (change)="$event ? selection.toggle(scenario) : null"
        (click)="$event.stopPropagation()"
        [aria-label]="checkboxLabel(scenario)"
        [checked]="selection.isSelected(scenario)"
        [color]="selection.hasValue() ? 'primary' : 'accent'"
      >
      </mat-checkbox>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Name</mat-header-cell>
    <mat-cell *matCellDef="let scenario">{{scenario?.name}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="publicationStatus">
    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Publication status</mat-header-cell>
    <mat-cell *matCellDef="let scenario">{{scenario?.publicationStatus}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="publishedBy">
    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Published by</mat-header-cell>
    <mat-cell *matCellDef="let scenario" data-testid="publishedBy">{{scenario?.publishedBy | fullName }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="publishedAt">
    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Publication date</mat-header-cell>
    <mat-cell *matCellDef="let scenario"
              data-testid="publishedAt">{{scenario?.publishedAt | date: 'shortDate'}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="reference">
    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Reference</mat-header-cell>
    <mat-cell *matCellDef="let scenario">{{scenario?.reference}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="packagingType">
    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Packaging type</mat-header-cell>
    <mat-cell *matCellDef="let scenario">{{scenario?.packagingType?.value}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="baseMaterialType">
    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Base material</mat-header-cell>
    <mat-cell *matCellDef="let scenario">{{scenario?.baseMaterialType?.value}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="productType">
    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Product type</mat-header-cell>
    <mat-cell *matCellDef="let scenario">{{scenario?.productType?.value}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="statusType">
    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Progress status</mat-header-cell>
    <mat-cell *matCellDef="let scenario">{{scenario?.statusType?.value}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="productionCountry">
    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Production country</mat-header-cell>
    <mat-cell *matCellDef="let scenario">{{scenario?.productionCountry?.value}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="brandType">
    <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Brand</mat-header-cell>
    <mat-cell *matCellDef="let scenario">{{scenario?.brandType?.value}}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="background-accent"></mat-header-row>
  <mat-row
    (click)="$event ? selection.toggle(scenario) : null"
    *matRowDef="let scenario; columns: displayedColumns"
    id="scenario-{{scenario.id}}"
    matRipple
  ></mat-row>
</mat-table>
<mat-paginator [pageSizeOptions]="[10, 20, 50]" class="table-footer"></mat-paginator>
