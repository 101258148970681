import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  endOfLifeError,
  findAllEndOfLives,
  findAllEndOfLivesSuccess,
  updateEndOfLife,
  updateEndOfLifeSuccess
} from './end-of-life.actions';
import { EndOfLifeFeatureState } from './end-of-life.reducer';
import { EndOfLifeDataService } from '../services/end-of-life.data-service';
import { selectEndOfLifeMaterialId } from './end-of-life.selectors';

@Injectable({
  providedIn: 'root',
})
export class EndOfLifeEffects {

  findAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(findAllEndOfLives),
      mergeMap(({ materialId }) => this.endOfLifeDataService.findAll(materialId)
        .pipe(
          map((endOfLives) => findAllEndOfLivesSuccess({ endOfLives })),
          catchError(() => of(endOfLifeError({ error: 'Failed to fetch end of lives' }))),
        )
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEndOfLife),
      switchMap(({ id, endOfLife }) => this.endOfLifeDataService.update(id, endOfLife)
        .pipe(
          map((response) => updateEndOfLifeSuccess({ endOfLife: response })),
          catchError(() => of(endOfLifeError({ error: 'Failed to update end of life' }))),
        )
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly endOfLifeDataService: EndOfLifeDataService,
    private readonly store: Store<EndOfLifeFeatureState>,
  ) {
  }
}
