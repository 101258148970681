import { Injectable } from '@angular/core';
import { Material, MaterialTransport } from '../models/material.models';
import { MaterialCompleteDto } from '../../../api/dtos/__generated__/materialCompleteDto';
import { MaterialMetadataDto } from '../../../api/dtos/__generated__/materialMetadataDto';
import { MaterialTransportMapperService } from './material-transport-mapper.service';
import { ConversionProcess } from '../models/conversion-process.model';
import { ConversionProcessMapperService } from './conversion-process-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class MaterialMapperService {

  constructor(
    private readonly materialTransportMapperService: MaterialTransportMapperService,
    private readonly  conversionProcessMapperService: ConversionProcessMapperService,
  ) {
  }

  mapMaterialFromMaterialDto(dto: MaterialCompleteDto): Material {
    const {
      id,
      recycledContent,
      componentId,
      mass,
      materialType,
    } = dto;

    return {
      componentId,
      materialType,
      recycledContent,
      mass,
      id,
    } as Material;
  }

  mapMaterialListFromMaterialDtoList(dtos: MaterialCompleteDto[]): Material[] {
    return dtos.map((dto) => this.mapMaterialFromMaterialDto(dto));
  }

  mapMaterialMetadataDtoFromMaterial(material: Material): MaterialMetadataDto {
    const {
      mass,
      recycledContent,
      materialType,
      id,
      componentId,
      recyclingRateType,
      compostable,
      recyclable,
      recyclingRate
    } = material;

    return {
      componentId,
      compostable,
      id,
      mass,
      materialType,
      recyclable,
      recycledContent,
      recyclingRate,
      recyclingRateType
    } as MaterialMetadataDto;
  }

  mapMaterialTransportListFromMaterialDto(dto: MaterialCompleteDto): MaterialTransport[] {
    return this.materialTransportMapperService.mapMaterialTransportListFromMaterialTransportDtoList(dto.transports);
  }

  mapMaterialTransportListFromMaterialDtoList(dtos: MaterialCompleteDto[]): MaterialTransport[] {
    return dtos.flatMap((dto) => this.mapMaterialTransportListFromMaterialDto(dto));
  }

  mapConversionProcessListFromMaterialCompleteDto(material: MaterialCompleteDto): ConversionProcess[] {
    return material.conversionProcesses.map((dto) => this.conversionProcessMapperService.mapConversionProcessFromConversionProcessDto(dto));
  }
}
