import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  private PROVIDER_NAME = 'providerName';
  private CLIENT_ID = 'clientId';

  private providerNameSubject = new ReplaySubject<string>();

  get clientId(): string {
    return localStorage.getItem(this.CLIENT_ID);
  }

  set clientId(value: string) {
    localStorage.setItem(this.CLIENT_ID, value);
  }

  get providerName(): string {
    return localStorage.getItem(this.PROVIDER_NAME);
  }

  set providerName(value: string) {
    localStorage.setItem(this.PROVIDER_NAME, value);
    this.providerNameSubject.next(value);
  }

  constructor() {
    const providerName = localStorage.getItem(this.PROVIDER_NAME);
    if (providerName) {
      this.providerNameSubject.next(providerName);
    }
  }

  getProviderName(): Observable<string> {
    return this.providerNameSubject.asObservable();
  }

  clear(): void {
    localStorage.removeItem(this.PROVIDER_NAME);
  }
}
