<mat-card data-testid="container">
  <mat-table
    *ngIf="dataSource"
    [dataSource]="dataSource"
    [trackBy]="trackBy"
    data-testid="mat-table"
    matSort
    matSortActive="version"
    matSortDirection="desc"
    matSortDisableClear
  >
    <ng-container matColumnDef="default">
      <mat-header-cell *matHeaderCellDef i18n>Default</mat-header-cell>
      <mat-cell *matCellDef="let version">
        <mat-checkbox
          (change)="$event ? selection.toggle(version) : null"
          (click)="$event.stopPropagation()"
          [aria-label]="version.displayName"
          [checked]="selection.isSelected(version)"
          [color]="selection.hasValue() ? 'primary' : 'accent'"
        >
        </mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="version">
      <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Version</mat-header-cell>
      <mat-cell *matCellDef="let version">{{ version.displayName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="releaseDate">
      <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Release date</mat-header-cell>
      <mat-cell *matCellDef="let version">{{ version.releaseDate }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="backgroundDatabase">
      <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Background database</mat-header-cell>
      <mat-cell *matCellDef="let version">{{ version.backgroundDatabase }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Description</mat-header-cell>
      <mat-cell *matCellDef="let version">{{ version.description }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="background-accent"></mat-header-row>
    <mat-row
      (click)="selection.isSelected(version) ? doNothing() : setDefaultVersion(version)"
      *matRowDef="let version; columns: displayedColumns"
      id="version-{{version.hash}}"
      matRipple
    ></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[10, 20, 50]" class="table-footer"></mat-paginator>
</mat-card>
