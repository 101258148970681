import { Component, OnDestroy, OnInit } from '@angular/core';
import { Scenario } from '../../../project/modeling/models/scenario.model';
import { Project } from '../../../project/modeling/models/project.model';
import { select, Store } from '@ngrx/store';
import * as fromScenario from '../../../project/modeling/store/scenario';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppState } from '../../../store';
import { getProject } from "../../../project/modeling/store/project";

@Component({
  templateUrl: './projects-page.component.html',
  styleUrls: [ './projects-page.component.scss' ],
})
export class ProjectsPageComponent implements OnInit, OnDestroy {

  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly store: Store<AppState>,
  ) { }

  ngOnInit(): void {
  }

  goToProjectScenario(scenario: Scenario) {
    if (!scenario.projectId) {
      return;
    }
    this.router.navigate([ `/projects/${scenario.projectId}/scenarios/${scenario.id}` ]);
  }

  goToProject(project: Project) {
    if (!project.id) {
      return;
    }
    this.store.dispatch(getProject({ id: project.id }));
    this.subscription.add(
      this.store.pipe(
        select(fromScenario.selectFirstScenario(project.id)),
        filter((s: Scenario) => !!s),
      ).subscribe((scenario: Scenario) => {
        this.router.navigate([ `/projects/${project.id}/scenarios/${scenario.id}` ])
        },
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
