import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../shared/snackbar/snackbar.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readonly UNEXPECTED_EVENT_MESSAGE = 'Sorry, an unexpected event occurred, please try again later or contact support';

  constructor(private readonly snackBar: MatSnackBar) { }

  warn(message?: string, error?: HttpErrorResponse): void {
    const warnMessage = this.buildWarnMessage(message, error);
    console.warn(error ?? warnMessage);
    this.openSnackBar(warnMessage);
  }

  warnAndRethrow(props: { message?: string, error?: HttpErrorResponse }): Observable<any> {
    this.warn(props?.message, props?.error);
    throw props.error;
  }

  featureNotYetAvailable(): void {
    this.openSnackBar('This feature is not available yet.');
  }

  private openSnackBar(message: string): void {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      duration: 5000,
    });
  }

  private buildWarnMessage(message?: string, error?: HttpErrorResponse): string {
    return message ?? error?.error?.message ?? this.UNEXPECTED_EVENT_MESSAGE;
  }
}
