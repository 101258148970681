import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {

  count = 0;
  requestOngoing: Subject<boolean> = new Subject();

  constructor() { }

  loading() {
    this.count++;
    this.requestOngoing.next(true);
  }

  complete() {
    this.count--;
    if (this.count === 0) {
      this.requestOngoing.next(false);
    }
  }

  isLoading(): Subject<boolean> {
    return this.requestOngoing;
  }
}
