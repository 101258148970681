import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScenarioLibraryPageComponent } from './scenario-library-page.component';
import { ScenarioLibraryRoutingModule } from './scenario-library-routing.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HomePageHeaderModule } from '../../../project/pages/home-page/home-page-header/home-page-header.module';
import { SharedModule } from '../../../shared/shared.module';
import { ScenarioLibraryTableModule } from '../../../scenario-library/scenario-library-table/scenario-library-table.module';
import { ScenarioLibraryTableHeaderModule } from '../../../scenario-library/scenario-library-table-header/scenario-library-table-header.module';
import { MatCardModule } from '@angular/material/card';


@NgModule({
  declarations: [
    ScenarioLibraryPageComponent,
  ],
  imports: [
    CommonModule,
    ScenarioLibraryRoutingModule,
    MatSidenavModule,
    HomePageHeaderModule,
    ScenarioLibraryTableModule,
    SharedModule,
    ScenarioLibraryTableHeaderModule,
    MatCardModule,
  ],
})
export class ScenarioLibraryPageModule {}
