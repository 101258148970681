import { createFeatureSelector, createSelector } from '@ngrx/store';
import { distributionMarketKey, DistributionMarketState } from './distribution-market.reducer';
import { DistributionMarket } from '../models/distribution-market';

export const selectDistributionMarketState = createFeatureSelector<DistributionMarketState>(distributionMarketKey)
export const selectDistributionMarkets = createSelector(
  selectDistributionMarketState,
  (state): DistributionMarket[] => state.distributionMarkets
);
export const selectDistributionMarketLoading = createSelector(
  selectDistributionMarketState,
  (state): boolean => state.loading
);
export const selectDistributionMarketError = createSelector(
  selectDistributionMarketState,
  (state): string => state.error
);
export const selectDistributionMarketScenarioId = createSelector(
  selectDistributionMarketState,
  (state): string | undefined => state.scenarioId
);
