import { Injectable } from '@angular/core';
import { DownstreamCompleteDto } from '../../../api/dtos/__generated__/downstreamCompleteDto';
import { DownstreamMetadataDto } from '../../../api/dtos/__generated__/downstreamMetadataDto';
import { Downstream } from '../models/downstream';

@Injectable({
  providedIn: 'root',
})
export class DownstreamMapperService {

  constructor() {
  }

  mapDownstreamFromDownstreamCompleteDto(dto: DownstreamCompleteDto): Downstream {
    if (!dto) {
      return;
    }
    const {
      id,
      stackable,
      height,
      depth,
      length,
      scenarioId,
    } = dto;

    return {
      id,
      stackable,
      height,
      depth,
      length,
      scenarioId,
    } as Downstream;
  }

  mapDownstreamMetadataDtoFromDownstream(downstream: Downstream): DownstreamMetadataDto {
    if (!downstream) {
      return;
    }
    const {
      id,
      stackable,
      height,
      depth,
      length,
      scenarioId,
    } = downstream;

    return {
      id,
      stackable,
      height,
      depth,
      length,
      scenarioId,
    } as DownstreamMetadataDto;
  }
}
