import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { VersionsDataDataService } from '../versions-data.data-service';
import { Action } from '@ngrx/store';
import {
  getVersions,
  getVersionsError,
  getVersionsSuccess,
  setDefaultVersion,
  setDefaultVersionError,
  setDefaultVersionSuccess
} from './versions-data.actions';
import { of, pipe, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Version, Versions } from '../models/versions-data.model';

@Injectable({
  providedIn: 'root'
})
export class VersionsDataEffects implements OnInitEffects {
  getVersionsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getVersions.type),
      switchMap(() => this.versionsDataService.get()
        .pipe(
          map((versions: Versions) => getVersionsSuccess({ versions })),
          catchError(() => of(getVersionsError({ message: 'Could not fetch versions data' }))),
        )
      ),
    )
  );

  setDefaultVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDefaultVersion.type),
      pipe(
        map((action: { type: string, version: Version }) => action.version),
        switchMap((version) => this.versionsDataService.setDefaultVersion(version)
          .pipe(
            map((actual) => setDefaultVersionSuccess({ version: actual })),
            catchError(() => of(setDefaultVersionError({ message: 'Could not set default version' })))
          ),
        )
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly versionsDataService: VersionsDataDataService,
  ) {
  }

  ngrxOnInitEffects(): Action {
    return getVersions();
  }
}
