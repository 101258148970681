import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { Auth } from '@aws-amplify/auth';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';


if (environment.production) {
  Sentry.init({
    dsn: 'https://47dc3c13be4b468fb91d4d0773877534@o461389.ingest.sentry.io/5534089',
    environment: environment.name,
    enabled: environment.enableSentry,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [environment.apiUrl],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
  enableProdMode();
}

Auth.configure(environment.Auth);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
