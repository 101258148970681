<div class="col">
  <h2 i18n>Scenario library</h2>
</div>
<div class="col">
  <h3 i18n>Update publication status of selected
    <span [ngPlural]="numberOfSelectedScenarios">
      <ng-template ngPluralCase="=1">scenario</ng-template>
      <ng-template ngPluralCase="other">scenarios</ng-template>
    </span>
    ({{numberOfSelectedScenarios}})
  </h3>
  <div id="action-buttons">
    <app-action-button
      (click)="approveSelected()"
      [disabled]="selectionModel?.isEmpty()"
      data-testid="approve-button"
    >
      Approved
    </app-action-button>
    <app-action-button
      (click)="rejectSelected()"
      [disabled]="selectionModel?.isEmpty()"
      color="primary"
      data-testid="reject-button"
    >
      Rejected
    </app-action-button>
    <app-action-button
      (click)="setSelectedToPending()"
      [disabled]="selectionModel?.isEmpty()"
      color="primary"
      data-testid="set-to-pending-button"
    >
      Pending
    </app-action-button>
    <app-action-button
      (click)="deleteSelected()"
      [disabled]="selectionModel?.isEmpty()"
      color="warn"
      data-testid="delete-button"
    >
      Deleted
    </app-action-button>
  </div>
</div>
