import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { Packaging } from '../../../models/packaging.models';
import {
  addSecondaryPackaging,
  addSecondaryPackagingSuccess,
  deleteAll, deleteAllSuccess,
  deleteSecondaryPackaging,
  deleteSecondaryPackagingSuccess,
  getSecondaryPackagingSuccess,
  secondaryPackagingError,
  updateSecondaryPackaging,
  updateSecondaryPackagingSuccess, updateUsage,
} from './secondary-packaging.actions';

export const secondaryPackagingFeatureKey = 'secondaryPackaging';

export interface SecondaryPackagingState extends EntityState<Packaging> {
  loading: boolean;
  lastCreated: Packaging | null;
  error: string | null;
}

export const adapter: EntityAdapter<Packaging> = createEntityAdapter<Packaging>();

export const initialState: SecondaryPackagingState = adapter.getInitialState({
  loading: false,
  lastCreated: null,
  error: null,
});

const reducer: ActionReducer<SecondaryPackagingState, Action> = createReducer(
  initialState,
  on(
    addSecondaryPackaging,
    updateSecondaryPackaging,
    deleteSecondaryPackaging, (state: SecondaryPackagingState, action) =>
      ({ ...state, loading: true, error: null, lastCreated: null }),
  ),
  on(getSecondaryPackagingSuccess, (state: SecondaryPackagingState, action) =>
    adapter.upsertMany(action.packaging, { ...state, loading: false }),
  ),
  on(addSecondaryPackagingSuccess, (state: SecondaryPackagingState, action) =>
    adapter.addOne(action.packaging, { ...state, loading: false, lastCreated: action.packaging }),
  ),
  on(updateSecondaryPackagingSuccess, (state: SecondaryPackagingState, action) => {
      const { packaging } = action;
      const update: Update<Packaging> = {
        id: packaging.id,
        changes: packaging,
      };
      return adapter.updateOne(update, { ...state, loading: false });
    },
  ),
  on(updateUsage, (state: SecondaryPackagingState, action) => {
    const { id, usage } = action;
    const update: Update<Packaging> = {
      id,
      changes: { usage },
    };
    return adapter.updateOne(update, state);
  }),
  on(deleteSecondaryPackagingSuccess, (state: SecondaryPackagingState, action) =>
    adapter.removeOne(action.id, { ...state, loading: false }),
  ),
  on(secondaryPackagingError, (state: SecondaryPackagingState, action) =>
    ({ ...state, loading: false, error: action.message })),
  on(deleteAllSuccess, (state: SecondaryPackagingState, action: { scenarioId: string }) => {
    const { scenarioId } = action;
    const idsToDelete = Object.values(state.entities)
      .filter((p: Packaging) => p.scenarioId === scenarioId)
      .map((p: Packaging) => p.id);
    return adapter.removeMany(idsToDelete, state);
  }),
);

export function secondaryPackagingReducer(state: SecondaryPackagingState | undefined, action: Action) {
  return reducer(state, action);
}
