import { Component, OnInit } from '@angular/core';
import { AppState } from '../../store';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';

// SAML integration : https://github.com/smoghal/cognito-amplify-idp-auth

@Component({
  template: ``,
  selector: 'app-signin',
})
export class SigninComponent implements OnInit {


  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    this.router.navigateByUrl('/');
  }

}
