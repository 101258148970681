import { Injectable } from '@angular/core';
import { ComponentTransport, PackagingComponent } from '../models/component.models';
import { ComponentTypeDto } from '../../../api/dtos/__generated__/componentTypeDto';
import { ComponentCompleteDto } from '../../../api/dtos/__generated__/componentCompleteDto';
import { ComponentMetadataDto } from '../../../api/dtos/__generated__/componentMetadataDto';
import { ComponentSubtype, ComponentType } from '../../../store/reference-data/models/reference-data.model';
import { ComponentSubtypeDto } from '../../../api/dtos/__generated__/componentSubtypeDto';
import { ComponentTransportMapperService } from './component-transport-mapper.service';
import { WashingProcess } from '../models/washing-process.models';
import { WashingProcessMapperService } from './washing-process-mapper.service';

@Injectable({
    providedIn: 'root',
})
export class ComponentMapperService {

    constructor(
        private readonly componentTransportMapperService: ComponentTransportMapperService,
        private readonly washingProcessMapperService: WashingProcessMapperService,
    ) {
    }

    mapPackagingComponentFromComponentCompleteDto(dto: ComponentCompleteDto): PackagingComponent {
        const {
            id,
            level,
            name,
            numberOfUse,
            packagingId,
            reusable,
            componentSubtype,
            componentType,
            size,
        } = dto;

        return {
            id,
            packagingLevel: level,
            name,
            numberOfUse,
            reusable,
            type: this.mapTypeFromComponentTypeDto(componentType),
            subtype: this.mapSubTypeFromComponentSubtypeDto(componentSubtype),
            packagingId,
            size,
        } as PackagingComponent;
    }

    mapPackagingComponentListFromComponentCompleteDtoList(dtos: ComponentCompleteDto[]): PackagingComponent[] {
        return dtos.map((dto) => this.mapPackagingComponentFromComponentCompleteDto(dto));
    }

    mapComponentMetadataDtoFromPackagingComponent(component: PackagingComponent): ComponentMetadataDto {
        const {
            numberOfUse,
            reusable,
            subtype,
            type,
            name,
            id,
            packagingId,
            packagingLevel,
            size,
        } = component;

        return {
            componentSubtype: subtype,
            componentType: type,
            id,
            level: packagingLevel,
            name,
            numberOfUse,
            packagingId,
            reusable,
            size,
        } as ComponentMetadataDto;
    }

    private mapTypeFromComponentTypeDto(dto: ComponentTypeDto): ComponentType {
        return dto as ComponentType;
    }

    private mapSubTypeFromComponentSubtypeDto(dto: ComponentSubtypeDto): ComponentSubtype {
        return dto as ComponentSubtype;
    }

    mapComponentTransportListFromComponentDto(dto: ComponentCompleteDto): ComponentTransport[] {
        return this.componentTransportMapperService.mapComponentTransportListFromComponentTransportDtoList(dto.transports);
    }

    mapWashingProcessListFromComponentDto(dto: ComponentCompleteDto): WashingProcess[] {
        return dto.washingProcesses.map((wpDto) => this.washingProcessMapperService.mapWashingProcessFromWashingProcessDto(wpDto));
    }
}
