import { Pipe, PipeTransform } from '@angular/core';
import { PercentPipe } from '@angular/common';
import { PackagingDesignIndicator } from '../../project/assessment/models/packaging-design-indicator.model';
import { EMF_THRESHOLD } from '../../project/assessment/recyclability_threshold';

@Pipe({
  name: 'percentOrNan',
})
export class PercentOrNanPipe implements PipeTransform {


  constructor(private readonly percentPipe: PercentPipe) {}

  transform(value: number | string, ...args: unknown[]): number | string {
    if (value === 'NaN') {
      return '-';
    }
    const [ indicator ] = args;
    if (indicator && indicator === PackagingDesignIndicator.RECYCLABILITY) {
      const recyclability = this.percentPipe.transform(value, '.0-1').replace(',', '');
      if (value >= EMF_THRESHOLD) {
        return `Yes (${recyclability})`;
      }
      return `No (${recyclability})`;
    }
    return this.percentPipe.transform(value, '.0-1').replace(',', '');
  }

}
