import { PublishedScenario } from '../models/published-scenario.model';

export function publishedScenarioSortingDataAccessor(item: PublishedScenario, property: string): string {
  switch (property) {
    case 'packagingType':
      return item.packagingType?.value;
    case 'baseMaterialType':
      return item.baseMaterialType?.value;
    case 'brandType':
      return item.brandType?.value;
    case 'productType':
      return item.productType?.value;
    case 'statusType':
      return item.statusType?.value;
    case 'productionCountry':
      return item.productionCountry?.value;
    case 'salesCountry':
      return item.salesCountry?.value;
    case 'publishedBy':
      return item?.publishedBy?.firstname && item?.publishedBy?.lastname ?
        `${item?.publishedBy?.firstname} ${item?.publishedBy?.lastname}`
        : '';
    default:
      return item[property];
  }
}
