import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScenarioLibraryTableComponent } from './scenario-library-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '../../shared/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ScenarioLibraryMappersModule } from '../scenario-library-mappers/scenario-library-mappers.module';
import { CollaboratorModule } from '../../collaborator/collaborator.module';
import { MatCardModule } from '@angular/material/card';
import { ScenarioFiltersComponent } from './scenario-filters.component';
import { FormModule } from '../../shared/form/form.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ ScenarioLibraryTableComponent, ScenarioFiltersComponent ],
  imports: [
    CommonModule,
    MatCardModule,
    MatTableModule,
    MatRippleModule,
    MatInputModule,
    SharedModule,
    MatCheckboxModule,
    MatSortModule,
    MatPaginatorModule,
    ScenarioLibraryMappersModule,
    CollaboratorModule,
    FormModule,
    MatChipsModule,
    MatIconModule,
  ],
  exports: [ ScenarioLibraryTableComponent ],
})
export class ScenarioLibraryTableModule {}
