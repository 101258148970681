import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorDto } from '../../api/dtos/__generated__/errorDto';
import { LicenseStatus } from '../../shared/license-status.model';

@Component({
  templateUrl: './license-status-dialog.component.html',
  styleUrls: [],
})
export class LicenseStatusDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public readonly data: ErrorDto) {
  }

  ngOnInit(): void {
  }

  expired(): string {
    return LicenseStatus.EXPIRED;
  }

  notConfigured(): string {
    return LicenseStatus.NOT_CONFIGURED;
  }
}
