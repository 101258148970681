import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionPageComponent } from './version-page.component';
import { MatCardModule } from '@angular/material/card';
import { VersionModule } from '../../../version/version.module';


@NgModule({
  declarations: [ VersionPageComponent ],
  imports: [ CommonModule, MatCardModule, VersionModule ],
})
export class VersionPageModule {
}
