import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { TokenService } from '../token.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LocalStorageService } from '../../core/local-storage/local-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: [ './login.component.scss' ],
})
export class LoginComponent implements OnInit {

  private redirectUrl: string;
  providerRegex = '\\s*[a-z_\\-A-Z0-9]+\\s*';
  hasNoProvider: boolean;
  loginForm: FormGroup;
  errorMessages = {
    minlength: 'Company code must contain at least 4 characters',
    required: 'Company code is required',
    invalid_client_id: 'Company code not recognized',
    pattern: 'Company identification can contain alphanumeric characters only'
  };

  get company() {
    return this.loginForm.get('company');
  }

  constructor(
    private route: ActivatedRoute,
    private tokenService: TokenService,
    private localStorageService: LocalStorageService
  ) {
  }

  ngOnInit(): void {
    const params = this.route.snapshot.queryParams;
    if (params.redirectUrl) {
      this.redirectUrl = params.redirectUrl;
    }
    if (params.clientId) {
      return this.federatedSignIn(params.clientId);
    }
    const providerName = this.localStorageService.providerName;
    if (!!providerName) {
      return this.federatedSignIn(providerName);
    }
    this.hasNoProvider = true;

    this.loginForm = new FormGroup({
      company: new FormControl(this.localStorageService.clientId,
        [ Validators.required, Validators.minLength(4), Validators.pattern(this.providerRegex) ],
        this.validateCompanyClientId.bind(this))
    });
  }

  signIn() {
    if (this.loginForm.valid) {
      this.federatedSignIn(this.loginForm.get('company').value);
    }
  }

  federatedSignIn(clientId: string): void {
    this.tokenService.validate(trim(clientId)).subscribe(provider => {
      this.localStorageService.clientId = provider;
      this.localStorageService.providerName = provider;
      Auth.federatedSignIn({ customProvider: provider });
    });
  }

  validateCompanyClientId(control: FormControl): Observable<ValidationErrors | null> {
    return timer(300).pipe(switchMap(() => {
      return this.tokenService.validate(trim(control.value))
        .pipe(map(provider => !!provider ? null : { invalid_client_id: true }),
          catchError((err) => {
            return of({ invalid_client_id: true });
          }));
    }));
  }

  errors(ctrl: AbstractControl): string[] {
    return ctrl.errors ? Object.keys(ctrl.errors) : [];
  }
}

function trim(s: string): string {
  return !!s ? s.trim() : '';
}
