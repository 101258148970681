import { createAction, props } from '@ngrx/store';
import { Packaging, Usage } from '../../../models/packaging.models';

export const createFirstSecondaryPackaging = createAction(
  '[Secondary Packaging/API] Create First Secondary Packaging',
  props<{ scenarioId: string }>(),
);

export const createFirstSecondaryPackagingSuccess = createAction(
  '[Secondary Packaging/API] Create First Secondary Packaging Success',
  props<{ packaging: Packaging }>(),
);

export const addSecondaryPackaging = createAction(
  '[Secondary Packaging/API] Add Secondary Packaging',
  props<{ scenarioId: string }>(),
);

export const addSecondaryPackagingSuccess = createAction(
  '[Secondary Packaging/API] Add Secondary Packaging Success',
  props<{ packaging: Packaging }>(),
);

export const getSecondaryPackagingSuccess = createAction(
  '[Secondary Packaging/API] Get Secondary Packaging Success',
  props<{ packaging: Packaging[] }>(),
);

export const updateUsage = createAction(
  '[Secondary Packaging/API] Update Usage',
  props<{ id: string, usage: Usage }>(),
);

export const updateSecondaryPackaging = createAction(
  '[Secondary Packaging/API] Update Secondary Packaging',
  props<{ packaging: Packaging }>(),
);

export const updateSecondaryPackagingSuccess = createAction(
  '[Secondary Packaging/API] Update Secondary Packaging Success',
  props<{ packaging: Packaging }>(),
);

export const deleteSecondaryPackaging = createAction(
  '[Secondary Packaging/API] Delete Secondary Packaging',
  props<{ id: string, scenarioId: string }>(),
);

export const deleteSecondaryPackagingSuccess = createAction(
  '[Secondary Packaging/API] Delete Secondary Packaging Success',
  props<{ id: string }>(),
);

export const secondaryPackagingError = createAction(
  '[Secondary Packaging/API] Secondary Packaging Error',
  props<{ message: string }>(),
);

export const deleteAll = createAction(
  '[Secondary Packaging/API] Delete All Secondary Packaging Of Scenario',
  props<{ scenarioId: string }>(),
);

export const deleteAllSuccess = createAction(
  '[Secondary Packaging/API] Delete All Secondary Packaging Of Scenario Success',
  props<{ scenarioId: string }>(),
);
