import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ToggleMenuService } from '../../../menu/toggle-menu.service';

@Component({
  templateUrl: './admin-home-page.component.html',
  styleUrls: [ './admin-home-page.component.scss' ],
})
export class AdminHomePageComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();
  isMenuOpen$: Observable<boolean>;

  constructor(
    private readonly toggleMenuService: ToggleMenuService,
  ) {
  }

  ngOnInit(): void {
    this.isMenuOpen$ = this.toggleMenuService.isOpen();
  }

  toggleMenu() {
    this.toggleMenuService.toggle();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
