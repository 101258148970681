import { Injectable } from '@angular/core';
import { EntityReferenceMapperService } from './entity-reference-mapper.service';
import { AbstractMapper } from '../../abstract-mapper';
import { ScenarioMaterialCircularityAssessment } from '../models/scenario-material-circularity-assessment.model';
import {
  ScenarioMaterialCircularityAssessmentDto
} from '../../../api/dtos/__generated__/scenarioMaterialCircularityAssessmentDto';
import { UnclassifiedIndicator } from '../models/unclassified-indicator.model';

@Injectable({
  providedIn: 'root',
})
export class ScenarioMaterialCircularityAssessmentMapperService
  extends AbstractMapper<ScenarioMaterialCircularityAssessmentDto, ScenarioMaterialCircularityAssessment> {

  constructor(
    private readonly entityReferenceMapper: EntityReferenceMapperService,
  ) {
    super();
  }

  from(dto: ScenarioMaterialCircularityAssessmentDto ): ScenarioMaterialCircularityAssessment | undefined {
    if (!dto) {
      return;
    }

    const {
      scenario,
      functionalUnit,
      totalImpact,
      consumerPackImpact,
      salesPackImpact,
      tradePackImpact,
      transportPackImpact,
    } = dto;

    return {
      scenario: this.entityReferenceMapper.from(scenario),
      indicator: UnclassifiedIndicator.MATERIAL_CIRCULARITY,
      functionalUnit,
      totalImpact,
      consumerPackImpact,
      salesPackImpact,
      tradePackImpact,
      transportPackImpact,
    } as ScenarioMaterialCircularityAssessment;
  }
}
