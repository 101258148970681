import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Downstream } from '../models/downstream';
import { FormBuilder, FormGroup } from '@angular/forms';
import { updateDownstream } from '../store/downstream.actions';
import { selectDownstream } from '../../../project/modeling/store/scenario';

@Component({
  selector: 'app-downstream-card',
  template: `
    <mat-card>
      <mat-card-title>
        <mat-icon svgIcon="transport-unit"></mat-icon>
        <div id="transport-unit">
          <h2 i18n>Pallet dimensions (pallet included)</h2>
        </div>
      </mat-card-title>
      <mat-card-content *ngIf="downstream$ | async as downstream" [formGroup]="form" class="downstream-form">
        <input formControlName="id" name="id" type="hidden">
        <ol>
          <li class="before-border-primary">
            <mat-form-field>
              <mat-label i18n>Depth</mat-label>
              <input autocomplete="off" formControlName="depth" matInput type="number" required>
              <span matSuffix>m</span>
            </mat-form-field>
          </li>
          <li class="before-border-primary">
            <mat-form-field>
              <mat-label i18n>Length</mat-label>
              <input autocomplete="off" formControlName="length" matInput type="number" required>
              <span matSuffix>m</span>
            </mat-form-field>
          </li>
          <li class="before-border-primary">
            <mat-form-field>
              <mat-label i18n>Height</mat-label>
              <input autocomplete="off" formControlName="height" matInput type="number" required>
              <span matSuffix>m</span>
            </mat-form-field>
          </li>
          <div class="row stackable">
            <span class="stackable-prefix" i18n matPrefix>Is the pallet stackable?</span>
            <mat-slide-toggle color="primary" formControlName="stackable"></mat-slide-toggle>
            <ng-container *ngIf="!!form.controls.stackable.value; else no">
              <span class="stackable-suffix" i18n matPrefix>Yes</span>
            </ng-container>
            <ng-template #no>
              <span class="stackable-suffix" i18n matPrefix>No</span>
            </ng-template>
          </div>
        </ol>
        <div class="cube">
          <mat-icon class="cube-icon stroke-primary" color="primary" svgIcon="cube"></mat-icon>
        </div>
      </mat-card-content>
    </mat-card>
  `,
  styleUrls: [ './downstream-card.component.scss' ],
})
export class DownstreamCardComponent implements OnInit, OnDestroy {

  @Input() scenarioId: string;
  @Input()
  set isLockedByMe(isLockedByMe: boolean) {
    if (!isLockedByMe) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
  }

  downstream$: Observable<Downstream | undefined>;
  subscription: Subscription = new Subscription();

  form: FormGroup = this.formBuilder.group({
    depth: [ null, { updateOn: 'blur' } ],
    length: [ null, { updateOn: 'blur' } ],
    height: [ null, { updateOn: 'blur' } ],
    stackable: false,
    scenarioId: null,
    id: null,
  });

  constructor(
    private readonly store: Store,
    private readonly formBuilder: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.downstream$ = this.store.pipe(select(selectDownstream(this.scenarioId)));
    this.subscription.add(this.downstream$.subscribe((downstream) => this.setFormValue(downstream)));
    this.subscription.add(this.formChangeListener());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setFormValue(downstream: Downstream | undefined) {
    if (!downstream) {
      return;
    }
    this.form.setValue({
      id: downstream?.id,
      scenarioId: downstream?.scenarioId,
      length: downstream?.length,
      height: downstream?.height,
      depth: downstream?.depth,
      stackable: downstream?.stackable,
    }, { emitEvent: false });
  }

  private formChangeListener(): Subscription {
    return this.form.valueChanges.subscribe((d: Partial<Downstream>) =>
      this.store.dispatch(updateDownstream({ id: d.id, downstream: d, }))
    );
  }
}
