export type GHGIndicator = 'IND_IMPACT_CC_GHG';

export const GHGIndicator = {
  CC: 'IND_IMPACT_CC_GHG' as GHGIndicator,
};

export const isGHG = (value: string): boolean => {
  if (typeof value !== 'string') {
    return false;
  }
  return !!Object.values(GHGIndicator).find(ind => ind.localeCompare(value.toUpperCase()) === 0);
};
