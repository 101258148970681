import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VERSIONS_DATA_ID, versionsDataFeatureKey, VersionsDataState } from './versions-data.reducer';
import { Versions } from '../models/versions-data.model';
import { AppState } from '../../../store';

export const selectVersionsDataFeatureState = createFeatureSelector<AppState, VersionsDataState>(versionsDataFeatureKey);

export const selectVersionsData = createSelector(
  selectVersionsDataFeatureState,
  (state: VersionsDataState) => {
    return state.entities[VERSIONS_DATA_ID];
  }
);

export const selectDefaultVersion = createSelector(
  selectVersionsData,
  (versions: Versions) => {
    return versions?.defaultVersion;
  },
);

export const selectAvailableVersions = createSelector(
  selectVersionsData,
  (versions: Versions) => versions?.availableVersions,
);

export const selectedVersion = createSelector(
  selectVersionsDataFeatureState,
  (state: VersionsDataState) => state?.selectedVersion,
);
