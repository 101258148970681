import { LCAIndicator } from '../../project/assessment/models/lca-indicator.model';
import { PackagingDesignIndicator } from '../../project/assessment/models/packaging-design-indicator.model';
import { Pipe, PipeTransform } from '@angular/core';
import { UnclassifiedIndicator } from '../../project/assessment/models/unclassified-indicator.model';
import { GHGIndicator } from '../../project/assessment/models/ghg-indicator.model';


@Pipe({
  name: 'indicatorLabel',
})
export class IndicatorLabelPipe implements PipeTransform {

  INDICATOR_LABEL_MAP = new Map<LCAIndicator | PackagingDesignIndicator | UnclassifiedIndicator | GHGIndicator, string>([
      [ LCAIndicator.CC, 'Climate change' ],
      [ LCAIndicator.OD, 'Ozone depletion' ],
      [ LCAIndicator.IR, 'Ionising radiation, HH' ],
      [ LCAIndicator.PO, 'Photochemical ozone formation, HH' ],
      [ LCAIndicator.PM, 'Particulate matter' ],
      [ LCAIndicator.HC, 'Human toxicity, cancer' ],
      [ LCAIndicator.HN, 'Human toxicity, non-cancer' ],
      [ LCAIndicator.AC, 'Acidification' ],
      [ LCAIndicator.EF, 'Eutrophication, freshwater' ],
      [ LCAIndicator.EM, 'Eutrophication, marine' ],
      [ LCAIndicator.ET, 'Eutrophication, terrestrial' ],
      [ LCAIndicator.EC, 'Ecotoxicity, freshwater' ],
      [ LCAIndicator.LU, 'Land use' ],
      [ LCAIndicator.RF, 'Resource use, fossils' ],
      [ LCAIndicator.RM, 'Resource use, minerals and metals' ],
      [ LCAIndicator.WS, 'Water Scarcity' ],
      [ PackagingDesignIndicator.RECYCLABILITY, 'Recyclability' ],
      [ PackagingDesignIndicator.RECYCLINGRATE, 'Recycling rate' ],
      [ PackagingDesignIndicator.REUSABILITY, 'Reusability' ],
      [ PackagingDesignIndicator.COMPOSTABILITY, 'Compostability' ],
      [ PackagingDesignIndicator.RENEWABLECONTENT, 'Renewable content' ],
      [ PackagingDesignIndicator.PACKWEIGHTRATIO, 'Packaging to product weight ratio' ],
      [ PackagingDesignIndicator.RECYCLEDCONTENT, 'Recycled content' ],
      [ UnclassifiedIndicator.SINGLE_SCORE, 'Single score' ],
      [ UnclassifiedIndicator.WASTE_STREAM, 'Waste stream' ],
      [ UnclassifiedIndicator.PLASTIC_FOOTPRINT, 'Plastic footprint' ],
      [ UnclassifiedIndicator.MATERIAL_CIRCULARITY, 'EMF circularity' ],
      [ GHGIndicator.CC, 'Climate change (according to the GHG protocol)' ],
    ],
  );

  transform(value: LCAIndicator | PackagingDesignIndicator | UnclassifiedIndicator | GHGIndicator | string, ...args: unknown[]): string {
    return this.INDICATOR_LABEL_MAP.get(value as LCAIndicator | PackagingDesignIndicator | UnclassifiedIndicator | GHGIndicator) ?? value;
  }

}
