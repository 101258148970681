import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConversionProcessMapperService } from './conversion-process-mapper.service';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ConversionProcess } from '../models/conversion-process.model';
import { ConversionProcessDto } from '../../../api/dtos/__generated__/conversionProcessDto';

@Injectable({
  providedIn: 'root',
})
export class ConversionProcessDataService {

  CONVERSION_PROCESS_URL = `${environment.apiUrl}/conversion-processes`;

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: ConversionProcessMapperService,
  ) { }

  create(conversionProcess: ConversionProcess): Observable<ConversionProcess> {
    const dto: ConversionProcessDto = this.mapper.mapConversionProcessDtoFromConversionProcess(conversionProcess);
    return this.http.post(this.CONVERSION_PROCESS_URL, dto).pipe(
      map((response: ConversionProcessDto) => this.mapper.mapConversionProcessFromConversionProcessDto(response)),
    );
  }

  update(conversionProcess: ConversionProcess): Observable<ConversionProcess> {
    const dto: ConversionProcessDto = this.mapper.mapConversionProcessDtoFromConversionProcess(conversionProcess);
    return this.http.put(`${this.CONVERSION_PROCESS_URL}/${conversionProcess.id}`, dto).pipe(
      map((response: ConversionProcessDto) => this.mapper.mapConversionProcessFromConversionProcessDto(response)),
    );
  }

  delete(id: string): Observable<string> {
    return this.http.delete(`${this.CONVERSION_PROCESS_URL}/${id}`).pipe(
      map(() => id),
    );
  }
}
