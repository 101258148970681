import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Transport } from '../models/distribution-market';

@Component({
  selector: 'app-transports-card',
  template: `
    <mat-card>
      <mat-card-title>
        <mat-icon svgIcon="transports"></mat-icon>
        <div id="transportation-title">
          <h2 i18n>Downstream transportation - {{country}}</h2>
          <mat-icon
            class="tooltip"
            color="primary"
            i18n-matTooltip
            matTooltip="Downstream transportation corresponds to the transportation of packaging from final product manufacturing site to retail"
          >
            help
          </mat-icon>
        </div>
      </mat-card-title>
      <mat-card-content>
        <ng-container *ngFor="let transport of state; let index = index; trackBy:trackByTransportId">
          <app-transport-form [transport]="transport"
                              [isLockedByMe]="isLockedByMe"
                              (changeTransport)="handleChangeTransport(index, $event)"
                              (removeTransport)="handleRemoveTransport(index)"></app-transport-form>
        </ng-container>
      </mat-card-content>
      <mat-card-actions class="card-actions">
        <app-action-button (click)="handleAddTransport()" i18n [disabled]="!isLockedByMe">
          Add a transportation type
        </app-action-button>
      </mat-card-actions>
    </mat-card>

  `,
  styles: [ `
    :host {
      width: 100%;
      background: transparent;
    }

    #transportation-title {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  ` ]
})
export class TransportsCardComponent implements OnInit {


  @Input() country: string;
  @Input() isLockedByMe: boolean;
  @Input() transports: Transport[];
  @Output() changeTransports: EventEmitter<Transport[]> = new EventEmitter();

  state: Transport[] = [];

  ngOnInit(): void {
    this.state = [ ...this.transports ];
  }

  trackByTransportId(index, transport): number {
    return transport.id;
  }

  handleAddTransport() {
    this.state = [ ...this.state, { type: { label: null, value: null }, distance: 0 } ];
  }

  handleChangeTransport(index: number, transport: Transport) {
    this.state[index] = transport;
    this.changeTransports.emit(this.state);
  }

  handleRemoveTransport(index: number) {
    this.state = [...this.state.slice(0, index), ...this.state.slice(index + 1)];
    this.changeTransports.emit(this.state);
  }

}

