import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from '../shared/shared.module';
import { EqopackBrandModule } from '../tenant/eqopack-brand/eqopack-brand.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ProfileButtonModule } from '../project/modeling/components/profile-button/profile-button.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AdminHeaderModule } from './components/admin-header/admin-header.module';
import { MenuModule } from '../menu/menu.module';
import { AdminHomePageModule } from './pages/admin-home-page/admin-home-page.module';
import { AdminMenuModule } from './components/admin-menu/admin-menu.module';
import { ScenarioLibraryPageModule } from './pages/scenario-library-page/scenario-library-page.module';
import { ProjectsPageModule } from './pages/projects-page/projects-page.module';
import { UserManagementPageModule } from './pages/user-management-page/user-management-page.module';
import { PortfoliosPageModule } from './pages/portfolios-page/portfolios-page.module';
import { PortfolioDetailPageModule } from './pages/portfolio-detail-page/portfolio-detail-page.module';
import { VersionPageModule } from './pages/version-page/version-page.module';
import { StatisticsPageModule } from './pages/statistics-page/statistics-page.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AdminHomePageModule,
    AdminRoutingModule,
    SharedModule,
    EqopackBrandModule,
    MatIconModule,
    MatButtonModule,
    ProfileButtonModule,
    MatToolbarModule,
    AdminHeaderModule,
    AdminMenuModule,
    MenuModule,
    ScenarioLibraryPageModule,
    UserManagementPageModule,
    ProjectsPageModule,
    PortfoliosPageModule,
    PortfolioDetailPageModule,
    VersionPageModule,
    StatisticsPageModule,
  ],
  exports: [],
})
export class AdminModule {}
