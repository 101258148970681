import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { DistributionMarketFeatureState } from '../store/distribution-market.reducer';
import { DistributionMarket, EditDistributionMarket, UpdateDistributionMarket } from '../models/distribution-market';
import { selectDistributionMarketLoading, selectDistributionMarkets } from '../store/distribution-market.selectors';
import {
  createDistributionMarket,
  deleteDistributionMarket,
  findAllDistributionMarkets,
  updateDistributionMarket
} from '../store/distribution-market.actions';
import { map } from 'rxjs/operators';
import { Country } from '../../../store/reference-data/models/reference-data.model';

@Component({
  selector: 'app-distribution-markets',
  template: `
    <mat-card>
      <mat-card-title>
        <mat-icon id="title-icon" svgIcon="transports"></mat-icon>
        <h2 i18n>Areas of distribution</h2>
      </mat-card-title>
      <mat-card-content>
        <ng-container *ngFor="let distributionMarket of distributionMarkets$ | async;">
          <app-distribution-market-form [distributionMarket]="distributionMarket"
                                        [isLockedByMe]="isLockedByMe"
                                        [percentage]="getWeightPercentage(distributionMarket.weight) | async"
                                        [excludedCountries]="getSelectedCountries() | async"
                                        (update)="handleUpdate(distributionMarket.id, $event)"
                                        (remove)="handleRemove(distributionMarket.id)"
                                        [removable]="enableRemove"></app-distribution-market-form>
        </ng-container>
        <div *ngIf="addDistributionMarket || (distributionMarkets$|async).length === 0">
          <app-distribution-market-form [distributionMarket]="undefined" [removable]="false"
                                        [isLockedByMe]="isLockedByMe"
                                        [excludedCountries]="getSelectedCountries() | async"
                                        (update)="handleCreate($event)"

          ></app-distribution-market-form>
        </div>
        <mat-card-actions class="card-actions">
          <app-action-button
            [disabled]="!isLockedByMe || addDistributionMarket || (distributionMarkets$|async).length === 0"
            (click)="handleClickAdd()">
            Add a distribution market
          </app-action-button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  `,
  styles: [ `
    :host {
      width: 100%;
    }
  ` ]
})
export class DistributionMarketsComponent implements OnInit, OnDestroy, OnChanges {

  private readonly subscription = new Subscription();
  @Input() scenarioId: string;
  @Input() isLockedByMe: boolean;
  distributionMarkets$: Observable<DistributionMarket[]>;
  loading$: Observable<boolean>;
  addDistributionMarket = false;
  createDistributionMarket = false;
  enableRemove = false;

  constructor(
    private readonly store: Store<DistributionMarketFeatureState>
  ) {
  }

  ngOnInit(): void {
    this.distributionMarkets$ = this.store.pipe(select(selectDistributionMarkets));
    this.loading$ = this.store.pipe(select(selectDistributionMarketLoading));
    this.store.dispatch(findAllDistributionMarkets({ scenarioId: this.scenarioId }));
    this.subscription.add(
      this.distributionMarkets$.subscribe((distributionMarkets) => {
        if (this.createDistributionMarket) {
          this.createDistributionMarket = false;
          this.addDistributionMarket = false;
        }
        this.enableRemove = distributionMarkets.length > 1;
      })
    );
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scenarioId) {
      this.store.dispatch(findAllDistributionMarkets({ scenarioId: changes.scenarioId.currentValue }));
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  handleCreate(value: EditDistributionMarket): void {
    this.createDistributionMarket = true;
    const distributionMarket: UpdateDistributionMarket = { ...value, scenarioId: this.scenarioId };
    this.store.dispatch(createDistributionMarket({ scenarioId: this.scenarioId, distributionMarket }));
  }

  handleUpdate(id: string, value: EditDistributionMarket): void {
    const distributionMarket = { ...value, scenarioId: this.scenarioId, id };
    this.store.dispatch(updateDistributionMarket({ id, distributionMarket }));
  }

  handleRemove(id: string): void {
    this.store.dispatch(deleteDistributionMarket({ id }));
  }

  handleClickAdd(): void {
    this.addDistributionMarket = true;
  }

  getSelectedCountries(): Observable<Country[]> {
    return this.distributionMarkets$.pipe(
      map((distributionMarket) => distributionMarket.map(value => value.country))
    );
  }

  getWeightPercentage(weight: number): Observable<number> {
    return this.distributionMarkets$.pipe(
      map((distributionMarket) =>
        distributionMarket
          .map(value => value.weight)
          .reduce((previousValue, currentValue) => previousValue + currentValue)
      ),
      map((totalWeight) => weight / totalWeight)
    );
  }
}
