import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Project, ProjectSearchParams, SortDirection } from '../../../modeling/models/project.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Pagination } from '../../../../shared/page.model';
import { combineLatest, Observable, Subscription } from 'rxjs';
import * as fromProject from '../../../modeling/store/project';
import { CreateProjectDialogComponent } from '../../../modeling/components/create-project-dialog/create-project-dialog.component';
import { filter, switchMap, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromScenario from '../../../modeling/store/scenario';
import { Scenario } from '../../../modeling/models/scenario.model';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from '../../../../store';
import { DeleteConfirmationModalComponent } from '../../../../shared/delete-confirmation-modal/delete-confirmation-modal.component';
import { CollaboratorDataService } from '../../../../collaborator/collaborator.data-service';
import { ProjectFilters } from './project-filters.component';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-table.component.html',
  styleUrls: [ './project-table.component.scss' ],
})
export class ProjectTableComponent implements OnInit, OnDestroy, AfterViewInit {

  filters: ProjectFilters = {};

  displayedColumns: string[] = [
    'name',
    'brandType',
    'code',
    'ownerName',
    'createdAt',
    'lastOpenedAt',
    'lastUpdatedBy',
    'lastUpdatedAt',
    'duplicate',
    'delete',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  page: Pagination;
  dataSource: MatTableDataSource<Project>;
  subscription: Subscription = new Subscription();
  loading$: Observable<boolean>;

  @Output()
  rowClick: EventEmitter<Project> = new EventEmitter<Project>();

  @Output()
  scenarioCreated: EventEmitter<Scenario> = new EventEmitter<Scenario>();

  constructor(
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
    private readonly collaboratorDataService: CollaboratorDataService,
  ) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<Project & { canDelete: boolean }>([]);
    const me$ = this.collaboratorDataService.getMe();

    const changes$ = combineLatest([ this.store.pipe(select(fromProject.projectsAndPagination)), me$ ]);

    this.subscription.add(
      changes$.subscribe(([ { projects, pagination }, me ]) => {
        this.dataSource.data = projects.map(p => {
          const canDelete = p.ownerId === me.id || me.isAdmin;
          return { ...p, canDelete };
        });
        this.page = pagination;
      }),
    );
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe((value) => {
      this.paginator.firstPage();
      this.fetchProjects();
    });
  }

  public handleChangeFilters(value: ProjectFilters): void {
    this.filters = value;
    this.paginator.firstPage();
    this.fetchProjects();
  }

  paginationChange(pageEvent: PageEvent) {
    const { pageIndex, pageSize } = pageEvent;
    const params: ProjectSearchParams = {
      page: +pageIndex,
      size: +pageSize,
      sortedBy: this.getProjectSortBy(this.sort.active),
      sortDirection: this.sort.direction === 'asc' ? SortDirection.ASC : SortDirection.DESC,
      ...this.filters
    };
    this.store.dispatch(fromProject.getProjects({ params }));
  }

  fetchProjects() {
    this.store.dispatch(fromProject.getProjects({
      params: this.searchParams()
    }));
  }

  private searchParams() {
    return {
      size: this.paginator.pageSize,
      page: this.paginator.pageIndex,
      sortedBy: this.getProjectSortBy(this.sort.active),
      sortDirection: this.sort.direction === 'asc' ? SortDirection.ASC : SortDirection.DESC,
      ...this.filters
    };
  }

  getProjectSortBy(value: string): string {
    switch (value) {
      case 'ownerName': return 'owner.firstname';
      case 'brandType': return 'brandType.value';
      default: return value;
    }
  }

  onRowClick(event: Event, project: Project) {
    this.rowClick.emit(project);
  }

  trackRowBy(index: number, columnName: string): number {
    if (this.dataSource.data) {
      return +this.dataSource.data[index]?.id;
    }
  }

  openNewProjectDialog(): void {
    const dialogRef = this.dialog.open(CreateProjectDialogComponent);
    const handleDialogRefClosed = dialogRef.afterClosed()
      .pipe(
        take(1),
        filter((dialogResponse) => dialogResponse !== ''),
        switchMap((project: Project) => {
          this.store.dispatch(fromProject.addProject({ project }));
          return this.store.pipe(
            select(fromScenario.selectLastCreated),
            filter((s: Scenario) => !!s),
          );
        }),
      ).subscribe((s: Scenario) => {
        this.scenarioCreated.emit(s);
      });
    this.subscription.add(handleDialogRefClosed);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  deleteProject(event: Event, project: Project) {
    event.preventDefault();
    const dialogRef = this.dialog.open(DeleteConfirmationModalComponent, {
      data: {
        type: 'project',
        name: project.name,
      },
      width: null,
    });
    this.subscription.add(
      dialogRef.afterClosed().pipe(
        filter(confirm => !!confirm),
      ).subscribe(_ => {
        const { id } = project;
        this.store.dispatch(fromProject.deleteProject({ id, andGetProjectsWith: this.searchParams() }));
      }),
    );
  }

  duplicateProject(event: Event, project: Project) {
    event.preventDefault();
    const { id } = project;
    this.store.dispatch(fromProject.duplicateProject({ id, andGetProjectsWith: this.searchParams() }));
  }
}
