import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { Scenario } from '../../models/scenario.model';
import {
  addScenario,
  addScenarioSuccess,
  deleteScenario,
  deleteScenarioSuccess,
  duplicateScenario,
  duplicateScenarioSuccess,
  getScenario,
  getScenariosSuccess,
  getScenarioSuccess,
  importScenario,
  importScenarioSuccess,
  scenarioError,
  updateAssessmentSelected,
  updateHasSecondaryPackaging,
  updateHasTertiaryPackaging,
  updateHasTertiaryPalletizationPackaging,
  updateScenario,
  updateScenarioSuccess,
} from './scenario.actions';

export const scenarioFeatureKey = 'scenario';

export interface ScenarioState extends EntityState<Scenario> {
  // additional entities state properties
  error: string;
  loading: boolean;
  lastCreated: Scenario;
}

export const adapter: EntityAdapter<Scenario> = createEntityAdapter<Scenario>();

export const initialState: ScenarioState = adapter.getInitialState({
  loading: false,
  error: null,
  lastCreated: null,
});

const reducer = createReducer(
  initialState,
  on(
    getScenario,
    addScenario,
    updateScenario,
    deleteScenario,
    importScenario,
    duplicateScenario, (state, action) =>
      ({ ...state, loading: true, error: null, lastCreated: null }),
  ),
  on(getScenarioSuccess, (state, action) =>
    adapter.upsertOne(action.scenario, { ...state, loading: false }),
  ),
  on(addScenarioSuccess, (state, action) =>
    adapter.addOne(action.scenario, { ...state, lastCreated: action.scenario, loading: false }),
  ),
  on(updateScenarioSuccess, (state, action) => {
      const { scenario } = action;
      const update: Update<Scenario> = {
        id: scenario.id,
        changes: scenario,
      };
      return adapter.updateOne(update, { ...state, loading: false });
    },
  ),
  on(deleteScenarioSuccess,
    (state, action) =>
      adapter.removeOne(action.id, { ...state, loading: false }),
  ),
  on(duplicateScenarioSuccess,
    (state, action) =>
      adapter.addOne(action.scenario, { ...state, loading: false }),
  ),
  on(getScenariosSuccess,
    (state, action) =>
      adapter.setAll(action.scenarios, { ...state, loading: false }),
  ),
  on(importScenarioSuccess, (state, action) => ({ ...state, loading: false })),
  on(scenarioError,
    (state, action) =>
      ({ ...state, loading: false, error: action.message }),
  ),
  on(updateAssessmentSelected, (state, action) => {
    const { id, selected } = action;
    const update = { id, changes: { assessmentSelected: selected } };
    return adapter.updateOne(update, state);
  }),
  on(updateHasSecondaryPackaging, (state, action) => {
    const { id, hasPackaging } = action;
    const update = { id, changes: { hasSecondaryPackaging: hasPackaging } };
    return adapter.updateOne(update, state);
  }),
  on(updateHasTertiaryPackaging, (state, action) => {
    const { id, hasPackaging } = action;
    const update = { id, changes: { hasTertiaryBoxPackaging: hasPackaging } };
    return adapter.updateOne(update, state);
  }),
  on(updateHasTertiaryPalletizationPackaging, (state, action) => {
    const { id, hasPackaging } = action;
    const update = { id, changes: { hasTertiaryPalletizationPackaging: hasPackaging } };
    return adapter.updateOne(update, state);
  }),
);


export function scenarioReducer(state: ScenarioState | undefined, action: Action) {
  return reducer(state, action);
}
