import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistics-page',
  template: `
    <app-statistics-header></app-statistics-header>
    <app-statistics-table></app-statistics-table>
  `,
  styleUrls: [ 'statistics-page.component.scss' ],
})
export class StatisticsPageComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }

}
