import { createAction, props } from '@ngrx/store';
import { PackagingComponent } from '../../models/component.models';

const COMPONENT_SIDE_EFFECT_ACTION = '[Component/API]';
const COMPONENT_ACTION = '[Component]';

export const addComponent = createAction(
  `${COMPONENT_SIDE_EFFECT_ACTION} Add Component`,
  props<{ packagingId: string }>(),
);

export const addComponentSuccess = createAction(
  `${COMPONENT_SIDE_EFFECT_ACTION} Add Component Success`,
  props<{ component: PackagingComponent }>(),
);

export const getComponentsSuccess = createAction(
  `${COMPONENT_SIDE_EFFECT_ACTION} Get Components Success`,
  props<{ components: PackagingComponent[] }>(),
);

export const updateComponent = createAction(
  `${COMPONENT_SIDE_EFFECT_ACTION} Update Component`,
  props<{ component: PackagingComponent }>(),
);

export const updateComponentSuccess = createAction(
  `${COMPONENT_SIDE_EFFECT_ACTION} Update Component Success`,
  props<{ component: PackagingComponent }>(),
);

export const deleteComponent = createAction(
  `${COMPONENT_SIDE_EFFECT_ACTION} Delete Component`,
  props<{ component: PackagingComponent }>(),
);

export const deleteComponentSuccess = createAction(
  `${COMPONENT_SIDE_EFFECT_ACTION} Delete Component Success`,
  props<{ component: PackagingComponent }>(),
);

export const componentError = createAction(
  `${COMPONENT_SIDE_EFFECT_ACTION} Component Error`,
  props<{ message: string }>(),
);

export const deleteAll = createAction(
  `${COMPONENT_ACTION} Delete All Component Of Given Packaging`,
  props<{ packagingId: string }>(),
);

export const deleteAllSuccess = createAction(
  `${COMPONENT_ACTION} Delete All Component Of Given Packaging Success`,
  props<{ packagingId: string }>(),
);

