import { ActionReducer, createReducer, on } from '@ngrx/store';
import { downstreamError, updateDownstream, updateDownstreamSuccess, } from './downstream.actions';

export const downstreamFeatureKey = 'downstream';

export interface DownstreamState {
  loading: boolean;
  error: string | null;
}

export const initialDownstreamState: DownstreamState = ({
  loading: false,
  error: null,
});

export interface DownstreamFeatureState {
  downstream: DownstreamState;
}

export const downstreamReducer: ActionReducer<DownstreamState> = createReducer<DownstreamState>(
  initialDownstreamState,
  on(updateDownstream, (state) => ({ ...state, loading: true, error: null })),
  on(updateDownstreamSuccess, (state) => ({ ...state, loading: false })),
  on(downstreamError, (state, action) => ({ ...state, error: action.message, loading: false })),
);

export default downstreamReducer;
