import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Scenario } from '../project/modeling/models/scenario.model';
import { PublishedScenarioCreationRequestDto } from '../api/dtos/__generated__/publishedScenarioCreationRequestDto';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '../core/notification.service';
import { PublishedScenarioStatus } from './models/published-scenario-status.model';
import { PublishedScenario } from './models/published-scenario.model';
import { PublishedScenarioMetadataMapperService } from './scenario-library-mappers/published-scenario-metadata-mapper.service';
import { PublishedScenarioMetadataDto } from '../api/dtos/__generated__/publishedScenarioMetadataDto';

@Injectable({
  providedIn: 'root',
})
export class ScenarioLibraryDataService {

  private readonly PUBLISHED_SCENARIOS = 'published-scenarios';

  constructor(
    private readonly http: HttpClient,
    private readonly notificationService: NotificationService,
    private readonly metadataMapperService: PublishedScenarioMetadataMapperService,
  ) { }

  publish(scenario: Partial<Scenario>): Observable<boolean> {
    const { id, name } = scenario;
    const dto: PublishedScenarioCreationRequestDto = { fromScenarioId: id, name };
    return this.http.post(`${environment.apiUrl}/${this.PUBLISHED_SCENARIOS}`, dto)
      .pipe(
        map(_ => true),
        catchError(_ => {
          this.notificationService.warn('Could not publish scenario to the library');
          return of(false);
        }),
      );
  }

  get(status?: PublishedScenarioStatus[]): Observable<PublishedScenario[]> {
    const params = !!status?.length ? `?status=${status.join(',')}` : '';
    return this.http.get(`${environment.apiUrl}/${this.PUBLISHED_SCENARIOS}${params}`).pipe(
      map((dtoList: PublishedScenarioMetadataDto[]) => this.metadataMapperService.fromList(dtoList)),
      catchError(err => {
        this.notificationService.warn('Could not retrieve published scenarios');
        throw err;
      }),
    );
  }

  getAll(): Observable<PublishedScenario[]> {
    const status = Object.values(PublishedScenarioStatus).filter(s => s !== PublishedScenarioStatus.DELETED);
    return this.get(status);
  }

  getApproved(): Observable<PublishedScenario[]> {
    return this.get([ PublishedScenarioStatus.APPROVED ]);
  }

  approve(scenario: PublishedScenario): Observable<PublishedScenario | null> {
    const approvedScenario: PublishedScenario = { ...scenario, publicationStatus: PublishedScenarioStatus.APPROVED };
    return this.update(approvedScenario);
  }

  reject(scenario: PublishedScenario): Observable<PublishedScenario | null> {
    const rejectedScenario: PublishedScenario = { ...scenario, publicationStatus: PublishedScenarioStatus.REJECTED };
    return this.update(rejectedScenario);
  }

  delete(scenario: PublishedScenario): Observable<PublishedScenario | null> {
    const deletedScenario: PublishedScenario = { ...scenario, publicationStatus: PublishedScenarioStatus.DELETED };
    return this.update(deletedScenario);
  }

  setToPending(scenario: PublishedScenario): Observable<PublishedScenario | null> {
    const updatedScenario: PublishedScenario = { ...scenario, publicationStatus: PublishedScenarioStatus.PENDING };
    return this.update(updatedScenario);
  }

  update(scenario: PublishedScenario): Observable<PublishedScenario | null> {
    const dto = this.metadataMapperService.to(scenario);
    return this.http.put(`${environment.apiUrl}/${this.PUBLISHED_SCENARIOS}/${dto.id}`, dto).pipe(
      map(updatedDto => this.metadataMapperService.from(updatedDto)),
      catchError(_ => {
        this.notificationService.warn(`Could not update published scenario with name ${scenario.name}`);
        return of(null);
      }),
    );
  }
}
