import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Version, Versions } from '../models/versions-data.model';
import { createReducer, on } from '@ngrx/store';
import {
  getVersions,
  getVersionsError,
  getVersionsSuccess,
  setDefaultVersion,
  setDefaultVersionError,
  setDefaultVersionSuccess, setVersion,
} from './versions-data.actions';

export const versionsDataFeatureKey = 'versionsData';

export interface VersionsDataState extends EntityState<Versions> {
  // additional entities state properties
  loading: boolean;
  error: string;
  selectedVersion: Version;
}

export interface VersionsDataFeatureState {
  versionsData: VersionsDataState;
}

export const adapter: EntityAdapter<Versions> = createEntityAdapter<Versions>();

export const initialState: VersionsDataState = adapter.getInitialState({
  selectedVersion: null,
  loading: false,
  error: null,
});

export const VERSIONS_DATA_ID = 1;

export const reducer = createReducer(
  initialState,
  on(getVersions, (state) =>
    ({ ...state, loading: true })
  ),
  on(getVersionsError, (state, action) =>
    ({ ...state, loading: false, error: action.message }),
  ),
  on(getVersionsSuccess, (state, action) => {
    return adapter.setOne(
      { id: VERSIONS_DATA_ID, ...(action.versions) },
      { ...state, loading: false, error: null, selectedVersion: action.versions.defaultVersion },
    );
  }),
  on(setDefaultVersion, (state) =>
    ({ ...state, loading: true }),
  ),
  on(setDefaultVersionError, (state, action) =>
    ({ ...state, loading: false, error: action.message }),
  ),
  on(setDefaultVersionSuccess, (state, action) => {
    return adapter.updateOne(
      {
        id: VERSIONS_DATA_ID,
        changes: {
          defaultVersion: action.version,
        },
      },
      { ...state, loading: false, error: null },
    );
  }),
  on(setVersion, (state, action) => ({...state, selectedVersion: action.version})),
);

export const {
  selectEntities,
} = adapter.getSelectors((state: VersionsDataFeatureState) => state.versionsData);
