import { createAction, props } from '@ngrx/store';
import { Material, RecyclingRateType } from '../../models/material.models';

const MATERIAL_SIDE_EFFECT_ACTION = '[Material/API]';
const MATERIAL_ACTION = '[Material]';


export const addMaterial = createAction(
  `${MATERIAL_SIDE_EFFECT_ACTION} Add Material`,
  props<{ componentId: string }>(),
);

export const addMaterialSuccess = createAction(
  `${MATERIAL_SIDE_EFFECT_ACTION} Add Material Success`,
  props<{ material: Material }>(),
);

export const getMaterialsSuccess = createAction(
  `${MATERIAL_SIDE_EFFECT_ACTION} Add Materials Success`,
  props<{ materials: Material[] }>(),
);

export const getMaterial = createAction(
  `${MATERIAL_SIDE_EFFECT_ACTION} Get Material`,
  props<{ id: string }>(),
);

export const getMaterialSuccess = createAction(
  `${MATERIAL_SIDE_EFFECT_ACTION} Get Material Success`,
  props<{ material: Material }>(),
);

export const updateMaterial = createAction(
  `${MATERIAL_SIDE_EFFECT_ACTION} Update Material`,
  props<{ material: Material }>(),
);

export const updateMaterialSuccess = createAction(
  `${MATERIAL_SIDE_EFFECT_ACTION} Update Material Success`,
  props<{ material: Material }>(),
);

export const duplicateMaterial = createAction(
  `${MATERIAL_SIDE_EFFECT_ACTION} Duplicate Material`,
  props<{ id: string }>(),
);

export const duplicateMaterialSuccess = createAction(
  `${MATERIAL_SIDE_EFFECT_ACTION} Duplicate Material Success`,
  props<{ material: Material }>(),
);

export const deleteMaterial = createAction(
  `${MATERIAL_SIDE_EFFECT_ACTION} Delete Material`,
  props<{ id: string }>(),
);

export const deleteMaterialSuccess = createAction(
  `${MATERIAL_SIDE_EFFECT_ACTION} Delete Material Success`,
  props<{ id: string }>(),
);

export const materialError = createAction(
  `${MATERIAL_SIDE_EFFECT_ACTION} Material Error`,
  props<{ message: string }>(),
);

export const updateRecyclable = createAction(
  `${MATERIAL_ACTION} Update Recyclable`,
  props<{ id: string, recyclable: boolean }>(),
);

export const updateCompostable = createAction(
  `${MATERIAL_ACTION} Update Compostable`,
  props<{ id: string, compostable: boolean }>(),
);

export const updateRecyclingRate = createAction(
  `${MATERIAL_ACTION} Update Recycling Rate`,
  props<{ id: string, recyclingRate: number }>(),
);

export const updateRecyclingRateType = createAction(
  `${MATERIAL_ACTION} Update Recycling Rate Type`,
  props<{ id: string, recyclingRateType: RecyclingRateType }>(),
);

export const deleteAll = createAction(
  `${MATERIAL_ACTION} Delete All Material of Given Component`,
  props<{ componentId: string }>(),
);

export const deleteAllSuccess = createAction(
  `${MATERIAL_ACTION} Delete All Material of Given Component Success`,
  props<{ componentId: string }>(),
);
