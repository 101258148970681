import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { DistributionMarketDataService } from '../services/distribution-market.data-service';
import { DistributionMarketFeatureState } from './distribution-market.reducer';
import {
  createDistributionMarket,
  createDistributionMarketSuccess,
  deleteDistributionMarket,
  deleteDistributionMarketSuccess,
  distributionMarketError,
  findAllDistributionMarkets,
  findAllDistributionMarketsSuccess,
  updateDistributionMarket,
  updateDistributionMarketSuccess,
  updateDistributionMarketTransports,
  updateDistributionMarketTransportsSuccess
} from './distribution-market.actions';
import { selectDistributionMarketScenarioId } from './distribution-market.selectors';

@Injectable({
  providedIn: 'root',
})
export class DistributionMarketEffects {

  findAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(findAllDistributionMarkets),
      mergeMap(({ scenarioId }) => this.distributionMarketDataService.findAll(scenarioId)
        .pipe(
          map((distributionMarkets) => findAllDistributionMarketsSuccess({ distributionMarkets })),
          catchError(() => of(distributionMarketError({ error: 'Failed to fetch distribution markets' }))),
        )
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createDistributionMarket),
      mergeMap(({
                  scenarioId,
                  distributionMarket
                }) => this.distributionMarketDataService.create(scenarioId, distributionMarket)
        .pipe(
          mergeMap(() => [
            findAllDistributionMarkets({ scenarioId }),
            createDistributionMarketSuccess()
          ]),
          catchError(() => of(distributionMarketError({ error: 'Failed to create distribution market' }))),
        )
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDistributionMarket),
      switchMap(({ id, distributionMarket }) => this.distributionMarketDataService.update(id, distributionMarket)
        .pipe(
          withLatestFrom(this.store.pipe(select(selectDistributionMarketScenarioId))),
          mergeMap(([ _, scenarioId ]) => [
            findAllDistributionMarkets({ scenarioId }),
            updateDistributionMarketSuccess()
          ]),
          catchError(() => of(distributionMarketError({ error: 'Failed to update distribution market' }))),
        )
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDistributionMarket),
      switchMap(({ id }) => this.distributionMarketDataService.delete(id)
        .pipe(
          withLatestFrom(this.store.pipe(select(selectDistributionMarketScenarioId))),
          mergeMap(([ _, scenarioId ]) => [
            findAllDistributionMarkets({ scenarioId }),
            deleteDistributionMarketSuccess()
          ]),
          catchError(() => of(distributionMarketError({ error: 'Failed to delete distribution market' }))),
        )
      ),
    ),
  );

  updateTransports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDistributionMarketTransports),
      switchMap(({ id, transports }) => this.distributionMarketDataService.updateTransports(id, transports)
        .pipe(
          withLatestFrom(this.store.pipe(select(selectDistributionMarketScenarioId))),
          mergeMap(([ _, scenarioId ]) => [
            updateDistributionMarketTransportsSuccess()
          ]),
          catchError(() => of(distributionMarketError({ error: 'Failed to update distribution market transports' }))),
        )
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly distributionMarketDataService: DistributionMarketDataService,
    private readonly store: Store<DistributionMarketFeatureState>,
  ) {
  }
}
