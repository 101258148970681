import { createAction, props } from '@ngrx/store';
import { Packaging } from '../../../models/packaging.models';

export const createFirstTertiaryPackaging = createAction(
  '[Tertiary Packaging/API] Create First Tertiary Packaging',
  props<{ scenarioId: string }>(),
);

export const createFirstTertiaryPackagingSuccess = createAction(
  '[Tertiary Packaging/API] Create First Tertiary Packaging Success',
  props<{ packaging: Packaging }>(),
);

export const addTertiaryPackaging = createAction(
  '[Tertiary Packaging/API] Add Tertiary Packaging',
  props<{ scenarioId: string }>(),
);

export const addTertiaryPackagingSuccess = createAction(
  '[Tertiary Packaging/API] Add Tertiary Packaging Success',
  props<{ packaging: Packaging }>(),
);

export const getTertiaryPackagingSuccess = createAction(
  '[Tertiary Packaging/API] Get Tertiary Packaging Success',
  props<{ packaging: Packaging[] }>(),
);

export const updateTertiaryPackaging = createAction(
  '[Tertiary Packaging/API] Update Tertiary Packaging',
  props<{ packaging: Packaging }>(),
);

export const updateTertiaryPackagingSuccess = createAction(
  '[Tertiary Packaging/API] Update Tertiary Packaging Success',
  props<{ packaging: Packaging }>(),
);

export const deleteTertiaryPackaging = createAction(
  '[Tertiary Packaging/API] Delete Tertiary Packaging',
  props<{ id: string, scenarioId: string }>(),
);

export const deleteTertiaryPackagingSuccess = createAction(
  '[Tertiary Packaging/API] Delete Tertiary Packaging Success',
  props<{ id: string }>(),
);

export const tertiaryPackagingError = createAction(
  '[Tertiary Packaging/API] Tertiary Packaging Error',
  props<{ message: string }>(),
);

export const deleteAll = createAction(
  '[Tertiary Packaging/API] Delete All Tertiary Packaging Of Scenario',
  props<{ scenarioId: string }>(),
);

export const deleteAllSuccess = createAction(
  '[Tertiary Packaging/API] Delete All Tertiary Packaging Of Scenario Success',
  props<{ scenarioId: string }>(),
);
