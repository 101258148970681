import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  addPrimaryPackaging,
  addPrimaryPackagingSuccess,
  createFirstPrimaryPackaging,
  createFirstPrimaryPackagingSuccess,
  deleteAll,
  deleteAllSuccess,
  primaryPackagingError,
  updatePrimaryPackaging,
  updatePrimaryPackagingSuccess,
  updateProductMass,
  updateProductVolume,
  updateUsage,
} from './primary-packaging.actions';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { Packaging, PackagingLevel, Usage } from '../../../models/packaging.models';
import { of } from 'rxjs';
import { PackagingDataService } from '../../../services/packaging.data-service';
import { AppState } from '../../../../../store';
import { select, Store } from '@ngrx/store';
import { selectById } from './primary-packaging.selectors';
import { createFirstSecondaryPackaging } from '../secondary';
import * as fromScenario from '../../scenario';
import * as fromComponent from '../../component';
import { selectByLevelAndScenarioId } from '../packaging.selectors';


@Injectable()
export class PrimaryPackagingEffects {

  createFirstPrimaryPackaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFirstPrimaryPackaging),
      switchMap((action: { type: string, scenarioId: string }) => {
          const p: Packaging = { scenarioId: action.scenarioId, level: PackagingLevel.PRIMARY } as Packaging;
          return this.packagingDataService.create(p)
            .pipe(
              mergeMap((packaging: Packaging) => [
                  createFirstPrimaryPackagingSuccess({ packaging }),
                  createFirstSecondaryPackaging({ scenarioId: action.scenarioId }),
                ],
              ),
              catchError(() => of(primaryPackagingError({ message: 'Something went wrong when adding new primary packaging' }))),
            );
        },
      ),
    ),
  );

  addPrimaryPackaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addPrimaryPackaging),
      switchMap((action: { type: string, scenarioId: string }) => {
          const p: Packaging = { scenarioId: action.scenarioId, level: PackagingLevel.PRIMARY } as Packaging;
          return this.packagingDataService.create(p)
            .pipe(
              mergeMap((packaging: Packaging) => [
                addPrimaryPackagingSuccess({ packaging }),
                fromScenario.getScenario({ id: action.scenarioId }),
              ]),
              catchError(() => of(primaryPackagingError({ message: 'Something went wrong when adding new primary packaging' }))),
            );
        },
      ),
    ),
  );

  updatePrimaryPackagingInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        updateProductMass,
        updateProductVolume,
        updateUsage,
      ),
      switchMap(
        (action:
           { type: string, id: string, mass: number } |
           { type: string, id: string, volume: number } |
           { type: string, id: string, usage: Usage },
        ) => this.store
          .pipe(
            select(selectById(action.id)),
            take(1),
            map((packaging: Packaging) => updatePrimaryPackaging({ packaging })),
          ),
      ),
    ),
  );

  updatePrimaryPackaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePrimaryPackaging),
      map((action: { type: string, packaging: Packaging }) => action.packaging),
      switchMap((pp: Packaging) => this.packagingDataService.update(pp)
        .pipe(
          map((packaging: Packaging) => updatePrimaryPackagingSuccess({ packaging })),
          catchError(() => of(primaryPackagingError({ message: 'Something went wrong when updating primary packaging' }))),
        ),
      )),
  );

  deleteAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAll),
      switchMap((action: { scenarioId: string }) => this.store.pipe(
        select(selectByLevelAndScenarioId(action.scenarioId, PackagingLevel.PRIMARY)),
        filter((packaging: Packaging) => !!packaging),
        mergeMap((packaging: Packaging) => [
            fromComponent.deleteAll({ packagingId: packaging.id }),
            deleteAllSuccess(({ scenarioId: action.scenarioId })),
          ],
        )),
      )),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly packagingDataService: PackagingDataService,
    private readonly store: Store<AppState>) {
  }

}
