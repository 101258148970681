import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
})
export class EqopackIconsModule {
  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
  ) {
    this.matIconRegistry.addSvgIcon(
      'eqopack-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/eqopack-icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'company-logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/company-logo.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'kleis-logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/kleis-logo.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'primary-packaging',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/primary-packaging.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'secondary-packaging',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/secondary-packaging.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'tertiary-packaging',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/tertiary-packaging.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'tertiary-palletization-packaging',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/tertiary-palletization.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'component-description',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/component-description.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'conversion-process',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/conversion-process.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'end-of-life',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/end-of-life.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'finishing-processes',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/finishing-processes.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'material-description',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/material-description.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'materials',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/materials.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'content-paste',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/content-paste.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'packaging-size',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/packaging-size.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'transport-unit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/transport-unit.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'transports',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/transports.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'transports',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/transports.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'cube',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/cube.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'done',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/done.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'packaging-indicators',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/packaging-indicators.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'recyclability',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/recyclability.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'reusability',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/reusability.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'recycled-content',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/recycled-content.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'recycling-rate',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/recycling-rate.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'compostability',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/compostability.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'renewable-content',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/renewable-content.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'packaging-weight',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/packaging-weight.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'fuel-depletion',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/fuel-depletion.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'ecotoxicity',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/ecotoxicity.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'waste-streams',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/waste-streams.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'plastic-footprint',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/plastic-footprint.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'single-score',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/single-score.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'minerals-use',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/minerals-use.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'water-scarcity',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/water-scarcity.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'land-use',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/land-use.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'fossils-use',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/fossils-use.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'acidification',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/acidification.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'eutrophication-freshwater',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/eutrophication-freshwater.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'eutrophication-marine',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/eutrophication-marine.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'eutrophication-terrestrial',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/eutrophication-terrestrial.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'fossils-use',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/fossils-use.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'human-toxicity-cancer',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/human-toxicity-cancer.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'human-toxicity',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/human-toxicity.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'ionising-radiation',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/ionising-radiation.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'land-use',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/land-use.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'minerals-use',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/minerals-use.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'particulate-matter',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/particulate-matter.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'photochemical-ozone',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/photochemical-ozone.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'water-scarcity',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/water-scarcity.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'ozone-depletion',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/ozone-depletion.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'climate-change',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/climate-change.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'climate-change-ghg',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/climate-change-ghg.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'material-circularity',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/assessment/material-circularity.svg'),
    );
  }
}
