import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromMaterialTransport from './';
import { catchError, concatMap, map, pluck, switchMap } from 'rxjs/operators';
import { MaterialTransportDataService } from '../../services/material-transport.data-service';
import { MaterialTransport } from '../../models/material.models';
import { of } from 'rxjs';
import { NotificationService } from '../../../../core/notification.service';


@Injectable()
export class MaterialTransportEffects {

  addMaterialTransport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMaterialTransport.addMaterialTransport),
      concatMap((action: { type: string, transport: Partial<MaterialTransport> }) => this.service.create(action.transport)
        .pipe(
          map((materialTransport: MaterialTransport) => fromMaterialTransport.addMaterialTransportSuccess({ materialTransport })),
          catchError(() => of(fromMaterialTransport.materialTransportError())),
        ),
      )),
  );

  updateMaterialTransport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMaterialTransport.updateMaterialTransport),
      pluck('materialTransport'),
      switchMap((t: Partial<MaterialTransport>) => this.service.update(t)
        .pipe(
          map((materialTransport: MaterialTransport) => fromMaterialTransport.updateMaterialTransportSuccess({ materialTransport })),
          catchError(() => {
            this.notificationService.warn('Could not save latest material transport changes');
            return of(fromMaterialTransport.materialTransportError());
          }),
        )),
    ));

  deleteMaterialTransport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMaterialTransport.deleteMaterialTransport),
      switchMap((action: { type: string, id: string }) => this.service.delete(action.id)
        .pipe(
          map((id: string) => fromMaterialTransport.deleteMaterialTransportSuccess({ id })),
          catchError(() => of(fromMaterialTransport.materialTransportError())),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly service: MaterialTransportDataService,
    private readonly notificationService: NotificationService,
  ) {}

}
