import {adapter, userManagementFeatureKey, UserManagementState} from './user-management.reducer';
import {AppState} from '../../../store';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export const selectUserManagementState = createFeatureSelector<UserManagementState>(userManagementFeatureKey);

export const {
  selectAll,
} = adapter.getSelectors((state: AppState) => state.userManagement);




