import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../store';
import * as fromStatistics from '../../store/statistics-data/store';
import {Statistics} from '../../store/statistics-data/models/statistics-data.model';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-statistics-table',
  template: `
    <div class="container" *ngIf="statistics">
      <div class="row">
        <app-statistics-card
          [icons]="[{ name: 'folder' }]"
          [label]="{ value: 'Projects'}"
          [value]="statistics.projects"
        >
        </app-statistics-card>
        <app-statistics-card
          [icons]="[{ name: 'folder' }, {name: 'schedule', class: 'smaller'}]"
          [value]="statistics.monthlyActiveProjects"
          [label]="{
            value: 'Active projects',
            tooltip: {
              value: 'Number of projects which have been modified in at least once within last month.',
              icon: 'help'
             }
           }"
        >
        </app-statistics-card>
      </div>
      <div class="row">
        <app-statistics-card
          [icons]="[{name: 'supervisor_account'}]"
          [value]="seatsDisplayValue"
          [label]="{value: 'Users'}"
        ></app-statistics-card>
        <app-statistics-card
          [icons]="[{name: 'supervisor_account'}, {name: 'schedule', class: 'smaller'}]"
          [value]="statistics.monthlyActiveUsers"
          [label]="{
            value: 'Active users',
            tooltip: {
              value: 'Number of users who logged in at least once within last month.',
              icon: 'help'
            }
          }"
        >
        </app-statistics-card>
      </div>
      <div class="row">
        <app-statistics-card
          [icons]="[{name: 'library_books'}]"
          [value]="statistics.projectScenarios"
          [label]="{value: 'Scenarios'}"
        >
        </app-statistics-card>
        <app-statistics-card
          [icons]="[{name: 'public'}]"
          [value]="statistics.publishedScenarios"
          [label]="{value: 'Published scenarios'}"

        >
        </app-statistics-card>

      </div>

    </div>
  `,
  styleUrls: ['statistics-table.component.scss'],
})
export class StatisticsTableComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  statistics$: Observable<Statistics>;
  statistics?: Statistics;

  seatsDisplayValue: string;

  constructor(private readonly store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.statistics$ = this.store.pipe(
      select(fromStatistics.selectStatisticsData),
    );
    this.subscription.add(this.statistics$.pipe(filter(stats => !!stats)).subscribe((stats) => {
      this.statistics = stats;
      this.seatsDisplayValue = `${stats.enabledUsers}/${stats.seatLimit}`;
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
