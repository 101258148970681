import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsPageComponent } from './projects-page.component';
import { ProjectTableModule } from '../../../project/pages/home-page/project-table/project-table.module';


@NgModule({
  declarations: [ ProjectsPageComponent ],
  imports: [
    CommonModule,
    ProjectTableModule,
  ],
})
export class ProjectsPageModule {}
