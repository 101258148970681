import { createAction, props } from '@ngrx/store';
import { Country } from '../../../../store/reference-data/models/reference-data.model';
import { ElectricityType } from '../../models/electricity.model';
import { ConversionProcess } from '../../models/conversion-process.model';

const CONVERSION_PROCESS_SIDE_EFFECT_ACTION = '[ConversionProcess/API]';
const CONVERSION_ACTION = '[ConversionProcess]';


export const addConversionProcess = createAction(
  `${CONVERSION_PROCESS_SIDE_EFFECT_ACTION} Add Conversion Process`,
  props<{ conversionProcess: ConversionProcess }>(),
);

export const addConversionProcessSuccess = createAction(
  `${CONVERSION_PROCESS_SIDE_EFFECT_ACTION} Add Conversion Process Success`,
  props<{ conversionProcess: ConversionProcess }>(),
);

export const getConversionProcessesSuccess = createAction(
  `${CONVERSION_PROCESS_SIDE_EFFECT_ACTION} Add Conversion Processes Success`,
  props<{ conversionProcesses: ConversionProcess[] }>(),
);

export const updateConversionProcess = createAction(
  `${CONVERSION_PROCESS_SIDE_EFFECT_ACTION} Update Conversion Process`,
  props<{ conversionProcess: ConversionProcess }>(),
);

export const updateConversionProcessSuccess = createAction(
  `${CONVERSION_PROCESS_SIDE_EFFECT_ACTION} Update Conversion Process Success`,
  props<{ conversionProcess: ConversionProcess }>(),
);

export const deleteConversionProcess = createAction(
  `${CONVERSION_PROCESS_SIDE_EFFECT_ACTION} Delete Conversion Process`,
  props<{ id: string }>(),
);

export const deleteConversionProcessSuccess = createAction(
  `${CONVERSION_PROCESS_SIDE_EFFECT_ACTION} Delete Conversion Process Success`,
  props<{ id: string }>(),
);

export const conversionProcessError = createAction(
  `${CONVERSION_PROCESS_SIDE_EFFECT_ACTION} Conversion Process Error`,
  props<{ message: string }>(),
);

export const updateElectricityCountry = createAction(
  `${CONVERSION_ACTION} Update Country`,
  props<{ id: string, electricityCountry: Country }>(),
);

export const updateElectricityType = createAction(
  `${CONVERSION_ACTION} Update Electricity Type`,
  props<{ id: string, electricityType: ElectricityType }>(),
);

export const deleteAll = createAction(
  `${CONVERSION_ACTION} Delete All Conversion Processes of Given Component`,
  props<{ materialId: string }>(),
);
