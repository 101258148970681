import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReferenceData } from './models/reference-data.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { ReferenceDataMappingService } from './reference-data.mapping-service';

@Injectable({
  providedIn: 'root',
})
export class ReferenceDataDataService {

  REFERENCE_DATA_URL = `${environment.apiUrl}/reference-data`;

  constructor(
    private readonly http: HttpClient,
    private readonly referenceDataMappingService: ReferenceDataMappingService,
  ) {}

  get(): Observable<ReferenceData> {
    return this.http.get(this.REFERENCE_DATA_URL)
      .pipe(map((data) => this.referenceDataMappingService.mapReferenceDataFromReferenceDataDto(data)));
  }
}
