import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Scenario } from '../models/scenario.model';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ScenarioMapperService } from './scenario-mapper.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ScenarioCreationRequestDto } from '../../../api/dtos/__generated__/scenarioCreationRequestDto';
import { ScenarioCompleteDto } from '../../../api/dtos/__generated__/scenarioCompleteDto';
import { ScenarioMetadataDto } from '../../../api/dtos/__generated__/scenarioMetadataDto';
import { PackagingComponent } from '../models/component.models';
import { FinishingProcess } from '../models/finishing-process.models';
import { Material, MaterialTransport } from '../models/material.models';
import { Packaging, PackagingLevel } from '../models/packaging.models';
import { Downstream } from '../../../packaging/downstream/models/downstream';

@Injectable({
  providedIn: 'root',
})
export class ScenarioDataService {

  SCENARIOS_URL = `${environment.apiUrl}/scenarios`;

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: ScenarioMapperService,
  ) {
  }

  create(projectId: string, fromPublishedScenarioId?: string): Observable<Scenario> {
    const dto: ScenarioCreationRequestDto = fromPublishedScenarioId ? { projectId, fromPublishedScenarioId } : { projectId };
    return this.http.post(this.SCENARIOS_URL, dto)
      .pipe(
        map((response: ScenarioCompleteDto) => this.mapper.mapScenarioFromScenarioDto(response)),
      );
  }

  get(id: string): Observable<{
    components: PackagingComponent[],
    finishingProcesses: FinishingProcess[],
    materials: Material[],
    materialTransports: MaterialTransport[],
    primaryPackaging: Packaging[],
    secondaryPackaging: Packaging[],
    tertiaryPackaging: Packaging[],
    tertiaryPalletizationPackaging: Packaging[],
    scenario: Scenario,
  }> {
    return this.http.get(`${this.SCENARIOS_URL}/${id}`)
      .pipe(
        switchMap((dto: ScenarioCompleteDto) => {
          return of({
            scenario: this.mapper.mapScenarioFromScenarioDto(dto),
            primaryPackaging: this.mapper.mapPackagingFromScenarioCompleteDto(dto, PackagingLevel.PRIMARY),
            secondaryPackaging: this.mapper.mapPackagingFromScenarioCompleteDto(dto, PackagingLevel.SECONDARY),
            tertiaryPackaging: this.mapper.mapPackagingFromScenarioCompleteDto(dto, PackagingLevel.TERTIARY_BOX),
            tertiaryPalletizationPackaging: this.mapper.mapPackagingFromScenarioCompleteDto(dto, PackagingLevel.TERTIARY_PALLETIZATION),
            components: this.mapper.mapComponentListFromScenarioDto(dto),
            materials: this.mapper.mapMaterialListFromScenarioDto(dto),
            materialTransports: this.mapper.mapMaterialTransportListFromScenarioDto(dto),
            finishingProcesses: this.mapper.mapFinishingProcessListFromScenarioDto(dto),
          });
        }),
      );
  }

  update(scenario: Scenario): Observable<Scenario> {
    const dto: ScenarioMetadataDto = this.mapper.mapScenarioMetadataFromScenario(scenario);
    return this.http.put(`${this.SCENARIOS_URL}/${scenario.id}`, dto)
      .pipe(
        map((response: ScenarioCompleteDto) => this.mapper.mapScenarioFromScenarioDto(response)),
        catchError(err => {
          return of(err);
        }),
      );
  }

  delete(id: string): Observable<string> {
    return this.http.delete(`${this.SCENARIOS_URL}/${id}`).pipe(
      map(() => id),
    );
  }

  duplicate(id: string, name?: string): Observable<{
    components: PackagingComponent[],
    finishingProcesses: FinishingProcess[],
    materials: Material[],
    materialTransports: MaterialTransport[],
    primaryPackaging: Packaging[],
    secondaryPackaging: Packaging[],
    tertiaryPackaging: Packaging[],
    tertiaryPalletizationPackaging: Packaging[],
    scenario: Scenario,
    downstream: Downstream,
  }> {
    return this.http.post(`${this.SCENARIOS_URL}/${id}/duplicate`, { name })
      .pipe(
        switchMap((dto: ScenarioCompleteDto) => {
          return of({
            scenario: this.mapper.mapScenarioFromScenarioDto(dto),
            primaryPackaging: this.mapper.mapPackagingFromScenarioCompleteDto(dto, PackagingLevel.PRIMARY),
            secondaryPackaging: this.mapper.mapPackagingFromScenarioCompleteDto(dto, PackagingLevel.SECONDARY),
            tertiaryPackaging: this.mapper.mapPackagingFromScenarioCompleteDto(dto, PackagingLevel.TERTIARY_BOX),
            tertiaryPalletizationPackaging: this.mapper.mapPackagingFromScenarioCompleteDto(dto, PackagingLevel.TERTIARY_PALLETIZATION),
            components: this.mapper.mapComponentListFromScenarioDto(dto),
            materials: this.mapper.mapMaterialListFromScenarioDto(dto),
            materialTransports: this.mapper.mapMaterialTransportListFromScenarioDto(dto),
            finishingProcesses: this.mapper.mapFinishingProcessListFromScenarioDto(dto),
            downstream: this.mapper.mapDownstreamFromScenarioDto(dto),
          });
        }),
      );
  }
}
