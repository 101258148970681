import { Collaborator } from './collaborator.model';


export const excludeMe = (me: Collaborator) => (c: Collaborator): boolean => {
  return c.id !== me.id;
};

export const excludeOwner = (ownerId: string) => (c: Collaborator): boolean => {
  return c.id !== ownerId;
};
