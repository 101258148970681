<div class="portfolio-detail">
  <div class="portfolio-detail-actions-group">
    <div class="portfolio-title-container">
      <button
        (click)="goBack()"
        class="border-primary go-back-button"
        color="primary"
        data-testid="home-button"
        mat-icon-button
      >
        <mat-icon class="home-icon">home</mat-icon>
      </button>
      <h2 data-testid="portfolio-title" i18n>{{(portfolio$| async)?.name}}</h2>
    </div>
    <div class="portfolio-actions">
      <app-action-button (click)="handleExport()" class="action" [disabled]="loading$ | async">Export</app-action-button>
      <app-action-button (click)="handleDelete()" class="action" [disabled]="loading$ | async">Delete</app-action-button>
    </div>
  </div>

  <div class="scenario-container">
    <div class="scenario-title-container">
      <h2 class="scenario-title" i18n>Scenarios</h2>
      <button (click)="importScenarios()" color="primary" i18n mat-button [disabled]="loading$ | async">Import</button>
    </div>
    <mat-card>
      <app-scenario-table
        *ngIf="portfolio$| async"
        [displayedColumns]="displayedColumns"
        [scenarios]="(portfolio$| async).scenarios"
        (deleteScenario)="handleDeleteScenario($event)">
      </app-scenario-table>
    </mat-card>
  </div>
</div>
