import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { PackagingComponent } from '../../models/component.models';
import {
  addComponent,
  addComponentSuccess,
  componentError,
  deleteAllSuccess,
  deleteComponent,
  deleteComponentSuccess,
  getComponentsSuccess,
  updateComponent,
  updateComponentSuccess,
} from './component.actions';

export const componentFeatureKey = 'component';

export interface ComponentState extends EntityState<PackagingComponent> {
  error: string | null;
  loading: boolean;
  lastCreated: PackagingComponent | null;
}

export const adapter: EntityAdapter<PackagingComponent> = createEntityAdapter<PackagingComponent>();

export const initialState: ComponentState = adapter.getInitialState({
  loading: false,
  error: null,
  lastCreated: null,
});

const reducer = createReducer(
  initialState,
  on(
    addComponent,
    updateComponent,
    deleteComponent, (state, action) =>
      ({ ...state, loading: true, error: null, lastCreated: null }),
  ),
  on(getComponentsSuccess,
    (state, action) =>
      adapter.upsertMany(action.components, { ...state, loading: false, error: null }),
  ),
  on(addComponentSuccess,
    (state, action) =>
      adapter.addOne(action.component, { ...state, loading: false, lastCreated: action.component }),
  ),
  on(updateComponentSuccess,
    (state, action) => {
      const { component } = action;
      const update: Update<PackagingComponent> = {
        id: component.id,
        changes: component,
      };
      return adapter.updateOne(update, { ...state, loading: false });
    },
  ),
  on(deleteComponentSuccess, (state, action) =>
    adapter.removeOne(`${action.component.id}`, { ...state, loading: false }),
  ),
  on(componentError, (state, action) =>
    ({ ...state, error: action.message, loading: false }),
  ),
  on(deleteAllSuccess, (state: ComponentState, action: { packagingId: string }) => {
    const { packagingId } = action;
    const idsToRemove: string[] = Object.values(state.entities)
      .filter((component: PackagingComponent) => component.packagingId === packagingId)
      .map((component: PackagingComponent) => component.id);
    return adapter.removeMany(idsToRemove, state);
  }),
);

export function componentReducer(state: ComponentState | undefined, action: Action) {
  return reducer(state, action);
}
