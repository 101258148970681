import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { Observable } from 'rxjs';
import { ProjectFilterDto } from '../../../../api/dtos/__generated__/projectFilterDto';
import { getProjectFilters, selectProjectFilters } from '../../../modeling/store/project';

export interface ProjectFilters {
  search?: string;
  brand?: string[];
  owner?: string[];
}

@Component({
  selector: 'app-project-filters',
  template: `
    <div id="project-filter-search">
      <app-string-input label="Search" (changeValue)="handleChangeValue('search', $event)"
                        [debounce]="true"></app-string-input>
    </div>
    <div id="project-filter-selectors">
      <app-multiple-selector label="Brand"
                             [options]="(projectFilters$ | async).brands ?? []"
                             [value]="filters.brand"
                             (changeValue)="handleChangeValue('brand', $event)"></app-multiple-selector>
      <app-multiple-selector label="Owner"
                             [options]="(projectFilters$ | async).owners ?? []"
                             [value]="filters.owner"
                             (changeValue)="handleChangeValue('owner', $event)"></app-multiple-selector>
    </div>
    <mat-chip-list>
      <ng-container *ngFor="let selector of projectFiltersSelectors">
        <mat-chip *ngIf="filters[selector.field] && filters[selector.field].length > 0" [removable]="true"
                  [selectable]="false" class="custom-chip-size"
                  (removed)="handleChangeValue(selector.field, undefined)">
          {{filters[selector.field]}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </ng-container>
    </mat-chip-list>
  `,
  styles: [ `
    :host {
      display: block;
      margin-bottom: 2rem;
    }

    #project-filter-selectors {
      display: flex
    }

    #project-filter-search {
      padding: 0 1rem;
    }

    app-multiple-selector {
      padding: 0 1rem;
    }

    .custom-chip-size {
      padding: 0 8px;
      min-height: 20px;
      font-size: 12px;
    }
  ` ],
})
export class ProjectFiltersComponent implements OnInit {
  projectFilters$: Observable<ProjectFilterDto>;
  projectFiltersSelectors: { field: string; label: string; }[] = [
    { field: 'brand', label: 'Brand' },
    { field: 'owner', label: 'Onwer' },
  ];

  @Input() filters: ProjectFilters;
  @Output() changeValue = new EventEmitter<ProjectFilters>();

  constructor(
    private readonly store: Store<AppState>,
  ) {
  }

  ngOnInit(): void {
    this.projectFilters$ = this.store.pipe(select(selectProjectFilters));
    this.store.dispatch(getProjectFilters());
  }

  handleChangeValue(field: string, value: string | undefined) {
    const newFilters = this.filters;
    newFilters[field] = value;
    this.changeValue.emit(newFilters);
  }
}
