import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../store';
import * as fromProject from '../project/modeling/store/project';

@Injectable()
export class ProjectIdInterceptor implements HttpInterceptor {

  projectId: string | undefined = undefined;

  constructor(private readonly store: Store<AppState>) {
    this.store.select(fromProject.projectLock).subscribe((lock) => this.projectId = lock?.projectId);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const addProjectId = this.projectId ? request.clone({ setHeaders: { projectId: this.projectId } }) : request;
    return next.handle(addProjectId);
  }
}
