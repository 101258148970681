import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ComponentDataService } from '../../services/component.data-service';
import {
  addComponent,
  addComponentSuccess,
  componentError,
  deleteAll,
  deleteAllSuccess,
  deleteComponent,
  deleteComponentSuccess,
  updateComponent,
  updateComponentSuccess,
} from './component.actions';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { PackagingComponent } from '../../models/component.models';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store';
import { selectAllByPackagingId } from './component.selectors';
import * as fromMaterial from '../material';
import * as fromFinishingProcess from '../finishing-process';
import * as fromComponentTransport from '../component-transport';
import * as fromWashingProcess from '../washing-process';
import { NotificationService } from '../../../../core/notification.service';


@Injectable()
export class ComponentEffects {

  addComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addComponent),
      switchMap((action: { type: string, packagingId: string }) => {
          const { packagingId } = action;
          return this.componentDataService.create(packagingId)
            .pipe(
              map((c: PackagingComponent) => addComponentSuccess({ component: c })),
              catchError(() => of(componentError({ message: 'Something went wrong when adding new component' }))),
            );
        },
      ),
    ),
  );

  updateComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateComponent),
      map((action: { type: string, component: PackagingComponent }) => action.component),
      switchMap((c: PackagingComponent) => this.componentDataService.update(c)
        .pipe(
          mergeMap((component: PackagingComponent) => {
            if (component.reusable === false) {
              return [
                fromComponentTransport.deleteAll({componentId: component.id}),
                fromWashingProcess.deleteAll({componentId: component.id}),
                updateComponentSuccess({component}),
              ];
            }
            return [ updateComponentSuccess({ component }) ];
          }),
          catchError(() => {
            this.notificationService.warn('Could not save latest component changes');
            return of(componentError({ message: 'Something went wrong when updating component' }));
          }),
        ),
      ),
    ),
  );

  deleteComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteComponent),
      map((action: { type: string, component: PackagingComponent }) => action.component),
      switchMap((component: PackagingComponent) => this.componentDataService.delete(component.id)
        .pipe(
          mergeMap(() => [
            fromComponentTransport.deleteAll({ componentId: component.id }),
            fromWashingProcess.deleteAll({ componentId: component.id }),
            fromMaterial.deleteAll({ componentId: component.id }),
            fromFinishingProcess.deleteAll({ componentId: component.id }),
            deleteComponentSuccess({ component }),
          ]),
          catchError(() => of(componentError({ message: 'Something went wrong when deleting component' }))),
        ),
      ),
    ),
  );

  deleteAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAll),
      switchMap((action: { type: string, packagingId: string }) => this.store.pipe(
        select(selectAllByPackagingId(action.packagingId)),
        filter((c: PackagingComponent[]) => !!c),
        take(1),
        mergeMap((components: PackagingComponent[]) => [
          ...components.flatMap((c: PackagingComponent) => [
            fromComponentTransport.deleteAll({ componentId: c.id }),
            fromWashingProcess.deleteAll({ componentId: c.id }),
            fromMaterial.deleteAll({ componentId: c.id }),
            fromFinishingProcess.deleteAll({ componentId: c.id }),
          ]),
          deleteAllSuccess({ packagingId: action.packagingId }),
        ])),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly componentDataService: ComponentDataService,
    private readonly store: Store<AppState>,
    private readonly notificationService: NotificationService,
  ) {
  }

}
