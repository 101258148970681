import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import portfolioReducer, { portfolioFeatureKey } from './portfolio.reducer';
import { PortfolioEffects } from './portfolio.effects';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(portfolioFeatureKey, portfolioReducer),
    EffectsModule.forFeature([PortfolioEffects]),
  ]
})
export class PortfolioStoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: PortfolioStoreModule) {
    if (parentModule) {
      throw new Error('PortfolioStoreModule is already loaded');
    }
  }
}
