import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { WashingProcessEffects } from './washing-process.effects';
import { WashingProcessDataService } from '../../services/washing-process.data-service';
import { washingProcessReducer } from './washing-process.reducer';
import { WashingProcessMapperService } from '../../services/washing-process-mapper.service';


export const washingProcessFeatureKey = 'washingProcess';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(washingProcessFeatureKey, washingProcessReducer),
    EffectsModule.forFeature([ WashingProcessEffects ]),
  ],
  providers: [
    WashingProcessDataService,
    WashingProcessMapperService
  ],
})

export class WashingProcessStoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: WashingProcessStoreModule) {
    if (parentModule) {
      throw new Error('WashingProcessStoreModule is already loaded');
    }
  }
}
