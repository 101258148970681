import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetDefaultVersionHeaderModule } from './set-default-version-header/set-default-version-header.module';
import { SetDefaultVersionTableModule } from './set-default-version-table/set-default-version-table.module';
import { SelectVersionModule } from './select-version/select-version.module';


@NgModule({
  declarations: [],
  exports: [
    SetDefaultVersionHeaderModule,
    SetDefaultVersionTableModule,
    SelectVersionModule,
  ],
  imports: [
    CommonModule,
    SetDefaultVersionHeaderModule,
    SetDefaultVersionTableModule,
    SelectVersionModule,
  ],
})
export class VersionModule {
}
