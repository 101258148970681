import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { distinctUntilChanged, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-string-input',
  template: `
    <mat-form-field class="search-input">
      <mat-label>{{label}}</mat-label>
      <input (keyup)="handleChange($event)" matInput>
    </mat-form-field>`,
  styles: [ `
    :host {
      width: 100%;
    }
    .mat-form-field {
      margin-top: 0;
    }
  ` ],
})
export class StringInputComponent implements OnInit, OnDestroy {
  private readonly value = new Subject<string | undefined>();
  subscription: Subscription = new Subscription();

  @Input() label: string | undefined;
  @Input() debounce: boolean | undefined;
  @Output() changeValue = new EventEmitter<string>();

  ngOnInit(): void {
    this.subscription.add(
      this.value
        .pipe(debounceTime(this.debounce ? 200 : 0), distinctUntilChanged())
        .subscribe((value) => this.changeValue.emit(value))
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  handleChange(event: Event) {
    this.value.next((event.target as HTMLInputElement).value);
  }
}
