import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ConversionProcessEffects } from './conversion-process.effects';
import { ConversionProcessDataService } from '../../services/conversion-process.data-service';
import { conversionProcessReducer } from './conversion-process.reducer';
import { ConversionProcessMapperService } from '../../services/conversion-process-mapper.service';


export const conversionProcessFeatureKey = 'conversionProcess';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(conversionProcessFeatureKey, conversionProcessReducer),
    EffectsModule.forFeature([ ConversionProcessEffects ]),
  ],
  providers: [
    ConversionProcessDataService,
    ConversionProcessMapperService
  ],
})

export class ConversionProcessStoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: ConversionProcessStoreModule) {
    if (parentModule) {
      throw new Error('ConversionProcessStoreModule is already loaded');
    }
  }
}
