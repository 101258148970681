import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { MaterialTransportEffects } from './material-transport.effects';
import { StoreModule } from '@ngrx/store';
import { materialTransportReducer, materialTransportsFeatureKey } from './material-transport.reducer';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(materialTransportsFeatureKey, materialTransportReducer),
    EffectsModule.forFeature([ MaterialTransportEffects ]),
  ],
})
export class MaterialTransportStoreModule {}
