import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Project } from '../../models/project.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first, map, tap } from 'rxjs/operators';
import { CollaboratorDataService } from '../../../../collaborator/collaborator.data-service';
import { FullNamePipe } from '../../../../collaborator/fullName.pipe';
import { BrandType } from '../../../../store/reference-data/models/reference-data.model';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '../../../../store';
import { select, Store } from '@ngrx/store';
import * as fromReferenceData from '../../../../store/reference-data/store';
import { arrayNotEmpty } from '../../../../shared/rxjs-utils/rxjsFilters';
import { dropdownValidator } from '../../../../shared/validators/dropdown-validator';
import { asyncAutocompleteFilter } from '../../../../shared/autocomplete-filter';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project-dialog.component.html',
  styleUrls: [ './create-project-dialog.component.scss' ],
})
export class CreateProjectDialogComponent implements OnInit, OnDestroy {

  projectForm: FormGroup;
  project: Project;
  brandTypes$: Observable<BrandType[]>;
  filteredBrandTypes$: Observable<BrandType[]>;
  subscription: Subscription = new Subscription();

  get addOrCreate(): number {
    return !!this.data ? 1 : 0;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store<AppState>,
    private readonly collaboratorService: CollaboratorDataService,
    private readonly fullNamePipe: FullNamePipe,
    @Inject(MAT_DIALOG_DATA) public data: Project,
  ) {
    this.project = data;
    this.projectForm = this.fb.group({
      id: data?.id,
      name: [ data?.name, Validators.required ],
      brandType: data?.brandType,
      code: data?.code,
      description: data?.description,
      owner: [ { value: data?.ownerName, disabled: true } ],
      createdAt: [ {
        value: data?.createdAt ? data?.createdAt?.toLocaleDateString() : new Date().toLocaleDateString(),
        disabled: true,
      } ],
    });
    this.subscription.add(
      this.projectForm
        .valueChanges
        .subscribe((project: Project) => {
          this.project = { ...this.project, ...project };
        }));
  }

  ngOnInit(): void {
    this.brandTypes$ = this.store.pipe(select(fromReferenceData.selectBrandTypes), arrayNotEmpty);
    const { brandType } = this.projectForm.controls;

    this.subscription.add(
      this.collaboratorService.getMe()
        .pipe(
          first(),
          map(this.fullNamePipe.transform),
        )
        .subscribe((username) =>
          this.projectForm.controls.owner.setValue(username, { emitEvent: false }),
        ),
    );
    this.subscription.add(this.brandTypes$.subscribe(types => brandType.setValidators([ dropdownValidator(types) ])));
    this.filteredBrandTypes$ = asyncAutocompleteFilter(brandType.valueChanges, this.brandTypes$);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  displayType(input: Partial<{ value: string }>): string {
    return input?.value;
  }

  blur() {
    (document.activeElement as HTMLElement)?.blur();
  }

  clearInput(controlName: string) {
    const control = this.projectForm.get(controlName);
    if (control.enabled) {
      control.setValue('');
    }
  }
}
