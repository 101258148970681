const domainNameClient = 'https://uat.eqo-pack.quantis.solutions';
export const environment = {
  production: true,
  name: 'uat',
  url: domainNameClient,
  apiUrl: 'https://api.uat.eqo-pack.quantis.solutions/v2',
  enableSentry: true,
  userManagement: {
    identityPoolId: null,
    centralUserPoolDomain: null,
  },
  Auth: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_0VC7LhGnX',
    userPoolWebClientId: '3tpdq2dq82fgho39flofcjhdif',

    oauth: {
      domain: 'qristo-uat.auth.eu-west-1.amazoncognito.com',
      scope: ['openid'],
      redirectSignIn: `${ domainNameClient }/signin`,
      redirectSignOut: `${ domainNameClient }/signout`,
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  },
  features: {
    portfolio: true,
    multipleMarkets: true,
  },
  theme: 'default',
};
