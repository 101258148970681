import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { getReferenceData, getReferenceDataError, getReferenceDataSuccess } from './reference-data.actions';
import { ReferenceData, WithLabelAndValue } from '../models/reference-data.model';

export const referenceDataFeatureKey = 'referenceData';

export interface ReferenceDataState extends EntityState<ReferenceData> {
  // additional entities state properties
  loading: boolean;
  error: string;
}

export interface ReferenceDataFeatureState {
  referenceData: ReferenceDataState;
}

export const adapter: EntityAdapter<ReferenceData> = createEntityAdapter<ReferenceData>();

export const initialState: ReferenceDataState = adapter.getInitialState({
  // additional entity state properties
  loading: false,
  error: null,
});

export const REFERENCE_DATA_ID = 1;

export const reducer = createReducer(
  initialState,
  on(getReferenceData, (state, action) =>
    ({ ...state, loading: true }),
  ),
  on(getReferenceDataError, ((state, action) =>
    ({ ...state, loading: false, error: action.message })),
  ),
  on(getReferenceDataSuccess, ((state, action) => {
    return adapter.setOne(
      { id: REFERENCE_DATA_ID, ...sortReferenceData(action.referenceData) },
      { ...state, loading: false, error: null },
    );
  })),
);

export function sortReferenceData(referenceData: ReferenceData): ReferenceData {
  return {
    ...referenceData,
    countries: referenceData.countries.slice().sort(compareByValue),
    baseMaterialTypes: referenceData.baseMaterialTypes.slice().sort(compareByValue),
    brandTypes: referenceData.brandTypes.slice().sort(compareByValue),
    packagingTypes: referenceData.packagingTypes.slice().sort(compareByValue),
    productTypes: referenceData.productTypes.slice().sort(compareByValue),
    statusTypes: referenceData.statusTypes.slice().sort(compareByValue),
    finishingProcessTypes: referenceData.finishingProcessTypes.slice().sort(compareByValue),
    transportTypes: referenceData.transportTypes.slice().sort(compareByValue),
    componentTypes: referenceData.componentTypes.slice().sort(compareByValue)
      .map(ct => {
        return { ...ct, componentSubtypes: ct.componentSubtypes.slice().sort(compareByValue) };
      }),
    materialTypes: referenceData.materialTypes.slice().sort(compareByValue)
      .map(mt => {
        return { ...mt, conversionProcessTypes: mt.conversionProcessTypes.slice().sort(compareByValue) };
      })
  };
}

export function compareByValue(a: WithLabelAndValue, b: WithLabelAndValue): number {
  return a.value?.localeCompare(b.value);
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors((state: ReferenceDataFeatureState) => state.referenceData);
