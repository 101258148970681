import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../../../core/notification.service';
import { catchError, map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { EndOfLife } from '../models/end-of-life';
import { EndOfLifeMapperService } from '../mappers/end-of-life-mapper.service';
import { EndOfLifeDto } from '../../../api/dtos/__generated__/endOfLifeDto';
import { UpdateEndOfLifeDto } from 'src/app/api/dtos/__generated__/updateEndOfLifeDto';

@Injectable({
  providedIn: 'root',
})
export class EndOfLifeDataService {
  private readonly BASE_URL = 'end-of-lives';

  constructor(
    private readonly http: HttpClient,
    private readonly notificationService: NotificationService,
    private readonly endOfLifeMapperService: EndOfLifeMapperService
  ) {
  }

  findAll(materialId: string): Observable<EndOfLife[]> {
    return this.http.get(`${environment.apiUrl}/${this.BASE_URL}?materialId=${materialId}`).pipe(
      map((dto: EndOfLifeDto[]) => this.endOfLifeMapperService.fromList(dto)),
      catchError(err => {
        this.notificationService.warn('Could not retrieve end of lives');
        throw err;
      }),
    );
  }

  update(id: string, endOfLife: EndOfLife): Observable<EndOfLife> {
    const body: UpdateEndOfLifeDto = this.endOfLifeMapperService.to(endOfLife);
    return this.http.put(`${environment.apiUrl}/${this.BASE_URL}/${id}`, body).pipe(
      map((dto: EndOfLifeDto) => this.endOfLifeMapperService.from(dto)),
      catchError(err => {
        this.notificationService.warn('Could not update end of life');
        throw err;
      }),
    );
  }
}
