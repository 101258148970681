import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { Material } from '../../models/material.models';
import {
  addMaterial,
  addMaterialSuccess,
  deleteAllSuccess,
  deleteMaterial,
  deleteMaterialSuccess,
  getMaterial,
  getMaterialsSuccess,
  getMaterialSuccess,
  materialError,
  updateCompostable,
  updateConversionElectricityType,
  updateConversionProcessElectricityTypeAndCountry,
  updateConversionProcessType,
  duplicateMaterial,
  duplicateMaterialSuccess,
  updateMaterial,
  updateMaterialSuccess,
  updateRecyclable,
  updateRecyclingRate,
  updateRecyclingRateType,
} from './material.actions';

export const materialFeatureKey = 'material';

export interface MaterialState extends EntityState<Material> {
  loading: boolean;
  lastCreated: Material | null;
  error: string | null;
}

export const adapter: EntityAdapter<Material> = createEntityAdapter<Material>();

export const initialState: MaterialState = adapter.getInitialState({
  loading: false,
  lastCreated: null,
  error: null,
});

export const reducer: ActionReducer<MaterialState, Action> = createReducer(
  initialState,
  on(
    addMaterial,
    getMaterial,
    duplicateMaterial,
    updateMaterial,
    updateConversionProcessType,
    updateConversionElectricityType,
    updateConversionProcessElectricityTypeAndCountry,
    updateRecyclable,
    updateRecyclingRate,
    updateCompostable,
    updateRecyclingRateType,
    deleteMaterial, (state, action) =>
      ({ ...state, loading: true, error: null, lastCreated: null }),
  ),
  on(getMaterialsSuccess, (state, action) =>
    adapter.upsertMany(action.materials, { ...state, loading: false }),
  ),
  on(addMaterialSuccess, duplicateMaterialSuccess, (state, action) =>
    adapter.addOne(action.material, { ...state, loading: false, lastCreated: action.material }),
  ),
  on(getMaterialSuccess, (state, action) =>
    adapter.upsertOne(action.material, { ...state, loading: false })),
  on(updateMaterialSuccess, (state, action) => {
      const { material } = action;
      const update: Update<Material> = {
        id: material.id,
        changes: material,
      };
      return adapter.updateOne(update, { ...state, loading: false });
    },
  ),
  on(deleteMaterialSuccess, (state, action) =>
    adapter.removeOne(action.id, { ...state, loading: false }),
  ),
  on(materialError, (state, action) =>
    ({ ...state, loading: false, error: action.message })),
  on(deleteAllSuccess, (state: MaterialState, action: { type: string, componentId: string }) => {
    const { componentId } = action;
    const ids: string[] = Object.values(state.entities)
      .filter((m: Material) => m.componentId === componentId)
      .map((m: Material) => m.id);
    return adapter.removeMany(ids, state);
  }),
);

export function materialReducer(state: MaterialState | undefined, action: Action) {
  return reducer(state, action);
}

