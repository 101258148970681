import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../../../store';
import { adapter, componentFeatureKey, ComponentState } from './component.reducer';
import { PackagingComponent } from '../../models/component.models';
import { Dictionary } from '@ngrx/entity';

export const selectComponentState = createFeatureSelector<AppState, ComponentState>(componentFeatureKey);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectComponentState);

export const selectById = (componentId: string) => createSelector(
  selectEntities,
  (components: Dictionary<PackagingComponent>) => components[componentId],
);

export const selectLastCreated = createSelector(
  selectComponentState,
  (state: ComponentState) => !state.loading ? state.lastCreated : undefined,
);

export const isLoading = createSelector(
  selectComponentState,
  (state: ComponentState) => state.loading,
);

export const selectAllByPackagingId = (id: string) => createSelector(
  selectAll,
  isLoading,
  (components: PackagingComponent[], loading: boolean) => {
    if (!loading) {
      return components
        .filter((component: PackagingComponent) =>
          component.packagingId === id,
        );
    }
  },
);

export const selectName = (id: string) => createSelector(
  selectById(id),
  (component: PackagingComponent) => component?.name,
);

export const selectType = (id: string) => createSelector(
  selectById(id),
  (component: PackagingComponent) => component?.type,
);

export const selectSubtype = (id: string) => createSelector(
  selectById(id),
  (component: PackagingComponent) => component?.subtype,
);

export const selectReusable = (id: string) => createSelector(
  selectById(id),
  (component: PackagingComponent) => component?.reusable,
);

export const selectNumberOfUse = (id: string) => createSelector(
  selectById(id),
  (component: PackagingComponent) => component?.numberOfUse,
);

export const somethingWentWrong = createSelector(
  selectComponentState,
  (state: ComponentState) => state.error
);

