import { Component, OnInit } from '@angular/core';
import { ToggleMenuService } from '../toggle-menu.service';

@Component({
  selector: 'app-open-menu-button',
  templateUrl: './open-menu-button.component.html',
  styleUrls: [ './open-menu-button.component.scss' ],
})
export class OpenMenuButtonComponent implements OnInit {

  constructor(private readonly toggleMenuService: ToggleMenuService) { }

  ngOnInit(): void {
  }

  toggleMenu(): void {
    this.toggleMenuService.toggle();
  }

}
