import { Pipe, PipeTransform } from '@angular/core';
import { PackagingLevel } from '../../project/modeling/models/packaging.models';
import { PackagingLevelDataSetNames } from "../horizontal-bar-chart/horizontal-bar-chart.model";

@Pipe({
  name: 'packagingLevel',
})
export class PackagingLevelPipe implements PipeTransform {

  PACKAGING_LEVEL_TO_LABELS_MAP = new Map<PackagingLevel | string, string | PackagingLevel>([
      [ PackagingLevel.PRIMARY, PackagingLevelDataSetNames.CONSUMER_PACKAGING.value],
      [ PackagingLevel.SECONDARY, PackagingLevelDataSetNames.SALES_PACKAGING.value],
      [ PackagingLevel.TERTIARY_BOX, PackagingLevelDataSetNames.TRADE_PACKAGING.value],
      [ PackagingLevel.TERTIARY_PALLETIZATION, PackagingLevelDataSetNames.TRANSPORT_PACKAGING.value],
      [ PackagingLevelDataSetNames.CONSUMER_PACKAGING.value, PackagingLevel.PRIMARY ],
      [ PackagingLevelDataSetNames.SALES_PACKAGING.value, PackagingLevel.SECONDARY ],
      [ PackagingLevelDataSetNames.TRADE_PACKAGING.value, PackagingLevel.TERTIARY_BOX ],
      [ PackagingLevelDataSetNames.TRANSPORT_PACKAGING.value, PackagingLevel.TERTIARY_PALLETIZATION ],
    ],
  );

  transform(value: PackagingLevel | string, ...args: unknown[]): PackagingLevel | string {
    if (this.PACKAGING_LEVEL_TO_LABELS_MAP.get(value)) {
      return this.PACKAGING_LEVEL_TO_LABELS_MAP.get(value);
    }
    return value;
  }

}
