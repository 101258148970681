import { Pipe, PipeTransform } from '@angular/core';
import { ElectricityType } from '../../project/modeling/models/electricity.model';

@Pipe({
  name: 'electricityType'
})
export class ElectricityTypePipe implements PipeTransform {
  transform(electricityType: ElectricityType, ...args: any[]): string {
    switch (electricityType) {
      case 'COUNTRY_SPECIFIC':
        return 'Country specific';
      case 'DEFAULT':
        return 'Default';
      case 'GREEN':
        return 'Renewable electricity';
    }
  }
}
