import { NgModule } from '@angular/core';
import { PortfolioDetailComponent } from './portfolio-detail.component';
import {
  ScenarioLibraryTableModule
} from '../../../scenario-library/scenario-library-table/scenario-library-table.module';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { ScenarioTableModule } from '../../../scenario-library/scenario-table/scenario-table.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [PortfolioDetailComponent],
  imports: [
    ScenarioLibraryTableModule,
    MatCardModule,
    MatButtonModule,
    ScenarioTableModule,
    CommonModule,
    SharedModule,
    MatIconModule
  ],
  exports: [PortfolioDetailComponent]
})
export class PortfolioDetailModule {
}
