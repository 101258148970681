import { createAction, props } from '@ngrx/store';
import { ReferenceData } from '../models/reference-data.model';


export const getReferenceData = createAction(
  '[ReferenceData/API] Load ReferenceData',
);

export const getReferenceDataSuccess = createAction(
  '[ReferenceData/API] Load ReferenceData Success',
  props<{ referenceData: ReferenceData }>(),
);

export const getReferenceDataError = createAction(
  '[ReferenceData/API] Load ReferenceData Error',
  props<{ message: string }>(),
);

