import { Injectable } from '@angular/core';
import { EntityReferenceMapperService } from './entity-reference-mapper.service';
import { ScenarioPackagingDesignAssessmentDto } from '../../../api/dtos/__generated__/scenarioPackagingDesignAssessmentDto';
import { ScenarioPackagingDesignAssessment } from '../models/scenario-packaging-design-assessment.model';
import { PackagingDesignIndicator } from '../models/packaging-design-indicator.model';
import { AbstractMapper } from '../../abstract-mapper';

@Injectable({
  providedIn: 'root',
})
// tslint:disable-next-line:max-line-length
export class ScenarioPackagingDesignAssessmentMapperService extends AbstractMapper<ScenarioPackagingDesignAssessmentDto, ScenarioPackagingDesignAssessment> {

  constructor(private readonly entityReferenceMapper: EntityReferenceMapperService) {
    super();
  }

  from(dto: ScenarioPackagingDesignAssessmentDto): ScenarioPackagingDesignAssessment | undefined {
    if (!dto) {
      return;
    }

    const {
      scenario,
      tertiaryPackagingPalletizationValue,
      tertiaryPackagingBoxValue,
      secondaryPackagingValue,
      primaryPackagingValue,
      totalValue,
      indicator,
    } = dto;

    return {
      scenario: this.entityReferenceMapper.from(scenario),
      tertiaryPackagingPalletizationValue,
      tertiaryPackagingBoxValue,
      secondaryPackagingValue,
      primaryPackagingValue,
      totalValue,
      indicator: indicator as PackagingDesignIndicator,
    } as ScenarioPackagingDesignAssessment;
  }
}
