import { createAction, props } from '@ngrx/store';
import { MaterialTransport } from '../../models/material.models';

export const getMaterialTransportsSuccess = createAction(
  '[MaterialTransport/API] Get MaterialTransport Success',
  props<{ materialTransports: MaterialTransport[] }>(),
);

export const addMaterialTransport = createAction(
  '[MaterialTransport/API] Add MaterialTransport',
  props<{ transport: Partial<MaterialTransport> }>(),
);

export const addMaterialTransportSuccess = createAction(
  '[MaterialTransport/API] Add MaterialTransport Success',
  props<{ materialTransport: MaterialTransport }>(),
);

export const updateMaterialTransport = createAction(
  '[MaterialTransport/API] Update MaterialTransport',
  props<{ materialTransport: Partial<MaterialTransport> }>(),
);

export const updateMaterialTransportSuccess = createAction(
  '[MaterialTransport/API] Update MaterialTransport Success',
  props<{ materialTransport: MaterialTransport }>(),
);

export const deleteMaterialTransport = createAction(
  '[MaterialTransport/API] Delete MaterialTransport',
  props<{ id: string }>(),
);

export const deleteMaterialTransportSuccess = createAction(
  '[MaterialTransport/API] Delete MaterialTransport Success',
  props<{ id: string }>(),
);

export const materialTransportError = createAction(
  '[MaterialTransport/API] MaterialTransports Error',
);

export const deleteAll = createAction(
  '[MaterialTransport/API] Delete All MaterialTransports Of Given Material',
  props<{ materialId: string }>(),
);
