import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MadeByComponent } from './made-by.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [ MadeByComponent ],
  exports: [
    MadeByComponent,
  ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
    ],
})
export class MadeByModule { }
