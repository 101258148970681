import { Injectable } from '@angular/core';
import { MassType, Material, RecyclingRateType } from '../models/material.models';
import { ConversionProcessType } from '../models/conversion-process.model';
import { Country, MaterialType } from '../../../store/reference-data/models/reference-data.model';
import { ElectricityType } from '../models/electricity.model';

@Injectable({
  providedIn: 'root',
})
export class ActionToMaterialService {

  constructor() { }

  map(action: {
    id: string,
    type: string,
    recyclingRate?: number,
    recyclingRateType?: RecyclingRateType,
    compostable?: boolean,
    recyclable?: boolean,
    conversionProcessType?: ConversionProcessType,
    electricityCountry?: Country,
    electricityType?: ElectricityType,
    recycledContent?: number,
    massType?: MassType,
    mass?: number,
    grammage?: number,
    materialType?: MaterialType,
    surface?: number,
  }): Partial<Material> {
    const { type, ...material } = action;
    if (material?.conversionProcessType || material?.electricityCountry || material?.electricityType) {
      const { id, ...others } = material;
      return {
        id,
        conversionProcess: {
          ...others,
        },
      } as Partial<Material>;
    }
    return {
      ...material,
    } as Partial<Material>;
  }
}
