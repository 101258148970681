import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortfolioTableComponent } from './portfolio-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SearchInputModule } from '../../../shared/search-input/search-input.module';
import { SharedModule } from '../../../shared/shared.module';
import { MatCardModule } from '@angular/material/card';
import { CreatePortfolioDialogModule } from '../create-portfolio-dialog/create-portfolio-dialog.module';


@NgModule({
  declarations: [PortfolioTableComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
    SharedModule,
    SearchInputModule,
    MatCardModule,
    CreatePortfolioDialogModule
  ],
  exports: [PortfolioTableComponent]
})
export class PortfolioTableModule {
}
