import {Injectable} from '@angular/core';
import {Collaborator} from './collaborator.model';
import {CollaboratorDto} from '../api/dtos/__generated__/collaboratorDto';
import {AbstractMapper} from '../project/abstract-mapper';
import {UpdateCollaboratorDto} from '../api/dtos/__generated__/updateCollaboratorDto';

@Injectable({
  providedIn: 'root',
})
export class CollaboratorMapperService extends AbstractMapper<CollaboratorDto, Collaborator> {

  constructor() {
    super();
  }

  from(dto: CollaboratorDto): Collaborator {
    if (!dto) {
      return null;
    }
    const {id, firstname, lastname, isAdmin, enabled} = dto;
    return {
      id,
      firstname,
      lastname,
      isAdmin,
      enabled,
    } as Collaborator;
  }

  to(collaborator: Collaborator): CollaboratorDto {
    if (!collaborator) {
      return null;
    }
    const {id, firstname, lastname, isAdmin, enabled} = collaborator;
    return {id, firstname, lastname, isAdmin, enabled};
  }

  toUpdateDto(collaborator: Collaborator): UpdateCollaboratorDto {
    if (!collaborator) {
      return null;
    }
    const {id, firstname, lastname, enabled} = collaborator;
    return {id, firstname, lastname, enabled} as UpdateCollaboratorDto;
  }
}
