import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EqopackBrandModule } from './eqopack-brand/eqopack-brand.module';
import { ThemeModule } from '../core/theme/theme.module';
import { TenantLogoService } from './tenant-logo.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EqopackBrandModule,
    ThemeModule,
  ],
  exports: [
    EqopackBrandModule,
  ],
  providers: [
    TenantLogoService,
  ],
})
export class TenantModule {}
