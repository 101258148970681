import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectTableComponent } from './project-table.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRippleModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from '../../../../shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { CreateProjectDialogModule } from '../../../modeling/components/create-project-dialog/create-project-dialog.module';
import { MatButtonModule } from '@angular/material/button';
import { DeleteConfirmationModalModule } from '../../../../shared/delete-confirmation-modal/delete-confirmation-modal.module';
import { CollaboratorDataService } from '../../../../collaborator/collaborator.data-service';
import { ProjectFiltersComponent } from './project-filters.component';
import { FormModule } from '../../../../shared/form/form.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatSortModule } from '@angular/material/sort';


@NgModule({
  declarations: [
    ProjectTableComponent,
    ProjectFiltersComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatRippleModule,
    MatCardModule,
    SharedModule,
    MatDialogModule,
    CreateProjectDialogModule,
    MatButtonModule,
    DeleteConfirmationModalModule,
    FormModule,
    MatChipsModule,
    MatSortModule,
  ],
  providers: [
    CollaboratorDataService,
  ],
  exports: [
    ProjectTableComponent,
  ],
})
export class ProjectTableModule {}
