import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '../../../../../store';
import { adapter, TertiaryPackagingState } from '../tertiary/tertiary-packaging.reducer';
import { Dictionary } from '@ngrx/entity';
import { Packaging } from '../../../models/packaging.models';
import {
  tertiaryPalletizationPackagingFeatureKey,
  TertiaryPalletizationPackagingState,
} from './tertiary-palletization-packaging.reducer';

export const selectTertiaryPalletizationPackagingState: MemoizedSelector<AppState, TertiaryPalletizationPackagingState> =
  createFeatureSelector(tertiaryPalletizationPackagingFeatureKey);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectTertiaryPalletizationPackagingState);

export const selectById = (packagingId: string) => createSelector(
  selectEntities,
  (packaging: Dictionary<Packaging>) => packaging[packagingId],
);

export const selectLastCreated = createSelector(
  selectTertiaryPalletizationPackagingState,
  (state: TertiaryPackagingState) => !state.loading ? state.lastCreated : undefined,
);

export const isLoading = createSelector(
  selectTertiaryPalletizationPackagingState,
  (state: TertiaryPackagingState) => state.loading,
);

export const selectByScenarioId = (scenarioId: string) => createSelector(
  selectAll,
  (packaging: Packaging[]) => {
    const [ palletization ] = packaging
      .filter((p: Packaging) => p?.scenarioId === scenarioId);
    return palletization;
  },
);

export const selectPackagingId = (scenarioId: string) => createSelector(
  selectByScenarioId(scenarioId),
  (p: Packaging) => p?.id,
);
