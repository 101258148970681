import { createAction, props } from '@ngrx/store';
import { ProjectAssessment } from '../models/project-assessment.model';
import { FunctionalUnit } from '../../../store/reference-data/models/reference-data.model';
import { Version } from '../../../store/versions-data/models/versions-data.model';

export const computeAssessment = createAction(
  '[Assessment/API] Compute Assessment',
  props<{ projectId: string, versionHash: string }>(),
);

export const deleteAssessment = createAction(
  '[Assessment/API] Delete Assessment',
  props<{ projectId: string }>(),
);

export const computeAssessmentSuccess = createAction(
  '[Assessment/API] Compute Assessment Success',
  props<{ assessment: ProjectAssessment }>(),
);

export const computeAssessmentError = createAction(
  '[Assessment/API] Error',
  props<{ message: string }>(),
);

export const setFunctionalUnit = createAction(
  '[Assessment] Set Functional Unit',
  props<{ functionalUnit: FunctionalUnit }>(),
);

export const allowWarningsDisplay = createAction(
  '[Assessment] Allow Warnings Display',
);

export const dismissWarningsDisplay = createAction(
  '[Assessment] Dismiss Warnings Display',
);
