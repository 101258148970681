import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Material, MaterialTransport } from '../models/material.models';
import { MaterialMapperService } from './material-mapper.service';
import { MaterialMetadataDto } from '../../../api/dtos/__generated__/materialMetadataDto';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { MaterialCompleteDto } from '../../../api/dtos/__generated__/materialCompleteDto';

@Injectable({
  providedIn: 'root',
})
export class MaterialDataService {

  MATERIALS_URL = `${environment.apiUrl}/materials`;

  constructor(
    private readonly mapper: MaterialMapperService,
    private readonly http: HttpClient,
  ) {
  }

  create(componentId: string): Observable<Material> {
    const dto: MaterialMetadataDto = { componentId };
    return this.http.post(this.MATERIALS_URL, dto).pipe(
      map((material: MaterialCompleteDto) => this.mapper.mapMaterialFromMaterialDto(material)),
    );
  }

  get(materialId: string): Observable<Material> {
    return this.http.get(`${this.MATERIALS_URL}/${materialId}`).pipe(
      map((material: MaterialCompleteDto) => this.mapper.mapMaterialFromMaterialDto(material)),
    );
  }

  update(material: Material): Observable<Material> {
    const dto: MaterialMetadataDto = this.mapper.mapMaterialMetadataDtoFromMaterial(material);
    return this.http.put(`${this.MATERIALS_URL}/${material.id}`, dto).pipe(
      map((response: MaterialCompleteDto) => this.mapper.mapMaterialFromMaterialDto(response)),
    );
  }

  duplicate(id: string): Observable<{
    material: Material,
    materialTransports: MaterialTransport[],
  }> {
    return this.http.post(`${this.MATERIALS_URL}/${id}/duplicate`, null).pipe(
      map((response: MaterialCompleteDto) => {
        return {
          material: this.mapper.mapMaterialFromMaterialDto(response),
          materialTransports: this.mapper.mapMaterialTransportListFromMaterialDto(response),
        };
      }),
    );
  }

  delete(id: string): Observable<string> {
    return this.http.delete(`${this.MATERIALS_URL}/${id}`).pipe(
      map(() => id),
    );
  }
}
