import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PublishedScenarioMetadataMapperService } from './published-scenario-metadata-mapper.service';
import { PublishedScenarioTableDataMapperService } from './published-scenario-table-data-mapper.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    PublishedScenarioMetadataMapperService,
    PublishedScenarioTableDataMapperService,
    DatePipe,
  ],
})
export class ScenarioLibraryMappersModule {}
