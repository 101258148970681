<div
  [class.menu-close]="!(isMenuOpen$ | async)"
  [class.menu-open]="isMenuOpen$ | async"
  id="container"
  data-testid="container"
>
  <mat-toolbar id="toolbar">
    <app-profile-button></app-profile-button>
  </mat-toolbar>
</div>

