import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './jwt.interceptor';
import { LoadingService } from './loading.service';
import { AuthorizationRequiredErrorComponent } from './components/authorization-required-error.component';
import { ThemeModule } from './theme/theme.module';
import { LicenseStatusExceptionInterceptor } from './license-status-exception.interceptor';
import { LicenseStatusDialogModule } from './license-status-dialog/license-status-dialog.module';
import { ProjectIdInterceptor } from './project-id.interceptor';
import {NotificationService} from './notification.service';


@NgModule({
  declarations: [
    AuthorizationRequiredErrorComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ThemeModule,
    LicenseStatusDialogModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProjectIdInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LicenseStatusExceptionInterceptor,
      multi: true,
    },
    LoadingService,
    NotificationService
  ],
  exports: [
    ThemeModule,
    LicenseStatusDialogModule,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
