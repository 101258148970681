import { Injectable } from '@angular/core';
import { AbstractMapper } from '../../project/abstract-mapper';
import { PortfolioMetadataDto } from '../../api/dtos/__generated__/portfolioMetadataDto';
import { PortfolioMetadata } from '../models/portfolio-metadata.model';

@Injectable({
  providedIn: 'root',
})
export class PortfolioMetadataMapperService extends AbstractMapper<PortfolioMetadataDto, PortfolioMetadata> {
  constructor() {
    super();
  }

  from(dto: PortfolioMetadataDto): PortfolioMetadata {
    return { ...dto, createdAt: new Date(dto.createdAt) };
  }
}
