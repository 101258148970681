import { Injectable } from '@angular/core';
import { Scenario } from '../models/scenario.model';
import { PackagingCompleteDto } from '../../../api/dtos/__generated__/packagingCompleteDto';
import { ComponentTransport, PackagingComponent } from '../models/component.models';
import { PackagingMapperService } from './packaging-mapper.service';
import { FinishingProcess } from '../models/finishing-process.models';
import { FinishingProcessMapperService } from './finishing-process-mapper.service';
import { Material, MaterialTransport } from '../models/material.models';
import { MaterialMapperService } from './material-mapper.service';
import { ScenarioMetadataDto } from '../../../api/dtos/__generated__/scenarioMetadataDto';
import { DownstreamMapperService } from '../../../packaging/downstream/mappers/downstream-mapper.service';
import { Packaging, PackagingLevel } from '../models/packaging.models';
import { ComponentCompleteDto } from '../../../api/dtos/__generated__/componentCompleteDto';
import { ScenarioCompleteDto } from '../../../api/dtos/__generated__/scenarioCompleteDto';
import { Downstream } from '../../../packaging/downstream/models/downstream';
import { ComponentMapperService } from './component-mapper.service';
import { WashingProcess } from '../models/washing-process.models';
import { ConversionProcess } from '../models/conversion-process.model';

@Injectable({
  providedIn: 'root',
})
export class ScenarioMapperService {

  constructor(
    private readonly packagingMapperService: PackagingMapperService,
    private readonly componentMapperService: ComponentMapperService,
    private readonly finishingProcessMapperService: FinishingProcessMapperService,
    private readonly materialMapperService: MaterialMapperService,
    private readonly downstreamMapperService: DownstreamMapperService,
  ) {
  }

  mapScenarioFromScenarioDto(scenarioCompleteDto: ScenarioCompleteDto): Scenario {
    const {
      id,
      projectId,
      name,
      description,
      reference,
      hasSecondaryPackaging,
      hasTertiaryBoxPackaging,
      hasTertiaryPalletizationPackaging,
      packagingType,
      baseMaterialType,
      productType,
      statusType,
      productionCountry,
      downstream,
    } = scenarioCompleteDto;

    return {
      id,
      name,
      projectId,
      description,
      status: { ...statusType },
      reference,
      packagingType: { ...packagingType },
      baseMaterial: {
        value: baseMaterialType?.value,
        label: baseMaterialType?.label,
      },
      productionCountry: {
        value: productionCountry?.value,
        label: productionCountry?.label,
      },
      productType: { ...productType },
      hasSecondaryPackaging,
      hasTertiaryBoxPackaging,
      hasTertiaryPalletizationPackaging,
      assessmentSelected: true,
      downstream
    };
  }

  mapScenarioListFromScenarioDtoList(dtos: ScenarioCompleteDto[]): Scenario[] {
    return dtos.map((scenario: ScenarioCompleteDto) => this.mapScenarioFromScenarioDto(scenario));
  }

  mapComponentListFromScenarioDto(dto: ScenarioCompleteDto): PackagingComponent[] {
    const packaging: PackagingCompleteDto[] = [
      ...dto.primaryPackagings,
      ...dto.secondaryPackagings,
      ...dto.tertiaryPackagingBoxes,
      ...dto.tertiaryPackagingPalletizations,
    ];
    return this.packagingMapperService.mapPackagingComponentListFromPackagingCompleteDtoList(packaging);
  }

  mapComponentListFromScenarioDtoList(dtos: ScenarioCompleteDto[]): PackagingComponent[] {
    return dtos.flatMap((s: ScenarioCompleteDto) => this.mapComponentListFromScenarioDto(s));
  }

  mapFinishingProcessListFromScenarioDto(dto: ScenarioCompleteDto): FinishingProcess[] {
    const packaging: PackagingCompleteDto[] = [
      ...dto.primaryPackagings,
      ...dto.secondaryPackagings,
      ...dto.tertiaryPackagingBoxes,
      ...dto.tertiaryPackagingPalletizations,
    ];

    return packaging.flatMap((packagingDto: PackagingCompleteDto) =>
      packagingDto.components.flatMap((componentDto: ComponentCompleteDto) =>
        this.finishingProcessMapperService.mapFinishingProcessListFromFinishingProcessDtoList(componentDto.finishingProcesses),
      ));
  }

  mapFinishingProcessListFromScenarioDtoList(dtos: ScenarioCompleteDto[]): FinishingProcess[] {
    return dtos.flatMap((dto: ScenarioCompleteDto) => this.mapFinishingProcessListFromScenarioDto(dto));
  }

  mapMaterialListFromScenarioDto(dto: ScenarioCompleteDto): Material[] {
    const packaging: PackagingCompleteDto[] = [
      ...dto.primaryPackagings,
      ...dto.secondaryPackagings,
      ...dto.tertiaryPackagingBoxes,
      ...dto.tertiaryPackagingPalletizations,
    ];
    return packaging.flatMap((packagingDto: PackagingCompleteDto) =>
      packagingDto.components.flatMap((componentDto: ComponentCompleteDto) =>
        this.materialMapperService.mapMaterialListFromMaterialDtoList(componentDto.materials),
      ));
  }

  mapMaterialListFromScenarioDtoList(dtos: ScenarioCompleteDto[]): Material[] {
    return dtos.flatMap((dto: ScenarioCompleteDto) => this.mapMaterialListFromScenarioDto(dto));
  }

  mapDownstreamFromScenarioDto(dto: ScenarioCompleteDto): Downstream {
    const { downstream } = dto;
    return this.downstreamMapperService.mapDownstreamFromDownstreamCompleteDto(downstream);
  }

  mapDownstreamListFromScenarioDtoList(dtos: ScenarioCompleteDto[]): Downstream[] {
    return dtos.flatMap((dto: ScenarioCompleteDto) => this.mapDownstreamFromScenarioDto(dto));
  }

  mapScenarioMetadataFromScenario(scenario: Scenario): ScenarioMetadataDto {
    const {
      name,
      projectId,
      description,
      status,
      reference,
      packagingType,
      baseMaterial,
      salesCountry,
      productionCountry,
      productType,
      hasSecondaryPackaging,
      hasTertiaryBoxPackaging,
      hasTertiaryPalletizationPackaging,
    } = scenario;

    return {
      projectId,
      name,
      description,
      reference,
      hasSecondaryPackaging,
      hasTertiaryBoxPackaging,
      hasTertiaryPalletizationPackaging,
      packagingType,
      baseMaterialType: baseMaterial,
      productType,
      statusType: status,
      productionCountry,
      distributionCountries: salesCountry,
    } as ScenarioMetadataDto;
  }

  mapPackagingFromScenarioCompleteDto(dto: ScenarioCompleteDto, level: PackagingLevel): Packaging[] {
    const {
      primaryPackagings,
      secondaryPackagings,
      tertiaryPackagingBoxes,
      tertiaryPackagingPalletizations,
    } = dto;
    switch (level) {
      case PackagingLevel.PRIMARY:
        return this.packagingMapperService.mapPackagingListFromPackagingCompleteDtoList(primaryPackagings);
      case PackagingLevel.SECONDARY:
        return this.packagingMapperService.mapPackagingListFromPackagingCompleteDtoList(secondaryPackagings);
      case PackagingLevel.TERTIARY_BOX:
        return this.packagingMapperService.mapPackagingListFromPackagingCompleteDtoList(tertiaryPackagingBoxes);
      case PackagingLevel.TERTIARY_PALLETIZATION:
        return this.packagingMapperService.mapPackagingListFromPackagingCompleteDtoList(tertiaryPackagingPalletizations);
    }
  }

  mapMaterialTransportListFromScenarioDto(dto: ScenarioCompleteDto): MaterialTransport[] {
    const packaging: PackagingCompleteDto[] = [
      ...dto.primaryPackagings,
      ...dto.secondaryPackagings,
      ...dto.tertiaryPackagingBoxes,
      ...dto.tertiaryPackagingPalletizations,
    ];
    return packaging.flatMap((packagingDto: PackagingCompleteDto) =>
      packagingDto.components.flatMap((componentDto: ComponentCompleteDto) =>
        this.materialMapperService.mapMaterialTransportListFromMaterialDtoList(componentDto.materials),
      ));
  }

  mapMaterialTransportListFromScenarioDtoList(dtos: ScenarioCompleteDto[]): MaterialTransport[] {
    return dtos.flatMap((dto: ScenarioCompleteDto) => this.mapMaterialTransportListFromScenarioDto(dto));
  }

  mapComponentTransportListFromScenarioDtoList(dtos: ScenarioCompleteDto[]): ComponentTransport[] {
    return dtos.flatMap(dto => this.mapComponentTransportListFromScenarioDto(dto));
  }

  mapComponentTransportListFromScenarioDto(dto: ScenarioCompleteDto): ComponentTransport[] {
    const packaging: PackagingCompleteDto[] = [
      ...dto.primaryPackagings,
      ...dto.secondaryPackagings,
      ...dto.tertiaryPackagingBoxes,
      ...dto.tertiaryPackagingPalletizations,
    ];
    return packaging.flatMap((packagingDto: PackagingCompleteDto) =>
      packagingDto.components.flatMap((componentDto: ComponentCompleteDto) =>
        this.componentMapperService.mapComponentTransportListFromComponentDto(componentDto)
      ));
  }

  mapWashingProcessListFromScenarioDtoList(dtos: Array<ScenarioCompleteDto>): WashingProcess[] {
    return dtos.flatMap(dto => this.mapWashingProcessListFromScenarioDto(dto));
  }

  private mapWashingProcessListFromScenarioDto(dto: ScenarioCompleteDto): WashingProcess[] {
    const packagings: PackagingCompleteDto[] = [
      ...dto.primaryPackagings,
      ...dto.secondaryPackagings,
      ...dto.tertiaryPackagingBoxes,
      ...dto.tertiaryPackagingPalletizations,
    ];
    return packagings.flatMap((packagingDto: PackagingCompleteDto) =>
    packagingDto.components.flatMap((componentDto: ComponentCompleteDto) =>
    this.componentMapperService.mapWashingProcessListFromComponentDto(componentDto)));
  }

  mapConversionProcessListFromScenarioDtoList(dtos: Array<ScenarioCompleteDto>): ConversionProcess[] {
    return dtos.flatMap((dto) => this.mapConversionProcessListFromScenarioDto(dto));
  }

  mapConversionProcessListFromScenarioDto(dto: ScenarioCompleteDto): ConversionProcess[] {
    const packagings: PackagingCompleteDto[] = [
      ...dto.primaryPackagings,
      ...dto.secondaryPackagings,
      ...dto.tertiaryPackagingBoxes,
      ...dto.tertiaryPackagingPalletizations,
    ];
    return packagings.flatMap((packagingDto: PackagingCompleteDto) =>
      packagingDto.components.flatMap((componentDto: ComponentCompleteDto) =>
        this.componentMapperService.mapConversionProcessListFromComponentDto(componentDto)));
  }
}
