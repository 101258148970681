import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToggleMenuService {

  private readonly open = new BehaviorSubject<boolean>(true);

  constructor() { }

  toggle(): void {
    const current = this.open.getValue();
    this.open.next(!current);
  }

  isOpen(): Observable<boolean> {
    return this.open.asObservable();
  }

}
