<div class="project-table">
  <div class="home-actions-group">
    <div class="home-actions">
      <h2 class="open-project" data-testid="project-table-title" i18n>Open project</h2>

      <app-action-button (click)="openNewProjectDialog()" data-testid="new-project-button" size="large">
        <span i18n>New project</span>
      </app-action-button>
    </div>
  </div>

  <div>
    <mat-card>
      <app-project-filters [filters]="filters" (changeValue)="handleChangeFilters($event)"></app-project-filters>
      <mat-table *ngIf="dataSource" [dataSource]="dataSource" matSort matSortActive="lastOpenedAt"
                 matSortDirection="desc" matSortDisableClear [trackBy]="trackRowBy" data-testid="project-table">
      <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Name</mat-header-cell>
          <mat-cell
            (click)="onRowClick($event, project)"
            *matCellDef="let project"> {{project?.name}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="brandType">
          <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Brand</mat-header-cell>
          <mat-cell
            (click)="onRowClick($event, project)"
            *matCellDef="let project"> {{project?.brandType?.value}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="code">
          <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Code</mat-header-cell>
          <mat-cell
            (click)="onRowClick($event, project)"
            *matCellDef="let project"> {{project?.code}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ownerName">
          <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Owner</mat-header-cell>
          <mat-cell
            (click)="onRowClick($event, project)"
            *matCellDef="let project"> {{project?.ownerName}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Created</mat-header-cell>
          <mat-cell
            (click)="onRowClick($event, project)"
            *matCellDef="let project"> {{project?.createdAt | date:'shortDate'}}  </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastOpenedAt">
          <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Last opened</mat-header-cell>
          <mat-cell
            (click)="onRowClick($event, project)"
            *matCellDef="let project"> {{project?.lastOpenedAt | date:'shortDate'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastUpdatedBy">
          <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Last updated by</mat-header-cell>
          <mat-cell
            (click)="onRowClick($event, project)"
            *matCellDef="let project"> {{project?.lastUpdatedBy}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastUpdatedAt">
          <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Last updated at</mat-header-cell>
          <mat-cell
            (click)="onRowClick($event, project)"
            *matCellDef="let project"> {{ project?.lastUpdatedAt | date:'shortDate' }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="duplicate">
          <mat-header-cell *matHeaderCellDef class="delete-column"></mat-header-cell>
          <mat-cell
            (click)="duplicateProject($event, project)"
            *matCellDef="let project"
            class="delete-column"
          >
            <button mat-icon-button>
              <mat-icon>content_copy</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef class="delete-column"></mat-header-cell>
          <mat-cell
            (click)="project.canDelete ? deleteProject($event, project) : onRowClick($event, project)"
            *matCellDef="let project"
            class="delete-column"
          >
            <button
              [color]="project.canDelete ? 'warn' : 'accent'"
              mat-icon-button
            >
              <mat-icon [class.hidden]="!project.canDelete">close</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="background-accent"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns;"
          [id]="'project-'+row?.id"
          matRipple
        >
        </mat-row>
      </mat-table>

      <div class="table-footer">
        <mat-paginator
          (page)="paginationChange($event)"
          [length]="page?.totalElements"
          [pageIndex]="page?.number"
          [pageSizeOptions]="[10, 50, 100]"
        >
        </mat-paginator>
      </div>
    </mat-card>
  </div>
</div>
