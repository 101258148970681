import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Statistics } from '../models/statistics-data.model';
import { createReducer, on } from '@ngrx/store';
import { getStatistics, getStatisticsError, getStatisticsSuccess } from './statistics-data.actions';

export const statisticsDataFeatureKey = 'statisticsData';

export interface StatisticsDataState extends EntityState<Statistics> {
  loading: boolean;
  error: string;
}

export interface StatisticsDataFeatureState {
  statisticsData: StatisticsDataState;
}

export const adapter: EntityAdapter<Statistics> = createEntityAdapter<Statistics>();

export const initialState: StatisticsDataState = adapter.getInitialState({
  loading: false,
  error: null,
});

export const STATISTICS_DATA_ID = 1;

export const reducer = createReducer(
  initialState,
  on(getStatistics, (state) =>
    ({ ...state, loading: true })
  ),
  on(getStatisticsError, (state, action) =>
    ({ ...state, loading: false, error: action.message })
  ),
  on(getStatisticsSuccess, (state, action) => {
    return adapter.setOne(
      { id: STATISTICS_DATA_ID, ...(action.statistics) },
      { ...state, loading: false, error: null }
    );
  }),
);

export const {
  selectEntities,
} = adapter.getSelectors((state: StatisticsDataFeatureState) => state.statisticsData);
