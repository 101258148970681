import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SetDefaultVersionTableComponent} from './set-default-version-table.component';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {CollaboratorModule} from '../../collaborator/collaborator.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRippleModule} from '@angular/material/core';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';


@NgModule({
  declarations: [SetDefaultVersionTableComponent],
  exports: [SetDefaultVersionTableComponent],
    imports: [CommonModule, MatTableModule, MatCardModule, CollaboratorModule, MatCheckboxModule, MatRippleModule, MatSortModule, MatPaginatorModule]
})
export class SetDefaultVersionTableModule {
}
