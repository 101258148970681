import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { Packaging } from '../../../models/packaging.models';
import {
  addTertiaryPalletizationPackaging,
  addTertiaryPalletizationPackagingSuccess,
  deleteAll, deleteAllSuccess,
  deleteTertiaryPalletizationPackaging,
  deleteTertiaryPalletizationPackagingSuccess,
  getTertiaryPalletizationPackagingSuccess,
  tertiaryPalletizationPackagingError,
  updateTertiaryPalletizationPackaging,
  updateTertiaryPalletizationPackagingSuccess,
} from './tertiary-palletization-packaging.actions';

export const tertiaryPalletizationPackagingFeatureKey = 'tertiaryPalletizationPackaging';

export interface TertiaryPalletizationPackagingState extends EntityState<Packaging> {
  loading: boolean;
  lastCreated: Packaging | null;
  error: string | null;
}

export const adapter: EntityAdapter<Packaging> = createEntityAdapter<Packaging>();

export const initialState: TertiaryPalletizationPackagingState = adapter.getInitialState({
  loading: false,
  lastCreated: null,
  error: null,
});

const reducer: ActionReducer<TertiaryPalletizationPackagingState, Action> = createReducer(
  initialState,
  on(
    addTertiaryPalletizationPackaging,
    updateTertiaryPalletizationPackaging,
    deleteTertiaryPalletizationPackaging, (state: TertiaryPalletizationPackagingState, action) =>
      ({ ...state, loading: true, error: null, lastCreated: null }),
  ),
  on(getTertiaryPalletizationPackagingSuccess, (state: TertiaryPalletizationPackagingState, action) =>
    adapter.upsertMany(action.packaging, { ...state, loading: false }),
  ),
  on(addTertiaryPalletizationPackagingSuccess, (state: TertiaryPalletizationPackagingState, action) =>
    adapter.addOne(action.packaging, { ...state, loading: false, lastCreated: action.packaging }),
  ),
  on(updateTertiaryPalletizationPackagingSuccess, (state: TertiaryPalletizationPackagingState, action) => {
      const { packaging } = action;
      const update: Update<Packaging> = {
        id: packaging.id,
        changes: packaging,
      };
      return adapter.updateOne(update, { ...state, loading: false });
    },
  ),
  on(deleteTertiaryPalletizationPackagingSuccess, (state: TertiaryPalletizationPackagingState, action) =>
    adapter.removeOne(action.id, { ...state, loading: false }),
  ),
  on(tertiaryPalletizationPackagingError, (state, action) =>
    ({ ...state, loading: false, error: action.message })),
  on(deleteAllSuccess, (state: TertiaryPalletizationPackagingState, action: { scenarioId: string }) => {
    const { scenarioId } = action;
    const idsToDelete = Object.values(state.entities)
      .filter((p: Packaging) => p.scenarioId === scenarioId)
      .map((p: Packaging) => p.id);
    return adapter.removeMany(idsToDelete, state);
  }),
);

export function tertiaryPalletizationPackagingReducer(state: TertiaryPalletizationPackagingState | undefined, action: Action) {
  return reducer(state, action);
}
