import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-input',
  template: `
    <mat-form-field class="search-input">
      <mat-label><span i18n>Search</span></mat-label>
      <input (keyup)="filter.emit($event)" matInput>
    </mat-form-field>`,
  styles: [ `.search-input {
    width: max-content;
    margin-top: 0;
  }` ],
})
export class SearchInputComponent implements OnInit {

  @Output()
  filter = new EventEmitter<Event>();

  constructor() { }

  ngOnInit(): void {
  }

}
