import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { PublishedScenario } from '../models/published-scenario.model';
import { SelectionModel } from '@angular/cdk/collections';
import { PublishedScenarioColumnKeys } from '../scenario-library-table/published-scenario-table.data';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ScenarioLibraryDataService } from '../scenario-library.data-service';
import { CollaboratorDataService } from '../../collaborator/collaborator.data-service';
import {
  PublishedScenarioTableDataMapperService
} from '../scenario-library-mappers/published-scenario-table-data-mapper.service';
import {
  publishedScenarioSortingDataAccessor
} from '../scenario-library-table/published-scenario-sorting-data-accessor';

@Component({
  selector: 'app-scenario-table',
  templateUrl: './scenario-table.component.html',
  styleUrls: ['./scenario-table.component.scss']
})
export class ScenarioTableComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  private readonly subscription = new Subscription();
  dataSource: MatTableDataSource<PublishedScenario>;
  selection = new SelectionModel<PublishedScenario>(true, []);

  @Input() displayedColumns: PublishedScenarioColumnKeys[] = [];
  @Input() scenarios: PublishedScenario[] = [];
  @Output() deleteScenario: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private readonly scenarioLibraryDataService: ScenarioLibraryDataService,
    private readonly collaboratorDataService: CollaboratorDataService,
    private readonly tableDataMapperService: PublishedScenarioTableDataMapperService,
  ) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<PublishedScenario>(this.scenarios);
    this.dataSource.sortingDataAccessor = publishedScenarioSortingDataAccessor;
    this.dataSource.filterPredicate = this.filterPredicate();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.dataSource) {
      return;
    }
    this.dataSource.data = changes.scenarios.currentValue;
  }

  trackBy(row: PublishedScenario): string {
    return row.id;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach((row: PublishedScenario) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(scenario?: PublishedScenario): string {
    if (!scenario) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(scenario) ? 'deselect' : 'select'} scenario ${scenario.id}`;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private filterPredicate() {
    return (data: PublishedScenario, search: string): boolean => {
      if (!data) {
        return false;
      }
      const scenario = this.tableDataMapperService.from(data);
      let match = false;
      Object.values(scenario)
        .filter(value => !!value)
        .forEach(value => match = match || value.toLowerCase().includes(search));
      return match;
    };
  }
}
