import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProjectEffects } from './project.effects';
import { ProjectDataService } from '../../services/project.data-service';
import { projectReducer, projectsFeatureKey } from './project.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(projectsFeatureKey, projectReducer),
    EffectsModule.forFeature([ ProjectEffects ]),
  ],
  providers: [
    ProjectDataService,
  ],
})
export class ProjectStoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: ProjectStoreModule) {
    if (parentModule) {
      throw new Error('ProjectStoreModule is already loaded');
    }
  }
}
