import { Injectable } from '@angular/core';
import { Project } from '../models/project.model';
import { ProjectCompleteDto } from '../../../api/dtos/__generated__/projectCompleteDto';
import { PageOfProjectMetadataDto } from '../../../api/dtos/__generated__/pageOfProjectMetadataDto';
import { Scenario } from '../models/scenario.model';
import { ScenarioMapperService } from './scenario-mapper.service';
import { Packaging } from '../models/packaging.models';
import { PackagingMapperService } from './packaging-mapper.service';
import { ComponentTransport, PackagingComponent } from '../models/component.models';
import { FinishingProcess } from '../models/finishing-process.models';
import { Material, MaterialTransport } from '../models/material.models';
import { ProjectCreationRequestDto } from '../../../api/dtos/__generated__/projectCreationRequestDto';
import { ProjectMetadataDto } from '../../../api/dtos/__generated__/projectMetadataDto';
import { ScenarioCompleteDto } from '../../../api/dtos/__generated__/scenarioCompleteDto';
import { Downstream } from '../../../packaging/downstream/models/downstream';
import { WashingProcess } from '../models/washing-process.models';
import { ConversionProcess } from '../models/conversion-process.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectMapperService {

  constructor(
    private readonly scenarioMapperService: ScenarioMapperService,
    private readonly packagingMapperService: PackagingMapperService,
  ) {
  }

  mapProjectFromProjectMetadataDto(projectMetadataDto: ProjectMetadataDto): Project {
    const {
      brandType,
      code,
      description,
      id,
      name,
      ownerId,
      ownerName,
      collaborators,
      createdAt,
      lastOpenedAt,
      lastUpdatedBy,
      lastUpdatedAt
    } = projectMetadataDto;
    return {
      id,
      brandType,
      collaborators,
      code,
      createdAt: new Date(createdAt),
      description,
      lastOpenedAt: new Date(lastOpenedAt),
      name,
      ownerId,
      ownerName,
      lastUpdatedBy,
      lastUpdatedAt: lastUpdatedAt ? new Date(lastUpdatedAt) : undefined,
    } as Project;
  }

  mapProjectListFromPageOfProjectDto(page: PageOfProjectMetadataDto): Project[] {
    return page.content.map((p: ProjectMetadataDto, index: number) => {
      return {
        position: index,
        ...this.mapProjectFromProjectMetadataDto(p)
      } as Project;
    });
  }

  mapScenarioListFromProjectDto(dto: ProjectCompleteDto): Scenario[] {
    return this.scenarioMapperService.mapScenarioListFromScenarioDtoList(dto.scenarios);
  }

  mapPrimaryPackagingFromProjectDto(dto: ProjectCompleteDto): Packaging[] {
    return dto.scenarios.flatMap((s: ScenarioCompleteDto) =>
      this.packagingMapperService.mapPackagingListFromPackagingCompleteDtoList(s.primaryPackagings));
  }

  mapSecondaryPackagingFromProjectDto(dto: ProjectCompleteDto): Packaging[] {
    return dto.scenarios.flatMap((s: ScenarioCompleteDto) =>
      this.packagingMapperService.mapPackagingListFromPackagingCompleteDtoList(s.secondaryPackagings),
    );
  }

  mapTertiaryPackagingFromProjectDto(dto: ProjectCompleteDto): Packaging[] {
    return dto.scenarios.flatMap((s: ScenarioCompleteDto) =>
      this.packagingMapperService.mapPackagingListFromPackagingCompleteDtoList(s.tertiaryPackagingBoxes),
    );
  }


  mapTertiaryPalletizationPackagingFromProjectDto(dto: ProjectCompleteDto): Packaging[] {
    return dto.scenarios.flatMap((s: ScenarioCompleteDto) =>
      this.packagingMapperService.mapPackagingListFromPackagingCompleteDtoList(s.tertiaryPackagingPalletizations),
    );
  }

  mapPackagingComponentListFromProjectDto(dto: ProjectCompleteDto): PackagingComponent[] {
    return this.scenarioMapperService.mapComponentListFromScenarioDtoList(dto.scenarios);
  }

  mapFinishingProcessListFromProjectDto(dto: ProjectCompleteDto): FinishingProcess[] {
    return this.scenarioMapperService.mapFinishingProcessListFromScenarioDtoList(dto.scenarios);
  }

  mapDownstreamListFromProjectDto(dto: ProjectCompleteDto): Downstream[] {
    return this.scenarioMapperService.mapDownstreamListFromScenarioDtoList(dto.scenarios);
  }


  mapMaterialListFromProjectDto(dto: ProjectCompleteDto): Material[] {
    return this.scenarioMapperService.mapMaterialListFromScenarioDtoList(dto.scenarios);
  }

  mapMaterialTransportListFromProjectDto(dto: ProjectCompleteDto): MaterialTransport[] {
    return this.scenarioMapperService.mapMaterialTransportListFromScenarioDtoList(dto.scenarios);
  }

  mapProjectCreationRequestDtoFromProject(project: Project): ProjectCreationRequestDto {
    const { brandType, code, description, name } = project;
    return {
      brandType,
      code,
      description,
      name,
    } as ProjectCreationRequestDto;
  }

  mapProjectMetadataDtoFromProject(project: Project): ProjectMetadataDto {
    const {
      description,
      code,
      brandType,
      name,
      collaborators,
    } = project;

    return {
      description,
      code,
      brandType,
      name,
      collaborators,
    } as ProjectMetadataDto;
  }

  mapComponentTransportListFromProjectDto(dto: ProjectCompleteDto): ComponentTransport[] {
    return this.scenarioMapperService.mapComponentTransportListFromScenarioDtoList(dto.scenarios);
  }

  mapWashingProcessListFromProjectDto(dto: ProjectCompleteDto): WashingProcess[] {
    return this.scenarioMapperService.mapWashingProcessListFromScenarioDtoList(dto.scenarios);
  }

  mapConversionProcessListFromProjectDto(dto: ProjectCompleteDto): ConversionProcess[] {
    return this.scenarioMapperService.mapConversionProcessListFromScenarioDtoList(dto.scenarios);
  }
}
