import { Injectable } from '@angular/core';
import { Packaging } from '../models/packaging.models';
import { PackagingCompleteDto } from '../../../api/dtos/__generated__/packagingCompleteDto';
import { ComponentMapperService } from './component-mapper.service';
import { PackagingComponent } from '../models/component.models';
import { PackagingMetadataDto } from '../../../api/dtos/__generated__/packagingMetadataDto';
import { PackagingCreationRequestDto } from '../../../api/dtos/__generated__/packagingCreationRequestDto';

@Injectable({
  providedIn: 'root',
})
export class PackagingMapperService {

  constructor(
    private readonly componentMapperService: ComponentMapperService,
  ) { }

  mapPackagingFromPackagingCompleteDto(dto: PackagingCompleteDto): Packaging {
    const {
      id,
      level,
      productMass,
      productVolume,
      multiplicity,
      scenarioId,
      usage,
    } = dto;

    return {
      id,
      level,
      productVolume,
      productMass,
      multiplicity,
      scenarioId,
      usage,
    } as Packaging;
  }

  mapPackagingListFromPackagingCompleteDtoList(dtos: PackagingCompleteDto[]): Packaging[] {
    return dtos.map((dto) => this.mapPackagingFromPackagingCompleteDto(dto));
  }

  mapPackagingComponentListFromPackagingCompleteDto(dto: PackagingCompleteDto): PackagingComponent[] {
    return this.componentMapperService.mapPackagingComponentListFromComponentCompleteDtoList(dto.components);
  }

  mapPackagingComponentListFromPackagingCompleteDtoList(dtos: PackagingCompleteDto[]): PackagingComponent[] {
    return dtos.flatMap((dto: PackagingCompleteDto) => this.mapPackagingComponentListFromPackagingCompleteDto(dto));
  }

  mapPackagingMetadataDtoFromPackaging(packaging: Packaging): PackagingMetadataDto {
    const {
      scenarioId,
      productMass,
      productVolume,
      multiplicity,
      level,
      usage,
    } = packaging;
    return {
      scenarioId,
      productMass,
      productVolume,
      multiplicity,
      level,
      usage,
    } as PackagingMetadataDto;
  }

  mapPackagingCreationRequestDtoFromPackaging(packaging: Packaging): PackagingCreationRequestDto {
    const {
      scenarioId,
      level,
    } = packaging;

    return {
      scenarioId,
      level,
    } as PackagingCreationRequestDto;
  }
}
