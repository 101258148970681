import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nan',
})
export class NanPipe implements PipeTransform {

  transform(value: number | string, ...args: unknown[]): number | string {
    if (value === 'NaN') {
      return '-';
    }
    return value;
  }

}
