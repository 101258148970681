import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FinishingProcessDataService } from '../../services/finishing-process.data-service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  addFinishingProcess,
  addFinishingProcessSuccess,
  deleteFinishingProcess,
  deleteFinishingProcessSuccess,
  finishingProcessError,
  updateFinishingProcess,
  updateFinishingProcessSuccess,
} from './finishing-process.actions';
import { FinishingProcess } from '../../models/finishing-process.models';


@Injectable()
export class FinishingProcessEffects {

  addFinishingProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addFinishingProcess.type),
      map((action: { type: string, finishingProcess: FinishingProcess }) => action.finishingProcess),
      switchMap((fp: FinishingProcess) => this.finishingProcessDataService.create(fp)
        .pipe(
          map((finishingProcess: FinishingProcess) => addFinishingProcessSuccess({ finishingProcess })),
          catchError(() => of(finishingProcessError({ message: 'Something went wrong when adding new finishing-process' }))),
        ),
      ),
    ),
  );

  updateFinishingProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateFinishingProcess.type),
      map((action: { type: string, finishingProcess: FinishingProcess }) => action.finishingProcess),
      switchMap((fp: FinishingProcess) => this.finishingProcessDataService.update(fp)
        .pipe(
          map((finishingProcess: FinishingProcess) => updateFinishingProcessSuccess({ finishingProcess })),
          catchError(() => of(finishingProcessError({ message: 'Something went wrong when updating finishing-process' }))),
        ),
      ),
    ),
  );

  deleteFinishingProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteFinishingProcess.type),
      map((action: { type: string, id: string }) => action.id),
      switchMap((id: string) => this.finishingProcessDataService.delete(id)
        .pipe(
          map((index: string) => deleteFinishingProcessSuccess({ id: index })),
          catchError(() => of(finishingProcessError({ message: 'Something went wrong when deleting finishing-process' }))),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly finishingProcessDataService: FinishingProcessDataService) {}
}
