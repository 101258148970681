import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: [ './admin-header.component.scss' ],
})
export class AdminHeaderComponent implements OnInit {

  @Input()
  isMenuOpen$: Observable<boolean>;

  constructor() { }

  ngOnInit(): void {
  }
}
