export interface BBox {
  width: number;
  height: number;
}

export interface RowData {
  name: string; // scenario
  value: number;
  bbox?: BBox;
  best?: boolean;
  category?: string; // packaging level or lifecycle stage
}

export interface AssessmentDataSet {
  data: RowData[];
  name?: string; // indicator
}

export interface DataSetName {
  value: string;
  key: string;
}

export const LCAStageDataSetNames = {
  RAW_MATERIAL_PRODUCTION: {
    value: 'Raw material production',
    key: 'rawMaterialProductionImpact',
  } as DataSetName,
  PROCESSES: {
    value: 'Processes',
    key: 'processesImpact',
  } as DataSetName,
  UPSTREAM_LOGISTICS: {
    value: 'Upstream logistics',
    key: 'upstreamLogisticsImpact',
  } as DataSetName,
  DOWNSTREAM_LOGISTICS: {
    value: 'Downstream logistics',
    key: 'downstreamLogisticsImpact',
  } as DataSetName,
  END_OF_LIFE: {
    value: 'End of life',
    key: 'endOfLifeImpact',
  } as DataSetName,
};

export const GHGStageDataSetNames = {
  RAW_MATERIAL_PRODUCTION: {
    value: 'Category 1, Raw material production',
    key: LCAStageDataSetNames.RAW_MATERIAL_PRODUCTION.key,
  } as DataSetName,
  PROCESSES: {
    value: 'Category 1, Processes',
    key: LCAStageDataSetNames.PROCESSES.key,
  } as DataSetName,
  UPSTREAM_LOGISTICS: {
    value: 'Category 4, Upstream logistics',
    key: LCAStageDataSetNames.UPSTREAM_LOGISTICS.key,
  } as DataSetName,
  DOWNSTREAM_LOGISTICS: {
    value: 'Category 9, Downstream logistics',
    key: LCAStageDataSetNames.DOWNSTREAM_LOGISTICS.key,
  } as DataSetName,
  END_OF_LIFE: {
    value: 'Category 12, End of life',
    key: LCAStageDataSetNames.END_OF_LIFE.key,
  } as DataSetName,
};

export const PackagingLevelDataSetNames = {
  CONSUMER_PACKAGING: {
    value: 'Consumer packaging',
    key: 'primaryPackagingImpact',
  } as DataSetName,
  SALES_PACKAGING: {
    value: 'Sales packaging',
    key: 'secondaryPackagingImpact',
  } as DataSetName,
  TRADE_PACKAGING: {
    value: 'Trade packaging',
    key: 'tertiaryPackagingBoxImpact',
  } as DataSetName,
  TRANSPORT_PACKAGING: {
    value: 'Transport packaging',
    key: 'tertiaryPackagingPalletizationImpact',
  } as DataSetName,
};

export interface AssessmentDetailsItem {
  name: string;
  production: number;
  upstreamLogistic: number;
  downstreamLogistic: number;
  endOfLife: number;
}

export interface AssessmentDetailsComponent {
  name: string;
  process: number;
  item?: AssessmentDetailsItem;
}

export interface AssessmentDetails {
  packagingLevel: string;
  component: AssessmentDetailsComponent;
}
