import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { LoadingService } from './loading.service';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { Auth } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  authService = Auth;

  constructor(private readonly loadingService: LoadingService,
              private readonly router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loadingService.loading();
    return from(this.authService.currentSession())
      .pipe(
        catchError(error => {
          from(this.router.navigateByUrl('/'));
          return throwError(error);
        }),
        switchMap((auth: CognitoUserSession) => {
          const jwt = auth.getIdToken();
          const requestWithJWT = request.clone({
            setHeaders: {
              Authorization: `Bearer ${jwt.getJwtToken()}`,
            },
          });
          return next.handle(requestWithJWT);
        }),
        finalize(() => this.loadingService.complete()));
  }
}
