import { createFeatureSelector, createSelector } from '@ngrx/store';
import { endOfLifeKey, EndOfLifeState } from './end-of-life.reducer';
import { EndOfLife } from '../models/end-of-life';

export const selectEndOfLifeState = createFeatureSelector<EndOfLifeState>(endOfLifeKey);
export const selectEndOfLives = createSelector(
  selectEndOfLifeState,
  (state): EndOfLife[] => state.endOfLives
);
export const selectEndOfLifeLoading = createSelector(
  selectEndOfLifeState,
  (state): boolean => state.loading
);
export const selectEndOfLifeError = createSelector(
  selectEndOfLifeState,
  (state): string => state.error
);
export const selectEndOfLifeMaterialId = createSelector(
  selectEndOfLifeState,
  (state): string | undefined => state.materialId
);
