import { ActionReducer, createReducer, on } from '@ngrx/store';
import { EndOfLife } from '../models/end-of-life';
import {
  endOfLifeError,
  findAllEndOfLives,
  findAllEndOfLivesSuccess,
  updateEndOfLife,
  updateEndOfLifeSuccess
} from './end-of-life.actions';

export const endOfLifeKey = 'endOfLife';

export interface EndOfLifeState {
  endOfLives: EndOfLife[];
  materialId: string | undefined;
  loading: boolean;
  error: string;
}

export const initialEndOfLifeState: EndOfLifeState = ({
  endOfLives: [],
  materialId: undefined,
  loading: false,
  error: ''
});

export interface EndOfLifeFeatureState {
  endOfLife: EndOfLifeState;
}

const endOfLifeReducer: ActionReducer<EndOfLifeState> = createReducer<EndOfLifeState>(
  initialEndOfLifeState,
  on(updateEndOfLife, (state) => ({ ...state, loading: true, error: '' })),
  on(
    findAllEndOfLives,
    (state, { materialId }) => ({
      ...state,
      materialId,
      loading: true,
      error: '',
      ...(state.materialId !== materialId && { endOfLives: [] }),
    })
  ),
  on(findAllEndOfLivesSuccess, (state, { endOfLives }) => ({ ...state, endOfLives, loading: false, })),
  on(updateEndOfLifeSuccess, (state, { endOfLife }) => {
    const endOfLives = [ ...state.endOfLives ];
    const index = endOfLives.findIndex((item) => item.id === endOfLife.id);
    if (index !== -1) {
      endOfLives[index] = endOfLife;
    }
    return { ...state, loading: false, endOfLives };
  }),
  on(endOfLifeError, (state, { error }) => ({ ...state, loading: false, error })),
);

export default endOfLifeReducer;
