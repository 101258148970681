import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { componentFeatureKey, componentReducer } from './component.reducer';
import { ComponentEffects } from './component.effects';
import { ComponentDataService } from '../../services/component.data-service';
import { ComponentMapperService } from '../../services/component-mapper.service';
import { SnackbarModule } from '../../../../shared/snackbar/snackbar.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(componentFeatureKey, componentReducer),
    EffectsModule.forFeature([ ComponentEffects ]),
    SnackbarModule,
  ],
  providers: [
    ComponentDataService,
    ComponentMapperService,
  ],
})
export class ComponentStoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: ComponentStoreModule) {
    if (parentModule) {
      throw new Error('ComponentStoreModule is already loaded');
    }
  }
}
