import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProjectAssessment } from '../models/project-assessment.model';
import * as AssessmentActions from './assessment.actions';
import { FunctionalUnit } from '../../../store/reference-data/models/reference-data.model';

export const assessmentsFeatureKey = 'assessment';

export interface AssessmentState extends EntityState<ProjectAssessment> {
  // additional entities state properties
  loading: boolean;
  error: string;
  displayWarnings: boolean;
  selectedUnit: FunctionalUnit;
}

const selectAssessmentId = (assessment: ProjectAssessment): string => assessment.projectId;

export const adapter: EntityAdapter<ProjectAssessment> = createEntityAdapter<ProjectAssessment>({
  selectId: selectAssessmentId,
});

export const initialState: AssessmentState = adapter.getInitialState({
  // additional entity state properties
  loading: false,
  error: null,
  displayWarnings: true,
  selectedUnit: {
    label: 'CONSUMER_PACK',
    value: 'consumer packaging',
  },
});


export const reducer = createReducer(
  initialState,
  on(AssessmentActions.computeAssessment,
    (state, action) => ({ ...state, loading: true, error: null }),
  ),
  on(AssessmentActions.computeAssessmentSuccess,
    (state, action) => {
      return adapter.upsertOne(action.assessment, { ...state, loading: false });
    },
  ),
  on(AssessmentActions.deleteAssessment,
    (state, action) => {
      return adapter.removeOne(action.projectId, state);
    },
  ),
  on(AssessmentActions.computeAssessmentError,
    (state, action) => ({ ...state, loading: false, error: action.message }),
  ),
  on(AssessmentActions.setFunctionalUnit, (state, action) => ({ ...state, selectedUnit: action.functionalUnit })),
  on(AssessmentActions.allowWarningsDisplay, (state, action) => ({ ...state, displayWarnings: true })),
  on(AssessmentActions.dismissWarningsDisplay, (state, action) => ({ ...state, displayWarnings: false })),
);

export function assessmentReducer(state: AssessmentState | undefined, action: Action) {
  return reducer(state, action);
}
