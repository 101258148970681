import { createAction, props } from '@ngrx/store';
import { FinishingProcess } from '../../models/finishing-process.models';
import { Country } from '../../../../store/reference-data/models/reference-data.model';
import { ElectricityType } from '../../models/electricity.model';

const FINISHING_PROCESS_SIDE_EFFECT_ACTION = '[FinishingProcess/API]';
const FINISHING_ACTION = '[FinishingProcess]';


export const addFinishingProcess = createAction(
  `${FINISHING_PROCESS_SIDE_EFFECT_ACTION} Add Finishing Process`,
  props<{ finishingProcess: FinishingProcess }>(),
);

export const addFinishingProcessSuccess = createAction(
  `${FINISHING_PROCESS_SIDE_EFFECT_ACTION} Add Finishing Process Success`,
  props<{ finishingProcess: FinishingProcess }>(),
);

export const getFinishingProcessesSuccess = createAction(
  `${FINISHING_PROCESS_SIDE_EFFECT_ACTION} Add Finishing Processes Success`,
  props<{ finishingProcesses: FinishingProcess[] }>(),
);

export const updateFinishingProcess = createAction(
  `${FINISHING_PROCESS_SIDE_EFFECT_ACTION} Update Finishing Process`,
  props<{ finishingProcess: FinishingProcess }>(),
);

export const updateFinishingProcessSuccess = createAction(
  `${FINISHING_PROCESS_SIDE_EFFECT_ACTION} Update Finishing Process Success`,
  props<{ finishingProcess: FinishingProcess }>(),
);

export const deleteFinishingProcess = createAction(
  `${FINISHING_PROCESS_SIDE_EFFECT_ACTION} Delete Finishing Process`,
  props<{ id: string }>(),
);

export const deleteFinishingProcessSuccess = createAction(
  `${FINISHING_PROCESS_SIDE_EFFECT_ACTION} Delete Finishing Process Success`,
  props<{ id: string }>(),
);

export const finishingProcessError = createAction(
  `${FINISHING_PROCESS_SIDE_EFFECT_ACTION} Finishing Process Error`,
  props<{ message: string }>(),
);

export const updateCountry = createAction(
  `${FINISHING_ACTION} Update Country`,
  props<{ id: string, country: Country }>(),
);

export const updateElectricityType = createAction(
  `${FINISHING_ACTION} Update Electricity Type`,
  props<{ id: string, electricityType: ElectricityType }>(),
);

export const deleteAll = createAction(
  `${FINISHING_ACTION} Delete All Finishing Processes of Given Component`,
  props<{ componentId: string }>(),
);
