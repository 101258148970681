import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ScenarioLibraryTableComponent } from '../../../scenario-library/scenario-library-table/scenario-library-table.component';
import { PublishedScenario } from '../../../scenario-library/models/published-scenario.model';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ScenarioLibraryDataService } from '../../../scenario-library/scenario-library.data-service';
import { MatTableDataSource } from '@angular/material/table';
import { PublishedScenarioColumnKeys } from '../../../scenario-library/scenario-library-table/published-scenario-table.data';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationModalComponent } from '../../../shared/delete-confirmation-modal/delete-confirmation-modal.component';

@Component({
  templateUrl: './scenario-library-page.component.html',
  styleUrls: [ './scenario-library-page.component.scss' ],
})
export class ScenarioLibraryPageComponent implements OnInit, OnDestroy, AfterViewInit {

  private readonly subscription = new Subscription();
  readonly displayedColumns: PublishedScenarioColumnKeys[] = [
    'selected',
    'name',
    'publicationStatus',
    'publishedBy',
    'publishedAt',
    'brandType',
    'reference',
    'packagingType',
    'baseMaterialType',
    'productType',
    'statusType',
    'productionCountry',
  ];


  @ViewChild(ScenarioLibraryTableComponent)
  scenarioTable: ScenarioLibraryTableComponent;

  selection: SelectionModel<PublishedScenario>;
  dataSource: MatTableDataSource<PublishedScenario>;


  constructor(
    private readonly scenarioLibraryDataService: ScenarioLibraryDataService,
    private readonly matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.selection = this.scenarioTable.selection;
    this.dataSource = this.scenarioTable.dataSource;
  }

  approveSelected(selection: PublishedScenario[]): void {
    selection.forEach(s => this.subscription.add(
      this.handleUpdate(this.scenarioLibraryDataService.approve(s)),
    ));
  }

  rejectSelected(selection: PublishedScenario[]): void {
    selection.forEach(s => this.subscription.add(
      this.handleUpdate(this.scenarioLibraryDataService.reject(s)),
    ));
  }

  deleteSelected(selection: PublishedScenario[]): void {
    const name = selection.reduce((prev, curr) => !prev ? curr.name : `${prev}, ${curr.name}`, '');
    const desc = selection?.length === 1 ? 'published scenario' : 'published scenarios';
    const ref = this.matDialog.open(DeleteConfirmationModalComponent, {
      data: { type: desc, name },
      width: null,
      maxWidth: '50%',
    });
    const afterClosed$ = ref.afterClosed().pipe(
      take(1),
      filter(v => !!v),
      map(() => selection.reduce((prev, curr) => [
        ...prev,
        this.handleDelete(this.scenarioLibraryDataService.delete(curr)) ], [] as Subscription[]),
      ));

    this.subscription.add(
      afterClosed$.subscribe(subscriptions => subscriptions.forEach(sub => this.subscription.add(sub))),
    );
  }

  setToPending(selection: PublishedScenario[]): void {
    selection.forEach(s => this.subscription.add(
      this.handleUpdate(this.scenarioLibraryDataService.setToPending(s)),
    ));
  }

  filterScenarios(event: Partial<Event>) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private handleUpdate(response: Observable<PublishedScenario>): Subscription {
    return response.pipe(
      filter(v => !!v),
    ).subscribe(updatedScenario => {
      this.dataSource.data = this.dataSource.data.map(u => u.id === updatedScenario.id ? updatedScenario : u);
      const selected = this.selection.selected.find(s => s.id === updatedScenario.id);
      this.selection.deselect(selected);
      this.selection.select(updatedScenario);
    });
  }

  private handleDelete(response: Observable<PublishedScenario>): Subscription {
    return response.pipe(
      filter(v => !!v),
    ).subscribe(deletedScenario => {
      this.dataSource.data = this.dataSource.data.filter(u => u.id !== deletedScenario.id);
      const selected = this.selection.selected.find(s => s.id === deletedScenario.id);
      this.selection.deselect(selected);
    });
  }

}
