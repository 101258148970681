import { Injectable } from '@angular/core';
import { MeasureWarningDto } from '../../../api/dtos/__generated__/measureWarningDto';
import { MeasureWarning } from '../models/measure-warning.model';
import { AbstractMapper } from '../../abstract-mapper';
import { EntityReferenceMapperService } from './entity-reference-mapper.service';
import { PackagingLevel } from '../../modeling/models/packaging.models';

@Injectable({
  providedIn: 'root',
})
export class MeasureWarningMapperService extends AbstractMapper<MeasureWarningDto, MeasureWarning> {


  constructor(private readonly entityReferenceMapper: EntityReferenceMapperService) {
    super();
  }

  from(dto: MeasureWarningDto): MeasureWarning | undefined {
    if (!dto) {
      return;
    }

    const {
      project,
      functionalUnit,
      scenario,
      message,
      materialTransport,
      material,
      level,
      finishingProcess,
      downstream,
      component,
    } = dto;

    return {
      project: this.entityReferenceMapper.from(project),
      scenario: this.entityReferenceMapper.from(scenario),
      message,
      functionalUnit,
      materialTransport: this.entityReferenceMapper.from(materialTransport),
      material: this.entityReferenceMapper.from(material),
      level: level as PackagingLevel,
      finishingProcess: this.entityReferenceMapper.from(finishingProcess),
      downstream: this.entityReferenceMapper.from(downstream),
      component: this.entityReferenceMapper.from(component),
    } as MeasureWarning;
  }
}
