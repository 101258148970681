import { ActionReducer, createReducer, on } from '@ngrx/store';
import {
  createPortfolio,
  createPortfolioSuccess,
  deletePortfolio,
  deletePortfolioScenarios,
  deletePortfolioScenariosSuccess,
  deletePortfolioSuccess,
  downloadPortfolioExport,
  downloadPortfolioExportSuccess,
  findAllPortfolios,
  findAllPortfoliosSuccess,
  findPortfolio,
  findPortfolioSuccess,
  importPortfolioScenarios,
  importPortfolioScenariosSuccess,
  portfolioError
} from './portfolio.actions';
import { PortfolioMetadata } from '../models/portfolio-metadata.model';
import { Portfolio } from '../models/portfolio.model';

export const portfolioFeatureKey = 'portfolio';

export interface PortfolioState {
  portfolios: PortfolioMetadata[];
  portfolio: Portfolio | undefined;
  loading: boolean;
  error: string;
}

export const initialPortfolioState: PortfolioState = ({
  portfolios: [],
  portfolio: undefined,
  loading: false,
  error: ''
});

export interface PortfolioFeatureState {
  portfolio: PortfolioState;
}

export const portfolioReducer: ActionReducer<PortfolioState> = createReducer<PortfolioState>(
  initialPortfolioState,
  on(
    findAllPortfolios,
    createPortfolio,
    findPortfolio,
    deletePortfolio,
    downloadPortfolioExport,
    importPortfolioScenarios,
    deletePortfolioScenarios,
    (state) => ({ ...state, loading: true, error: '' })
  ),
  on(findPortfolio, (state) => ({ ...state, loading: true, error: '', portfolio: undefined })),
  on(findAllPortfoliosSuccess, (state, { portfolios }) => ({ ...state, loading: false, portfolios })),
  on(
    createPortfolioSuccess,
    findPortfolioSuccess,
    importPortfolioScenariosSuccess,
    deletePortfolioScenariosSuccess,
    (state, { portfolio }) => ({ ...state, loading: false, portfolio })
  ),
  on(
    deletePortfolioSuccess,
    downloadPortfolioExportSuccess,
    (state) => ({ ...state, loading: false })
  ),
  on(portfolioError, (state, { error }) => ({ ...state, loading: false, error })),
);

export default portfolioReducer;
