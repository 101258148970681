import { createFeatureSelector, createSelector } from '@ngrx/store';
import { portfolioFeatureKey, PortfolioState } from './portfolio.reducer';
import { PortfolioMetadata } from '../models/portfolio-metadata.model';
import { Portfolio } from '../models/portfolio.model';
import { AppState } from '../../store';

export const selectPortfolioState = createFeatureSelector<AppState, PortfolioState>(portfolioFeatureKey);

export const selectPortfolios = createSelector(
  selectPortfolioState,
  (state: PortfolioState): PortfolioMetadata[] => state.portfolios,
);
export const selectPortfolio = createSelector(
  selectPortfolioState,
  (state: PortfolioState): Portfolio | undefined => state.portfolio,
);
export const selectPortfolioLoading = createSelector(
  selectPortfolioState,
  (state: PortfolioState): boolean => state.loading,
);
export const selectPortfolioError = createSelector(
  selectPortfolioState,
  (state: PortfolioState): string => state.error,
);
