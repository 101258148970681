<div class="col">
  <h2 i18n data-testid="user-management-title">User management
    <span data-testid="seats">{{seatsDisplayValue}}</span>
  </h2>
  <app-search-input
    (filter)="filter.emit($event)"
    data-testid="search-input"
  ></app-search-input>
</div>
<div class="col">
  <h3> Enable or disable accounts of selected
    <ng-container [ngPlural]="selectionModel.selected?.length ?? 0">
      <ng-template ngPluralCase=">1">collaborators</ng-template>
      <ng-template ngPluralCase="other">collaborator</ng-template>
    </ng-container>
    ({{ selectionModel.selected?.length ?? 0 }})
  </h3>
  <div id="action-buttons">
    <app-action-button
      (click)="enableSelected()"
      [disabled]="selectionModel?.isEmpty()"
      data-testid="approve-button"
      color="primary"
    >
      Enable
    </app-action-button>
    <app-action-button
      (click)="disableSelected()"
      [disabled]="selectionModel?.isEmpty()"
      color="warn"
      data-testid="reject-button"
    >
      Disable
    </app-action-button>
  </div>
</div>
