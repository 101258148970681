import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LoadingService } from './core/loading.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
})
export class AppComponent implements OnInit {
  loading$: Observable<boolean>;

  constructor(private readonly loadingService: LoadingService) {}

  ngOnInit(): void {
    this.loading$ = this.loadingService.isLoading();
  }
}
