import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialTableModule } from '../../pages/project-modeling-page/component-page/material-table/material-table.module';
import { MaterialDataService } from '../../services/material.data-service';
import { StoreModule } from '@ngrx/store';
import { materialFeatureKey, materialReducer } from './material.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MaterialEffects } from './material.effects';
import { MaterialMapperService } from '../../services/material-mapper.service';
import { SnackbarModule } from '../../../../shared/snackbar/snackbar.module';
import { MergeMaterialsService } from '../../services/merge-materials.service';
import { ActionToMaterialService } from '../../services/action-to-material.service';

@NgModule({
  imports: [
    CommonModule,
    MaterialTableModule,
    StoreModule.forFeature(materialFeatureKey, materialReducer),
    EffectsModule.forFeature([ MaterialEffects ]),
    SnackbarModule,
  ],
  providers: [
    MaterialDataService,
    MaterialMapperService,
    MergeMaterialsService,
    ActionToMaterialService,
  ],
})
export class MaterialStoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: MaterialStoreModule) {
    if (parentModule) {
      throw new Error('MaterialStoreModule is already loaded');
    }
  }
}
