import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminHeaderComponent } from './admin-header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ProfileButtonModule } from '../../../project/modeling/components/profile-button/profile-button.module';


@NgModule({
  declarations: [
    AdminHeaderComponent,
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    ProfileButtonModule,
  ],
  exports: [
    AdminHeaderComponent,
  ],
})
export class AdminHeaderModule {}
