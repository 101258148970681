import { Pipe, PipeTransform } from '@angular/core';
import { NanPipe } from './nan.pipe';
import { ScientificPipe } from './scientific.pipe';
import { LCAIndicator } from '../../project/assessment/models/lca-indicator.model';
import { ScenarioSummaryValue } from '../../project/assessment/pages/models/scenario-summary.model';
import { PackagingDesignIndicator } from '../../project/assessment/models/packaging-design-indicator.model';
import { UnclassifiedIndicator } from '../../project/assessment/models/unclassified-indicator.model';
import { PercentOrNanPipe } from './percent-or-nan.pipe';
import { GHGIndicator } from '../../project/assessment/models/ghg-indicator.model';

@Pipe({
    name: 'scenarioSummary',
})
export class ScenarioSummaryPipe implements PipeTransform {

    private readonly lcaIndicators = LCAIndicator;
    private readonly ghgIndicator = GHGIndicator;
    private readonly packagingDesignIndicators = PackagingDesignIndicator;
    private readonly unclassifiedIndicator = UnclassifiedIndicator;

    constructor(
        private readonly nan: NanPipe,
        private readonly scientific: ScientificPipe,
        private readonly percentOrNan: PercentOrNanPipe,
    ) {
    }


    transform(value: ScenarioSummaryValue, ...args: unknown[]): string | number {
        if (this.shouldBeDisplayedAsScientific(value)) {
            return this.toScientificNotation(value);
        }
        if (this.isRecyclabilityIndicator(value)) {
            return this.percentOrNan.transform(+value.impact.value, this.packagingDesignIndicators.RECYCLABILITY);
        }
        if (this.isPackagingDesign(value) || this.isMaterialCircularityIndicator(value)) {
            return this.percentOrNan.transform(value.impact.value);
        }
        return `${value.impact.value}`;
    }

    private isPackagingDesign(value: ScenarioSummaryValue): boolean {
        return !!Object.values(this.packagingDesignIndicators)
            .find(indicator => indicator.localeCompare(value.label) === 0);
    }

    private isRecyclabilityIndicator(value: ScenarioSummaryValue): boolean {
        return value.label.localeCompare(this.packagingDesignIndicators.RECYCLABILITY) === 0;
    }

    private isMaterialCircularityIndicator(value: ScenarioSummaryValue): boolean {
        return value.label.localeCompare(this.unclassifiedIndicator.MATERIAL_CIRCULARITY) === 0;
    }

    private isPlasticFootprintIndicator(value: ScenarioSummaryValue): boolean {
        return value.label.localeCompare(this.unclassifiedIndicator.PLASTIC_FOOTPRINT) === 0;
    }

    private shouldBeDisplayedAsScientific(value: ScenarioSummaryValue): boolean {
        return this.isGHG(value) || this.isLCA(value) || this.isSingleScore(value) || this.isPlasticFootprintIndicator(value);
    }

    private isGHG(value: ScenarioSummaryValue): boolean {
        return !!Object.values(this.ghgIndicator).find(i => i.localeCompare(value.label) === 0);
    }

    private isLCA(value: ScenarioSummaryValue): boolean {
        return !!Object.values(this.lcaIndicators).find(indicator => indicator.localeCompare(value.label) === 0);
    }

    private isSingleScore(value: ScenarioSummaryValue): boolean {
        return this.unclassifiedIndicator.SINGLE_SCORE.localeCompare(value.label) === 0;
    }

    private toScientificNotation(value: ScenarioSummaryValue): string {
        const impact = this.nan.transform(value.impact.value);
        if (typeof impact === 'number') {
            return this.scientific.transform(impact);
        }
        return impact;
    }

}
