import { BrandType } from '../../../store/reference-data/models/reference-data.model';
import {Collaborator} from '../../../collaborator/collaborator.model';
import {ProjectMemberUser} from './project-member-user.models';

export interface Project {
  readonly position?: number;
  readonly id?: string;
  readonly name: string;
  readonly brandType: BrandType;
  readonly code: string;
  readonly ownerName: string;
  readonly ownerId: string;
  readonly createdAt: Date;
  readonly lastOpenedAt?: Date;
  readonly lastUpdatedBy?: string;
  readonly lastUpdatedAt?: Date;
  readonly description?: string;
  readonly collaborators?: ProjectMemberUser[];
}

export interface ProjectSearchParams {
  readonly page?: number;
  readonly search?: string;
  readonly size?: number;
  readonly sortDirection?: SortDirection;
  readonly sortedBy?: string;
  readonly brand?: string[];
  readonly owner?: string[];
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}
