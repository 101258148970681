import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { Packaging, Usage } from '../../../models/packaging.models';
import {
  addPrimaryPackaging,
  addPrimaryPackagingSuccess,
  deleteAllSuccess,
  getPrimaryPackagingSuccess,
  primaryPackagingError,
  updatePrimaryPackaging,
  updatePrimaryPackagingSuccess,
  updateProductMass,
  updateProductVolume,
  updateUsage,
} from './primary-packaging.actions';

export const primaryPackagingFeatureKey = 'primaryPackaging';

export interface PrimaryPackagingState extends EntityState<Packaging> {
  loading: boolean;
  lastCreated: Packaging | null;
  error: string | null;
}

export const adapter: EntityAdapter<Packaging> = createEntityAdapter<Packaging>();

export const initialState: PrimaryPackagingState = adapter.getInitialState({
  loading: false,
  lastCreated: null,
  error: null,
});

export const reducer: ActionReducer<PrimaryPackagingState, Action> = createReducer(
  initialState,
  on(
    addPrimaryPackaging,
    updatePrimaryPackaging, (state: PrimaryPackagingState, action) =>
      ({ ...state, loading: true, error: null, lastCreated: null }),
  ),
  on(getPrimaryPackagingSuccess, (state: PrimaryPackagingState, action) =>
    adapter.upsertMany(action.packaging, { ...state, loading: false }),
  ),
  on(addPrimaryPackagingSuccess, (state: PrimaryPackagingState, action) =>
    adapter.addOne(action.packaging, { ...state, loading: false, lastCreated: action.packaging }),
  ),
  on(updatePrimaryPackagingSuccess, (state: PrimaryPackagingState, action) => {
      const { packaging } = action;
      const update: Update<Packaging> = { id: packaging.id, changes: packaging };
      return adapter.updateOne(update, { ...state, loading: false });
    },
  ),
  on(primaryPackagingError, (state: PrimaryPackagingState, action) =>
    ({ ...state, loading: false, error: action.message })),
  on(updateProductMass, (state, action: { id: string, mass: number }) => {
    const { id, mass } = action;
    const update: Update<Packaging> = { id, changes: { productMass: mass } };
    return adapter.updateOne(update, state);
  }),
  on(updateProductVolume, (state: PrimaryPackagingState, action: { id: string, volume: number }) => {
    const { id, volume } = action;
    const update: Update<Packaging> = { id, changes: { productVolume: volume } };
    return adapter.updateOne(update, state);
  }),
  on(updateUsage, (state: PrimaryPackagingState, action: { id: string, usage: Usage }) => {
    const { id, usage } = action;
    const update: Update<Packaging> = { id, changes: { usage } };
    return adapter.updateOne(update, state);
  }),
  on(deleteAllSuccess, (state: PrimaryPackagingState, action: { scenarioId: string }) => {
    const { scenarioId } = action;
    const idsToDelete = Object.values(state.entities)
      .filter((p: Packaging) => p.scenarioId === scenarioId)
      .map((p: Packaging) => p.id);
    return adapter.removeMany(idsToDelete, state);
  }),
);

export function primaryPackagingReducer(state: PrimaryPackagingState | undefined, action: Action) {
  return reducer(state, action);
}
