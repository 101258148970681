import { DistributionMarket } from '../models/distribution-market';
import { ActionReducer, createReducer, on } from '@ngrx/store';
import {
  createDistributionMarket,
  createDistributionMarketSuccess,
  deleteDistributionMarket,
  deleteDistributionMarketSuccess,
  distributionMarketError,
  findAllDistributionMarkets,
  findAllDistributionMarketsSuccess,
  updateDistributionMarket,
  updateDistributionMarketSuccess, updateDistributionMarketTransports, updateDistributionMarketTransportsSuccess
} from './distribution-market.actions';

export const distributionMarketKey = 'distributionMarket';

export interface DistributionMarketState {
  distributionMarkets: DistributionMarket[];
  scenarioId: string | undefined;
  loading: boolean;
  error: string;
}

export const initialDistributionMarketState: DistributionMarketState = ({
  distributionMarkets: [],
  scenarioId: undefined,
  loading: false,
  error: ''
});

export interface DistributionMarketFeatureState {
  distributionMarket: DistributionMarketState;
}

const distributionMarketReducer: ActionReducer<DistributionMarketState> = createReducer<DistributionMarketState>(
  initialDistributionMarketState,
  on(
    createDistributionMarket,
    updateDistributionMarket,
    deleteDistributionMarket,
    updateDistributionMarketTransports,
    (state) => ({ ...state, loading: true, error: '' })
  ),
  on(
    findAllDistributionMarkets,
    (state, { scenarioId }) => ({
      ...state,
      scenarioId,
      loading: true,
      error: '',
      ...(state.scenarioId !== scenarioId && { distributionMarkets: [] }),
    })
  ),
  on(
    findAllDistributionMarketsSuccess,
    (state, { distributionMarkets }) => ({ ...state, distributionMarkets, loading: false, })
  ),
  on(
    createDistributionMarketSuccess,
    updateDistributionMarketSuccess,
    updateDistributionMarketTransportsSuccess,
    deleteDistributionMarketSuccess,
    (state) => ({ ...state, loading: false, })
  ),
  on(distributionMarketError, (state, { error }) => ({ ...state, loading: false, error })),
);

export default distributionMarketReducer;
