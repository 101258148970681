<button
  [class.border-accent-500]="color === 'accent'"
  [class.border-primary]="color === 'primary'"
  [class.border-warn]="color === 'warn'"
  [class.color-accent-500]="color === 'accent'"
  [class.color-primary]="color === 'primary'"
  [class.color-warn]="color === 'warn'"
  [class.large]="size === 'large'"
  [color]="color"
  [disabled]="disabled"
  class="action-button"
  mat-stroked-button
  type="button"
>
  <ng-content i18n></ng-content>
</button>
