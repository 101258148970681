import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { primaryPackagingFeatureKey, primaryPackagingReducer } from './primary/primary-packaging.reducer';
import { EffectsModule } from '@ngrx/effects';
import { PrimaryPackagingEffects } from './primary/primary-packaging.effects';
import { PackagingDataService } from '../../services/packaging.data-service';
import { PackagingMapperService } from '../../services/packaging-mapper.service';
import { secondaryPackagingFeatureKey, secondaryPackagingReducer } from './secondary/secondary-packaging.reducer';
import { SecondaryPackagingEffects } from './secondary/secondary-packaging.effects';
import { tertiaryPackagingFeatureKey, tertiaryPackagingReducer } from './tertiary/tertiary-packaging.reducer';
import { TertiaryPackagingEffects } from './tertiary/tertiary-packaging.effects';
import {
  tertiaryPalletizationPackagingFeatureKey,
  tertiaryPalletizationPackagingReducer,
} from './tertiary-palletization/tertiary-palletization-packaging.reducer';
import { TertiaryPalletizationPackagingEffects } from './tertiary-palletization/tertiary-palletization-packaging.effects';
import { PackagingEffects } from './packaging.effects';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(primaryPackagingFeatureKey, primaryPackagingReducer),
    StoreModule.forFeature(secondaryPackagingFeatureKey, secondaryPackagingReducer),
    StoreModule.forFeature(tertiaryPackagingFeatureKey, tertiaryPackagingReducer),
    StoreModule.forFeature(tertiaryPalletizationPackagingFeatureKey, tertiaryPalletizationPackagingReducer),
    EffectsModule.forFeature([
      PrimaryPackagingEffects,
      SecondaryPackagingEffects,
      TertiaryPackagingEffects,
      TertiaryPalletizationPackagingEffects,
      PackagingEffects,
    ]),
  ],
  providers: [
    PackagingDataService,
    PackagingMapperService,
  ],
})
export class PackagingStoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: PackagingStoreModule) {
    if (parentModule) {
      throw new Error('PackagingStoreModule is already loaded');
    }
  }
}
