import { Injectable } from '@angular/core';
import { AbstractMapper } from '../../project/abstract-mapper';
import { PublishedScenario } from '../models/published-scenario.model';
import { PublishedScenarioMetadataDto } from '../../api/dtos/__generated__/publishedScenarioMetadataDto';
import { PublishedScenarioCompleteDto } from '../../api/dtos/__generated__/publishedScenarioCompleteDto';
import { CollaboratorMapperService } from '../../collaborator/collaborator-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class PublishedScenarioMetadataMapperService extends AbstractMapper<PublishedScenarioMetadataDto, PublishedScenario> {

  constructor(private readonly collaboratorMapperService: CollaboratorMapperService) { super(); }

  from(dto: PublishedScenarioMetadataDto | PublishedScenarioCompleteDto): PublishedScenario {
    const {
      id,
      name,
      description,
      reference,
      hasSecondaryPackaging,
      hasTertiaryBoxPackaging,
      hasTertiaryPalletizationPackaging,
      packagingType,
      baseMaterialType,
      productType,
      statusType,
      productionCountry,
      publicationStatus,
      brandType,
      publishedAt,
      publishedBy,
    } = dto;
    return {
      id,
      name,
      description,
      reference,
      hasSecondaryPackaging,
      hasTertiaryBoxPackaging,
      hasTertiaryPalletizationPackaging,
      packagingType,
      baseMaterialType,
      productType,
      statusType,
      productionCountry,
      publicationStatus,
      brandType,
      publishedAt: new Date(publishedAt),
      publishedBy: this.collaboratorMapperService.from(publishedBy),
    };
  }

  to(model: PublishedScenario): PublishedScenarioMetadataDto {
    const {
      id,
      name,
      description,
      reference,
      hasSecondaryPackaging,
      hasTertiaryBoxPackaging,
      hasTertiaryPalletizationPackaging,
      packagingType,
      baseMaterialType,
      productType,
      statusType,
      productionCountry,
      salesCountry,
      publicationStatus,
      brandType,
      publishedAt,
      publishedBy
    } = model;

    return {
      id,
      name,
      description,
      reference,
      hasSecondaryPackaging,
      hasTertiaryBoxPackaging,
      hasTertiaryPalletizationPackaging,
      packagingType,
      baseMaterialType,
      productType,
      statusType,
      productionCountry,
      salesCountry,
      publicationStatus,
      brandType,
      publishedAt: publishedAt.toISOString(),
      publishedBy: this.collaboratorMapperService.to(publishedBy)

    } as PublishedScenarioMetadataDto;
  }
}
