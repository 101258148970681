import { Pipe, PipeTransform } from '@angular/core';
import { Mass, MassType } from '../../project/modeling/models/material.models';

@Pipe({
  name: 'grammage',
})
export class GrammagePipe implements PipeTransform {

  transform(mass: Mass, ...args: unknown[]): string {
    if (mass?.massType === MassType.SURFACE_TIMES_GRAMMAGE) {
      return mass?.grammage ? `${mass.grammage}` : '-';
    }
    return '-';
  }

}
