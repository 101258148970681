import { Injectable } from '@angular/core';
import { Material } from '../models/material.models';

@Injectable({
  providedIn: 'root',
})
export class MergeMaterialsService {

  constructor() { }

  merge(newMaterial: Partial<Material>, storedMaterial: Partial<Material>): Material {
    const merged = {
      ...storedMaterial,
      ...newMaterial,
      conversionProcess: !!storedMaterial?.conversionProcess || !!newMaterial?.conversionProcess ? {
        ...storedMaterial.conversionProcess,
        ...newMaterial.conversionProcess,
      } : null,
      mass: !!storedMaterial?.mass || !!newMaterial?.mass ? {
        ...storedMaterial.mass,
        ...newMaterial.mass,
      } : null,
    };
    if (this.shouldResetConversionProcess(newMaterial, storedMaterial)) {
      merged.conversionProcess = null;
    }
    return merged;
  }

  private shouldResetConversionProcess(newMaterial: Partial<Material>, storedMaterial: Partial<Material>): boolean {
    const newMaterialType = newMaterial?.materialType;
    const storedMaterialType = storedMaterial?.materialType;
    const materialTypeHasChanged = !!newMaterialType?.label && newMaterialType?.label !== storedMaterialType?.label;
    return materialTypeHasChanged;
  }
}
