import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { scenarioFeatureKey, scenarioReducer } from './scenario.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ScenarioEffects } from './scenario.effects';
import { ScenarioDataService } from '../../services/scenario.data-service';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(scenarioFeatureKey, scenarioReducer),
    EffectsModule.forFeature([ ScenarioEffects ]),
    MatSnackBarModule,
  ],
  providers: [
    ScenarioDataService,
  ],
})
export class ScenarioStoreModule {}
