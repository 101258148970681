import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FinishingProcess } from '../models/finishing-process.models';
import { HttpClient } from '@angular/common/http';
import { FinishingProcessMapperService } from './finishing-process-mapper.service';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { FinishingProcessDto } from '../../../api/dtos/__generated__/finishingProcessDto';

@Injectable({
  providedIn: 'root',
})
export class FinishingProcessDataService {

  FINISHING_PROCESS_URL = `${environment.apiUrl}/finishing-processes`;

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: FinishingProcessMapperService,
  ) { }

  create(finishingProcess: FinishingProcess): Observable<FinishingProcess> {
    const dto: FinishingProcessDto = this.mapper.mapFinishingProcessDtoFromFinishingProcess(finishingProcess);
    return this.http.post(this.FINISHING_PROCESS_URL, dto).pipe(
      map((response: FinishingProcessDto) => this.mapper.mapFinishingProcessFromFinishingProcessDto(response)),
    );
  }

  update(finishingProcess: FinishingProcess): Observable<FinishingProcess> {
    const dto: FinishingProcessDto = this.mapper.mapFinishingProcessDtoFromFinishingProcess(finishingProcess);
    return this.http.put(`${this.FINISHING_PROCESS_URL}/${finishingProcess.id}`, dto).pipe(
      map((response: FinishingProcessDto) => this.mapper.mapFinishingProcessFromFinishingProcessDto(response)),
    );
  }

  delete(id: string): Observable<string> {
    return this.http.delete(`${this.FINISHING_PROCESS_URL}/${id}`).pipe(
      map(() => id),
    );
  }
}
