import { ActionCreator, createAction, props } from '@ngrx/store';
import { Scenario } from '../../models/scenario.model';
import { TypedAction } from '@ngrx/store/src/models';

const SCENARIO_SIDE_EFFECT_ACTION = '[Scenario/API]';
const SCENARIO_ACTION = '[Scenario]';

export const getScenario = createAction(
  `${SCENARIO_SIDE_EFFECT_ACTION} Get Scenario`,
  props<{ id: string }>(),
);

export const getScenarioSuccess = createAction(
  `${SCENARIO_SIDE_EFFECT_ACTION} Get Scenario Success`,
  props<{ scenario: Scenario }>(),
);

export const getScenariosSuccess = createAction(
  `${SCENARIO_SIDE_EFFECT_ACTION} Get Scenarios Success`,
  props<{ scenarios: Scenario[] }>(),
);

export const addScenario = createAction(
  `${SCENARIO_SIDE_EFFECT_ACTION} Add Scenario`,
  props<{ projectId: string }>(),
);

export const addScenarioSuccess = createAction(
  `${SCENARIO_SIDE_EFFECT_ACTION} Add Scenario Success`,
  props<{ scenario: Scenario }>(),
);

export const importScenario = createAction(
  `${SCENARIO_SIDE_EFFECT_ACTION} Import Scenario`,
  props<{ projectId: string, fromPublishedScenarioId: string }>(),
);

export const importScenarioSuccess = createAction(
  `${SCENARIO_SIDE_EFFECT_ACTION} Import Scenario Success`,
  props<{ scenarioId: string }>(),
);

export const updateScenario = createAction(
  `${SCENARIO_SIDE_EFFECT_ACTION} Update Scenario`,
  props<{ scenario: Partial<Scenario> }>(),
);

export const updateScenarioSuccess = createAction(
  `${SCENARIO_SIDE_EFFECT_ACTION} Update Scenario Success`,
  props<{ scenario: Scenario }>(),
);

export const deleteScenario = createAction(
  `${SCENARIO_SIDE_EFFECT_ACTION} Delete Scenario`,
  props<{ id: string, projectId: string }>(),
);

export const deleteScenarioSuccess = createAction(
  `${SCENARIO_SIDE_EFFECT_ACTION} Delete Scenario Success`,
  props<{ id: string }>(),
);

export const duplicateScenario = createAction(
  `${SCENARIO_SIDE_EFFECT_ACTION} Duplicate Scenario`,
  props<{ id: string, name?: string }>(),
);

export const duplicateScenarioSuccess = createAction(
  `${SCENARIO_SIDE_EFFECT_ACTION} Duplicate Scenario Success`,
  props<{ scenario: Scenario }>(),
);

export const scenarioError = createAction(
  `${SCENARIO_SIDE_EFFECT_ACTION} Scenario Error`,
  props<{ message: string }>(),
);

export const updateAssessmentSelected = createAction(
  `${SCENARIO_ACTION} Update Scenario Assessment Selected`,
  props<{ id: string, selected: boolean }>(),
);

export const updateHasSecondaryPackaging = createAction(
  `${SCENARIO_ACTION} Update Has Secondary Packaging`,
  props<{ id: string, scenarioId: string, hasPackaging: boolean }>(),
);

export const updateHasTertiaryPackaging = createAction(
  `${SCENARIO_ACTION} Update Has Tertiary Packaging`,
  props<{ id: string, scenarioId: string, hasPackaging: boolean }>(),
);

export const updateHasTertiaryPalletizationPackaging = createAction(
  `${SCENARIO_ACTION} Update Has Tertiary Palletization Packaging`,
  props<{ id: string, scenarioId: string, hasPackaging: boolean }>(),
);


export type UpdatePackagingAssociationAction = ActionCreator<string, (props: { id: string, scenarioId: string, hasPackaging: boolean }) => ({ id: string, scenarioId: string, hasPackaging: boolean }) & TypedAction<string>>;
