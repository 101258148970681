import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { PublishedScenario } from '../models/published-scenario.model';

@Component({
  selector: 'app-scenario-library-table-header',
  templateUrl: './scenario-library-table-header.component.html',
  styleUrls: [ './scenario-library-table-header.component.scss' ],
})
export class ScenarioLibraryTableHeaderComponent implements OnInit {

  selectionModel: SelectionModel<PublishedScenario>;

  @Output()
  approve = new EventEmitter<PublishedScenario[]>();
  @Output()
  reject = new EventEmitter<PublishedScenario[]>();
  @Output()
  delete = new EventEmitter<PublishedScenario[]>();
  @Output()
  setToPending = new EventEmitter<PublishedScenario[]>();
  @Output()
  filter = new EventEmitter<Event>();

  @Input()
  set selection(selection: SelectionModel<PublishedScenario>) {
    this.selectionModel = selection;
  }

  constructor() { }

  ngOnInit(): void {
  }

  approveSelected(): void {
    this.approve.emit(this.selectionModel.selected);
  }

  rejectSelected(): void {
    this.reject.emit(this.selectionModel.selected);
  }

  deleteSelected(): void {
    this.delete.emit(this.selectionModel.selected);
  }

  setSelectedToPending(): void {
    this.setToPending.emit(this.selectionModel.selected);
  }

  get numberOfSelectedScenarios(): number {
    return this.selectionModel?.selected?.length ?? 0;
  }
}
