import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Material } from '../../../../models/material.models';

@Component({
  selector: 'app-material-table',
  templateUrl: './material-table.component.html',
  styleUrls: [ './material-table.component.scss' ],
})
export class MaterialTableComponent implements OnInit {

  @Output()
  addMaterial: EventEmitter<void> = new EventEmitter();
  @Output()
  materialClick: EventEmitter<Material> = new EventEmitter();
  @Output()
  duplicateMaterial: EventEmitter<Material> = new EventEmitter();
  @Output()
  deleteMaterial: EventEmitter<Material> = new EventEmitter();
  dataSource: MatTableDataSource<Material>;
  displayedColumns: string[] = [ 'material',
                                 'mass',
                                 'surface',
                                 'grammage',
                                 'duplicate',
                                 'delete' ];
  isLockedByMe: boolean;

  @Input()
  set materials(m: Material[]) {
    this.dataSource = new MatTableDataSource(m);
  }

  @Input()
  set lockedByMe(lockedByMe: boolean) {
    this.isLockedByMe = lockedByMe;
  }

  constructor() { }

  ngOnInit(): void {
  }
}
