import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateProjectDialogComponent } from './create-project-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';


@NgModule({
  declarations: [
    CreateProjectDialogComponent,
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        MatDialogModule,
        MatAutocompleteModule,
    ],
  exports: [
    CreateProjectDialogComponent,
  ],
})
export class CreateProjectDialogModule {}
