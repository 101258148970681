import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StatisticsHeaderModule} from './header/statistics-header.module';
import {StatisticsTableModule} from './table/statistics-table.module';
import {StatisticsDataModule} from '../store/statistics-data/statistics-data.module';
import {StatisticsCardModule} from './statistics-card/statistics-card.module';

@NgModule({
  exports: [
    StatisticsHeaderModule,
    StatisticsTableModule,
    StatisticsCardModule
  ],
  imports: [
    CommonModule,
    StatisticsHeaderModule,
    StatisticsTableModule,
    StatisticsDataModule,
    StatisticsCardModule
  ]
})
export class StatisticsModule {
}
