import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { FinishingProcess } from '../../models/finishing-process.models';
import {
  addFinishingProcess,
  addFinishingProcessSuccess,
  deleteAll,
  deleteFinishingProcess,
  deleteFinishingProcessSuccess,
  finishingProcessError,
  getFinishingProcessesSuccess,
  updateCountry,
  updateElectricityType,
  updateFinishingProcess,
  updateFinishingProcessSuccess,
} from './finishing-process.actions';


export interface FinishingProcessState extends EntityState<FinishingProcess> {
  loading: boolean;
  lastCreated: FinishingProcess | null;
  error: string | null;
}

export const adapter: EntityAdapter<FinishingProcess> = createEntityAdapter<FinishingProcess>();

export const initialState: FinishingProcessState = adapter.getInitialState({
  loading: false,
  lastCreated: null,
  error: null,
});

const reducer = createReducer(
  initialState,
  on(
    addFinishingProcess,
    updateFinishingProcess,
    deleteFinishingProcess, (state: FinishingProcessState, action) =>
      ({ ...state, loading: true, error: null, lastCreated: null }),
  ),
  on(getFinishingProcessesSuccess, (state, action) =>
    adapter.upsertMany(action.finishingProcesses, { ...state, loading: false, error: null }),
  ),
  on(addFinishingProcessSuccess,
    (state, action) =>
      adapter.addOne(action.finishingProcess, {
        ...state,
        loading: false,
        error: null,
        lastCreated: action.finishingProcess,
      }),
  ),
  on(updateFinishingProcessSuccess, (state, action) => {
    const { finishingProcess } = action;
    const update: Update<FinishingProcess> = {
      id: finishingProcess.id,
      changes: finishingProcess,
    };
    return adapter.updateOne(update, { ...state, loading: false, error: null });
  }),
  on(deleteFinishingProcessSuccess, (state, action) => {
    const { id } = action;
    return adapter.removeOne(id, { ...state, loading: false, error: null });
  }),
  on(finishingProcessError, (state, action) =>
    ({ ...state, loading: false, error: action.message })),
  on(updateCountry, (state, action) => {
    const { id, country } = action;
    const update: Update<FinishingProcess> = { id, changes: { country } };
    return adapter.updateOne(update, state);
  }),
  on(updateElectricityType, (state, action) => {
    const { id, electricityType } = action;
    const update: Update<FinishingProcess> = { id, changes: { electricity: electricityType } };
    return adapter.updateOne(update, state);
  }),
  on(deleteAll, (state: FinishingProcessState, action: { type: string, componentId: string }) => {
    const { componentId } = action;
    const ids: string[] = Object.values(state.entities)
      .filter((fp: FinishingProcess) => fp.componentId === componentId)
      .map((fp: FinishingProcess) => fp.id);
    return adapter.removeMany(ids, state);
  }),
);

export function finishingProcessReducer(state: FinishingProcessState | null, action: Action) {
  return reducer(state, action);
}

