import { AbstractControl, ValidationErrors } from '@angular/forms';

export const dropdownValidator = (
  authorizedValues: Partial<{ value: string }>[],
) => (control: AbstractControl): ValidationErrors | null => {
  let inputValue = '';
  if (typeof control?.value === 'string') {
    return { dropdownError: true };
  }
  if (typeof control?.value?.value === 'string') {
    inputValue = control?.value?.value;
  }
  if (!inputValue) {
    return null;
  }
  const included = authorizedValues.find(v => v.value.toLowerCase()
    .localeCompare(inputValue?.toLowerCase()) === 0);
  if (!included?.value) {
    return { dropdownError: true };
  }
  return null;
};

