import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ReferenceDataEffects } from './store/reference-data.effects';
import { StoreModule } from '@ngrx/store';
import { reducer, referenceDataFeatureKey } from './store/reference-data.reducer';
import { ReferenceDataDataService } from './reference-data.data-service';
import { ReferenceDataMappingService } from './reference-data.mapping-service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(referenceDataFeatureKey, reducer),
    EffectsModule.forFeature([ ReferenceDataEffects ]),
  ],
  providers: [
    ReferenceDataDataService,
    ReferenceDataMappingService,
  ],
})
export class ReferenceDataModule {}
