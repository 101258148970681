import {createAction, props} from '@ngrx/store';
import {Collaborator} from '../../../collaborator/collaborator.model';

export const getAllCollaborators = createAction(
  '[UserManagement/API] Get all collaborators',
);

export const getAllCollaboratorsSuccess = createAction(
  '[UserManagement/API] Get all collaborators success',
  props<{ collaborators: Collaborator[] }>(),
);

export const getAllCollaboratorsError = createAction(
  '[UserManagement/API] Get all collaborators error',
  props<{ message: string }>(),
);

export const updateCollaborators = createAction(
  '[UserManagement/API] Update collaborators',
  props<{ collaborators: Collaborator[] }>()
);

export const updateCollaboratorsSuccess = createAction(
  '[UserManagement/API] Update collaborators success',
  props<{ collaborators: Collaborator[] }>(),
);

export const updateCollaboratorsError = createAction(
  '[UserManagement/API] Update collaborators error',
  props<{ message: string }>(),
);

export const updateCollaborator = createAction(
  '[UserManagement/API] Update collaborator',
  props<{ collaborator: Collaborator }>(),
);

export const updateCollaboratorSuccess = createAction(
  '[UserManagement/API] Update collaborator success',
  props<{ collaborator: Collaborator }>(),
);

export const updateCollaboratorError = createAction(
  '[UserManagement/API] Update collaborator error',
  props<{ message: string, collaborator: Collaborator }>()
);
