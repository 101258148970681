import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Collaborator} from '../../../collaborator/collaborator.model';
import {SelectionModel} from '@angular/cdk/collections';
import {Statistics} from '../../../store/statistics-data/models/statistics-data.model';

@Component({
  selector: 'app-user-table-header',
  templateUrl: './user-table-header.component.html',
  styleUrls: ['./user-table-header.component.scss']
})
export class UserTableHeaderComponent implements OnInit {

  selectionModel: SelectionModel<Collaborator> = new SelectionModel(true, []);

  @Input()
  set selection(selection: SelectionModel<Collaborator>) {
    if (!!selection) {
      this.selectionModel = selection;
    }
  }

  seatsDisplayValue: string;

  @Input()
  set statistics(statistics: Statistics) {
    if (!!statistics) {
      this.seatsDisplayValue = `(${statistics.enabledUsers} users / ${statistics.seatLimit} seats)`;
    }
  }

  @Output()
  filter: EventEmitter<Event> = new EventEmitter();
  @Output()
  enable: EventEmitter<Collaborator[]> = new EventEmitter();
  @Output()
  disable: EventEmitter<Collaborator[]> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  enableSelected() {
    this.enable.emit(this.selectionModel.selected);
  }

  disableSelected() {
    this.disable.emit(this.selectionModel.selected);
  }
}
