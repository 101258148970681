import { Injectable } from '@angular/core';
import { ProjectLockDto } from '../../api/dtos/__generated__/projectLockDto';
import { ProjectLock } from './project-lock.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectLockMapperService {

  constructor() {}

  map(dto: ProjectLockDto, mine: boolean): ProjectLock {
    const { projectId, owner, lastAction } = dto;
    return {
      projectId,
      owner,
      itDoesBelongToMe: mine,
      lastAction: new Date(lastAction),
    } as ProjectLock;
  }
}
