import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../store';
import * as fromProject from '../../modeling/store/project';
import { ProjectLock } from '../../project-lock/project-lock.model';
import { switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GetProjectsResolver implements Resolve<boolean> {

  constructor(private readonly store: Store<AppState>) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.store.dispatch(fromProject.getProjects({}));
    return this.store.pipe(
      select(fromProject.projectLock),
      take(1),
      switchMap((projectLock: ProjectLock) => {
        if (projectLock?.projectId) {
          this.store.dispatch(fromProject.releaseProjectLock({ projectId: projectLock.projectId }));
        }
        return of(true);
      }),
    );
  }
}
