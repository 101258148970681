import { Injectable } from '@angular/core';
import { MaterialTransport } from '../models/material.models';
import { MaterialTransportDto } from '../../../api/dtos/__generated__/materialTransportDto';

@Injectable({
  providedIn: 'root',
})
export class MaterialTransportMapperService {

  constructor() { }

  mapMaterialTransportFromMaterialTransportDto(dto: MaterialTransportDto): MaterialTransport {
    const {
      id,
      materialId,
      distance,
      transportType,
    } = dto;

    return {
      id,
      materialId,
      distance,
      transportType: {
        label: transportType?.label,
        value: transportType?.value,
      },
    } as MaterialTransport;
  }

  mapMaterialTransportListFromMaterialTransportDtoList(dtos: MaterialTransportDto[]): MaterialTransport[] {
    return dtos.map((dto) => this.mapMaterialTransportFromMaterialTransportDto(dto));
  }

  mapMaterialTransportDtoFromMaterialTransport(transport: MaterialTransport): MaterialTransportDto {
    const {
      id,
      materialId,
      transportType,
      distance,
    } = transport;

    return {
      id,
      materialId,
      transportType,
      distance,
    } as MaterialTransportDto;
  }
}
