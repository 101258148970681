import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EqopackBrandComponent } from './eqopack-brand.component';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [ EqopackBrandComponent ],
  imports: [
    CommonModule,
    MatIconModule,
  ],
  exports: [ EqopackBrandComponent ],
})
export class EqopackBrandModule {}
