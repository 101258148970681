import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '../../../../store';
import { Dictionary } from '@ngrx/entity';
import { adapter, materialFeatureKey, MaterialState } from './material.reducer';
import { Material } from '../../models/material.models';

export const selectMaterialState: MemoizedSelector<AppState, MaterialState> =
  createFeatureSelector(materialFeatureKey);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectMaterialState);

export const selectById = (materialId: string) => createSelector(
  selectEntities,
  (material: Dictionary<Material>) => material[materialId],
);

export const selectLastCreated = createSelector(
  selectMaterialState,
  (state: MaterialState) => {
    if (!state.loading) {
      return state.lastCreated;
    }
  },
);

export const isLoading = createSelector(
  selectMaterialState,
  (state: MaterialState) => state.loading,
);

export const selectByComponentId = (componentId: string) => createSelector(
  selectAll,
  (materials: Material[]) => materials.filter((material: Material) => material?.componentId === componentId),
);

export const selectRecyclable = (materialId: string) => createSelector(
  selectById(materialId),
  (material: Material) => material?.recyclable,
);

export const selectCompostable = (materialId: string) => createSelector(
  selectById(materialId),
  (material: Material) => material?.compostable,
);

export const selectRecyclingRate = (materialId: string) => createSelector(
  selectById(materialId),
  (material: Material) => material?.recyclingRate,
);

export const selectRecyclingRateType = (materialId: string) => createSelector(
  selectById(materialId),
  (material: Material) => material?.recyclingRateType,
);

export const somethingWentWrong = createSelector(
  selectMaterialState,
  (state: MaterialState) => state.error,
);

