import { NgModule } from '@angular/core';
import { PortfoliosPageComponent } from './portfolios-page.component';
import {PortfolioTableModule} from '../../../portfolio/components/portfolio-table/portfolio-table.module';

@NgModule({
  declarations: [
    PortfoliosPageComponent
  ],
  imports: [
    PortfolioTableModule,
  ],
})
export class PortfoliosPageModule {}
