import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as fromReferenceData from '../../../store/reference-data/store';
import { dropdownValidator } from '../../../shared/validators/dropdown-validator';
import { arrayNotEmpty } from '../../../shared/rxjs-utils/rxjsFilters';
import { positive } from '../../../shared/validators/positive.validator';
import { Country } from '../../../store/reference-data/models/reference-data.model';
import { filter, map, take } from 'rxjs/operators';
import { DistributionMarket, EditDistributionMarket } from '../models/distribution-market';
import { ReferenceDataFeatureState } from '../../../store/reference-data/store/reference-data.reducer';
import {
  DeleteConfirmationModalComponent
} from '../../../shared/delete-confirmation-modal/delete-confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-distribution-market-form',
  template: `
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <app-string-selector label="Area"
                               [value]="this.form.controls.country?.value?.value"
                               [options]="countriesNames$ |async"
                               (changeValue)="handleChangeCountry($event)"
                               [disabled]="this.form.disabled" required="true"></app-string-selector>
          <mat-error *ngIf="form.controls?.country.hasError('dropdownError')" data-testid="countryDropdownError" i18n>
            You must select a country from the list
          </mat-error>
        </div>
        <div class="col">
          <mat-form-field id="weight">
            <mat-label i18n>Sales volume</mat-label>
            <input autocomplete="off" formControlName="weight" id="weight" matInput type="number">
            <mat-error *ngIf="form.controls?.weight.hasError('positiveError')" data-testid="positiveError" i18n>
              Sales volume should be greater than zero
            </mat-error>
          </mat-form-field>
        </div>
        <p>{{percentage | percent}}</p>
        <button (pointerup)="removeAction()" *ngIf="form.enabled && removable" color="warn" data-testid="delete"
                mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </form>
  `,
  styles: [ `
    :host {
      width: 100%;
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-evenly;
    }
  ` ],
})
export class DistributionMarketFormComponent implements OnInit, OnDestroy {

  form = this.formBuilder.group({
    country: [ null, { validators: [ Validators.required ] } ],
    weight: [ null, {
      validators: [ Validators.required, positive ],
      updateOn: 'blur',
    } ],
  });

  countries$: Observable<Country[]>;
  countriesNames$: Observable<string[]>;
  subscription: Subscription = new Subscription();

  @Output() update = new EventEmitter<EditDistributionMarket>();
  @Output() remove = new EventEmitter<EditDistributionMarket>();
  @Input() removable = true;
  @Input() percentage = 0;
  @Input() excludedCountries: Country[] = [];

  @Input()
  set isLockedByMe(isLockedByMe: boolean) {
    if (!isLockedByMe) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
  }

  @Input()
  set distributionMarket(value: DistributionMarket | undefined) {
    this.setFormValue(value);
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store<ReferenceDataFeatureState>,
    private readonly dialog: MatDialog,
  ) {
  }

  handleChangeCountry(value: string | undefined) {
    const sub = this.countries$.pipe(take(1))
      .subscribe((countries: Country[]) => {
        const newValue = countries.filter((country) => country.value === value)[0];
        this.form.controls.country.setValue(newValue, { emitEvent: true });
      });
    sub.unsubscribe();
  }

  displayCountry(country: Country): string {
    return country?.value;
  }

  removeAction() {
    const dialogRef = this.dialog.open(DeleteConfirmationModalComponent, {
      data: {
        type: 'distribution market',
        name: this.form.value.country.value,
      },
      width: null,
    });
    this.subscription.add(dialogRef.afterClosed()
      .pipe(filter(confirm => !!confirm))
      .subscribe(_ => this.remove.emit(this.form.value)),
    );
  }

  focusIn() {
    const { country } = this.form.controls;
    if (country.enabled) {
      country.patchValue('');
    }
  }

  ngOnInit(): void {
    this.subscription.add(this.setFormValidators());
    this.subscription.add(this.registerFormChanges());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setFormValidators(): Subscription {
    const { country } = this.form.controls;
    const allCountries$ = this.store.pipe(select(fromReferenceData.selectCountries), arrayNotEmpty);
    this.countries$ = allCountries$.pipe(
      map((countries: Country[]) => countries.filter((c) =>
        !this.excludedCountries.map((v) => v.value).includes(c.value) || country.value?.value === c.value
      ))
    );
    this.countriesNames$ = this.countries$.pipe(map((countries) => countries.map((c) => c.value)));
    return allCountries$.subscribe(types => {
      country.setValidators([ dropdownValidator(types), Validators.required ]);
    });
  }

  private setFormValue(distributionCountry: DistributionMarket | undefined): void {
    this.form.setValue({
      country: distributionCountry?.country ?? null,
      weight: distributionCountry?.weight ?? 1,
    }, { emitEvent: false });
  }

  private registerFormChanges(): Subscription {
    return this.form.valueChanges
      .pipe(filter(() => this.form.valid))
      .subscribe(distributionCountry => this.update.emit(distributionCountry));
  }
}
