<form [formGroup]="projectForm" novalidate>
  <h2 mat-dialog-title data-testid="project-information-title">
    <ng-container *ngIf="!data; else edit"><span i18n>New &nbsp;</span></ng-container>
    <ng-template #edit><span i18n>Edit &nbsp;</span></ng-template>
    <span i18n>project</span>
  </h2>
  <mat-dialog-content>
    <div class="row">
      <div class="col">
        <mat-form-field>
          <input formControlName="name" matInput placeholder="Project name">
          <mat-error *ngIf="projectForm.controls.name.hasError('required')" i18n>
            Project name is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field (pointerdown)="clearInput('brandType')" id="brand-type-form-field">
          <input
            [matAutocomplete]="brandTypeAutocomplete"
            formControlName="brandType"
            id="brandType"
            matInput
            type="text"
            placeholder="Brand"
          >
          <mat-autocomplete
            #brandTypeAutocomplete="matAutocomplete"
            (optionSelected)="blur()"
            [displayWith]="displayType"
          >
            <mat-option *ngFor="let type of filteredBrandTypes$ | async" [value]="type">
              {{type.value}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="projectForm.controls?.brandType.hasError('dropdownError')" data-testid="brand-type-error"
                     i18n>
            You must select a brand from the list
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field>
          <input formControlName="code" i18n-placeholder matInput placeholder="Code">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field>
          <textarea formControlName="description" i18n-placeholder matInput placeholder="Description"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field>
          <mat-label i18n>Owner</mat-label>
          <input formControlName="owner" matInput>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field>
          <mat-label i18n>Creation date</mat-label>
          <input formControlName="createdAt" matInput>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button i18n mat-dialog-close mat-stroked-button type="button">Cancel</button>
    <button [disabled]="projectForm.invalid"
            [mat-dialog-close]="project"
            class="border-primary"
            color="primary"
            i18n
            id="add-button"
            mat-stroked-button
            type="submit">
      { addOrCreate, plural, =0 {Create} =1 {Confirm} }
    </button>
  </mat-dialog-actions>
</form>
