import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Statistics } from './models/statistics-data.model';
import { StatisticsDto } from '../../api/dtos/__generated__/statisticsDto';
import { map } from 'rxjs/operators';
import { STATISTICS_DATA_ID } from './store/statistics-data.reducer';

@Injectable({
  providedIn: 'root',
})
export class StatisticsDataDataService {
  STATISTICS_DATA_URL = `${environment.apiUrl}/statistics`;

  constructor(
    private readonly http: HttpClient,
  ) {
  }

  get(): Observable<Statistics> {
    return this.http.get(this.STATISTICS_DATA_URL)
      .pipe(map((data: StatisticsDto) =>
        ({ id: STATISTICS_DATA_ID, ...data } as Statistics)
      ));
  }
}
