import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorDto } from '../../api/dtos/__generated__/errorDto';

@Component({
  template: `
    <ngx-extended-pdf-viewer
      [src]="data"
      height="90vh"
      useBrowserLocale="true"
      [enableDragAndDrop]="false"
      [showOpenFileButton]="false"
    ></ngx-extended-pdf-viewer>`,
  styles: [ '' ],
})
export class PdfDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public readonly data: string) { }

  ngOnInit(): void {
  }

}
