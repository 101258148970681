import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { WashingProcess } from '../../models/washing-process.models';
import {
  addWashingProcess,
  addWashingProcessSuccess,
  deleteAll,
  deleteWashingProcess,
  deleteWashingProcessSuccess,
  washingProcessError,
  getWashingProcessesSuccess,
  updateCountry,
  updateElectricityType,
  updateWashingProcess,
  updateWashingProcessSuccess,
} from './washing-process.actions';


export interface WashingProcessState extends EntityState<WashingProcess> {
  loading: boolean;
  lastCreated: WashingProcess | null;
  error: string | null;
}

export const adapter: EntityAdapter<WashingProcess> = createEntityAdapter<WashingProcess>();

export const initialState: WashingProcessState = adapter.getInitialState({
  loading: false,
  lastCreated: null,
  error: null,
});

const reducer = createReducer(
  initialState,
  on(
    addWashingProcess,
    updateWashingProcess,
    deleteWashingProcess, (state: WashingProcessState, action) =>
      ({ ...state, loading: true, error: null, lastCreated: null }),
  ),
  on(getWashingProcessesSuccess, (state, action) =>
    adapter.upsertMany(action.washingProcesses, { ...state, loading: false, error: null }),
  ),
  on(addWashingProcessSuccess,
    (state, action) =>
      adapter.addOne(action.washingProcess, {
        ...state,
        loading: false,
        error: null,
        lastCreated: action.washingProcess,
      }),
  ),
  on(updateWashingProcessSuccess, (state, action) => {
    const { washingProcess } = action;
    const update: Update<WashingProcess> = {
      id: washingProcess.id,
      changes: washingProcess,
    };
    return adapter.updateOne(update, { ...state, loading: false, error: null });
  }),
  on(deleteWashingProcessSuccess, (state, action) => {
    const { id } = action;
    return adapter.removeOne(id, { ...state, loading: false, error: null });
  }),
  on(washingProcessError, (state, action) =>
    ({ ...state, loading: false, error: action.message })),
  on(updateCountry, (state, action) => {
    const { id, country } = action;
    const update: Update<WashingProcess> = { id, changes: { country } };
    return adapter.updateOne(update, state);
  }),
  on(updateElectricityType, (state, action) => {
    const { id, electricityType } = action;
    const update: Update<WashingProcess> = { id, changes: { electricity: electricityType } };
    return adapter.updateOne(update, state);
  }),
  on(deleteAll, (state: WashingProcessState, action: { type: string, componentId: string }) => {
    const { componentId } = action;
    const ids: string[] = Object.values(state.entities)
      .filter((fp: WashingProcess) => fp.componentId === componentId)
      .map((fp: WashingProcess) => fp.id);
    return adapter.removeMany(ids, state);
  }),
);

export function washingProcessReducer(state: WashingProcessState | null, action: Action) {
  return reducer(state, action);
}

