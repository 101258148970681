import { Injectable } from '@angular/core';
import { FinishingProcess } from '../models/finishing-process.models';
import { FinishingProcessTypeDto } from '../../../api/dtos/__generated__/finishingProcessTypeDto';
import { FinishingProcessDto } from '../../../api/dtos/__generated__/finishingProcessDto';
import { FinishingProcessType } from '../../../store/reference-data/models/reference-data.model';

@Injectable({
  providedIn: 'root',
})
export class FinishingProcessMapperService {

  constructor() { }

  mapFinishingProcessFromFinishingProcessDto(dto: FinishingProcessDto): FinishingProcess {
    const {
      id,
      componentId,
      electricityType,
      electricityCountry,
      finishingProcessType,
      surface,
    } = dto;

    return {
      id,
      surface,
      componentId,
      electricity: electricityType,
      country: {
        label: electricityCountry?.label,
        value: electricityCountry?.value,
      },
      type: this.mapFinishingProcessType(finishingProcessType),
    };
  }

  mapFinishingProcessListFromFinishingProcessDtoList(dtos: FinishingProcessDto[]): FinishingProcess[] {
    return dtos.map((dto: FinishingProcessDto) => this.mapFinishingProcessFromFinishingProcessDto(dto));
  }

  mapFinishingProcessDtoFromFinishingProcess(fp: FinishingProcess): FinishingProcessDto {
    const {
      country,
      electricity,
      type,
      surface,
      id,
      componentId,
    } = fp;

    return {
      electricityCountry: country,
      electricityType: electricity,
      finishingProcessType: this.mapFinishingProcessTypeDto(type),
      surface,
      id,
      componentId,
    } as FinishingProcessDto;
  }

  private mapFinishingProcessType(dto: FinishingProcessTypeDto): FinishingProcessType {
    const { label, value } = dto;
    return { label, value } as FinishingProcessType;
  }

  private mapFinishingProcessTypeDto(type: FinishingProcessType): FinishingProcessTypeDto {
    const { label, value } = type;
    return { label, value } as FinishingProcessTypeDto;
  }
}
