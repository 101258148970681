<form [formGroup]="portfolioForm" novalidate>
  <h2 mat-dialog-title data-testid="project-information-title">
    <span i18n>New portfolio</span>
  </h2>
  <mat-dialog-content>
    <div class="row">
      <div class="col">
        <mat-form-field>
          <input formControlName="name" matInput placeholder="Portfolio name">
          <mat-error *ngIf="portfolioForm.controls.name.hasError('required')" i18n>
            Portfolio name is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button i18n mat-dialog-close mat-stroked-button type="button">Cancel</button>
    <button [disabled]="portfolioForm.invalid"
            [mat-dialog-close]="submit()"
            class="border-primary"
            color="primary"
            i18n
            id="add-button"
            mat-stroked-button
            type="submit">
      Create
    </button>
  </mat-dialog-actions>
</form>
