import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { CollaboratorDataService } from '../collaborator/collaborator.data-service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanLoad {

  constructor(
    private readonly collaboratorDataService: CollaboratorDataService,
    private readonly router: Router,
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.collaboratorDataService.isAdmin().pipe(
      tap(isAdmin => {
        if (!isAdmin) {
          this.router.navigate([ '' ]);
        }
      }),
    );
  }

}
