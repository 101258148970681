import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { SigninComponent } from './auth/signin/signin.component';
import { AdminGuard } from './admin/admin-guard.guard';

export const ROUTER_PATH_PROJECTS = 'projects';
export const ROUTER_PATH_ADMIN = 'admin';
export const ROUTER_PATH_LOGIN = 'login';
export const ROUTER_PATH_SIGNIN = 'signin';
export const ROUTER_PATH_SIGNOUT = 'signout';
export const ROUTER_PROJECT_GENERAL_INFORMATION = 'general-information';
export const ROUTER_PROJECT_COMPONENT = 'components';
export const ROUTER_PROJECT_COMPONENT_MATERIAL = 'materials';
export const ROUTER_PROJECT_DOWNSTREAM_LOGISTICS = 'downstream-logistics';

const routes: Routes = [
  {
    path: 'changelog',
    loadChildren: () => import('src/app/changelog/changelog.module')
      .then(m => m.ChangelogModule),
    canLoad: [ AuthGuard ],
  },
  {
    path: ROUTER_PATH_ADMIN,
    loadChildren: () => import('src/app/admin/admin.module')
      .then(m => m.AdminModule),
    canLoad: [ AuthGuard, AdminGuard ],
  },
  {
    path: ROUTER_PATH_PROJECTS,
    loadChildren: () => import('src/app/project/project.module')
      .then(m => m.ProjectModule),
    canLoad: [ AuthGuard ],
  },
  {
    path: ROUTER_PATH_LOGIN, component: LoginComponent,
  },
  {
    path: ROUTER_PATH_SIGNIN, component: SigninComponent,
  },
  {
    path: ROUTER_PATH_SIGNOUT,
    redirectTo: ROUTER_PATH_PROJECTS,
    pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: ROUTER_PATH_PROJECTS,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy',
  }) ],
  exports: [ RouterModule ],
})
export class AppRoutingModule {
}
