import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Pagination } from '../../../shared/page.model';
import { MatTableDataSource } from '@angular/material/table';
import { PortfolioMetadata } from '../../models/portfolio-metadata.model';
import { select, Store } from '@ngrx/store';
import { PortfolioFeatureState } from '../../store/portfolio.reducer';
import { createPortfolio, findAllPortfolios } from '../../store/portfolio.actions';
import { Subscription } from 'rxjs';
import { selectPortfolios } from '../../store/portfolio.selectors';
import { filter, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CreatePortfolioDialogComponent } from '../create-portfolio-dialog/create-portfolio-dialog.component';
import { CreatePortfolio } from '../../models/create-portfolio.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-portfolio-table',
  templateUrl: './portfolio-table.component.html',
  styleUrls: ['./portfolio-table.component.scss']
})
export class PortfolioTableComponent implements OnInit, AfterViewInit, OnDestroy {

  displayedColumns: string[] = [
    'name',
    'createdAt',
  ];

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild('searchInput')
  searchInput: { nativeElement: HTMLInputElement };
  page: Pagination;
  dataSource: MatTableDataSource<PortfolioMetadata>;
  subscription: Subscription = new Subscription();

  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly store: Store<PortfolioFeatureState>,
  ) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<PortfolioMetadata>([]);
    this.dataSource.paginator = this.paginator;
    this.store.dispatch(findAllPortfolios());
    this.subscription.add(
      this.store
        .pipe(select(selectPortfolios))
        .subscribe((portfolios) => this.dataSource.data = portfolios)
    );
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  trackBy(row: PortfolioMetadata): string {
    return row.id;
  }

  onRowClick(portfolioId: string) {
    this.router.navigate([`/admin/portfolios/${portfolioId}`]);
  }

  handleChangeSearch(event: Event) {
    this.dataSource.filter = (event.target as HTMLInputElement).value;
  }

  openNewPortfolioDialog() {
    const dialogRef = this.dialog.open(CreatePortfolioDialogComponent);
    this.subscription.add(
      dialogRef.afterClosed()
        .pipe(take(1), filter((dialogResponse) => dialogResponse && dialogResponse !== ''))
        .subscribe((portfolio: CreatePortfolio) => this.store.dispatch(createPortfolio({ portfolio })))
    );
  }
}
