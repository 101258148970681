import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromComponentTransport from './';
import { catchError, concatMap, map, pluck, switchMap } from 'rxjs/operators';
import { ComponentTransportDataService } from '../../services/component-transport.data-service';
import { ComponentTransport } from '../../models/component.models';
import { of } from 'rxjs';
import { NotificationService } from '../../../../core/notification.service';


@Injectable()
export class ComponentTransportEffects {

  addComponentTransport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromComponentTransport.addComponentTransport),
      concatMap((action: { type: string, transport: Partial<ComponentTransport> }) => this.service.create(action.transport)
        .pipe(
          map((componentTransport: ComponentTransport) => fromComponentTransport.addComponentTransportSuccess({ componentTransport })),
          catchError(() => of(fromComponentTransport.componentTransportError())),
        ),
      )),
  );

  updateComponentTransport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromComponentTransport.updateComponentTransport),
      pluck('componentTransport'),
      switchMap((t: Partial<ComponentTransport>) => this.service.update(t)
        .pipe(
          map((componentTransport: ComponentTransport) => fromComponentTransport.updateComponentTransportSuccess({ componentTransport })),
          catchError(() => {
            this.notificationService.warn('Could not save latest component transport changes');
            return of(fromComponentTransport.componentTransportError());
          }),
        )),
    ));

  deleteComponentTransport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromComponentTransport.deleteComponentTransport),
      switchMap((action: { type: string, id: string }) => this.service.delete(action.id)
        .pipe(
          map((id: string) => fromComponentTransport.deleteComponentTransportSuccess({ id })),
          catchError(() => of(fromComponentTransport.componentTransportError())),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly service: ComponentTransportDataService,
    private readonly notificationService: NotificationService,
  ) {}

}
