import { Observable, of } from 'rxjs';
import { filter, map, startWith, tap, withLatestFrom } from 'rxjs/operators';

export const autocompleteFilter = (
  input: string,
  referenceList$: Observable<Partial<{ value: string }>[]>,
  callback: (props: Partial<{ value: string }>) => void,
): Observable<string[]> => {
  return of(input).pipe(
    startWith(''),
    withLatestFrom(referenceList$),
    map(([ v, types ]: [ string, { value: string }[] ]) => {
      return types.filter(type =>
        type.value?.toLowerCase().includes(v.toLowerCase()),
      );
    }),
    map((types: { value: string }[]) => {
      if (!types?.length) {
        return [];
      }
      const [ first ] = types;
      if (first.value?.toLowerCase() === input.toLowerCase()) {
        callback(first);
      }
      return types.map(t => t.value);
    }),
  );
};

export const asyncAutocompleteFilter = (
  input: Observable<string>,
  referenceList$: Observable<Partial<{ value: string }>[]>,
): Observable<Partial<{ value: string }[]>> => {
  return input.pipe(
    startWith(''),
    withLatestFrom(referenceList$),
    filter(([ v, types ]: [ any, { value: string }[] ]) => !!types?.length),
    map(([ v, types ]: [ any, { value: string }[] ]) => types
      .filter(type => {
        const value = v?.value ?? v ?? '';
        return type.value?.toLowerCase().includes(value?.toLowerCase());
      }) ?? [],
    ),
  );
};
