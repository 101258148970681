import { adapter, componentTransportsFeatureKey, ComponentTransportState } from './component-transport.reducer';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '../../../../store';
import { ComponentTransport } from '../../models/component.models';
import { Dictionary } from '@ngrx/entity';

export const selectComponentTransportState: MemoizedSelector<AppState, ComponentTransportState> =
  createFeatureSelector(componentTransportsFeatureKey);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectComponentTransportState);

export const selectByComponentId = (componentId: string) => createSelector(
  selectAll,
  (transports: ComponentTransport[]) => transports.filter((t: ComponentTransport) => t.componentId === componentId),
);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities: Dictionary<ComponentTransport>) => entities[id],
);

export const somethingWentWrong = createSelector(
  selectComponentTransportState,
  (state: ComponentTransportState) => state?.error,
);
