import { createAction, props } from '@ngrx/store';

import { Project, ProjectSearchParams } from '../../models/project.model';
import { Page } from '../../../../shared/page.model';
import { ProjectLock } from '../../../project-lock/project-lock.model';
import { ProjectFilterDto } from '../../../../api/dtos/__generated__/projectFilterDto';
import { UpdateProjectScenarioDto } from '../../../../api/dtos/__generated__/updateProjectScenarioDto';

export const getProjects = createAction(
  '[Project/API] Get Projects',
  props<{ params?: ProjectSearchParams }>(),
);

export const getProjectsSuccess = createAction(
  '[Project/API] Get Projects Success',
  props<{ page: Page<Project> }>(),
);

export const getProjectFilters = createAction('[Project/API] Get Project filters');
export const getProjectFiltersSuccess = createAction(
  '[Project/API] Get Project filters Success',
  props<{ projectFilters: ProjectFilterDto }>()
);

export const getProject = createAction(
  '[Project/API] Get Project',
  props<{ id: string }>(),
);

export const getProjectSuccess = createAction(
  '[Project/API] Get Project Success',
  props<{ project: Project, projectLock: ProjectLock }>(),
);

export const addProject = createAction(
  '[Project/API] Add Project',
  props<{ project: Project }>(),
);

export const addProjectSuccess = createAction(
  '[Project/API] Add Project Success',
  props<{ project: Project }>(),
);

export const updateProject = createAction(
  '[Project/API] Update Project',
  props<{ project: Project }>(),
);

export const updateProjectSuccess = createAction(
  '[Project/API] Update Project Success',
  props<{ project: Project }>(),
);

export const deleteProject = createAction(
  '[Project/API] Delete Project',
  props<{ id: string, andGetProjectsWith: ProjectSearchParams }>(),
);

export const deleteProjectSuccess = createAction(
  '[Project/API] Delete Project Success',
  props<{ id: string }>(),
);

export const duplicateProject = createAction(
  '[Project/API] Duplicate Project',
  props<{ id: string, andGetProjectsWith: ProjectSearchParams }>(),
);

export const duplicateProjectSuccess = createAction(
  '[Project/API] Duplicate Project Success',
  props<{ project: Project }>(),
);

export const projectError = createAction(
  '[Project/API] Error',
  props<{ message: string }>(),
);

export const dismissProjectLockDisclaimer = createAction(
  '[Project] Dismiss Project Lock Disclaimer',
);

export const releaseProjectLock = createAction(
  '[Project/API] Release Project Lock',
  props<{ projectId: string }>(),
);

export const releaseProjectLockSuccess = createAction(
  '[Project/API] Release Project Lock Success',
);

export const updateProjectScenarios = createAction(
  '[Project/API] Update project scenarios',
  props<{ projectId: string; scenarios: UpdateProjectScenarioDto[] }>(),
);
export const updateProjectScenariosSuccess = createAction(
  '[Project/API] Update project scenarios success'
);

