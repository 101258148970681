import { createAction, props } from '@ngrx/store';
import { Packaging, Usage } from '../../../models/packaging.models';

export const createFirstPrimaryPackaging = createAction(
  '[Primary Packaging/API] Create First Primary Packaging',
  props<{ scenarioId: string }>(),
);

export const createFirstPrimaryPackagingSuccess = createAction(
  '[Primary Packaging/API] Create First Primary Packaging Success',
  props<{ packaging: Packaging }>(),
);

export const addPrimaryPackaging = createAction(
  '[Primary Packaging/API] Add Primary Packaging',
  props<{ scenarioId: string }>(),
);

export const addPrimaryPackagingSuccess = createAction(
  '[Primary Packaging/API] Add Primary Packaging Success',
  props<{ packaging: Packaging }>(),
);

export const getPrimaryPackagingSuccess = createAction(
  '[Primary Packaging/API] Get Primary Packaging Success',
  props<{ packaging: Packaging[] }>(),
);

export const updatePrimaryPackaging = createAction(
  '[Primary Packaging/API] Update Primary Packaging',
  props<{ packaging: Packaging }>(),
);

export const updatePrimaryPackagingSuccess = createAction(
  '[Primary Packaging/API] Update Primary Packaging Success',
  props<{ packaging: Packaging }>(),
);

export const primaryPackagingError = createAction(
  '[Primary Packaging/API] Primary Packaging Error',
  props<{ message: string }>(),
);

export const updateProductMass = createAction(
  '[Primary Packaging/API] Update Product Mass',
  props<{ id: string, mass: number }>(),
);

export const updateProductVolume = createAction(
  '[Primary Packaging/API] Update Product Volume',
  props<{ id: string, volume: number }>(),
);

export const updateUsage = createAction(
  '[Primary Packaging/API] Update Usage',
  props<{ id: string, usage: Usage }>(),
);

export const deleteAll = createAction(
  '[Primary Packaging/API] Delete All Primary Packaging Of Scenario',
  props<{ scenarioId: string }>(),
);

export const deleteAllSuccess = createAction(
  '[Primary Packaging/API] Delete All Primary Packaging Of Scenario Success',
  props<{ scenarioId: string }>(),
);
