import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { DownstreamCompleteDto } from '../../../api/dtos/__generated__/downstreamCompleteDto';
import { DownstreamMetadataDto } from '../../../api/dtos/__generated__/downstreamMetadataDto';
import { DownstreamMapperService } from '../mappers/downstream-mapper.service';
import { Downstream } from '../models/downstream';

@Injectable({
  providedIn: 'root',
})
export class DownstreamDataService {

  DOWNSTREAMS_URL = `${environment.apiUrl}/downstreams`;

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: DownstreamMapperService,
  ) {
  }

  update(downstream: Downstream): Observable<Downstream> {
    const dto: DownstreamMetadataDto = this.mapper.mapDownstreamMetadataDtoFromDownstream(downstream);
    return this.http.put(`${this.DOWNSTREAMS_URL}/${downstream.id}`, dto).pipe(
      map((complete: DownstreamCompleteDto) => this.mapper.mapDownstreamFromDownstreamCompleteDto(complete)),
    );
  }
}
