import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { PortfolioDataService } from '../services/portfolio.data-service';
import {
  createPortfolio,
  createPortfolioSuccess,
  deletePortfolio,
  deletePortfolioScenarios,
  deletePortfolioScenariosSuccess,
  deletePortfolioSuccess,
  downloadPortfolioExport,
  downloadPortfolioExportSuccess,
  findAllPortfolios,
  findAllPortfoliosSuccess,
  findPortfolio,
  findPortfolioSuccess,
  importPortfolioScenarios,
  importPortfolioScenariosSuccess,
  portfolioError
} from './portfolio.actions';
import { PortfolioFeatureState } from './portfolio.reducer';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PortfolioEffects {

  findAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(findAllPortfolios),
      mergeMap(() => this.portfolioDataService.findAll()
        .pipe(
          map((portfolios) => findAllPortfoliosSuccess({ portfolios })),
          catchError(() => of(portfolioError({ error: 'Failed to fetch portfolios' }))),
        )
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createPortfolio),
      mergeMap(({ portfolio }) => this.portfolioDataService.create(portfolio)
        .pipe(
          map((response) => {
            this.router.navigate([`/admin/portfolios/${response.id}`]);
            return createPortfolioSuccess({ portfolio: response });
          }),
          catchError(() => of(portfolioError({ error: 'Failed to create portfolio' }))),
        )
      ),
    ),
  );

  findById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(findPortfolio),
      mergeMap(({ id }) => this.portfolioDataService.findById(id)
        .pipe(
          map((portfolio) => findPortfolioSuccess({ portfolio })),
          catchError(() => of(portfolioError({ error: 'Failed to fetch portfolio' }))),
        )
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePortfolio),
      mergeMap(({ id }) => this.portfolioDataService.delete(id)
        .pipe(
          map(() => {
            this.router.navigate([`/admin/portfolios`]);
            return deletePortfolioSuccess();
          }),
          catchError(() => of(portfolioError({ error: 'Failed to delete portfolio' }))),
        )
      ),
    ),
  );

  downloadExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(downloadPortfolioExport),
      mergeMap(({ id, versionHash }) => this.portfolioDataService.downloadExport(id, versionHash)
        .pipe(
          map((url) => {
            window.open(url);
            return downloadPortfolioExportSuccess();
          }),
          catchError(() => of(portfolioError({ error: 'Failed to download portfolio export' }))),
        )
      ),
    ),
  );

  importScenarios$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importPortfolioScenarios),
      mergeMap(({ id, imports }) => this.portfolioDataService.importScenarios(id, imports)
        .pipe(
          map((portfolio) => importPortfolioScenariosSuccess({ portfolio })),
          catchError(() => of(portfolioError({ error: 'Failed to import scenarios to portfolio' }))),
        )
      ),
    ),
  );

  deleteScenarios$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePortfolioScenarios),
      mergeMap(({ id, scenariosIds }) => this.portfolioDataService.deleteScenarios(id, scenariosIds)
        .pipe(
          map((portfolio) => deletePortfolioScenariosSuccess({ portfolio })),
          catchError(() => of(portfolioError({ error: 'Failed to delete portfolio\'s scenarios' }))),
        )
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly portfolioDataService: PortfolioDataService,
    private readonly store: Store<PortfolioFeatureState>,
    private readonly router: Router,
  ) {
  }
}
