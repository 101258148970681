import { Injectable } from '@angular/core';
import { AbstractMapper } from '../../project/abstract-mapper';
import { CreatePortfolioDto } from '../../api/dtos/__generated__/createPortfolioDto';
import { CreatePortfolio } from '../models/create-portfolio.model';

@Injectable({
  providedIn: 'root',
})
export class CreatePortfolioMapperService extends AbstractMapper<CreatePortfolioDto, CreatePortfolio> {
  constructor() {
    super();
  }

  to(createPortfolio: CreatePortfolio): CreatePortfolioDto {
    return { ...createPortfolio };
  }

  from(dto: CreatePortfolioDto): CreatePortfolio {
    return { ...dto };
  }
}
