import { Injectable } from '@angular/core';
import { AbstractMapper } from '../../abstract-mapper';
import { EntityReferenceMapperService } from './entity-reference-mapper.service';
import { ScenarioPlasticFootprintAssessmentDto } from '../../../api/dtos/__generated__/scenarioPlasticFootprintAssessmentDto';
import { ScenarioPlasticFootprintAssessment } from '../models/scenario-plastic-footprint-assessment.model';
import { UnclassifiedIndicator } from '../models/unclassified-indicator.model';

@Injectable({
  providedIn: 'root',
})
export class ScenarioPlasticFootprintAssessmentMapperService
  extends AbstractMapper<ScenarioPlasticFootprintAssessmentDto, ScenarioPlasticFootprintAssessment> {
  constructor(private readonly entityReferenceMapper: EntityReferenceMapperService) {
    super();
  }

  from(dto: ScenarioPlasticFootprintAssessmentDto): ScenarioPlasticFootprintAssessment {
    if (!dto) {
      return;
    }

    const {
      scenario,
      functionalUnit,
      plasticWaste,
      plasticLeakage,
      plasticLeakageIntensity,
    } = dto;

    return {
      indicator: UnclassifiedIndicator.PLASTIC_FOOTPRINT,
      scenario: this.entityReferenceMapper.from(scenario),
      functionalUnit,
      plasticWaste,
      plasticLeakage,
      plasticLeakageIntensity,
      totalImpact: plasticLeakage,
    };
  }
}
