import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import {
  addTertiaryPackaging,
  addTertiaryPackagingSuccess,
  deleteAll, deleteAllSuccess,
  deleteTertiaryPackaging,
  deleteTertiaryPackagingSuccess,
  getTertiaryPackagingSuccess,
  tertiaryPackagingError,
  updateTertiaryPackaging,
  updateTertiaryPackagingSuccess,
} from './tertiary-packaging.actions';
import { Packaging } from '../../../models/packaging.models';

export const tertiaryPackagingFeatureKey = 'tertiaryPackaging';

export interface TertiaryPackagingState extends EntityState<Packaging> {
  loading: boolean;
  lastCreated: Packaging | null;
  error: string | null;
}

export const adapter: EntityAdapter<Packaging> = createEntityAdapter<Packaging>();

export const initialState: TertiaryPackagingState = adapter.getInitialState({
  loading: false,
  lastCreated: null,
  error: null,
});

const reducer: ActionReducer<TertiaryPackagingState, Action> = createReducer(
  initialState,
  on(
    addTertiaryPackaging,
    updateTertiaryPackaging,
    deleteTertiaryPackaging, (state: TertiaryPackagingState, action) =>
      ({ ...state, loading: true, error: null, lastCreated: null }),
  ),
  on(getTertiaryPackagingSuccess, (state: TertiaryPackagingState, action) =>
    adapter.upsertMany(action.packaging, { ...state, loading: false }),
  ),
  on(addTertiaryPackagingSuccess, (state: TertiaryPackagingState, action) =>
    adapter.addOne(action.packaging, { ...state, loading: false, lastCreated: action.packaging }),
  ),
  on(updateTertiaryPackagingSuccess, (state: TertiaryPackagingState, action) => {
      const { packaging } = action;
      const update: Update<Packaging> = {
        id: packaging.id,
        changes: packaging,
      };
      return adapter.updateOne(update, { ...state, loading: false });
    },
  ),
  on(deleteTertiaryPackagingSuccess, (state: TertiaryPackagingState, action) =>
    adapter.removeOne(action.id, { ...state, loading: false }),
  ),
  on(tertiaryPackagingError, (state, action) =>
    ({ ...state, loading: false, error: action.message })),
  on(deleteAllSuccess, (state: TertiaryPackagingState, action: { scenarioId: string }) => {
    const { scenarioId } = action;
    const idsToDelete = Object.values(state.entities)
      .filter((p: Packaging) => p.scenarioId === scenarioId)
      .map((p: Packaging) => p.id);
    return adapter.removeMany(idsToDelete, state);
  }),
);

export function tertiaryPackagingReducer(state: TertiaryPackagingState | undefined, action: Action) {
  return reducer(state, action);
}
