<mat-drawer-container data-testid="admin-drawer-container">
  <mat-drawer
    [opened]="isMenuOpen$ | async"
    mode="side"
    class="background-accent"
    data-testid="admin-drawer"
  >
    <app-admin-menu
      (closeMenu)="toggleMenu()"
      data-test-id="admin-menu"
    ></app-admin-menu>
  </mat-drawer>
  <mat-drawer-content class="drawer" data-testid="admin-drawer-content">
    <app-admin-header [isMenuOpen$]="isMenuOpen$"></app-admin-header>
    <ng-container *ngIf="!(isMenuOpen$ | async)">
      <app-open-menu-button data-testid="open-menu-button"></app-open-menu-button>
    </ng-container>
    <div class="main-content full-width">
      <router-outlet data-testid="router-outlet"></router-outlet>
    </div>
    <span class="background-gradient" id="background-gradient"></span>
  </mat-drawer-content>
</mat-drawer-container>
