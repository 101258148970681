import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchInputComponent } from './search-input.component';
import { MatInputModule } from '@angular/material/input';


@NgModule({
  declarations: [ SearchInputComponent ],
  imports: [
    CommonModule,
    MatInputModule,
  ],
  exports: [ SearchInputComponent ],
})
export class SearchInputModule {}
