export type IndicatorPriority = 'MAJOR' | 'MINOR';

export const IndicatorPriority = {
  MAJOR: 'MAJOR' as IndicatorPriority,
  MINOR: 'MINOR' as IndicatorPriority,
};

export const isMajor = (input: string) => {
  if (typeof input !== 'string') {
    return false;
  }
  return input.toUpperCase().localeCompare(IndicatorPriority.MAJOR) === 0;
};

export const isMinor = (input: string) => {
  if (typeof input !== 'string') {
    return false;
  }
  return input.toUpperCase().localeCompare(IndicatorPriority.MINOR) === 0;
};

