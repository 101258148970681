import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import distributionMarketReducer, { distributionMarketKey } from './distribution-market.reducer';
import { DistributionMarketEffects } from './distribution-market.effects';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(distributionMarketKey, distributionMarketReducer),
    EffectsModule.forFeature([ DistributionMarketEffects ]),
  ]
})
export class DistributionMarketStoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: DistributionMarketStoreModule) {
    if (parentModule) {
      throw new Error('DistributionMarketStoreModule is already loaded');
    }
  }
}
