import { NgModule } from '@angular/core';
import { StatisticsHeaderComponent } from './statistics-header.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ StatisticsHeaderComponent ],
  exports: [ StatisticsHeaderComponent ],
  imports: [ CommonModule ],
})
export class StatisticsHeaderModule {
}
