import { Injectable } from '@angular/core';
import { AbstractMapper } from '../../../project/abstract-mapper';
import { DistributionMarketDto } from '../../../api/dtos/__generated__/distributionMarketDto';
import { DistributionMarket, UpdateDistributionMarket } from '../models/distribution-market';

@Injectable({
  providedIn: 'root',
})
export class DistributionMarketMapperService extends AbstractMapper<DistributionMarketDto, DistributionMarket> {
  constructor() {
    super();
  }

  from(dto: DistributionMarketDto): DistributionMarket {
    return { ...dto };
  }

  to(distributionMarket: DistributionMarket): DistributionMarketDto {
    return { ...distributionMarket };
  }
}
