import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SetDefaultVersionHeaderComponent} from "./set-default-version-header.component";



@NgModule({
  declarations: [
    SetDefaultVersionHeaderComponent
  ],
  exports: [
    SetDefaultVersionHeaderComponent
  ],
  imports: [
    CommonModule
  ]
})
export class SetDefaultVersionHeaderModule { }
