import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-page-header',
  templateUrl: './home-page-header.component.html',
  styleUrls: [ './home-page-header.component.scss' ],
})
export class HomePageHeaderComponent implements OnInit {

  constructor(private readonly router: Router) { }

  ngOnInit(): void {
  }

  goHome(): void {
    this.router.navigate([ `/` ]);
  }
}
