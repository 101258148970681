import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { getReferenceData, getReferenceDataError, getReferenceDataSuccess } from './reference-data.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ReferenceDataDataService } from '../reference-data.data-service';
import { ReferenceData } from '../models/reference-data.model';
import { of } from 'rxjs';
import { Action } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class ReferenceDataEffects implements OnInitEffects {

  getReferenceData$ = createEffect(() => (
    this.actions$.pipe(
      ofType(getReferenceData.type),
      switchMap(() => this.referenceDataDataService.get()
        .pipe(
          map((referenceData: ReferenceData) => getReferenceDataSuccess({ referenceData })),
          catchError(() => of(getReferenceDataError({ message: 'Could not fetch reference data' }))),
        ),
      ),
    )),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly referenceDataDataService: ReferenceDataDataService,
  ) {}

  ngrxOnInitEffects(): Action {
    return getReferenceData();
  }
}
