import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ComponentTransportMapperService } from './component-transport-mapper.service';
import { HttpClient } from '@angular/common/http';
import { ComponentTransport } from '../models/component.models';
import { Observable } from 'rxjs';
import { ComponentTransportDto } from '../../../api/dtos/__generated__/componentTransportDto';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ComponentTransportDataService {
  COMPONENTS_TRANSPORTS_URL = `${environment.apiUrl}/component-transports`;

  constructor(
    private readonly mapper: ComponentTransportMapperService,
    private readonly http: HttpClient,
  ) { }

  create(transport: Partial<ComponentTransport>): Observable<ComponentTransport> {
    const dto: ComponentTransportDto = this.mapper.mapComponentTransportDtoFromComponentTransport(transport);
    return this.http.post(this.COMPONENTS_TRANSPORTS_URL, dto).pipe(
      map((component: ComponentTransportDto) => this.mapper.mapComponentTransportFromComponentTransportDto(component)),
    );
  }

  update(transport: ComponentTransport): Observable<ComponentTransport> {
    const dto: ComponentTransportDto = this.mapper.mapComponentTransportDtoFromComponentTransport(transport);
    return this.http.put(`${this.COMPONENTS_TRANSPORTS_URL}/${transport.id}`, dto).pipe(
      map((response: ComponentTransportDto) => this.mapper.mapComponentTransportFromComponentTransportDto(response)),
    );
  }

  delete(id: string): Observable<string> {
    return this.http.delete(`${this.COMPONENTS_TRANSPORTS_URL}/${id}`).pipe(
      map(() => id),
    );
  }
}
