import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {Observable, switchMap} from 'rxjs';
import { EndOfLife } from '../models/end-of-life';
import { EndOfLifeFeatureState } from '../store/end-of-life.reducer';
import { findAllEndOfLives } from '../store/end-of-life.actions';
import { selectEndOfLifeLoading, selectEndOfLives } from '../store/end-of-life.selectors';
import {map, withLatestFrom} from 'rxjs/operators';

@Component({
  selector: 'app-end-of-lives',
  template: `
    <mat-card>
      <mat-card-title>
        <mat-icon id="title-icon" svgIcon="end-of-life"></mat-icon>
        <div id="title">
          <h2 i18n>End of life</h2>
        </div>
      </mat-card-title>
      <mat-card-content>
      </mat-card-content>
      <ng-container *ngFor="let endOfLife of endOfLives$|async">
        <app-end-of-life [endOfLife]="endOfLife"></app-end-of-life>
      </ng-container>
    </mat-card>
  `,
  styles: [ `
    :host {
      width: 100%;
    }
  ` ]
})
export class EndOfLivesComponent implements OnInit {

  @Input() materialId: string;
  endOfLives$: Observable<(EndOfLife & { isLockedByMe: boolean})[]>;
  loading$: Observable<boolean>;

  @Input() isLockedByMe$: Observable<boolean>;

  constructor(
    private readonly store: Store<EndOfLifeFeatureState>
  ) {
  }

  ngOnInit(): void {
    this.endOfLives$ = this.store.pipe(
      select(selectEndOfLives),
      withLatestFrom(this.isLockedByMe$),
      map(([eol, lock]) => eol.map(e => ({...e, isLockedByMe: lock})))
    );
    this.loading$ = this.store.pipe(select(selectEndOfLifeLoading));
    this.store.dispatch(findAllEndOfLives({ materialId: this.materialId }));

  }
}
