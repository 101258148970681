import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitialsPipe } from './initials.pipe';
import { FullNamePipe } from './fullName.pipe';


@NgModule({
  declarations: [
    InitialsPipe,
    FullNamePipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    InitialsPipe,
    FullNamePipe
  ],
  providers: [
    InitialsPipe,
    FullNamePipe,
  ],
})
export class CollaboratorModule {}
