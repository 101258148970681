import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { adapter, WashingProcessState } from './washing-process.reducer';
import { AppState } from '../../../../store';
import { washingProcessFeatureKey } from './washing-process-store.module';
import { Dictionary } from '@ngrx/entity';
import { WashingProcess } from '../../models/washing-process.models';


export const selectWashingProcessState: MemoizedSelector<AppState, WashingProcessState> =
  createFeatureSelector(washingProcessFeatureKey);

export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal,
} = adapter.getSelectors(selectWashingProcessState);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities: Dictionary<WashingProcess>) => entities[id],
);

export const isLoading = createSelector(
  selectWashingProcessState,
  (state: WashingProcessState) => state.loading,
);

export const selectLastCreated = createSelector(
  selectWashingProcessState,
  (state: WashingProcessState) => !state.loading ? state.lastCreated : undefined,
);

export const selectByComponentId = (componentId: string) => createSelector(
  selectAll,
  (entities: WashingProcess[]) => entities.filter(
    (washingProcess: WashingProcess) => washingProcess?.componentId === componentId,
  ),
);

export const selectElectricityType = (id: string) => createSelector(
  selectById(id),
  (washingProcess: WashingProcess) => washingProcess?.electricity,
);

export const selectCountry = (id: string) => createSelector(
  selectById(id),
  (washingProcess: WashingProcess) => washingProcess?.country,
);
