<app-scenario-library-table-header
  (approve)="approveSelected($event)"
  (delete)="deleteSelected($event)"
  (filter)="filterScenarios($event)"
  (reject)="rejectSelected($event)"
  (setToPending)="setToPending($event)"
  [selection]="selection"
  data-testid="library-table-header"
></app-scenario-library-table-header>
<div class="scenario-library-container">
  <mat-card class="eqo-card">
    <app-scenario-library-table
      [displayedColumns]="displayedColumns"
      data-testid="library-table"
    ></app-scenario-library-table>
  </mat-card>
</div>
