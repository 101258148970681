import * as fromPrimary from './primary';
import * as fromSecondary from './secondary';
import * as fromTertiary from './tertiary';
import * as fromTertiaryPalletization from './tertiary-palletization';
import { Packaging, PackagingLevel } from '../../models/packaging.models';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '../../../../store';
import { selectScenarioById } from '../scenario';
import { Scenario } from '../../models/scenario.model';


export const selectByLevelAndScenarioId = (scenarioId: string, type: PackagingLevel):
  MemoizedSelector<AppState, Packaging> => {
  if (type === PackagingLevel.PRIMARY) {
    return fromPrimary.selectByScenarioId(scenarioId);
  }
  if (type === PackagingLevel.SECONDARY) {
    return fromSecondary.selectByScenarioId(scenarioId);
  }
  if (type === PackagingLevel.TERTIARY_BOX) {
    return fromTertiary.selectByScenarioId(scenarioId);
  }
  if (type === PackagingLevel.TERTIARY_PALLETIZATION) {
    return fromTertiaryPalletization.selectByScenarioId(scenarioId);
  }
};

export const selectById = (id: string) => createSelector(
  fromPrimary.selectById(id),
  fromSecondary.selectById(id),
  fromTertiary.selectById(id),
  fromTertiaryPalletization.selectById(id),
  (primary: Packaging, secondary, box, palletization) => {
    if (primary) {
      return primary;
    }
    if (secondary) {
      return secondary;
    }
    if (box) {
      return box;
    }
    if (palletization) {
      return palletization;
    }
  },
);

export const selectPackagings = (scenarioId: string) => createSelector(
  fromPrimary.selectByScenarioId(scenarioId),
  fromSecondary.selectByScenarioId(scenarioId),
  fromTertiary.selectByScenarioId(scenarioId),
  fromTertiaryPalletization.selectByScenarioId(scenarioId),
  (primary, secondary, tertiary, palletization) => ({
    primary, secondary, tertiary, palletization,
  }),
);

export const selectLower = (level: PackagingLevel, scenarioId: string) => createSelector(
  selectPackagings(scenarioId),
  ({ primary, secondary, tertiary, palletization }) => {
    switch (level) {
      case PackagingLevel.TERTIARY_PALLETIZATION:
        if (tertiary) {
          return tertiary;
        }
      // tslint:disable-next-line:no-switch-case-fall-through
      case PackagingLevel.TERTIARY_BOX:
        if (secondary) {
          return secondary;
        }
      // tslint:disable-next-line:no-switch-case-fall-through
      case PackagingLevel.SECONDARY:
        if (primary) {
          return primary;
        }
    }
  },
);

export const selectNumberOfPrimaryPackagingInSecondaryPackaging = (scenarioId: string) => createSelector(
  selectScenarioById(scenarioId),
  fromPrimary.selectByScenarioId(scenarioId),
  (scenario: Scenario, packaging: Packaging) => scenario?.hasSecondaryPackaging ? packaging?.multiplicity : null,
);

export const numberOfChildPackagingInTertiaryBoxPackaging = (scenarioId: string) => createSelector(
  selectScenarioById(scenarioId),
  fromPrimary.selectByScenarioId(scenarioId),
  fromSecondary.selectByScenarioId(scenarioId),
  (scenario: Scenario, primary: Packaging, secondary: Packaging) => {
    if (scenario?.hasTertiaryBoxPackaging && scenario?.hasSecondaryPackaging) {
      return secondary?.multiplicity;
    }

    if (scenario?.hasTertiaryBoxPackaging && !scenario?.hasSecondaryPackaging) {
      return primary?.multiplicity;
    }
  },
);

export const numberOfChildPackagingInTertiaryPalletizationPackaging = (scenarioId: string) => createSelector(
  selectScenarioById(scenarioId),
  fromPrimary.selectByScenarioId(scenarioId),
  fromSecondary.selectByScenarioId(scenarioId),
  fromTertiary.selectByScenarioId(scenarioId),
  (scenario: Scenario, primary: Packaging, secondary: Packaging, tertiary: Packaging) => {
    if (scenario?.hasTertiaryPalletizationPackaging) {
      if (scenario?.hasTertiaryBoxPackaging) {
        return tertiary?.multiplicity;
      }
      if (scenario?.hasSecondaryPackaging) {
        return secondary?.multiplicity;
      }
      return primary?.multiplicity;
    }
  },
);
