import { NgModule } from '@angular/core';
import { PortfolioStoreModule } from './store/portfolio-store.module';
import { PortfolioTableModule } from './components/portfolio-table/portfolio-table.module';
import { PortfolioDetailModule } from './components/portfolio-detail/portfolio-detail.module';

@NgModule({
  imports: [PortfolioTableModule, PortfolioDetailModule, PortfolioStoreModule],
  exports: [],
})
export class PortfolioModule {
}
