import { LCAIndicator } from '../../project/assessment/models/lca-indicator.model';
import { PackagingDesignIndicator } from '../../project/assessment/models/packaging-design-indicator.model';
import { Pipe, PipeTransform } from '@angular/core';
import { UnclassifiedIndicator } from '../../project/assessment/models/unclassified-indicator.model';
import { GHGIndicator } from '../../project/assessment/models/ghg-indicator.model';

@Pipe({
  name: 'indicatorIcon',
})
export class IndicatorIconPipe implements PipeTransform {

  INDICATOR_ICON_MAP = new Map<LCAIndicator | PackagingDesignIndicator | UnclassifiedIndicator | GHGIndicator, string>([
      [ LCAIndicator.CC, 'climate-change' ],
      [ LCAIndicator.OD, 'ozone-depletion' ],
      [ LCAIndicator.IR, 'ionising-radiation' ],
      [ LCAIndicator.PO, 'photochemical-ozone' ],
      [ LCAIndicator.PM, 'particulate-matter' ],
      [ LCAIndicator.HC, 'human-toxicity-cancer' ],
      [ LCAIndicator.HN, 'human-toxicity' ],
      [ LCAIndicator.AC, 'acidification' ],
      [ LCAIndicator.EF, 'eutrophication-freshwater' ],
      [ LCAIndicator.EM, 'eutrophication-marine' ],
      [ LCAIndicator.ET, 'eutrophication-terrestrial' ],
      [ LCAIndicator.EC, 'ecotoxicity' ],
      [ LCAIndicator.LU, 'land-use' ],
      [ LCAIndicator.RF, 'fossils-use' ],
      [ LCAIndicator.RM, 'minerals-use' ],
      [ LCAIndicator.WS, 'water-scarcity' ],
      [ PackagingDesignIndicator.RECYCLABILITY, 'recyclability' ],
      [ PackagingDesignIndicator.RECYCLINGRATE, 'recycling-rate' ],
      [ PackagingDesignIndicator.REUSABILITY, 'reusability' ],
      [ PackagingDesignIndicator.COMPOSTABILITY, 'compostability' ],
      [ PackagingDesignIndicator.RENEWABLECONTENT, 'renewable-content' ],
      [ PackagingDesignIndicator.PACKWEIGHTRATIO, 'packaging-weight' ],
      [ PackagingDesignIndicator.RECYCLEDCONTENT, 'recycled-content' ],
      [ UnclassifiedIndicator.SINGLE_SCORE, 'single-score' ],
      [ UnclassifiedIndicator.WASTE_STREAM, 'waste-streams' ],
      [ UnclassifiedIndicator.PLASTIC_FOOTPRINT, 'plastic-footprint' ],
      [ UnclassifiedIndicator.MATERIAL_CIRCULARITY, 'material-circularity' ],
      [ GHGIndicator.CC, 'climate-change-ghg' ],
    ],
  );

  transform(value: LCAIndicator | PackagingDesignIndicator | UnclassifiedIndicator | GHGIndicator | string, ...args: unknown[]): string {
    return this.INDICATOR_ICON_MAP.get(value as LCAIndicator | PackagingDesignIndicator | UnclassifiedIndicator | GHGIndicator);
  }

}

