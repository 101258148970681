import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DistributionMarketsComponent } from './components/distribution-markets.component';
import { DistributionMarketFormComponent } from './components/distribution-market-form.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from '../../shared/shared.module';
import { DistributionsMarketsTransportsComponent } from './components/distributions-markets-transports.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TransportsCardComponent } from './components/transports-card.component';
import { TransportFormComponent } from './components/transport-form.component';
import { FormModule } from '../../shared/form/form.module';

@NgModule({
  declarations: [
    DistributionMarketsComponent,
    DistributionMarketFormComponent,
    TransportsCardComponent,
    TransportFormComponent,
    DistributionsMarketsTransportsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatCardModule,
    SharedModule,
    MatTooltipModule,
    FormModule,
  ],
  exports: [
    DistributionMarketsComponent,
    DistributionsMarketsTransportsComponent
  ],
})
export class DistributionMarketModule {
}
