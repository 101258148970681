import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ComponentTransportEffects } from './component-transport.effects';
import { StoreModule } from '@ngrx/store';
import { componentTransportReducer, componentTransportsFeatureKey } from './component-transport.reducer';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(componentTransportsFeatureKey, componentTransportReducer),
    EffectsModule.forFeature([ ComponentTransportEffects ]),
  ],
})
export class ComponentTransportStoreModule {}
