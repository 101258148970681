import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PackagingComponent } from '../models/component.models';
import { HttpClient } from '@angular/common/http';
import { ComponentMapperService } from './component-mapper.service';
import { environment } from '../../../../environments/environment';
import { ComponentCompleteDto } from '../../../api/dtos/__generated__/componentCompleteDto';
import { map } from 'rxjs/operators';
import { ComponentCreationRequestDto } from '../../../api/dtos/__generated__/componentCreationRequestDto';
import { ComponentMetadataDto } from '../../../api/dtos/__generated__/componentMetadataDto';

@Injectable({
  providedIn: 'root',
})
export class ComponentDataService {

  COMPONENTS_URL = `${environment.apiUrl}/components`;

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: ComponentMapperService,
  ) { }

  create(packagingId: string): Observable<PackagingComponent> {
    const dto: ComponentCreationRequestDto = { packagingId };
    return this.http.post(this.COMPONENTS_URL, dto).pipe(
      map((c: ComponentCompleteDto) => this.mapper.mapPackagingComponentFromComponentCompleteDto(c)),
    );
  }

  update(component: PackagingComponent): Observable<PackagingComponent> {
    const dto: ComponentMetadataDto = this.mapper.mapComponentMetadataDtoFromPackagingComponent(component);
    return this.http.put(`${this.COMPONENTS_URL}/${component.id}`, dto).pipe(
      map((complete: ComponentCompleteDto) => this.mapper.mapPackagingComponentFromComponentCompleteDto(complete)),
    );
  }

  delete(id: string): Observable<string> {
    return this.http.delete(`${this.COMPONENTS_URL}/${id}`).pipe(
      map(() => id),
    );
  }
}
