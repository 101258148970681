<mat-card *ngIf="hasNoProvider">
  <mat-card-title i18n data-testid="login-title">Company Identification</mat-card-title>
  <mat-card-content>
    <form [formGroup]="loginForm" (ngSubmit)="signIn()">
      <p>
        <mat-form-field>
          <mat-label>
            <mat-icon mat-list-icon>business</mat-icon>
          </mat-label>
          <input
            type="text"
            matInput
            placeholder="Company Identification"
            formControlName="company"
            name="company"
            data-testid="login-input"
          />
          <mat-error>
            <ng-container *ngFor="let error of errors(company)">
              <span i18n>{{errorMessages[error]}}</span>
            </ng-container>
          </mat-error>
        </mat-form-field>
      </p>
      <button class="primary" mat-button (click)="signIn()" i18n data-testid="login-connect">Connect</button>
    </form>
  </mat-card-content>
</mat-card>
