import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FinishingProcessEffects } from './finishing-process.effects';
import { FinishingProcessDataService } from '../../services/finishing-process.data-service';
import { finishingProcessReducer } from './finishing-process.reducer';
import { FinishingProcessMapperService } from '../../services/finishing-process-mapper.service';


export const finishingProcessFeatureKey = 'finishingProcess';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(finishingProcessFeatureKey, finishingProcessReducer),
    EffectsModule.forFeature([ FinishingProcessEffects ]),
  ],
  providers: [
    FinishingProcessDataService,
    FinishingProcessMapperService
  ],
})

export class FinishingProcessStoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: FinishingProcessStoreModule) {
    if (parentModule) {
      throw new Error('FinishingProcessStoreModule is already loaded');
    }
  }
}
