import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '../../../../../store';
import { Dictionary } from '@ngrx/entity';
import { Packaging } from '../../../models/packaging.models';
import { adapter, tertiaryPackagingFeatureKey, TertiaryPackagingState } from './tertiary-packaging.reducer';

export const selectTertiaryPackagingState: MemoizedSelector<AppState, TertiaryPackagingState> =
  createFeatureSelector(tertiaryPackagingFeatureKey);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectTertiaryPackagingState);

export const selectById = (packagingId: string) => createSelector(
  selectEntities,
  (packaging: Dictionary<Packaging>) => packaging[packagingId],
);

export const selectLastCreated = createSelector(
  selectTertiaryPackagingState,
  (state: TertiaryPackagingState) => !state.loading ? state.lastCreated : undefined,
);

export const isLoading = createSelector(
  selectTertiaryPackagingState,
  (state: TertiaryPackagingState) => state.loading,
);

export const selectByScenarioId = (scenarioId: string) => createSelector(
  selectAll,
  (packaging: Packaging[]) => {
    const [ tertiary ] = packaging
      .filter((p: Packaging) => p?.scenarioId === scenarioId);
    return tertiary;
  },
);

export const selectPackagingId = (scenarioId: string) => createSelector(
  selectByScenarioId(scenarioId),
  (p: Packaging) => p?.id,
);
