import { Injectable } from '@angular/core';
import { Material, MaterialTransport } from '../models/material.models';
import { MaterialCompleteDto } from '../../../api/dtos/__generated__/materialCompleteDto';
import { MaterialMetadataDto } from '../../../api/dtos/__generated__/materialMetadataDto';
import { MaterialTransportMapperService } from './material-transport-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class MaterialMapperService {

  constructor(
    private readonly materialTransportMapperService: MaterialTransportMapperService,
  ) { }

  mapMaterialFromMaterialDto(dto: MaterialCompleteDto): Material {
    const {
      id,
      recycledContent,
      componentId,
      conversionProcess,
      mass,
      materialType,
    } = dto;

    return {
      componentId,
      materialType,
      recycledContent,
      conversionProcess,
      mass,
      id,
    } as Material;
  }

  mapMaterialListFromMaterialDtoList(dtos: MaterialCompleteDto[]): Material[] {
    return dtos.map((dto) => this.mapMaterialFromMaterialDto(dto));
  }

  mapMaterialMetadataDtoFromMaterial(material: Material): MaterialMetadataDto {
    const {
      mass,
      recycledContent,
      materialType,
      id,
      componentId,
      conversionProcess,
      recyclingRateType,
      compostable,
      recyclable,
      recyclingRate
    } = material;

    return {
      componentId,
      compostable,
      conversionProcess,
      id,
      mass,
      materialType,
      recyclable,
      recycledContent,
      recyclingRate,
      recyclingRateType
    } as MaterialMetadataDto;
  }

  mapMaterialTransportListFromMaterialDto(dto: MaterialCompleteDto): MaterialTransport[] {
    return this.materialTransportMapperService.mapMaterialTransportListFromMaterialTransportDtoList(dto.transports);
  }

  mapMaterialTransportListFromMaterialDtoList(dtos: MaterialCompleteDto[]): MaterialTransport[] {
    return dtos.flatMap((dto) => this.mapMaterialTransportListFromMaterialDto(dto));
  }
}
