import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ComponentTransport } from '../../models/component.models';
import * as fromComponentTransport from './component-transport.actions';

export const componentTransportsFeatureKey = 'componentTransport';

export interface ComponentTransportState extends EntityState<ComponentTransport> {
  // additional entities state properties
  error: string;
}

export const adapter: EntityAdapter<ComponentTransport> = createEntityAdapter<ComponentTransport>();

export const initialState: ComponentTransportState = adapter.getInitialState({
  // additional entity state properties
  error: null,
});

const reducer = createReducer(
  initialState,
  on(fromComponentTransport.updateComponentTransport,
    fromComponentTransport.addComponentTransport,
    state => ({ ...state, error: null })),
  on(
    fromComponentTransport.addComponentTransportSuccess,
    (state: ComponentTransportState, action: { type: string, componentTransport: ComponentTransport }) =>
      adapter.upsertOne(action.componentTransport, { ...state, error: null }),
  ),
  on(
    fromComponentTransport.updateComponentTransportSuccess,
    (state: ComponentTransportState, action: { type: string, componentTransport: ComponentTransport }) =>
      adapter.upsertOne(action.componentTransport, { ...state, error: null }),
  ),
  on(
    fromComponentTransport.deleteComponentTransportSuccess,
    (state: ComponentTransportState, action: { type: string, id: string }) =>
      adapter.removeOne(action.id, { ...state, error: null }),
  ),
  on(
    fromComponentTransport.getComponentTransportsSuccess,
    (state: ComponentTransportState, action: { type: string, componentTransports: ComponentTransport[] }) =>
      adapter.upsertMany(action.componentTransports, { ...state, error: null }),
  ),
  on(fromComponentTransport.deleteAll, (state: ComponentTransportState, action: { type: string, componentId: string }) => {
    const { componentId } = action;
    const ids: string[] = Object.values(state.entities)
      .filter((componentTransport: ComponentTransport) => componentTransport.componentId === componentId)
      .map((componentTransport: ComponentTransport) => componentTransport.id);
    return adapter.removeMany(ids, { ...state, error: null });
  }),
  on(fromComponentTransport.componentTransportError, (state, action) => ({
    ...state,
    error: 'something went wrong',
  })),
);


export function componentTransportReducer(state: ComponentTransportState | undefined, action: Action) {
  return reducer(state, action);
}
