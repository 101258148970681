import {NgModule} from '@angular/core';
import {StatisticsTableComponent} from './statistics-table.component';
import {CommonModule} from '@angular/common';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {StatisticsCardModule} from '../statistics-card/statistics-card.module';

@NgModule({
  declarations: [StatisticsTableComponent],
  exports: [StatisticsTableComponent],
  imports: [CommonModule, MatCardModule, MatTableModule, MatGridListModule, MatIconModule, MatTooltipModule, StatisticsCardModule],
})
export class StatisticsTableModule {
}
