import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Collaborator} from '../../../collaborator/collaborator.model';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-users-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss'],
})
export class UserTableComponent implements OnInit, AfterViewInit, OnDestroy {

  private readonly subscription = new Subscription();
  dataSource: MatTableDataSource<Collaborator>;
  selection = new SelectionModel<Collaborator>(true, []);

  displayedColumns = [
    'selection',
    'enabled',
    'firstname',
    'lastname',
    'isAdmin',
  ];

  @Input()
  set filterValue(value: string) {
    if (this.dataSource) {
      this.dataSource.filter = value?.trim()?.toLowerCase();
    }
  }

  me: Collaborator;

  @Input()
  me$: Observable<Collaborator>;

  @Input()
  collaborators$: Observable<Collaborator[]>;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor() {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.collaborators$.subscribe(collaborators => {
        this.dataSource = new MatTableDataSource<Collaborator>([]);
        this.dataSource.data = collaborators;
        this.selection.clear();
      }),
    );

    this.subscription.add(this.me$.subscribe(me => this.me = me));
  }

  masterToggle(): void {
    if (this.selection.isEmpty()) {
      this.selection.select(...this.dataSource.data.filter(c => !(c.id === this.me.id)));
    } else {
      this.selection.deselect(...this.dataSource.data);
    }
  }

  areAllSelected(): boolean {
    return this.dataSource.data.map(c => this.selection.isSelected(c)).reduce((p, c) => p && c, true);
  }

  trackBy(index: number, collaborator: Collaborator): string {
    return collaborator.id;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isMe(collaborator: Collaborator) {
    return `${this.me.id === collaborator.id}`;
  }

  checkboxLabel(): string {
    return `${this.areAllSelected() ? 'Select' : 'Deselect'} all`;
  }
}
