import { adapter, materialTransportsFeatureKey, MaterialTransportState } from './material-transport.reducer';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState } from '../../../../store';
import { MaterialTransport } from '../../models/material.models';
import { Dictionary } from '@ngrx/entity';

export const selectMaterialTransportState: MemoizedSelector<AppState, MaterialTransportState> =
  createFeatureSelector(materialTransportsFeatureKey);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectMaterialTransportState);

export const selectByMaterialId = (materialId: string) => createSelector(
  selectAll,
  (transports: MaterialTransport[]) => transports.filter((t: MaterialTransport) => t.materialId === materialId),
);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities: Dictionary<MaterialTransport>) => entities[id],
);

export const somethingWentWrong = createSelector(
  selectMaterialTransportState,
  (state: MaterialTransportState) => state?.error,
);
