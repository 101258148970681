import { createAction, props } from '@ngrx/store';
import { Downstream } from '../models/downstream';

const BASE = '[Downstream]';

export const updateDownstream = createAction(
  `${BASE} Update Downstream`,
  props<{ id: string, downstream: Partial<Downstream> }>(),
);

export const updateDownstreamSuccess = createAction(
  `${BASE} Update Downstream Success`,
  props<{ downstream: Downstream }>(),
);

export const downstreamError = createAction(
  `${BASE} Downstream Error`,
  props<{ message: string }>(),
);
