import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {reducer, versionsDataFeatureKey} from './store/versions-data.reducer';
import {EffectsModule} from '@ngrx/effects';
import {VersionsDataEffects} from './store/versions-data.effects';
import {VersionsDataDataService} from './versions-data.data-service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(versionsDataFeatureKey, reducer),
    EffectsModule.forFeature([VersionsDataEffects]),
  ],
  providers: [
    VersionsDataDataService,
  ],
})
export class VersionsDataModule {
  constructor(@Optional() @SkipSelf() parentModule?: VersionsDataModule) {
    if (parentModule) {
      throw new Error('VersionsDataModule is already loaded. Import it in the AppModule only');
    }

  }

}
