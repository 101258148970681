import { Injectable } from '@angular/core';
import { AbstractMapper } from '../../project/abstract-mapper';
import { PortfolioDto } from '../../api/dtos/__generated__/portfolioDto';
import { Portfolio } from '../models/portfolio.model';
import { PortfolioScenarioMapperService } from './portfolio-scenario-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class PortfolioMapperService extends AbstractMapper<PortfolioDto, Portfolio> {
  constructor(private readonly portfolioScenarioMapperService: PortfolioScenarioMapperService) {
    super();
  }

  from(dto: PortfolioDto): Portfolio {
    return {
      ...dto,
      createdAt: new Date(dto.createdAt),
      scenarios: this.portfolioScenarioMapperService.fromList(dto.scenarios)
    };
  }
}
