import { Pipe, PipeTransform } from '@angular/core';
import { Collaborator } from './collaborator.model';

@Pipe({
  name: 'fullName',
})
export class FullNamePipe implements PipeTransform {

  transform(value: Collaborator, ...args: unknown[]): unknown {
    if (!value) {
      return null;
    }
    const { firstname, lastname } = value;
    return `${firstname ?? ''} ${lastname ?? ''}`;
  }

}
