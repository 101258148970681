import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducer, statisticsDataFeatureKey } from './store/statistics-data.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StatisticsDataEffects } from './store/statistics-data.effects';
import { StatisticsDataDataService } from './statistics-data.data-service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(statisticsDataFeatureKey, reducer),
    EffectsModule.forFeature([ StatisticsDataEffects ]),
  ],
  providers: [
    StatisticsDataDataService,
  ],
})
export class StatisticsDataModule {
}
