import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {UserTableComponent} from '../../user-management/user-table/user-table.component';
import {Collaborator} from '../../../collaborator/collaborator.model';
import {AppState} from '../../../store';
import {select, Store} from '@ngrx/store';
import * as fromUserManagement from '../../user-management/store';
import * as fromStatistics from '../../../store/statistics-data/store'
import {Observable} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {CollaboratorDataService} from '../../../collaborator/collaborator.data-service';
import {Statistics} from '../../../store/statistics-data/models/statistics-data.model';

@Component({
  selector: 'app-user-management-page',
  template: `
    <app-user-table-header
      (filter)="applyFilter($event)"
      [selection]="selection"
      (enable)="enableCollaborators($event)"
      (disable)="disableCollaborators($event)"
      [statistics]="statistics$ | async"
      data-testid="user-management-header"
    ></app-user-table-header>
    <app-users-table
      [collaborators$]="collaborators$"
      [filterValue]="filterValue"
      [me$]="me$"
      data-testid="user-management-table"
    >
    </app-users-table>
  `,
  styleUrls: ['./user-management-page.component.scss'],
})
export class UserManagementPageComponent implements OnInit, AfterViewInit {

  @ViewChild(UserTableComponent)
  userTable: UserTableComponent;

  selection: SelectionModel<Collaborator>;

  collaborators$: Observable<Collaborator[]>;
  filterValue: string;

  me$: Observable<Collaborator>;

  statistics$: Observable<Statistics>;

  constructor(
    private readonly store: Store<AppState>,
    private readonly collaboratorDataService: CollaboratorDataService,
  ) {
  }

  ngOnInit(): void {
    this.collaborators$ = this.store.pipe(select(fromUserManagement.selectAll));
    this.me$ = this.collaboratorDataService.getMe();
    this.statistics$ = this.store.pipe(select(fromStatistics.selectStatisticsData));
  }

  ngAfterViewInit(): void {
    this.selection = this.userTable.selection;
  }

  applyFilter(event: Partial<Event>) {
    this.filterValue = (event.target as HTMLInputElement).value;
    console.log(this.filterValue);
  }

  enableCollaborators(collaboratorsToUpdate: Collaborator[]) {
    const collaborators = collaboratorsToUpdate.map(c => ({...c, enabled: true}));
    this.store.dispatch(fromUserManagement.updateCollaborators({collaborators}));
  }

  disableCollaborators(collaboratorsToUpdate: Collaborator[]) {
    const collaborators = collaboratorsToUpdate.map(c => ({...c, enabled: false}));
    this.store.dispatch(fromUserManagement.updateCollaborators({collaborators}));
  }
}
