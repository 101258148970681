import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MaterialTransport } from '../models/material.models';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { MaterialTransportMapperService } from './material-transport-mapper.service';
import { MaterialTransportDto } from '../../../api/dtos/__generated__/materialTransportDto';

@Injectable({
  providedIn: 'root',
})
export class MaterialTransportDataService {

  MATERIALS_TRANSPORTS_URL = `${environment.apiUrl}/material-transports`;

  constructor(
    private readonly mapper: MaterialTransportMapperService,
    private readonly http: HttpClient,
  ) { }

  create(transport: Partial<MaterialTransport>): Observable<MaterialTransport> {
    const dto: MaterialTransportDto = this.mapper.mapMaterialTransportDtoFromMaterialTransport(transport);
    return this.http.post(this.MATERIALS_TRANSPORTS_URL, dto).pipe(
      map((material: MaterialTransportDto) => this.mapper.mapMaterialTransportFromMaterialTransportDto(material)),
    );
  }

  update(transport: MaterialTransport): Observable<MaterialTransport> {
    const dto: MaterialTransportDto = this.mapper.mapMaterialTransportDtoFromMaterialTransport(transport);
    return this.http.put(`${this.MATERIALS_TRANSPORTS_URL}/${transport.id}`, dto).pipe(
      map((response: MaterialTransportDto) => this.mapper.mapMaterialTransportFromMaterialTransportDto(response)),
    );
  }

  delete(id: string): Observable<string> {
    return this.http.delete(`${this.MATERIALS_TRANSPORTS_URL}/${id}`).pipe(
      map(() => id),
    );
  }
}
