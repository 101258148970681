import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreatePortfolio } from '../../models/create-portfolio.model';

@Component({
  selector: 'app-create-portfolio-dialog',
  templateUrl: './create-portfolio-dialog.component.html',
  styleUrls: ['./create-portfolio-dialog.component.scss']
})
export class CreatePortfolioDialogComponent implements OnInit {

  portfolioForm: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
  ) {
    this.portfolioForm = this.fb.group({
      name: [undefined, Validators.required],
    });
  }

  ngOnInit(): void {
  }

  submit(): CreatePortfolio | undefined {
    if (this.portfolioForm.invalid) {
      return undefined;
    }
    return { name: this.portfolioForm.value.name };
  }
}
