import { Directive, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Directive({
  selector: '[appTheme]',
})
export class ThemeDirective implements OnInit, OnDestroy {

  private readonly subscription = new Subscription();

  @HostBinding('class') elementClass = 'default';

  constructor(
    private readonly overlayContainer: OverlayContainer,
    private readonly localStorageService: LocalStorageService,
  ) {
  }

  ngOnInit(): void {
    const theme$ = this.localStorageService.getProviderName().pipe(
      map(theme => {
        this.overlayContainer.getContainerElement().classList.remove(this.elementClass);
        this.overlayContainer.getContainerElement().classList.add(theme);
        this.elementClass = theme;
      }),
    );
    this.subscription.add(theme$.subscribe());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
