import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MaterialTransport } from '../../models/material.models';
import * as fromMaterialTransport from './material-transport.actions';

export const materialTransportsFeatureKey = 'materialTransport';

export interface MaterialTransportState extends EntityState<MaterialTransport> {
  // additional entities state properties
  error: string;
}

export const adapter: EntityAdapter<MaterialTransport> = createEntityAdapter<MaterialTransport>();

export const initialState: MaterialTransportState = adapter.getInitialState({
  // additional entity state properties
  error: null,
});

const reducer = createReducer(
  initialState,
  on(fromMaterialTransport.updateMaterialTransport,
    fromMaterialTransport.addMaterialTransport,
    state => ({ ...state, error: null })),
  on(
    fromMaterialTransport.addMaterialTransportSuccess,
    (state: MaterialTransportState, action: { type: string, materialTransport: MaterialTransport }) =>
      adapter.upsertOne(action.materialTransport, { ...state, error: null }),
  ),
  on(
    fromMaterialTransport.updateMaterialTransportSuccess,
    (state: MaterialTransportState, action: { type: string, materialTransport: MaterialTransport }) =>
      adapter.upsertOne(action.materialTransport, { ...state, error: null }),
  ),
  on(
    fromMaterialTransport.deleteMaterialTransportSuccess,
    (state: MaterialTransportState, action: { type: string, id: string }) =>
      adapter.removeOne(action.id, { ...state, error: null }),
  ),
  on(
    fromMaterialTransport.getMaterialTransportsSuccess,
    (state: MaterialTransportState, action: { type: string, materialTransports: MaterialTransport[] }) =>
      adapter.upsertMany(action.materialTransports, { ...state, error: null }),
  ),
  on(fromMaterialTransport.deleteAll, (state: MaterialTransportState, action: { type: string, materialId: string }) => {
    const { materialId } = action;
    const ids: string[] = Object.values(state.entities)
      .filter((materialTransport: MaterialTransport) => materialTransport.materialId === materialId)
      .map((materialTransport: MaterialTransport) => materialTransport.id);
    return adapter.removeMany(ids, { ...state, error: null });
  }),
  on(fromMaterialTransport.materialTransportError, (state, action) => ({
    ...state,
    error: 'something went wrong',
  })),
);


export function materialTransportReducer(state: MaterialTransportState | undefined, action: Action) {
  return reducer(state, action);
}
