import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { adapter, FinishingProcessState } from './finishing-process.reducer';
import { AppState } from '../../../../store';
import { finishingProcessFeatureKey } from './finishing-process-store.module';
import { Dictionary } from '@ngrx/entity';
import { FinishingProcess } from '../../models/finishing-process.models';


export const selectFinishingProcessState: MemoizedSelector<AppState, FinishingProcessState> =
  createFeatureSelector(finishingProcessFeatureKey);

export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal,
} = adapter.getSelectors(selectFinishingProcessState);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities: Dictionary<FinishingProcess>) => entities[id],
);

export const isLoading = createSelector(
  selectFinishingProcessState,
  (state: FinishingProcessState) => state.loading,
);

export const selectLastCreated = createSelector(
  selectFinishingProcessState,
  (state: FinishingProcessState) => !state.loading ? state.lastCreated : undefined,
);

export const selectByComponentId = (componentId: string) => createSelector(
  selectAll,
  (entities: FinishingProcess[]) => entities.filter(
    (finishingProcess: FinishingProcess) => finishingProcess?.componentId === componentId,
  ),
);

export const selectElectricityType = (id: string) => createSelector(
  selectById(id),
  (finishingProcess: FinishingProcess) => finishingProcess?.electricity,
);

export const selectCountry = (id: string) => createSelector(
  selectById(id),
  (finishingProcess: FinishingProcess) => finishingProcess?.country,
);
