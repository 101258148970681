import { Injectable } from '@angular/core';
import { ScenarioSingleScoreAssessmentDto } from '../../../api/dtos/__generated__/scenarioSingleScoreAssessmentDto';
import { ScenarioSingleScoreAssessment } from '../models/scenario-single-score-assessment.model';
import { AbstractMapper } from '../../abstract-mapper';
import { DetailItemAssessmentMapperService } from './material-or-finishing-process-assessment-mapper.service';
import { EntityReferenceMapperService } from './entity-reference-mapper.service';

@Injectable({
  providedIn: 'root',
})
// tslint:disable-next-line:max-line-length
export class ScenarioSingleScoreAssessmentMapperService extends AbstractMapper<ScenarioSingleScoreAssessmentDto, ScenarioSingleScoreAssessment> {

  constructor(
    private readonly detailItemAssessmentMapperService: DetailItemAssessmentMapperService,
    private readonly entityReferenceMapper: EntityReferenceMapperService,
  ) {
    super();
  }

  from(dto: ScenarioSingleScoreAssessmentDto): ScenarioSingleScoreAssessment | undefined {
    if (!dto) {
      return;
    }

    const {
      details,
      scenario,
      upstreamLogisticsImpact,
      totalImpact,
      tertiaryPackagingPalletizationImpact,
      tertiaryPackagingBoxImpact,
      secondaryPackagingImpact,
      rawMaterialProductionImpact,
      primaryPackagingImpact,
      functionalUnit,
      endOfLifeImpact,
      downstreamLogisticsImpact,
      processesImpact,
    } = dto;

    return {
      details: this.detailItemAssessmentMapperService.fromList(details),
      scenario: this.entityReferenceMapper.from(scenario),
      upstreamLogisticsImpact,
      totalImpact,
      tertiaryPackagingPalletizationImpact,
      tertiaryPackagingBoxImpact,
      secondaryPackagingImpact,
      rawMaterialProductionImpact,
      primaryPackagingImpact,
      functionalUnit,
      endOfLifeImpact,
      downstreamLogisticsImpact,
      processesImpact,
    } as ScenarioSingleScoreAssessment;
  }
}
