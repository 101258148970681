import { createAction, props } from '@ngrx/store';
import { Version, Versions } from '../models/versions-data.model';

export const getVersions = createAction(
  '[VersionsData/API] Get Versions',
);

export const getVersionsSuccess = createAction(
  '[VersionsData/API] Get Versions Success',
  props<{ versions: Versions }>(),
);

export const getVersionsError = createAction(
  '[VersionsData/API] Get Versions Error',
  props<{ message: string }>(),
);

export const setDefaultVersion = createAction(
  '[VersionsData/API] Set Default Version',
  props<{ version: Version }>(),
);

export const setDefaultVersionSuccess = createAction(
  '[VersionsData/API] Set Default Version Success',
  props<{ version: Version }>(),
);

export const setDefaultVersionError = createAction(
  '[VersionsData/API] Set Default Version Error',
  props<{ message: string }>(),
);

export const setVersion = createAction(
  '[VersionsData] Set Version',
  props<{ version: Version }>(),
);
