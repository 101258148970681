import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { adapter, ConversionProcessState } from './conversion-process.reducer';
import { AppState } from '../../../../store';
import { conversionProcessFeatureKey } from './conversion-process-store.module';
import { Dictionary } from '@ngrx/entity';
import { ConversionProcess } from '../../models/conversion-process.model';


export const selectConversionProcessState: MemoizedSelector<AppState, ConversionProcessState> =
  createFeatureSelector(conversionProcessFeatureKey);

export const {
  selectAll,
  selectIds,
  selectEntities,
  selectTotal,
} = adapter.getSelectors(selectConversionProcessState);

export const selectById = (id: string) => createSelector(
  selectEntities,
  (entities: Dictionary<ConversionProcess>) => entities[id],
);

export const isLoading = createSelector(
  selectConversionProcessState,
  (state: ConversionProcessState) => state.loading,
);

export const selectLastCreated = createSelector(
  selectConversionProcessState,
  (state: ConversionProcessState) => !state.loading ? state.lastCreated : undefined,
);

export const selectByMaterialId = (materialId: string) => createSelector(
  selectAll,
  (entities: ConversionProcess[]) => entities.filter(
    (conversionProcess: ConversionProcess) => conversionProcess?.materialId === materialId,
  ),
);

export const selectElectricityType = (id: string) => createSelector(
  selectById(id),
  (conversionProcess: ConversionProcess) => conversionProcess?.electricity,
);

export const selectCountry = (id: string) => createSelector(
  selectById(id),
  (conversionProcess: ConversionProcess) => conversionProcess?.country,
);
