import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ConversionProcessDataService } from '../../services/conversion-process.data-service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  addConversionProcess,
  addConversionProcessSuccess,
  deleteConversionProcess,
  deleteConversionProcessSuccess,
  conversionProcessError,
  updateConversionProcess,
  updateConversionProcessSuccess,
} from './conversion-process.actions';
import { ConversionProcess } from '../../models/conversion-process.model';


@Injectable()
export class ConversionProcessEffects {

  addConversionProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addConversionProcess.type),
      map((action: { type: string, conversionProcess: ConversionProcess }) => action.conversionProcess),
      switchMap((cp: ConversionProcess) => this.conversionProcessDataService.create(cp)
        .pipe(
          map((conversionProcess: ConversionProcess) => addConversionProcessSuccess({ conversionProcess })),
          catchError(() => of(conversionProcessError({ message: 'Something went wrong when adding new conversion-process' }))),
        ),
      ),
    ),
  );

  updateConversionProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateConversionProcess.type),
      map((action: { type: string, conversionProcess: ConversionProcess }) => action.conversionProcess),
      switchMap((cp: ConversionProcess) => this.conversionProcessDataService.update(cp)
        .pipe(
          map((conversionProcess: ConversionProcess) => updateConversionProcessSuccess({ conversionProcess })),
          catchError(() => of(conversionProcessError({ message: 'Something went wrong when updating conversion-process' }))),
        ),
      ),
    ),
  );

  deleteConversionProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteConversionProcess.type),
      map((action: { type: string, id: string }) => action.id),
      switchMap((id: string) => this.conversionProcessDataService.delete(id)
        .pipe(
          map((index: string) => deleteConversionProcessSuccess({ id: index })),
          catchError(() => of(conversionProcessError({ message: 'Something went wrong when deleting conversion-process' }))),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly conversionProcessDataService: ConversionProcessDataService) {}
}
