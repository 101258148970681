import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultipleSelectorComponent } from './multiple-selector.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { StringInputComponent } from './string-input.component';
import { MatInputModule } from '@angular/material/input';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { StringSelectorComponent } from './string-selector.component';

@NgModule({
  declarations: [
    MultipleSelectorComponent,
    StringSelectorComponent,
    StringInputComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
  ],
  exports: [
    MultipleSelectorComponent,
    StringInputComponent,
    StringSelectorComponent,
  ],
})
export class FormModule {
}
