import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scientific',
})
export class ScientificPipe implements PipeTransform {

  private readonly MAX_INTEGER_DIGITS = 3;

  transform(value: number | string, ...args: unknown[]): string {
    if (typeof value === 'string') {
      return value;
    }

    if (value === 0) {
      return '0';
    }

    return this.toExponentialOrFixed(value);
  }

  private toExponentialOrFixed(value: number): string {
    const abs: number = Math.abs(value);

    if (abs < 1e-4 || abs > 1e+5) {
      return value.toExponential(2);
    }

    const rangedLog = Math.min(Math.log10(abs), this.MAX_INTEGER_DIGITS);
    const precision = this.MAX_INTEGER_DIGITS - rangedLog;
    return value.toFixed(precision);
  }
}
