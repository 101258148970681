import { NgModule } from '@angular/core';
import { ProjectStoreModule } from './modeling/store/project/project-store.module';
import { ReferenceDataModule } from '../store/reference-data/reference-data.module';
import { ComponentStoreModule } from './modeling/store/component/component-store.module';
import { FinishingProcessStoreModule } from './modeling/store/finishing-process/finishing-process-store.module';
import { MaterialStoreModule } from './modeling/store/material/material-store.module';
import { ScenarioStoreModule } from './modeling/store/scenario/scenario-store.module';
import { PackagingStoreModule } from './modeling/store/packaging/packaging-store.module';
import { MaterialTransportStoreModule } from './modeling/store/material-transport/material-transport-store.module';
import { DownstreamStoreModule } from '../packaging/downstream/store/downstream-store.module';
import { AssessmentStoreModule } from './assessment/store/assessment-store.module';
import { ComponentTransportStoreModule } from './modeling/store/component-transport/component-transport-store.module';
import { WashingProcessStoreModule } from './modeling/store/washing-process/washing-process-store.module';

@NgModule({
  imports: [
    ProjectStoreModule,
    ReferenceDataModule,
    ComponentStoreModule,
    ComponentTransportStoreModule,
    DownstreamStoreModule,
    FinishingProcessStoreModule,
    WashingProcessStoreModule,
    MaterialStoreModule,
    ScenarioStoreModule,
    PackagingStoreModule,
    MaterialTransportStoreModule,
    AssessmentStoreModule,
  ],
})

export class ProjectModelingStoreModule {
}
