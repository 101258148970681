import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { EndOfLivesComponent } from './components/end-of-lives.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { EndOfLifeComponent } from './components/end-of-life/end-of-life.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';

@NgModule({
  declarations: [
    EndOfLivesComponent,
    EndOfLifeComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule,
    MatDividerModule,
  ],
  exports: [
    EndOfLivesComponent
  ],
})
export class EndOfLifeModule {
}
