import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminHomePageComponent} from './pages/admin-home-page/admin-home-page.component';
import {
  UserManagementPageComponent
} from './pages/user-management-page/user-management-page.component';
import {ProjectsPageComponent} from './pages/projects-page/projects-page.component';
import {
  ScenarioLibraryPageComponent
} from './pages/scenario-library-page/scenario-library-page.component';
import {GetProjectsResolver} from '../project/pages/home-page/get-projects.resolver';
import {PortfoliosPageComponent} from './pages/portfolios-page/portfolios-page.component';
import {
  PortfolioDetailPageComponent
} from './pages/portfolio-detail-page/portfolio-detail-page.component';
import {VersionPageComponent} from './pages/version-page/version-page.component';
import {StatisticsPageComponent} from './pages/statistics-page/statistics-page.component';
import {AdminResolver} from './admin.resolver';

const routes: Routes = [
  {
    path: '',
    component: AdminHomePageComponent,
    resolve: {
      admin: AdminResolver,
    },
    children: [
      {
        path: 'projects',
        component: ProjectsPageComponent,
        resolve: {
          project: GetProjectsResolver,
        },
      },
      {
        path: 'portfolios/:portfolioId',
        component: PortfolioDetailPageComponent,
      },
      {
        path: 'portfolios',
        component: PortfoliosPageComponent,
      },
      {
        path: 'portfolios',
        component: PortfoliosPageComponent,
      },
      {
        path: 'scenario-library',
        component: ScenarioLibraryPageComponent,
      },
      {
        path: 'user-management',
        component: UserManagementPageComponent,
      },
      {
        path: 'version',
        component: VersionPageComponent,
      },
      {
        path: 'statistics',
        component: StatisticsPageComponent,
      },
      {
        path: '',
        redirectTo: 'projects',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {
}
