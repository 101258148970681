import {Component, Input, OnInit} from '@angular/core';
import {StatisticsIcon, StatisticsInputLabel, StatisticsLabel} from './statistic-card.models';

@Component({
  selector: 'app-statistics-card',
  template: `
    <mat-card>
      <mat-card-content [class.small]="small">
        <div class="icon" *ngIf="!!icons?.length">
          <ng-container *ngFor="let icon of icons; index as i">
            <mat-icon inline [ngClass]="icon.class">{{ icon.name }}</mat-icon>
          </ng-container>
        </div>
        <div class="number" *ngIf="value !== undefined && value !== null">
          {{ value }}
        </div>
        <div class="label" *ngIf="label">
          {{ label.value }}
          <ng-container *ngIf="label?.tooltip">
            <mat-icon
                class="tooltip"
                color="primary"
                i18n-matTooltip
                [matTooltip]="label.tooltip.value"
            >
              {{ label.tooltip.icon }}
            </mat-icon>
          </ng-container>
        </div>
      </mat-card-content>
    </mat-card>
  `,
  styleUrls: ['./statistics-card.component.scss']
})
export class StatisticsCardComponent implements OnInit {

  @Input() icons: StatisticsIcon[];
  @Input() value: StatisticsLabel;
  @Input() label: StatisticsInputLabel;
  @Input() small = false;


  constructor() {
  }

  ngOnInit(): void {
  }

}
