import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  addTertiaryPalletizationPackaging,
  addTertiaryPalletizationPackagingSuccess,
  deleteAll,
  deleteAllSuccess,
  deleteTertiaryPalletizationPackaging,
  deleteTertiaryPalletizationPackagingSuccess,
  tertiaryPalletizationPackagingError,
  updateTertiaryPalletizationPackaging,
  updateTertiaryPalletizationPackagingSuccess,
} from './tertiary-palletization-packaging.actions';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { Packaging, PackagingLevel } from '../../../models/packaging.models';
import { of } from 'rxjs';
import { PackagingDataService } from '../../../services/packaging.data-service';
import * as fromScenario from '../../scenario';
import * as fromComponent from '../../component';
import { select, Store } from '@ngrx/store';
import { selectByLevelAndScenarioId } from '../packaging.selectors';
import { AppState } from '../../../../../store';


@Injectable()
export class TertiaryPalletizationPackagingEffects {

  addTertiaryPalletizationPackaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addTertiaryPalletizationPackaging),
      map((action: { type: string, scenarioId: string }) => ({
        scenarioId: action.scenarioId,
        level: PackagingLevel.TERTIARY_PALLETIZATION,
      })),
      switchMap((p: Packaging) => this.tertiaryPackagingDataService.create(p)
        .pipe(
          mergeMap((packaging: Packaging) => [
            addTertiaryPalletizationPackagingSuccess({ packaging }),
            fromScenario.getScenario({ id: packaging.scenarioId }),
          ]),
          catchError(() => of(tertiaryPalletizationPackagingError({ message: 'Something went wrong when adding new tertiary palletization packaging' }))),
        ),
      ),
    ),
  );

  updateTertiaryPalletizationPackaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTertiaryPalletizationPackaging),
      map((action: { type: string, packaging: Packaging }) => action.packaging),
      switchMap((pp: Packaging) => this.tertiaryPackagingDataService.update(pp)
        .pipe(
          map((packaging: Packaging) => updateTertiaryPalletizationPackagingSuccess({ packaging })),
          catchError(() => of(tertiaryPalletizationPackagingError({ message: 'Something went wrong when updating tertiary palletization packaging' }))),
        ),
      ),
    ),
  );

  deleteTertiaryPalletizationPackaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTertiaryPalletizationPackaging),
      switchMap((action: { type: string, id: string, scenarioId: string }) =>
        this.tertiaryPackagingDataService.delete(action.id)
          .pipe(
            mergeMap((packagingId: string) => [
              deleteTertiaryPalletizationPackagingSuccess({ id: packagingId }),
              fromScenario.getScenario({ id: action.scenarioId }),
              fromComponent.deleteAll({ packagingId }),
            ]),
            catchError(() => of(tertiaryPalletizationPackagingError({ message: 'Something went wrong when deleting tertiary palletization packaging' }))),
          ),
      ),
    ),
  );

  deleteAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAll),
      switchMap((action: { scenarioId: string }) => {
        return this.store.pipe(
          select(selectByLevelAndScenarioId(action.scenarioId, PackagingLevel.TERTIARY_PALLETIZATION)),
          filter((packaging: Packaging) => !!packaging),
          mergeMap((packaging: Packaging) => [
            fromComponent.deleteAll({ packagingId: packaging.id }),
            deleteAllSuccess({ scenarioId: action.scenarioId }),
          ]),
        );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly tertiaryPackagingDataService: PackagingDataService,
    private readonly store: Store<AppState>) {}
}
