import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { Project } from '../../models/project.model';
import {
  addProject,
  addProjectSuccess,
  deleteProject,
  deleteProjectSuccess,
  dismissProjectLockDisclaimer,
  duplicateProject,
  duplicateProjectSuccess,
  getProject, getProjectFilters, getProjectFiltersSuccess,
  getProjects,
  getProjectsSuccess,
  getProjectSuccess,
  projectError,
  releaseProjectLockSuccess,
  updateProject,
  updateProjectSuccess,
} from './project.actions';
import { Pagination } from '../../../../shared/page.model';
import { sortByLastOpened } from './sort-by-last-opened';
import { ProjectLock } from '../../../project-lock/project-lock.model';
import { ProjectFilterDto } from "../../../../api/dtos/__generated__/projectFilterDto";

export const projectsFeatureKey = 'project';

export interface ProjectState extends EntityState<Project> {
  // additional entities state properties
  error: string;
  loading: boolean;
  lastCreated: Project;
  pagination: Pagination;
  lock: ProjectLock;
  projectFilters: ProjectFilterDto;
  displayProjectLockDisclaimer: boolean;
}

export const adapter: EntityAdapter<Project> = createEntityAdapter<Project>({
  sortComparer: sortByLastOpened,
});

export const initialState: ProjectState = adapter.getInitialState({
  loading: false,
  error: null,
  lastCreated: null,
  pagination: null,
  lock: null,
  projectFilters: { owners: [], brands: [] },
  displayProjectLockDisclaimer: true,
});

const reducer = createReducer(
  initialState,
  on(
    getProject,
    getProjects,
    getProjectFilters,
    addProject,
    updateProject,
    deleteProject,
    duplicateProject,
    projectError,
    duplicateProjectSuccess,
    deleteProjectSuccess,
    (state, action) => ({ ...state, loading: true, lastCreated: null, error: null }),
  ),
  on(getProjectSuccess,
    (state, action) => {
      const project: Project = action.project;
      let displayProjectLockDisclaimer = state.displayProjectLockDisclaimer;
      if (state?.lock?.itDoesBelongToMe && !action.projectLock.itDoesBelongToMe) {
        displayProjectLockDisclaimer = true;
      }
      return adapter.upsertOne(project, {
        ...state,
        lock: action.projectLock,
        displayProjectLockDisclaimer,
        loading: false,
      });
    },
  ),
  on(getProjectFiltersSuccess, (state, action) => ({ ...state, projectFilters: action.projectFilters })),
  on(
    getProjectsSuccess,
    (state, action) => {
      const { page } = action;
      const projects: Project[] = page.content;
      if (!projects) {
        return state;
      }
      const pagination: Pagination = (({ content, ...others }) => others)(page);
      return adapter.setAll(projects, { ...state, pagination, loading: false });
    },
  ),
  on(addProjectSuccess,
    (state, action) => {
      const project: Project = action.project as Project;
      return adapter.addOne(project, { ...state, loading: false, lastCreated: project });
    },
  ),
  on(updateProjectSuccess,
    (state, action) => {
      const { project } = action;
      const update: Update<Project> = { id: project.id, changes: project };
      return adapter.updateOne(update, { ...state, loading: false });
    },
  ),
  on(duplicateProjectSuccess,
    (state, action) => {
      const project: Project = action.project as Project;
      return adapter.addOne(project, { ...state, loading: false });
    },
  ),
  on(projectError, (state, action) =>
    ({ ...state, error: action.message, loading: false }),
  ),
  on(deleteProjectSuccess, (state: ProjectState, action) =>
    adapter.removeOne(action.id, { ...state, loading: false }),
  ),
  on(dismissProjectLockDisclaimer, (state: ProjectState, action) => ({
    ...state, displayProjectLockDisclaimer: false,
  })),
  on(releaseProjectLockSuccess, (state, action) => ({ ...state, displayProjectLockDisclaimer: true, lock: null })),
);

export function projectReducer(state: ProjectState | undefined, action: Action) {
  return reducer(state, action);
}


