export type INDICATOR_TYPE = 'LCA'
  | 'PACKAGING_DESIGN'
  | 'WASTE_STREAM'
  | 'SINGLE_SCORE'
  | 'PLASTIC_FOOTPRINT'
  | 'GHG'
  | 'MATERIAL_CIRCULARITY';

export const IndicatorType = {
  LCA: 'LCA' as INDICATOR_TYPE,
  PACKAGING_DESIGN: 'PACKAGING_DESIGN' as INDICATOR_TYPE,
  WASTE_STREAM: 'WASTE_STREAM' as INDICATOR_TYPE,
  SINGLE_SCORE: 'SINGLE_SCORE' as INDICATOR_TYPE,
  PLASTIC_FOOTPRINT: 'PLASTIC_FOOTPRINT' as INDICATOR_TYPE,
  MATERIAL_CIRCULARITY: 'MATERIAL_CIRCULARITY' as INDICATOR_TYPE,
  GHG: 'GHG' as INDICATOR_TYPE
};
