import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, scenarioFeatureKey, ScenarioState } from './scenario.reducer';
import { AppState } from '../../../../store';
import { Dictionary } from '@ngrx/entity';
import { Scenario } from '../../models/scenario.model';
import { Downstream } from '../../../../packaging/downstream/models/downstream';

export const selectScenarioState = createFeatureSelector<AppState, ScenarioState>(scenarioFeatureKey);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectScenarioState);

export const isLoading = createSelector(
  selectScenarioState,
  (state: ScenarioState) => state.loading,
);

export const selectLastCreated = createSelector(
  selectScenarioState,
  isLoading,
  ((state: ScenarioState, loading: boolean) => !loading ? state.lastCreated : undefined),
);

export const selectScenarioById = (scenarioId: string) => createSelector(
  selectEntities,
  (entities: Dictionary<Scenario>): Scenario => entities[scenarioId],
);

export const selectScenarioName = (scenarioId: string) => createSelector(
  selectScenarioById(scenarioId),
  (s: Scenario) => s?.name,
);

export const selectAllScenariosOfProject = (projectId: string) => createSelector(
  selectEntities,
  ((entities: Dictionary<Scenario>) => Object.values(entities)
    .filter((s: Scenario) => s?.projectId === projectId)),
);

export const selectAllScenariosByIds = (scenarioIds: string[]) => createSelector(
  selectEntities,
  ((entities: Dictionary<Scenario>) => Object.values(entities)
    .filter((s: Scenario) => scenarioIds.includes(s?.id)))
);

export const selectSelectedScenariosForAssessment = (projectId: string) => createSelector(
  selectEntities,
  ((entities: Dictionary<Scenario>) => Object.values(entities)
    .filter((s: Scenario) => s?.projectId === projectId && s?.assessmentSelected === true)),
);

export const selectPackagingType = (id: string) => createSelector(
  selectScenarioById(id),
  (scenario: Scenario) => scenario?.packagingType,
);

export const selectBaseMaterial = (id: string) => createSelector(
  selectScenarioById(id),
  (scenario: Scenario) => scenario?.baseMaterial,
);

export const selectProductType = (id: string) => createSelector(
  selectScenarioById(id),
  (scenario: Scenario) => scenario?.productType,
);

export const selectProductionCountry = (id: string) => createSelector(
  selectScenarioById(id),
  (scenario: Scenario) => scenario?.productionCountry,
);

export const selectDistributionArea = (id: string) => createSelector(
  selectScenarioById(id),
  (scenario: Scenario) => scenario?.salesCountry,
);

export const selectReference = (id: string) => createSelector(
  selectScenarioById(id),
  (scenario: Scenario) => scenario?.reference,
);

export const selectStatus = (id: string) => createSelector(
  selectScenarioById(id),
  (scenario: Scenario) => scenario?.status,
);

export const selectDescription = (id: string) => createSelector(
  selectScenarioById(id),
  (scenario: Scenario) => scenario?.description,
);

export const selectHasSecondaryPackaging = (id: string) => createSelector(
  selectScenarioById(id),
  (scenario: Scenario) => scenario?.hasSecondaryPackaging,
);

export const selectHasTertiaryPackaging = (id: string) => createSelector(
  selectScenarioById(id),
  (scenario: Scenario) => scenario?.hasTertiaryBoxPackaging,
);

export const selectHasTertiaryPalletizationPackaging = (id: string) => createSelector(
  selectScenarioById(id),
  (scenario: Scenario) => scenario?.hasTertiaryPalletizationPackaging,
);

export const selectFirstScenario = (projectId: string) => createSelector(
  selectAllScenariosOfProject(projectId),
  (scenarios: Scenario[]) => {
    const [ first ] = scenarios;
    return first;
  },
);

export const selectFirstScenarioExcept = (projectId: string, exceptId: string) => createSelector(
  selectAllScenariosOfProject(projectId),
  (scenarios: Scenario[]) => {
    const [ first ] = scenarios.filter((s: Scenario) => s.id !== exceptId);
    return first;
  },
);

export const isLastScenario = (projectId: string) => createSelector(
  selectAllScenariosOfProject(projectId),
  (scenarios: Scenario[]) => scenarios.length === 1,
);

export const somethingWentWrong = createSelector(
  selectScenarioState,
  (state: ScenarioState) => state.error,
);

export const selectDownstream = (id: string) => createSelector(
  selectScenarioState,
  (state): Downstream | undefined => state.entities[id]?.downstream
);
