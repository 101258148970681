import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WashingProcess } from '../models/washing-process.models';
import { HttpClient } from '@angular/common/http';
import { WashingProcessMapperService } from './washing-process-mapper.service';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { WashingProcessDto } from '../../../api/dtos/__generated__/washingProcessDto';

@Injectable({
  providedIn: 'root',
})
export class WashingProcessDataService {

  WASHING_PROCESS_URL = `${environment.apiUrl}/washing-processes`;

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: WashingProcessMapperService,
  ) { }

  create(washingProcess: WashingProcess): Observable<WashingProcess> {
    const dto: WashingProcessDto = this.mapper.mapWashingProcessDtoFromWashingProcess(washingProcess);
    return this.http.post(this.WASHING_PROCESS_URL, dto).pipe(
      map((response: WashingProcessDto) => this.mapper.mapWashingProcessFromWashingProcessDto(response)),
    );
  }

  update(washingProcess: WashingProcess): Observable<WashingProcess> {
    const dto: WashingProcessDto = this.mapper.mapWashingProcessDtoFromWashingProcess(washingProcess);
    return this.http.put(`${this.WASHING_PROCESS_URL}/${washingProcess.id}`, dto).pipe(
      map((response: WashingProcessDto) => this.mapper.mapWashingProcessFromWashingProcessDto(response)),
    );
  }

  delete(id: string): Observable<string> {
    return this.http.delete(`${this.WASHING_PROCESS_URL}/${id}`).pipe(
      map(() => id),
    );
  }
}
