import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProjectAssessment } from '../models/project-assessment.model';
import { ProjectAssessmentMapperService } from './project-assessment-mapper.service';
import { ProjectAssessmentDto } from '../../../api/dtos/__generated__/projectAssessmentDto';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AssessmentDataService {

  private readonly PROJECTS_URL = `${environment.apiUrl}/projects`;

  constructor(
    private readonly http: HttpClient,
    private readonly projectAssessmentMapper: ProjectAssessmentMapperService,
  ) { }

  assess(projectId: string, versionHash: string): Observable<ProjectAssessment> {
    const params = { versionHash };
    return this.http.post(`${this.PROJECTS_URL}/${projectId}/assess`, null, { params }).pipe(
      map((response: ProjectAssessmentDto) => this.projectAssessmentMapper.from(response)),
    );
  }
}
