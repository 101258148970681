import { Injectable } from '@angular/core';
import { AbstractMapper } from '../../project/abstract-mapper';
import { AbstractScenarioMetadataDto } from '../../api/dtos/__generated__/abstractScenarioMetadataDto';
import { PortfolioScenario } from '../models/portfolio-scenario.model';

@Injectable({
  providedIn: 'root',
})
export class PortfolioScenarioMapperService extends AbstractMapper<AbstractScenarioMetadataDto, PortfolioScenario> {
  from(dto: AbstractScenarioMetadataDto): PortfolioScenario {
    const {
      id,
      name,
      description,
      reference,
      packagingType,
      baseMaterialType,
      productType,
      statusType,
      productionCountry,
    } = dto;
    return {
      id,
      name,
      description,
      reference,
      packagingType,
      baseMaterialType,
      productType,
      statusType,
      productionCountry,
    };
  }

  to(object: PortfolioScenario): AbstractScenarioMetadataDto {
    const {
      id,
      name,
      description,
      reference,
      packagingType,
      baseMaterialType,
      productType,
      statusType,
      productionCountry,
    } = object;
    return {
      id,
      name,
      description,
      reference,
      packagingType,
      baseMaterialType,
      productType,
      statusType,
      productionCountry,
    };
  }
}
