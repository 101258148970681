export type PackagingDesignIndicator =
  'IND_RECYCLABILITY'
  | 'IND_RECYCLING_RATE'
  | 'IND_REUSABILITY'
  | 'IND_COMPOSTABILITY'
  | 'IND_RENEWABLE_CONTENT'
  | 'IND_RECYCLED_CONTENT'
  | 'IND_PACK_WEIGHT_RATIO';

export const PackagingDesignIndicator = {
  RECYCLABILITY: 'IND_RECYCLABILITY' as PackagingDesignIndicator,
  RECYCLINGRATE: 'IND_RECYCLING_RATE' as PackagingDesignIndicator,
  REUSABILITY: 'IND_REUSABILITY' as PackagingDesignIndicator,
  COMPOSTABILITY: 'IND_COMPOSTABILITY' as PackagingDesignIndicator,
  RENEWABLECONTENT: 'IND_RENEWABLE_CONTENT' as PackagingDesignIndicator,
  RECYCLEDCONTENT: 'IND_RECYCLED_CONTENT' as PackagingDesignIndicator,
  PACKWEIGHTRATIO: 'IND_PACK_WEIGHT_RATIO' as PackagingDesignIndicator,
};

export const isPackagingDesignIndicator = (value: string): boolean => {
  if (typeof value !== 'string') {
    return false;
  }
  return !!Object.values(PackagingDesignIndicator).find(ind => ind.localeCompare(value.toUpperCase()) === 0);
};

export const isRecyclability = (value: string): boolean => {
  if (typeof value !== 'string') {
    return false;
  }
  return PackagingDesignIndicator.RECYCLABILITY.localeCompare(value.toUpperCase()) === 0;
};

export const isPackagingToWeightRatio = (value: string): boolean => {
  if (typeof value !== 'string') {
    return false;
  }
  return PackagingDesignIndicator.PACKWEIGHTRATIO.localeCompare(value.toUpperCase()) === 0;
};
