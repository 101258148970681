import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScenarioLibraryTableHeaderComponent } from './scenario-library-table-header.component';
import { SharedModule } from '../../shared/shared.module';
import { SearchInputModule } from '../../shared/search-input/search-input.module';


@NgModule({
  declarations: [ ScenarioLibraryTableHeaderComponent ],
  imports: [
    CommonModule,
    SharedModule,
    SearchInputModule,
  ],
  exports: [
    ScenarioLibraryTableHeaderComponent,
  ],
})
export class ScenarioLibraryTableHeaderModule {}
