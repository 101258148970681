export interface Packaging {
  readonly id: string;
  readonly level: PackagingLevel;
  readonly scenarioId: string;
  readonly productMass?: number;
  readonly productVolume?: number;
  readonly multiplicity?: number;
  readonly usage?: Usage;
}

export type PackagingLevel = 'PRIMARY' | 'SECONDARY' | 'TERTIARY_BOX' | 'TERTIARY_PALLETIZATION';

export const PackagingLevel = {
  PRIMARY: 'PRIMARY' as PackagingLevel,
  SECONDARY: 'SECONDARY' as PackagingLevel,
  TERTIARY_BOX: 'TERTIARY_BOX' as PackagingLevel,
  TERTIARY_PALLETIZATION: 'TERTIARY_PALLETIZATION' as PackagingLevel,
};

export type Usage = 'IN_HOME' | 'ON_THE_GO';

export const Usage = {
  IN_HOME: 'IN_HOME' as Usage,
  ON_THE_GO: 'ON_THE_GO' as Usage,
};
