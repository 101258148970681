import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {UserManagementEffects} from './user-management.effects';
import {reducer, userManagementFeatureKey} from './user-management.reducer';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(userManagementFeatureKey, reducer),
    EffectsModule.forFeature([UserManagementEffects]),
  ],
})
export class UserManagementStoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: UserManagementStoreModule) {
    if (parentModule) {
      throw new Error('UserManagementStoreModule is already loaded');
    }
  }
}
