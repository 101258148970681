import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { computeAssessment, computeAssessmentError, computeAssessmentSuccess } from './assessment.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AssessmentDataService } from '../services/assessment.data-service';
import { ProjectAssessment } from '../models/project-assessment.model';
import { of } from 'rxjs';


@Injectable()
export class AssessmentEffects {

  assess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(computeAssessment),
      switchMap((action: { type: string, projectId: string, versionHash: string }) => {
        return this.assessmentDataService.assess(action.projectId, action.versionHash)
          .pipe(
            map((assessment: ProjectAssessment) => computeAssessmentSuccess({ assessment })),
            catchError(() => of(computeAssessmentError({ message: 'Something went wrong when computing project assessment' }))),
          );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly assessmentDataService: AssessmentDataService,
  ) {}

}
