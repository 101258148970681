import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  getAllCollaborators,
  getAllCollaboratorsError,
  getAllCollaboratorsSuccess,
  updateCollaborator,
  updateCollaboratorError,
  updateCollaborators,
  updateCollaboratorsSuccess,
  updateCollaboratorSuccess
} from './user-management.actions';
import {of, switchMap} from 'rxjs';
import {catchError, map, withLatestFrom} from 'rxjs/operators';
import {Collaborator} from '../../../collaborator/collaborator.model';
import {UserManagementDataService} from '../user-management.data-service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store';
import {selectUserManagementState} from './user-management.selectors';
import {getStatistics} from '../../../store/statistics-data/store';

@Injectable({
  providedIn: 'root'
})
export class UserManagementEffects {
  getAllCollaborators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllCollaborators.type),
      switchMap(() => this.userManagementService.getAll()
        .pipe(
          map((collaborators: Collaborator[]) => getAllCollaboratorsSuccess({collaborators})),
          catchError(() => of(getAllCollaboratorsError({message: 'Could not fetch user-management data'}))),
        )
      ),
    )
  );

  updateCollaborators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCollaborators.type),
      map((props: {
        collaborators: Collaborator[]
      }) => updateCollaborator({collaborator: props.collaborators[0]}))
    )
  );

  updateCollaborator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCollaborator.type),
      switchMap((props: { collaborator: Collaborator }) =>
        this.userManagementService.update(props.collaborator).pipe(
          map((collaborator: Collaborator) => updateCollaboratorSuccess({collaborator})),
          catchError(() => of(updateCollaboratorError({
              message: `Could not update collaborator with id ${props.collaborator.id}`,
              collaborator: props.collaborator
            }))
          ),
        )),
    )
  );

  updateCollaboratorSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCollaboratorSuccess.type),
      withLatestFrom(this.store.pipe(select(selectUserManagementState))),
      map(([_, state]) => {
        if (!!state.toUpdate?.length) {
          return updateCollaborator({collaborator: state.toUpdate[0]});
        }
        return updateCollaboratorsSuccess({collaborators: state.updated});
      })
    )
  );

  updateCollaboratorError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCollaboratorError.type),
      withLatestFrom(this.store.pipe(select(selectUserManagementState))),
      map(([_, state]) => {
        if (!!state.toUpdate?.length) {
          return updateCollaborator({collaborator: state.toUpdate[0]});
        }
      }),
    )
  );

  updateCollaboratorsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCollaboratorsSuccess.type),
      map(_ => getStatistics())
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly userManagementService: UserManagementDataService,
    private readonly store: Store<AppState>,
  ) {
  }
}
