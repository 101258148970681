import { NgModule } from '@angular/core';
import { StatisticsPageComponent } from './statistics-page.component';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { StatisticsModule } from '../../../statistics/statistics.module';

@NgModule({
  declarations: [ StatisticsPageComponent ],
  imports: [
    CommonModule,
    MatCardModule,
    StatisticsModule,
  ]
})
export class StatisticsPageModule {
}
