import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../core/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TenantLogoService {

  private readonly ASSETS_PATH = './assets/logos/';

  constructor(private readonly localStorageService: LocalStorageService) {}

  get logo(): Observable<string | undefined> {
    return this.localStorageService.getProviderName().pipe(
      map(theme =>  `${this.ASSETS_PATH}${theme}.png`)
    );
  }
}
