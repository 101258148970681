import { Injectable } from '@angular/core';
import { ComponentTransportDto } from '../../../api/dtos/__generated__/componentTransportDto';
import { ComponentTransport } from '../models/component.models';

@Injectable({
  providedIn: 'root',
})
export class ComponentTransportMapperService {
  constructor() { }

  mapComponentTransportFromComponentTransportDto(dto: ComponentTransportDto): ComponentTransport {
    const {
      id,
      componentId,
      distance,
      transportType,
    } = dto;

    return {
      id,
      componentId,
      distance,
      transportType: {
        label: transportType?.label,
        value: transportType?.value,
      },
    } as ComponentTransport;
  }

  mapComponentTransportListFromComponentTransportDtoList(dtos: ComponentTransportDto[]): ComponentTransport[] {
    return dtos.map((dto) => this.mapComponentTransportFromComponentTransportDto(dto));
  }

  mapComponentTransportDtoFromComponentTransport(transport: ComponentTransport): ComponentTransportDto {
    const {
      id,
      componentId,
      transportType,
      distance,
    } = transport;

    return {
      id,
      componentId,
      transportType,
      distance,
    } as ComponentTransportDto;
  }
}
