import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  addSecondaryPackaging,
  addSecondaryPackagingSuccess,
  createFirstSecondaryPackaging,
  createFirstSecondaryPackagingSuccess,
  deleteAll,
  deleteAllSuccess,
  deleteSecondaryPackaging,
  deleteSecondaryPackagingSuccess,
  secondaryPackagingError,
  updateSecondaryPackaging,
  updateSecondaryPackagingSuccess,
  updateUsage,
} from './secondary-packaging.actions';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { Packaging, PackagingLevel, Usage } from '../../../models/packaging.models';
import { of } from 'rxjs';
import { PackagingDataService } from '../../../services/packaging.data-service';
import * as fromScenario from '../../scenario';
import * as fromComponent from '../../component';
import { createFirstTertiaryPackaging } from '../tertiary';
import { selectByLevelAndScenarioId } from '../packaging.selectors';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../store';
import { selectById } from './secondary-packaging.selectors';


@Injectable()
export class SecondaryPackagingEffects {

  createFirstSecondaryPackaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFirstSecondaryPackaging),
      switchMap((action: { type: string, scenarioId: string }) => {
          const p: Packaging = {
            scenarioId: action.scenarioId,
            level: PackagingLevel.SECONDARY,
          } as Packaging;
          return this.packagingDataService.create(p)
            .pipe(
              mergeMap((packaging: Packaging) => [
                createFirstSecondaryPackagingSuccess({ packaging }),
                createFirstTertiaryPackaging({ scenarioId: action.scenarioId }),
              ]),
              catchError(() => of(secondaryPackagingError({ message: 'Something went wrong when adding new secondary packaging' }))),
            );
        },
      ),
    ),
  );

  addSecondaryPackaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSecondaryPackaging),
      switchMap((action: { type: string, scenarioId: string }) => {
          const p: Packaging = {
            scenarioId: action.scenarioId,
            level: PackagingLevel.SECONDARY,
          } as Packaging;
          return this.packagingDataService.create(p)
            .pipe(
              mergeMap((packaging: Packaging) => [
                addSecondaryPackagingSuccess({ packaging }),
                fromScenario.getScenario({ id: packaging.scenarioId }),
              ]),
              catchError(() => of(secondaryPackagingError({ message: 'Something went wrong when adding new secondary packaging' }))),
            );
        },
      ),
    ),
  );

  updateSecondaryPackaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSecondaryPackaging.type),
      map((action: { type: string, packaging: Packaging }) => action.packaging),
      switchMap((p: Packaging) => this.packagingDataService.update(p)
        .pipe(
          map((packaging: Packaging) => updateSecondaryPackagingSuccess({ packaging })),
          catchError(() => of(secondaryPackagingError({ message: 'Something went wrong when updating secondary packaging' }))),
        ),
      ),
    ),
  );

  updateSecondaryPackagingUsage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        updateUsage,
      ),
      switchMap(
        (action:
           { type: string, id: string, usage: Usage }
        ) => this.store
          .pipe(
            select(selectById(action.id)),
            take(1),
            map((packaging: Packaging) => updateSecondaryPackaging({ packaging })),
          ),
      ),
    ),
  );

  deleteSecondaryPackaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSecondaryPackaging),
      switchMap((action: { type: string, id: string, scenarioId: string }) => {
          return this.packagingDataService.delete(action.id)
            .pipe(
              mergeMap((packagingId: string) => [
                deleteSecondaryPackagingSuccess({ id: packagingId }),
                fromScenario.getScenario({ id: action.scenarioId }),
                fromComponent.deleteAll({ packagingId }),
              ]),
              catchError(() => of(secondaryPackagingError({ message: 'Something went wrong when deleting secondary packaging' }))),
            );
        },
      ),
    ),
  );

  deleteAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAll),
      switchMap((action: { scenarioId: string }) => {
        return this.store.pipe(
          select(selectByLevelAndScenarioId(action.scenarioId, PackagingLevel.SECONDARY)),
          filter((packaging: Packaging) => !!packaging),
          mergeMap((packaging: Packaging) => [
            fromComponent.deleteAll({ packagingId: packaging.id }),
            deleteAllSuccess({ scenarioId: action.scenarioId }),
          ]),
        );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly packagingDataService: PackagingDataService,
    private readonly store: Store<AppState>,
  ) {
  }
}
