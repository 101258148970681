import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileButtonComponent } from './profile-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '../../../../auth/auth.service';
import { CollaboratorDataService } from '../../../../collaborator/collaborator.data-service';
import { DocumentationModule } from '../../../../shared/documentation/documentation.module';
import { CollaboratorModule } from '../../../../collaborator/collaborator.module';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  declarations: [
    ProfileButtonComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    CollaboratorModule,
    DocumentationModule,
    MatDialogModule,
  ],
  providers: [
    CollaboratorDataService,
    AuthService,
  ],
  exports: [
    ProfileButtonComponent,
  ],
})
export class ProfileButtonModule {}
