import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MaterialDataService } from '../../services/material.data-service';
import * as fromMaterial from './';
import { selectByComponentId, updateMaterial } from './';
import * as fromMaterialTransport from '../material-transport';
import * as fromReferenceData from '../../../../store/reference-data/store';
import { catchError, filter, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Material, MaterialTransport } from '../../models/material.models';
import { AppState } from '../../../../store';
import { select, Store } from '@ngrx/store';
import { ActionToMaterialService } from '../../services/action-to-material.service';
import { MergeMaterialsService } from '../../services/merge-materials.service';
import { valueNotNull } from '../../../../shared/rxjs-utils/rxjsFilters';
import { NotificationService } from '../../../../core/notification.service';
import { TransportType } from '../../models/transport.models';
import { findAllEndOfLives } from '../../../../packaging/end-of-life/store/end-of-life.actions';


@Injectable()
export class MaterialEffects {

  addMaterial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMaterial.addMaterial),
      switchMap((action: {
          type: string,
          componentId: string
        }) => this.materialDataService.create(action.componentId)
          .pipe(
            withLatestFrom(
              this.store.pipe(select(fromReferenceData.selectTransportBoat)),
              this.store.pipe(select(fromReferenceData.selectTransportLorry)),
            ),
            mergeMap(([ material, ship, truck ]: [ Material, TransportType, TransportType ]) => {
              return [
                fromMaterial.addMaterialSuccess({ material }),
                fromMaterialTransport.addMaterialTransport({
                  transport: {
                    materialId: material.id,
                    transportType: truck,
                    distance: 1000,
                  },
                }),
                fromMaterialTransport.addMaterialTransport({
                  transport: {
                    materialId: material.id,
                    transportType: ship,
                    distance: 18000,
                  },
                }),
              ];
            }),
            catchError(() => of(fromMaterial.materialError({ message: 'Something went wrong when adding new material' }))),
          ),
      ),
    ),
  );

  updateMaterialInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromMaterial.updateRecyclingRate,
        fromMaterial.updateRecyclingRateType,
        fromMaterial.updateCompostable,
        fromMaterial.updateRecyclable,
        fromMaterial.updateConversionProcessType,
        fromMaterial.updateConversionProcessElectricityTypeAndCountry,
        fromMaterial.updateConversionElectricityType,
      ),
      map(this.actionToMaterial.map),
      switchMap((newMaterial: Material) => this.store.pipe(
        select(fromMaterial.selectById(newMaterial.id)),
        valueNotNull,
        take(1),
        map((storedMaterial: Material) => this.mergeMaterials.merge(newMaterial, storedMaterial)),
      )),
      map((material: Material) => updateMaterial({ material })),
    ),
  );

  getMaterial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMaterial.getMaterial),
      map((action) => action.id),
      switchMap((id: string) => this.materialDataService.get(id)
        .pipe(
          map((material: Material) => fromMaterial.getMaterialSuccess({ material })),
          catchError(() => {
            this.notificationService.warn('Could not fetch material');
            return of(fromMaterial.materialError({ message: 'Something went wrong when fetching material' }));
          }))
      )));

  updateMaterial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMaterial.updateMaterial),
      map((action: { type: string, material: Partial<Material> }) => action.material),
      switchMap((newMaterial: Material) => this.store.pipe(
        select(fromMaterial.selectById(newMaterial.id)),
        take(1),
        map((storedMaterial: Material) => this.mergeMaterials.merge(newMaterial, storedMaterial)),
      )),
      switchMap((m: Material) => this.materialDataService.update(m)
        .pipe(
          mergeMap((material: Material) => [
            fromMaterial.updateMaterialSuccess({ material }),
            findAllEndOfLives({ materialId: material.id })
          ]),
          catchError(() => {
            this.notificationService.warn('Could not save latest material changes');
            return of(fromMaterial.materialError({ message: 'Something went wrong when updating material' }));
          }),
        ),
      ),
    ),
  );

  duplicateMaterial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMaterial.duplicateMaterial),
      map((action: { type: string, id: string }) => action.id),
      switchMap((id: string) => this.materialDataService.duplicate(id)
        .pipe(
          mergeMap(({ material, materialTransports }: {
            material: Material,
            materialTransports: MaterialTransport[]
          }) => [
            fromMaterial.duplicateMaterialSuccess({ material }),
            fromMaterialTransport.getMaterialTransportsSuccess({ materialTransports })
          ]),
          catchError(() => {
            this.notificationService.warn('Could not duplicate material');
            return of(fromMaterial.materialError({ message: 'Something went wrong when duplicating material' }));
          }),
        ),
      ),
    )
  );

  deleteMaterial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMaterial.deleteMaterial),
      map((action: { type: string, id: string }) => action.id),
      switchMap((id: string) => this.materialDataService.delete(id)
        .pipe(
          mergeMap((index: string) => [
            fromMaterialTransport.deleteAll({ materialId: index }),
            fromMaterial.deleteMaterialSuccess({ id: index }),
          ]),
          catchError(() => of(fromMaterial.materialError({ message: 'Something went wrong when deleting material' }))),
        )),
    ));

  deleteAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMaterial.deleteAll),
      switchMap((action: { type: string, componentId: string }) => this.store.pipe(
        select(selectByComponentId(action.componentId)),
        filter((materials: Material[]) => !!materials),
        take(1),
        mergeMap((materials: Material[]) => [
          ...materials.map((m: Material) => fromMaterialTransport.deleteAll({ materialId: m.id })),
          fromMaterial.deleteAllSuccess({ componentId: action.componentId }),
        ]),
      )),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly materialDataService: MaterialDataService,
    private readonly store: Store<AppState>,
    private readonly notificationService: NotificationService,
    private readonly actionToMaterial: ActionToMaterialService,
    private readonly mergeMaterials: MergeMaterialsService,
  ) {
  }

}
