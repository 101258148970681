import { Pipe, PipeTransform } from '@angular/core';
import { Mass, MassType } from '../../project/modeling/models/material.models';

@Pipe({
  name: 'mass',
})
export class MassPipe implements PipeTransform {

  transform(mass: Mass, ...args: unknown[]): string {
    if (mass?.massType === MassType.EXPLICIT_MASS) {
      return mass?.mass ? `${mass?.mass}` : '-';
    }
    return '-';
  }

}
