import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {
  getAllCollaborators,
  getAllCollaboratorsError,
  getAllCollaboratorsSuccess,
  updateCollaborator,
  updateCollaboratorError,
  updateCollaborators,
  updateCollaboratorsError,
  updateCollaboratorsSuccess,
  updateCollaboratorSuccess
} from './user-management.actions';
import {Collaborator} from '../../../collaborator/collaborator.model';

export const userManagementFeatureKey = 'userManagement';

export interface UserManagementState extends EntityState<Collaborator> {
  // additional entities state properties
  loading: boolean;
  error: string;
  toUpdate: Collaborator[];
  updated: Collaborator[];
}

export interface UserManagementFeatureState {
  userManagement: UserManagementState;
}

export const adapter: EntityAdapter<Collaborator> = createEntityAdapter<Collaborator>();

export const initialState: UserManagementState = adapter.getInitialState({
  loading: false,
  error: null,
  toUpdate: [],
  updated: []
});

export const reducer = createReducer(
  initialState,
  on(getAllCollaborators,
    updateCollaborator, (state) =>
      ({...state, loading: true, error: null})
  ),
  on(getAllCollaboratorsError, (state, action) =>
    ({...state, loading: false, error: action.message}),
  ),
  on(getAllCollaboratorsSuccess, (state, action) => {
    return adapter.setAll(
      action.collaborators,
      {...state, loading: false, error: null},
    );
  }),
  on(updateCollaborator, (state, action) => ({
    ...state,
    loading: true,
    error: null,
    toUpdate: state.toUpdate.filter(c => c.id !== action.collaborator.id),
  })),
  on(updateCollaboratorSuccess, (state, action) => {
    return adapter.setOne(action.collaborator, {
      ...state,
      loading: false,
      updated: [...state.updated, action.collaborator]
    });
  }),
  on(updateCollaboratorError, (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.message,
    };
  }),
  on(updateCollaborators, (state, action) => ({
      ...state,
      loading: true,
      error: null,
      toUpdate: action.collaborators
    })
  ),
  on(updateCollaboratorsSuccess, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    toUpdate: [],
    updated: [],
  })),
  on(updateCollaboratorsError, (state, action) => ({
    ...state,
    loading: false,
    error: action.message,
    toUpdate: [],
    updated: [],
  })),
);
