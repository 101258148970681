import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SelectVersionComponent } from './select-version.component';

@NgModule({
  declarations: [ SelectVersionComponent ],
  imports: [
    CommonModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
  ],
  exports: [ SelectVersionComponent ],
})
export class SelectVersionModule {
}
