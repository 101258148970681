import { Component, OnDestroy, OnInit } from '@angular/core';
import { ImportScenarioDialogComponent } from '../../../scenario-library/import-scenario-dialog/import-scenario-dialog.component';
import { combineLatestWith, filter, map, take } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  deletePortfolio,
  deletePortfolioScenarios,
  downloadPortfolioExport,
  findPortfolio,
  importPortfolioScenarios
} from '../../store/portfolio.actions';
import { select, Store } from '@ngrx/store';
import { selectPortfolio, selectPortfolioLoading } from '../../store/portfolio.selectors';
import { Portfolio } from '../../models/portfolio.model';
import { PublishedScenarioColumnKeys } from '../../../scenario-library/scenario-library-table/published-scenario-table.data';
import { AppState } from '../../../store';
import { selectedVersion } from '../../../store/versions-data/store';
import { Version } from '../../../store/versions-data/models/versions-data.model';

@Component({
  selector: 'app-portfolio-detail',
  templateUrl: './portfolio-detail.component.html',
  styleUrls: [ './portfolio-detail.component.scss' ]
})
export class PortfolioDetailComponent implements OnInit, OnDestroy {

  private readonly subscription = new Subscription();
  displayedColumns: PublishedScenarioColumnKeys[] = [
    'name',
    'reference',
    'packagingType',
    'baseMaterialType',
    'productType',
    'statusType',
    'productionCountry',
    'delete'
  ];

  dialogRef: MatDialogRef<ImportScenarioDialogComponent>;
  portfolioId$: Observable<string>;
  portfolio$: Observable<Portfolio>;
  loading$: Observable<boolean>;
  selectedVersion: Version;

  constructor(
    private readonly dialog: MatDialog,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store<AppState>,
    private readonly router: Router
  ) {
  }

  ngOnInit(): void {
    this.portfolioId$ = this.activatedRoute.params.pipe(map((params: Params) => params?.portfolioId));
    this.portfolio$ = this.store.pipe(select(selectPortfolio));
    this.loading$ = this.store.pipe(select(selectPortfolioLoading));
    this.subscription.add(
      this.store.pipe(select(selectedVersion)).subscribe((version) => {
        this.selectedVersion = version;
      })
    );
    this.subscription.add(
      this.portfolioId$.subscribe((id) => this.store.dispatch(findPortfolio({ id })))
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  goBack(): void {
    this.router.navigate([ '/portfolios' ]);
  }

  importScenarios() {
    this.dialogRef = this.dialog.open(ImportScenarioDialogComponent, { width: '80%' });
    this.subscription.add(
      this.dialogRef
        .afterClosed()
        .pipe(
          filter(v => !!v && !!v?.length),
          map(s => s.map(r => r.id)),
          combineLatestWith(this.portfolioId$)
        )
        .subscribe(([ publishedScenarioIds, id ]) => {
          return this.store.dispatch(importPortfolioScenarios({ id, imports: { publishedScenarioIds } }));
        }),
    );
  }

  async handleExport() {
    const id: string = await firstValueFrom(this.portfolioId$);
    this.store.dispatch(downloadPortfolioExport({ id, versionHash: this.selectedVersion.hash }));
  }

  async handleDelete() {
    const id: string = await firstValueFrom(this.portfolioId$);
    this.store.dispatch(deletePortfolio({ id }));
  }

  async handleDeleteScenario(scenarioId: string) {
    const id: string = await firstValueFrom(this.portfolioId$);
    this.store.dispatch(deletePortfolioScenarios({ id, scenariosIds: [ scenarioId ] }));
  }
}

