import { createFeatureSelector, createSelector } from '@ngrx/store';
import { STATISTICS_DATA_ID, statisticsDataFeatureKey, StatisticsDataState } from './statistics-data.reducer';

export const selectStatisticsDataFeatureState = createFeatureSelector<StatisticsDataState>(statisticsDataFeatureKey);

export const selectStatisticsData = createSelector(
  selectStatisticsDataFeatureState,
  (state: StatisticsDataState) => {
    return state.entities[STATISTICS_DATA_ID];
  }
);
