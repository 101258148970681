import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  addTertiaryPackaging,
  addTertiaryPackagingSuccess,
  createFirstTertiaryPackaging,
  createFirstTertiaryPackagingSuccess,
  deleteAll,
  deleteAllSuccess,
  deleteTertiaryPackaging,
  deleteTertiaryPackagingSuccess,
  tertiaryPackagingError,
  updateTertiaryPackaging,
  updateTertiaryPackagingSuccess,
} from './tertiary-packaging.actions';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { Packaging, PackagingLevel } from '../../../models/packaging.models';
import { of } from 'rxjs';
import { PackagingDataService } from '../../../services/packaging.data-service';
import * as fromScenario from '../../scenario';
import { AppState } from '../../../../../store';
import { select, Store } from '@ngrx/store';
import { addTertiaryPalletizationPackaging } from '../tertiary-palletization';
import * as fromComponent from '../../component';
import { selectByLevelAndScenarioId } from '../packaging.selectors';


@Injectable()
export class TertiaryPackagingEffects {

  createFirstTertiaryPackaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFirstTertiaryPackaging),
      switchMap((action: { type: string, scenarioId: string }) => {
          const p: Packaging = {
            scenarioId: action.scenarioId,
            level: PackagingLevel.TERTIARY_BOX,
          } as Packaging;
          return this.tertiaryPackagingDataService.create(p)
            .pipe(
              mergeMap((packaging: Packaging) => [
                createFirstTertiaryPackagingSuccess({ packaging }),
                addTertiaryPalletizationPackaging({ scenarioId: action.scenarioId }),
              ]),
              catchError(() => of(tertiaryPackagingError({ message: 'Something went wrong when adding new tertiary packaging' }))),
            );
        },
      ),
    ),
  );

  addTertiaryPackaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addTertiaryPackaging),
      switchMap((action: { type: string, scenarioId: string }) => {
          const p: Packaging = {
            scenarioId: action.scenarioId,
            level: PackagingLevel.TERTIARY_BOX,
          } as Packaging;
          return this.tertiaryPackagingDataService.create(p)
            .pipe(
              mergeMap((packaging: Packaging) => [
                addTertiaryPackagingSuccess({ packaging }),
                fromScenario.getScenario({ id: action.scenarioId }),
              ]),
              catchError(() => of(tertiaryPackagingError({ message: 'Something went wrong when adding new tertiary packaging' }))),
            );
        },
      ),
    ),
  );

  updateTertiaryPackaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTertiaryPackaging.type),
      map((action: { type: string, packaging: Packaging }) => action.packaging),
      switchMap((pp: Packaging) => this.tertiaryPackagingDataService.update(pp)
        .pipe(
          map((packaging: Packaging) => updateTertiaryPackagingSuccess({ packaging })),
          catchError(() => of(tertiaryPackagingError({ message: 'Something went wrong when updating tertiary packaging' }))),
        ),
      ),
    ),
  );

  deleteTertiaryPackaging$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTertiaryPackaging),
      switchMap((action: { type: string, id: string, scenarioId: string }) =>
        this.tertiaryPackagingDataService.delete(action.id)
          .pipe(
            mergeMap((packagingId: string) => [
              deleteTertiaryPackagingSuccess({ id: packagingId }),
              fromScenario.getScenario({ id: action.scenarioId }),
              fromComponent.deleteAll({ packagingId }),
            ]),
            catchError(() => of(tertiaryPackagingError({ message: 'Something went wrong when deleting tertiary packaging' }))),
          ),
      ),
    ),
  );

  deleteAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAll),
      switchMap((action: { scenarioId: string }) => {
        return this.store.pipe(
          select(selectByLevelAndScenarioId(action.scenarioId, PackagingLevel.TERTIARY_BOX)),
          filter((packaging: Packaging) => !!packaging),
          mergeMap((packaging: Packaging) => [
            fromComponent.deleteAll({ packagingId: packaging.id }),
            deleteAllSuccess({ scenarioId: action.scenarioId }),
          ]),
        );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly tertiaryPackagingDataService: PackagingDataService,
    private readonly store: Store<AppState>) {}

}
