import { Injectable } from '@angular/core';
import { WasteStreamAssessmentDto } from '../../../api/dtos/__generated__/wasteStreamAssessmentDto';
import { WasteStreamAssessment } from '../models/waste-stream-assessment.model';
import { AbstractMapper } from '../../abstract-mapper';
import { EntityReferenceMapperService } from './entity-reference-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class WasteStreamAssessmentMapperService extends AbstractMapper<WasteStreamAssessmentDto, WasteStreamAssessment> {

  constructor(private readonly entityReferenceMapper: EntityReferenceMapperService) {
    super();
  }

  from(dto: WasteStreamAssessmentDto): WasteStreamAssessment | undefined {
    if (!dto) {
      return;
    }
    const {
      recycling,
      incinerationWithEnergyRecovery,
      functionalUnit,
      disposal,
      scenario,
    } = dto;

    return {
      recycling,
      incinerationWithEnergyRecovery,
      functionalUnit: functionalUnit,
      disposal,
      scenario: this.entityReferenceMapper.from(scenario),
    } as WasteStreamAssessment;
  }
}
