import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WashingProcessDataService } from '../../services/washing-process.data-service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  addWashingProcess,
  addWashingProcessSuccess,
  deleteWashingProcess,
  deleteWashingProcessSuccess,
  washingProcessError,
  updateWashingProcess,
  updateWashingProcessSuccess,
} from './washing-process.actions';
import { WashingProcess } from '../../models/washing-process.models';


@Injectable()
export class WashingProcessEffects {

  addWashingProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addWashingProcess.type),
      map((action: { type: string, washingProcess: WashingProcess }) => action.washingProcess),
      switchMap((fp: WashingProcess) => this.washingProcessDataService.create(fp)
        .pipe(
          map((washingProcess: WashingProcess) => addWashingProcessSuccess({ washingProcess })),
          catchError(() => of(washingProcessError({ message: 'Something went wrong when adding new washing-process' }))),
        ),
      ),
    ),
  );

  updateWashingProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateWashingProcess.type),
      map((action: { type: string, washingProcess: WashingProcess }) => action.washingProcess),
      switchMap((fp: WashingProcess) => this.washingProcessDataService.update(fp)
        .pipe(
          map((washingProcess: WashingProcess) => updateWashingProcessSuccess({ washingProcess })),
          catchError(() => of(washingProcessError({ message: 'Something went wrong when updating washing-process' }))),
        ),
      ),
    ),
  );

  deleteWashingProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteWashingProcess.type),
      map((action: { type: string, id: string }) => action.id),
      switchMap((id: string) => this.washingProcessDataService.delete(id)
        .pipe(
          map((index: string) => deleteWashingProcessSuccess({ id: index })),
          catchError(() => of(washingProcessError({ message: 'Something went wrong when deleting washing-process' }))),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly washingProcessDataService: WashingProcessDataService) {}
}
