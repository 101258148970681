import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import {
  addConversionProcess,
  addConversionProcessSuccess,
  conversionProcessError,
  deleteAll,
  deleteConversionProcess,
  deleteConversionProcessSuccess,
  getConversionProcessesSuccess,
  updateConversionProcess,
  updateConversionProcessSuccess,
  updateElectricityCountry,
  updateElectricityType,
} from './conversion-process.actions';
import { ConversionProcess } from '../../models/conversion-process.model';


export interface ConversionProcessState extends EntityState<ConversionProcess> {
  loading: boolean;
  lastCreated: ConversionProcess | null;
  error: string | null;
}

export const adapter: EntityAdapter<ConversionProcess> = createEntityAdapter<ConversionProcess>();

export const initialState: ConversionProcessState = adapter.getInitialState({
  loading: false,
  lastCreated: null,
  error: null,
});

const reducer = createReducer(
  initialState,
  on(
    addConversionProcess,
    updateConversionProcess,
    deleteConversionProcess, (state: ConversionProcessState, _) =>
      ({ ...state, loading: true, error: null, lastCreated: null }),
  ),
  on(getConversionProcessesSuccess, (state, action) =>
    adapter.upsertMany(action.conversionProcesses, { ...state, loading: false, error: null }),
  ),
  on(addConversionProcessSuccess,
    (state, action) =>
      adapter.addOne(action.conversionProcess, {
        ...state,
        loading: false,
        error: null,
        lastCreated: action.conversionProcess,
      }),
  ),
  on(updateConversionProcessSuccess, (state, action) => {
    const { conversionProcess } = action;
    const update: Update<ConversionProcess> = {
      id: conversionProcess.id,
      changes: conversionProcess,
    };
    return adapter.updateOne(update, { ...state, loading: false, error: null });
  }),
  on(deleteConversionProcessSuccess, (state, action) => {
    const { id } = action;
    return adapter.removeOne(id, { ...state, loading: false, error: null });
  }),
  on(conversionProcessError, (state, action) =>
    ({ ...state, loading: false, error: action.message })),
  on(updateElectricityCountry, (state, action) => {
    const { id, electricityCountry } = action;
    const update: Update<ConversionProcess> = { id, changes: { country: electricityCountry } };
    return adapter.updateOne(update, state);
  }),
  on(updateElectricityType, (state, action) => {
    const { id, electricityType } = action;
    const update: Update<ConversionProcess> = { id, changes: { electricity: electricityType } };
    return adapter.updateOne(update, state);
  }),
  on(deleteAll, (state: ConversionProcessState, action: { type: string, materialId: string }) => {
    const { materialId } = action;
    const ids: string[] = Object.values(state.entities)
      .filter((cp: ConversionProcess) => cp.materialId === materialId)
      .map((cp: ConversionProcess) => cp.id);
    return adapter.removeMany(ids, state);
  }),
);

export function conversionProcessReducer(state: ConversionProcessState | null, action: Action) {
  return reducer(state, action);
}

