import {Pipe, PipeTransform} from '@angular/core';
import {Collaborator} from '../../collaborator/collaborator.model';

@Pipe({
  name: 'collaboratorRole',
})
export class CollaboratorRolePipe implements PipeTransform {

  transform(collaborator: Collaborator, ...args: unknown[]): string {
    return collaborator?.isAdmin ? 'admin' : 'user';
  }

}
