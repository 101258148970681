import { Injectable } from '@angular/core';
import { AbstractMapper } from '../../abstract-mapper';
import { EntityReferenceMapperService } from './entity-reference-mapper.service';
import { LCAIndicator } from '../models/lca-indicator.model';
import { PackagingLevel } from '../../modeling/models/packaging.models';
import { GHGIndicator } from '../models/ghg-indicator.model';
import { DetailItemAssessmentDto } from '../../../api/dtos/__generated__/detailItemAssessmentDto';
import { DetailItemAssessment } from '../models/detail-item-assessment.model';

@Injectable({
  providedIn: 'root',
})
export class DetailItemAssessmentMapperService extends AbstractMapper<DetailItemAssessmentDto & {
  overrideIndicator?: GHGIndicator
}, DetailItemAssessment> {

  constructor(private readonly entityReferenceMapper: EntityReferenceMapperService) {
    super();
  }

  from(dto: DetailItemAssessmentDto & { overrideIndicator?: GHGIndicator }): DetailItemAssessment | undefined {
    if (!dto) {
      return;
    }

    const {
      indicator,
      functionalUnit,
      endOfLifeImpact,
      downstreamLogisticsImpact,
      upstreamLogisticsImpact,
      processesImpact,
      rawMaterialProductionImpact,
      item,
      component,
      level,
      overrideIndicator,
    } = dto;

    return {
      indicator: overrideIndicator ?? indicator as LCAIndicator,
      functionalUnit,
      endOfLifeImpact,
      downstreamLogisticsImpact,
      upstreamLogisticsImpact,
      processesImpact,
      rawMaterialProductionImpact,
      item,
      component: this.entityReferenceMapper.from(component),
      level: level as PackagingLevel,
    } as DetailItemAssessment;
  }
}
