import { Pipe, PipeTransform } from '@angular/core';
import { Collaborator } from './collaborator.model';

@Pipe({
  name: 'initials',
})
export class InitialsPipe implements PipeTransform {

  transform(value: Collaborator, ...args: unknown[]): string | undefined {
    if (!value) {
      return;
    }
    const { firstname, lastname } = value;
    return `${firstname?.charAt(0)?.toUpperCase() ?? ''}${lastname?.charAt(0)?.toUpperCase() ?? ''}`;
  }

}
