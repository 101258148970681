import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ScenarioLibraryDataService } from '../scenario-library.data-service';
import { MatTableDataSource } from '@angular/material/table';
import { PublishedScenario } from '../models/published-scenario.model';
import { Subscription } from 'rxjs';
import { CollaboratorDataService } from '../../collaborator/collaborator.data-service';
import { switchMap, tap } from 'rxjs/operators';
import { PublishedScenarioTableDataMapperService } from '../scenario-library-mappers/published-scenario-table-data-mapper.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { PublishedScenarioColumnKeys } from './published-scenario-table.data';
import { publishedScenarioSortingDataAccessor } from './published-scenario-sorting-data-accessor';

@Component({
  selector: 'app-scenario-library-table',
  templateUrl: './scenario-library-table.component.html',
  styleUrls: [ './scenario-library-table.component.scss' ],
})
export class ScenarioLibraryTableComponent implements OnInit, OnDestroy, AfterViewInit {

  private readonly subscription = new Subscription();
  dataSource: MatTableDataSource<PublishedScenario>;
  selection = new SelectionModel<PublishedScenario>(true, []);

  @Input() displayedColumns: PublishedScenarioColumnKeys[] = [];
  @Input() dialog = false;
  isAdmin: boolean;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private readonly scenarioLibraryDataService: ScenarioLibraryDataService,
    private readonly collaboratorDataService: CollaboratorDataService,
    private readonly tableDataMapperService: PublishedScenarioTableDataMapperService,
  ) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<PublishedScenario>([]);
    this.dataSource.sortingDataAccessor = publishedScenarioSortingDataAccessor;
    const isAdmin$ = this.collaboratorDataService.isAdmin();
    const scenarios$ = isAdmin$.pipe(
      tap(admin => this.isAdmin = admin),
      switchMap(admin => {
        if (admin) {
          return this.scenarioLibraryDataService.getAll();
        }
        return this.scenarioLibraryDataService.getApproved();
      }),
    );

    this.subscription.add(
      scenarios$.subscribe(scenarios => {
        this.dataSource.data = scenarios;
      }),
    );

    this.dataSource.filterPredicate = this.filterPredicate();
  }


  trackBy(row: PublishedScenario): string {
    return row.id;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach((row: PublishedScenario) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(scenario?: PublishedScenario): string {
    if (!scenario) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(scenario) ? 'deselect' : 'select'} scenario ${scenario.id}`;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private filterPredicate() {
    return (data: PublishedScenario, search: string): boolean => {
      if (!data) {
        return false;
      }
      const scenario = this.tableDataMapperService.from(data);
      let match = false;
      Object.values(scenario)
        .filter(value => !!value)
        .forEach(value => match = match || value.toLowerCase().includes(search));
      return match;
    };
  }
}


