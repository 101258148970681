import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-made-by',
  template: `
    <span>A webtool developed by</span>
    <img id="quantis-logo" src="assets/quantis-logo.png"/>
  `,
  styleUrls: [ './made-by.component.scss']
})
export class MadeByComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
