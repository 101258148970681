import { Injectable } from '@angular/core';
import { ConversionProcessDto } from '../../../api/dtos/__generated__/conversionProcessDto';
import { ConversionProcess } from '../models/conversion-process.model';
import { ConversionProcessTypeDto } from '../../../api/dtos/__generated__/conversionProcessTypeDto';
import { ConversionProcessType } from '../../../store/reference-data/models/reference-data.model';

@Injectable({
  providedIn: 'root',
})
export class ConversionProcessMapperService {

  constructor() { }

  mapConversionProcessFromConversionProcessDto(dto: ConversionProcessDto): ConversionProcess {
    const {
      id,
      materialId,
      electricityType,
      electricityCountry,
      conversionProcessType,
    } = dto;

    return {
      id,
      materialId,
      electricity: electricityType,
      country: {
        label: electricityCountry?.label,
        value: electricityCountry?.value,
      },
      conversionProcessType: this.mapConversionProcessType(conversionProcessType),
    };
  }

  mapConversionProcessListFromConversionProcessDtoList(dtos: ConversionProcessDto[]): ConversionProcess[] {
    return dtos.map((dto: ConversionProcessDto) => this.mapConversionProcessFromConversionProcessDto(dto));
  }

  mapConversionProcessDtoFromConversionProcess(cp: ConversionProcess): ConversionProcessDto {
    const {
      country,
      electricity,
      conversionProcessType,
      id,
      materialId,
    } = cp;

    return {
      id,
      materialId,
      conversionProcessType: this.mapConversionProcessTypeDto(conversionProcessType),
      electricityCountry: country,
      electricityType: electricity,
    } as ConversionProcessDto;
  }

  private mapConversionProcessType(dto: ConversionProcessTypeDto): ConversionProcessType {
    const { label, value } = dto;
    return { label, value } as ConversionProcessType;
  }

  private mapConversionProcessTypeDto(type: ConversionProcessType): ConversionProcessTypeDto {
    const { label, value } = type;
    return { label, value } as ConversionProcessTypeDto;
  }
}
