import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorDto } from '../api/dtos/__generated__/errorDto';
import { LicenseStatusDialogService } from './license-status-dialog/license-status-dialog.service';

@Injectable()
export class LicenseStatusExceptionInterceptor implements HttpInterceptor {

  constructor(private readonly licenseStatusDialogService: LicenseStatusDialogService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((err: any) => {
          if (this.licenseStatusDialogService.accept(err)) {
            this.licenseStatusDialogService.handle(err.error as ErrorDto);
            return of(null);
          }
          return throwError(err);
        })
      );
  }
}
