import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromVersion from '../../store/versions-data/store';
import {AppState} from '../../store';
import {Observable, Subscription} from 'rxjs';
import {Version, Versions} from '../../store/versions-data/models/versions-data.model';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionChange, SelectionModel} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

@Component({
    selector: 'app-set-default-version-table',
    templateUrl: './set-default-version-table.component.html',
    styleUrls: ['./set-default-version-table.component.scss']
})
export class SetDefaultVersionTableComponent implements OnInit, OnDestroy, AfterViewInit {

    subscription = new Subscription();
    dataSource: MatTableDataSource<Version>;
    versions$: Observable<Versions>;
    displayedColumns = ['default', 'version', 'releaseDate', 'backgroundDatabase', 'description'];
    selection = new SelectionModel<Version>(false, []);
    defaultVersion: Version;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private readonly store: Store<AppState>) {
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        this.versions$ = this.store.pipe(select(fromVersion.selectVersionsData));

        this.subscription.add(this.versions$.subscribe((versions: Versions) => {
            this.dataSource = new MatTableDataSource<Version>([]);
            this.dataSource.sortingDataAccessor = (item: Version, sortHeaderId: string): string | number => {
                return item.displayName;
            };
            this.defaultVersion = versions.defaultVersion;
            this.selection = new SelectionModel<Version>(false, [
                versions.availableVersions.find((v: Version) => v.hash === versions.defaultVersion.hash)
            ]);
            this.dataSource.data = versions.availableVersions;
        }));

        this.selection.changed.subscribe((change: SelectionChange<Version>) => {
            const [version, others = null] = change.added;
            this.store.dispatch(fromVersion.setDefaultVersion({version}));
        });
    }

    trackBy(index: number, version: Version): string {
        return version.hash;
    }

    setDefaultVersion(version: Version): void {
        this.selection.toggle(version);
        if (this.selection.isSelected(version)) {
            this.store.dispatch(fromVersion.setDefaultVersion({version}));
        }
    }

    doNothing() {
    }

}
