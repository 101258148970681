import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppState } from '../../../../store';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { RecyclingRateType } from '../../../../project/modeling/models/material.models';
import { EndOfLife } from '../../models/end-of-life';
import { updateEndOfLife } from '../../store/end-of-life.actions';
import {isLockedByMe} from '../../../../project/modeling/store/project';

@Component({
  selector: 'app-end-of-life',
  templateUrl: 'end-of-life.component.html',
  styleUrls: [ 'end-of-life.component.scss' ],
})
export class EndOfLifeComponent implements OnInit, OnDestroy {
  private _endOfLife: EndOfLife & { isLockedByMe: boolean};

  DEFAULT = RecyclingRateType.DEFAULT;
  CUSTOM = RecyclingRateType.CUSTOM;

  @Input()
  get endOfLife(): EndOfLife & { isLockedByMe: boolean} {
    return this._endOfLife;
  }

  set endOfLife(value: EndOfLife & { isLockedByMe: boolean}) {
    this._endOfLife = value;
    this.setForm(value);
  }

  subscription: Subscription = new Subscription();
  form: FormGroup;
  recyclingRateType: RecyclingRateType;
  defaultRecyclingRateIsDefined: boolean;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store<AppState>,
  ) {
    this.form = this.formBuilder.group({
      recyclable: null,
      compostable: null,
      recyclingRate: [
        null,
        {
          updateOn: 'blur',
          validators: [ Validators.min(0), Validators.max(100) ]
        } ],
    });
  }

  ngOnInit(): void {
    this.subscription.add(this.recyclableChangeListener());
    this.subscription.add(this.compostableChangeListener());
    this.subscription.add(this.recyclingRateChangeListener());
  }

  setForm(endOfLife: EndOfLife & { isLockedByMe: boolean}): void {
    this.recyclingRateType = endOfLife.recyclingRateType ?? this.DEFAULT;
    let recyclingRateValue = endOfLife.recyclable ? endOfLife.recyclingRate : 0.0;
    this.defaultRecyclingRateIsDefined = endOfLife?.defaultRecyclingRate != null;
    if (endOfLife.recyclable && this.recyclingRateType === this.DEFAULT) {
      recyclingRateValue = endOfLife.defaultRecyclingRate;
    }
    this.form.setValue({
      recyclable: endOfLife.recyclable ?? false,
      compostable: endOfLife.compostable ?? false,
      recyclingRate: recyclingRateValue?.toFixed(2) ?? 0.00,
    }, { emitEvent: false });

    const enableRecyclingRate = this.shouldEnableRecyclingRateInput(endOfLife.recyclable, this.recyclingRateType);
    if (enableRecyclingRate) {
      this.form.controls.recyclingRate.enable({ emitEvent: false });
    } else {
      this.form.controls.recyclingRate.disable({ emitEvent: false });
    }

    if (endOfLife.isLockedByMe) {
      this.form.enable({ emitEvent: false });
    } else {
      this.form.disable({ emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  handleChange(newValue: EndOfLife) {
    this.store.dispatch(updateEndOfLife({ id: this.endOfLife.id, endOfLife: newValue }));
  }

  private compostableChangeListener(): Subscription {
    return this.form.controls.compostable.valueChanges
      .subscribe((compostable: boolean) => this.handleChange({ ...this.endOfLife, compostable }));
  }

  private recyclingRateChangeListener(): Subscription {
    return this.form.controls.recyclingRate.valueChanges
      .subscribe((recyclingRate: number) => this.handleChange({ ...this.endOfLife, recyclingRate }));
  }

  private recyclableChangeListener(): Subscription {
    return this.form.controls.recyclable.valueChanges
      .subscribe((recyclable: boolean) => this.handleChange({ ...this.endOfLife, recyclable }));
  }

  updateUseOfDefaultRecyclingRate(event: MatButtonToggleChange): void {
    const recyclingRateType = event.value as RecyclingRateType;
    this.recyclingRateType = recyclingRateType;
    this.handleChange({ ...this.endOfLife, recyclingRateType });
    // if (recyclingRateType === 'USE_DEFAULT') {
    //   this.form.controls.recyclingRate.disable({ emitEvent: false });
    // } else {
    //   this.form.controls.recyclingRate.enable({ emitEvent: false });
    //   this.form.controls.recyclingRate.setValue(this.endOfLife.recyclingRate);
    // }
  }

  get shouldDisplayRecyclingRateWarning(): boolean {
    const isRecyclable = this.form.controls.recyclable.value;
    const useDefault = this.recyclingRateType === RecyclingRateType.DEFAULT;
    return isRecyclable && useDefault && !this.defaultRecyclingRateIsDefined;
  }

  private shouldEnableRecyclingRateInput(recyclable: boolean, recyclingRateType: RecyclingRateType): boolean {
    return !!recyclable && recyclingRateType === this.CUSTOM;
  }
}
