import { NgModule } from '@angular/core';
import { CommonModule, PercentPipe } from '@angular/common';
import { ActionButtonComponent } from './action-button/action-button.component';
import { EqopackIconsModule } from './eqopack-icons.module';
import { FontAwesomeIconsModuleModule } from './font-awesome-icons-module.module';
import { MatButtonModule } from '@angular/material/button';
import { MadeByModule } from './made-by/made-by.module';
import { SnackbarModule } from './snackbar/snackbar.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NanPipe } from './pipes/nan.pipe';
import { ScientificPipe } from './pipes/scientific.pipe';
import { PackagingLevelPipe } from './pipes/packaging-level.pipe';
import { IndicatorLabelPipe } from './pipes/indicator-label.pipe';
import { IndicatorIconPipe } from './pipes/indicator-icon.pipe';
import { FunctionalUnitPipe } from './pipes/functional-unit.pipe';
import { ScenarioSummaryPipe } from './pipes/scenario-summary-pipe';
import { PercentOrNanPipe } from './pipes/percent-or-nan.pipe';
import { SurfacePipe } from './pipes/surface.pipe';
import { GrammagePipe } from './pipes/grammage.pipe';
import { MassPipe } from './pipes/mass.pipe';
import { SearchInputModule } from './search-input/search-input.module';
import {CollaboratorRolePipe} from './pipes/collaborator-role.pipe';
import { ElectricityTypePipe } from './pipes/electricity-type.pipe';


@NgModule({
  declarations: [
    ActionButtonComponent,
    NanPipe,
    ScientificPipe,
    ElectricityTypePipe,
    PackagingLevelPipe,
    IndicatorLabelPipe,
    IndicatorIconPipe,
    FunctionalUnitPipe,
    ScenarioSummaryPipe,
    PercentOrNanPipe,
    SurfacePipe,
    GrammagePipe,
    MassPipe,
    CollaboratorRolePipe,
  ],
  imports: [
    CommonModule,
    EqopackIconsModule,
    FontAwesomeIconsModuleModule,
    MatButtonModule,
    MadeByModule,
    SnackbarModule,
    MatTooltipModule,
    SearchInputModule,
  ],
  exports: [
    ActionButtonComponent,
    EqopackIconsModule,
    FontAwesomeIconsModuleModule,
    MadeByModule,
    SnackbarModule,
    NanPipe,
    ScientificPipe,
    PackagingLevelPipe,
    IndicatorLabelPipe,
    IndicatorIconPipe,
    FunctionalUnitPipe,
    ScenarioSummaryPipe,
    PercentOrNanPipe,
    SurfacePipe,
    MassPipe,
    GrammagePipe,
    SearchInputModule,
    CollaboratorRolePipe,
    ElectricityTypePipe,
  ],
  providers: [
    PackagingLevelPipe,
    NanPipe,
    ScientificPipe,
    PercentPipe,
    PercentOrNanPipe,
  ],
})
export class SharedModule {}
