<h2 mat-dialog-title>Warning</h2>
<mat-dialog-content>
  <div [ngSwitch]="data.code">
    <div *ngSwitchCase="expired()" i18n>
      <p>Your access period has expired.</p>
      <p>If you wish to extend it, please, feel free to reach out to us.</p>
    </div>
    <div *ngSwitchCase="notConfigured()" i18n>
      <p>Your tenant has not been configured yet.</p>
      <p>You will be able to access the tool as soon as your tenant configuration is completed.</p>
      <p>For further information, please, feel free to reach out to us.</p>
    </div>
    <div *ngSwitchDefault i18n>
      <p>An error occurred regarding your license status.</p>
      <ul>
        <li>{{data.timestamp}}</li>
        <li>{{data.type}}</li>
        <li>{{data.code}}</li>
        <li>{{data.message}}</li>
      </ul>
      <p>Please send us the information above, so that we can fix it as soon as possible.</p>
    </div>
  </div>
</mat-dialog-content>
