import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import downstreamReducer, { downstreamFeatureKey } from './downstream.reducer';
import { DownstreamEffects } from './downstream.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(downstreamFeatureKey, downstreamReducer),
    EffectsModule.forFeature([ DownstreamEffects ]),
  ],
})
export class DownstreamStoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: DownstreamStoreModule) {
    if (parentModule) {
      throw new Error('DownstreamStoreModule is already loaded');
    }
  }
}
