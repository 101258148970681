import { Pipe, PipeTransform } from '@angular/core';
import { FunctionalUnit } from '../../store/reference-data/models/reference-data.model';

@Pipe({
  name: 'functionalUnit',
})
export class FunctionalUnitPipe implements PipeTransform {

  constructor() {}

  transform(value: FunctionalUnit, ...args: unknown[]): string {
    return value.value;
  }

}
