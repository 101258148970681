import { Injectable } from '@angular/core';
import { AbstractMapper } from '../../../project/abstract-mapper';
import { EndOfLifeDto } from '../../../api/dtos/__generated__/endOfLifeDto';
import { EndOfLife } from '../models/end-of-life';

@Injectable({
  providedIn: 'root',
})
export class EndOfLifeMapperService extends AbstractMapper<EndOfLifeDto, EndOfLife> {
  constructor() {
    super();
  }

  from(dto: EndOfLifeDto): EndOfLife {
    return { ...dto };
  }

  to(distributionMarket: EndOfLife): EndOfLifeDto {
    return { ...distributionMarket };
  }
}
