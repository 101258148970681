import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DistributionMarket, Transport } from '../models/distribution-market';
import { DistributionMarketFeatureState } from '../store/distribution-market.reducer';
import { selectDistributionMarketLoading, selectDistributionMarkets } from '../store/distribution-market.selectors';
import { findAllDistributionMarkets, updateDistributionMarketTransports } from '../store/distribution-market.actions';

@Component({
  selector: 'app-distribution-markets-transports',
  template: `
    <ng-container *ngFor="let distributionMarket of distributionMarket$|async">
      <app-transports-card [transports]="distributionMarket.transports"
                           [country]="distributionMarket.country.value"
                           [isLockedByMe]="isLockedByMe"
                           (changeTransports)="handleChange(distributionMarket.id, $event)"></app-transports-card>
    </ng-container>
  `,
  styles: [ `
    :host {
      width: 100%;
    }

    #app-transports-card {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1.25rem;
      margin: 1rem 4rem 1rem 4rem;
    }
  ` ]
})
export class DistributionsMarketsTransportsComponent implements OnInit {

  @Input() scenarioId: string;
  @Input() isLockedByMe: boolean;
  distributionMarket$: Observable<DistributionMarket[]>;
  loading$: Observable<boolean>;

  constructor(
    private readonly store: Store<DistributionMarketFeatureState>
  ) {
  }

  ngOnInit(): void {
    this.distributionMarket$ = this.store.pipe(select(selectDistributionMarkets));
    this.loading$ = this.store.pipe(select(selectDistributionMarketLoading));
    this.store.dispatch(findAllDistributionMarkets({ scenarioId: this.scenarioId }));
  }

  handleChange(distributionMarketId: string, transports: Transport[]) {
    this.store.dispatch(updateDistributionMarketTransports({ id: distributionMarketId, transports }));
  }
}
