import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProjectDataService } from '../../services/project.data-service';
import {
  addProject,
  addProjectSuccess,
  deleteProject,
  deleteProjectSuccess,
  duplicateProject,
  duplicateProjectSuccess,
  getProject,
  getProjectFilters,
  getProjectFiltersSuccess,
  getProjects,
  getProjectsSuccess,
  getProjectSuccess,
  projectError,
  releaseProjectLock,
  releaseProjectLockSuccess,
  updateProject,
  updateProjectScenarios,
  updateProjectScenariosSuccess,
  updateProjectSuccess,
} from './project.actions';
import * as fromComponent from '../component/';
import * as fromComponentTransport from '../component-transport';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Page } from '../../../../shared/page.model';
import { Project, ProjectSearchParams } from '../../models/project.model';
import { ComponentTransport, PackagingComponent } from '../../models/component.models';
import { FinishingProcess } from '../../models/finishing-process.models';
import * as fromFinishingProcess from '../finishing-process/finishing-process.actions';
import * as fromConversionProcess from '../conversion-process/conversion-process.actions';
import * as fromWashingProcess from '../washing-process/washing-process.actions';
import * as fromMaterial from '../material/material.actions';
import * as fromMaterialTransport from '../material-transport/material-transport.actions';
import * as fromPrimary from '../packaging/primary/primary-packaging.actions';
import * as fromSecondary from '../packaging/secondary/secondary-packaging.actions';
import * as fromTertiary from '../packaging/tertiary/tertiary-packaging.actions';
import * as fromTertiaryPalletization from '../packaging/tertiary-palletization/tertiary-palletization-packaging.actions';
import * as fromScenario from '../scenario/scenario.actions';
import { Material, MaterialTransport } from '../../models/material.models';
import { Packaging } from '../../models/packaging.models';
import { Scenario } from '../../models/scenario.model';
import { ProjectLockDataService } from '../../../project-lock/project-lock.data-service';
import { ProjectLock } from '../../../project-lock/project-lock.model';
import { NotificationService } from '../../../../core/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { WashingProcess } from '../../models/washing-process.models';
import { ConversionProcess } from '../../models/conversion-process.model';


@Injectable({
  providedIn: 'root',
})
export class ProjectEffects {

  getProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjects),
      map((action: { type: string, params?: ProjectSearchParams }) => action?.params),
      switchMap((params?: ProjectSearchParams) => this.projectDataService.get(params)
        .pipe(
          mergeMap((results: {
            page: Page<Project>
          }) => {
            const { page } = results;
            return [ getProjectsSuccess({ page }) ];
          }),
          catchError((error: HttpErrorResponse) => {
            this.notificationService.warn('Could not fetch projects', error);
            return of(projectError({ message: 'Something went wrong when loading projects' }));
          })))));

  getProjectFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjectFilters),
      switchMap(() => this.projectDataService.findProjectFilters().pipe(
        map((projectFilters) => getProjectFiltersSuccess({ projectFilters })),
        catchError((error) => {
          this.notificationService.warn('Could not fetch projects', error);
          return of(projectError({ message: 'Something went wrong when loading project filters' }));
        })
      ))));

  addProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProject),
      map((action: { type: string, project: Project }) => action.project),
      switchMap((p: Project) => {
        return this.projectDataService.create(p)
          .pipe(
            mergeMap((project: Project) => {
              return [
                addProjectSuccess({ project }),
                fromScenario.addScenario({ projectId: project.id }),
              ];
            }),
            catchError((error) => {
              this.notificationService.warn('Could create project', error);
              return of(projectError({ message: 'Something went wrong when creating a new project' }));
            }),
          );
      })));

  updateProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProject),
      map((action: { type: string, project: Project }) => action.project),
      switchMap((p: Project) => this.projectDataService.update(p)
        .pipe(
          map((project: Project) => updateProjectSuccess({ project })),
          catchError((error) => {
            this.notificationService.warn('Could not update project', error);
            return of(projectError({ message: 'Something went wrong when updating project' }));
          }),
        ))));

  deleteProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteProject),
      switchMap((action: { type: string, id: string, andGetProjectsWith?: ProjectSearchParams }) =>
        this.projectDataService.delete(action.id)
          .pipe(
            mergeMap((projectId) => [
                deleteProjectSuccess({ id: projectId }),
                getProjects({ params: action.andGetProjectsWith }),
              ],
            ),
            catchError((err: HttpErrorResponse) => {
                if (this.isLocked(err)) {
                  this.notificationService.warn('This project is currently locked by someone else, please try later');
                } else {
                  this.notificationService.warn('Something went wrong when deleting project');
                }
                return of(projectError({ message: 'Something went wrong when deleting project' }));
              },
            )),
      )));

  duplicateProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(duplicateProject),
      switchMap((action: { type: string, id: string, andGetProjectsWith?: ProjectSearchParams }) =>
        this.projectDataService.duplicate(action.id)
          .pipe(
            mergeMap((response) => [
                duplicateProjectSuccess({ project: response.project }),
                getProjects({ params: action.andGetProjectsWith }),
              ],
            ),
            catchError((err: HttpErrorResponse) => {
              if (this.isLocked(err)) {
                this.notificationService.warn('This project is currently locked by someone else, please try later');
              } else {
                this.notificationService.warn('Something went wrong when duplicating project');
              }
              return of(projectError({ message: 'Something went wrong when duplicating project' }));
            }),
          )),
    ));

  getProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProject),
      map((action: { type: string, id: string }) => action.id),
      switchMap((id: string): Observable<ProjectLock | string> => this.projectLockDataService.lock(id).pipe(
        catchError(() => {
          return of(id);
        })),
      ),
      switchMap((lockOrProjectId: ProjectLock | string) => {
        const id = typeof lockOrProjectId === 'string' ? lockOrProjectId : (lockOrProjectId as ProjectLock).projectId;
        return this.projectDataService.getById(id).pipe(
          switchMap(results => this.storeProjectTree(results, typeof lockOrProjectId === 'string' ? null : lockOrProjectId as ProjectLock)),
          catchError(() => {
            this.notificationService.warn('Could not load the requested project');
            return of(projectError({ message: 'Could not load the requested project' }));
          }),
        );
      }),
    ),
  );

  releaseProjectLock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(releaseProjectLock),
      switchMap((action: { type: string, projectId: string }) => this.projectLockDataService.unlock(action.projectId)),
      map(() => releaseProjectLockSuccess()),
      catchError((err: Error) => of(projectError({ message: err.message }))),
    ));

  updateProjectScenarios$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProjectScenarios),
      switchMap((action) => this.projectDataService.updateProjectScenarios(action.projectId, action.scenarios)
        .pipe(
          mergeMap(() => [
            updateProjectScenariosSuccess(),
            getProject({ id: action.projectId })
          ]),
          catchError((error) => {
            this.notificationService.warn('Could not update project scenarios', error);
            return of(projectError({ message: 'Something went wrong when updating projects scenarios' }));
          })
        )
      )
    ));

  constructor(
    private readonly actions$: Actions,
    private readonly projectDataService: ProjectDataService,
    private readonly projectLockDataService: ProjectLockDataService,
    private readonly notificationService: NotificationService,
  ) {
  }

  private isLocked(err: HttpErrorResponse): boolean {
    const LOCKED = 423;
    return err.status === LOCKED;
  }

  private storeProjectTree(
    results: {
      project: Project,
      components: PackagingComponent[],
      componentTransports: ComponentTransport[],
      finishingProcesses: FinishingProcess[],
      conversionProcesses: ConversionProcess[],
      washingProcesses: WashingProcess[],
      materials: Material[],
      materialTransports: MaterialTransport[],
      primaryPackaging: Packaging[],
      secondaryPackaging: Packaging[],
      tertiaryPackaging: Packaging[],
      tertiaryPalletizationPackaging: Packaging[],
      scenarios: Scenario[],
    },
    projectLock: ProjectLock,
  ) {
    const {
      project,
      components,
      componentTransports,
      finishingProcesses,
      conversionProcesses,
      washingProcesses,
      materials,
      materialTransports,
      primaryPackaging,
      secondaryPackaging,
      tertiaryPackaging,
      tertiaryPalletizationPackaging,
      scenarios,
    } = results;
    return [
      getProjectSuccess({ project, projectLock }),
      fromComponent.getComponentsSuccess({ components }),
      fromComponentTransport.getComponentTransportsSuccess({ componentTransports }),
      fromFinishingProcess.getFinishingProcessesSuccess({ finishingProcesses }),
      fromConversionProcess.getConversionProcessesSuccess({ conversionProcesses }),
      fromWashingProcess.getWashingProcessesSuccess({ washingProcesses }),
      fromMaterial.getMaterialsSuccess({ materials }),
      fromMaterialTransport.getMaterialTransportsSuccess({ materialTransports }),
      fromPrimary.getPrimaryPackagingSuccess({ packaging: primaryPackaging }),
      fromSecondary.getSecondaryPackagingSuccess({ packaging: secondaryPackaging }),
      fromTertiary.getTertiaryPackagingSuccess({ packaging: tertiaryPackaging }),
      fromTertiaryPalletization.getTertiaryPalletizationPackagingSuccess({ packaging: tertiaryPalletizationPackaging }),
      fromScenario.getScenariosSuccess({ scenarios }),
    ];
  }
}
