import { createAction, props } from '@ngrx/store';
import { Statistics } from '../models/statistics-data.model';

export const getStatistics = createAction(
  '[StatisticsData/API] Get Statistics',
);

export const getStatisticsSuccess = createAction(
  '[StatisticsData/API] Get Statistics Success',
  props<{ statistics: Statistics }>(),
);

export const getStatisticsError = createAction(
  '[StatisticsData/API] Get Statistics Error',
  props<{ message: string }>(),
);

