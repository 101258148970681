import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, projectsFeatureKey, ProjectState } from './project.reducer';
import { Project } from '../../models/project.model';
import { Dictionary } from '@ngrx/entity';
import { AppState } from '../../../../store';
import { Pagination } from '../../../../shared/page.model';
import { ProjectLock } from '../../../project-lock/project-lock.model';

export const selectProjectState = createFeatureSelector<AppState, ProjectState>(projectsFeatureKey);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(selectProjectState);

export const isLoading = createSelector(
  selectProjectState,
  (state: ProjectState) => state.loading,
);

export const selectProjectFilters = createSelector(
  selectProjectState,
  (state: ProjectState) => state.projectFilters,
);

export const selectLastCreated = createSelector(
  selectProjectState,
  isLoading,
  ((state: ProjectState, loading: boolean) => !loading ? state.lastCreated : undefined),
);

export const selectProjectById = (id: string) => createSelector(
  selectEntities,
  (projects: Dictionary<Project>) => projects[id],
);

export const selectProjectName = (projectId: string) => createSelector(
  selectProjectById(projectId),
  (project: Project) => project?.name,
);

export const selectPagination = createSelector(
  selectProjectState,
  (state: ProjectState) => state?.pagination,
);

const selectAllSortedByPosition = createSelector(
  selectAll,
  (projects: Project[]) => projects.sort((a, b) => a.position - b.position)
);

export const projectsAndPagination = createSelector(
  selectAllSortedByPosition,
  selectPagination,
  (projects: Project[], pagination: Pagination) => ({ projects, pagination }),
);

export const projectLock = createSelector(
  selectProjectState,
  (state: ProjectState) => state?.lock,
);

export const isLockedByMe = createSelector(
  projectLock,
  (lock: ProjectLock) => lock?.itDoesBelongToMe,
);

export const lockOwner = createSelector(
  projectLock,
  (lock: ProjectLock) => lock?.owner,
);

export const lastChanges = createSelector(
  projectLock,
  (lock: ProjectLock) => lock?.lastAction,
);

export const displayProjectLockDisclaimer = createSelector(
  selectProjectState,
  (state) => state.displayProjectLockDisclaimer,
);
