import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { updateSecondaryPackaging } from './secondary';
import { map, switchMap, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Packaging, PackagingLevel } from '../../models/packaging.models';
import { AppState } from '../../../../store';
import { updatePrimaryPackaging } from './primary';
import { updateTertiaryPackaging } from './tertiary';
import { updateMultiplicity } from './packaging.actions';
import { selectById } from './packaging.selectors';


@Injectable()
export class PackagingEffects {

  updateMultiplicity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMultiplicity),
      switchMap((action: { type: string, id: string, multiplicity: number }) => this.store.pipe(
        select(selectById(action.id)),
        take(1),
        map((packaging: Packaging) => {
          const updated = { ...packaging, multiplicity: action.multiplicity };
          switch (packaging.level) {
            case PackagingLevel.PRIMARY:
              return updatePrimaryPackaging({ packaging: updated });
            case PackagingLevel.SECONDARY:
              return updateSecondaryPackaging({ packaging: updated });
            case PackagingLevel.TERTIARY_BOX:
              return updateTertiaryPackaging({ packaging: updated });
          }
        }),
      )),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
  ) {}

}
