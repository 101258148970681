import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { AssessmentEffects } from './assessment.effects';
import { StoreModule } from '@ngrx/store';
import { assessmentReducer, assessmentsFeatureKey } from './assessment.reducer';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(assessmentsFeatureKey, assessmentReducer),
    EffectsModule.forFeature([ AssessmentEffects ]),
  ],
})
export class AssessmentStoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: AssessmentStoreModule) {
    if (parentModule) {
      throw new Error('AssessmentStoreModule is already loaded');
    }
  }
}
