import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { StatisticsDataDataService } from '../statistics-data.data-service';
import { getStatistics, getStatisticsError, getStatisticsSuccess } from './statistics-data.actions';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Statistics } from '../models/statistics-data.model';

@Injectable({
  providedIn: 'root',
})
export class StatisticsDataEffects {
  getStatisticsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStatistics.type),
      switchMap(() => this.statisticsDataDataService.get()
        .pipe(
          map((statistics: Statistics) => getStatisticsSuccess({ statistics })),
          catchError(() => of(getStatisticsError({message: 'Could not fetch statistics data'}))),
        ))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly statisticsDataDataService: StatisticsDataDataService,
  ) {
  }
}
