export type UnclassifiedIndicator = 'IND_SINGLE_SCORE' | 'IND_WASTE_STREAM' | 'IND_PLASTIC_FOOTPRINT';

export const UnclassifiedIndicator = {
  SINGLE_SCORE: 'IND_SINGLE_SCORE' as UnclassifiedIndicator,
  WASTE_STREAM: 'IND_WASTE_STREAM' as UnclassifiedIndicator,
  PLASTIC_FOOTPRINT: 'IND_PLASTIC_FOOTPRINT' as UnclassifiedIndicator,
  MATERIAL_CIRCULARITY: 'IND_MATERIAL_CIRCULARITY' as UnclassifiedIndicator,
};

export const isSingleScore = (value: string): boolean => {
  if (typeof value !== 'string') {
    return false;
  }
  return UnclassifiedIndicator.SINGLE_SCORE.localeCompare(value.toUpperCase()) === 0;
};

export const isMaterialCircularity = (value: string): boolean => {
  if (typeof value !== 'string') {
    return false;
  }
  return UnclassifiedIndicator.MATERIAL_CIRCULARITY.localeCompare(value.toUpperCase()) === 0;
};
