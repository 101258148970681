import { createAction, props } from '@ngrx/store';
import { PortfolioMetadata } from '../models/portfolio-metadata.model';
import { CreatePortfolio } from '../models/create-portfolio.model';
import { Portfolio } from '../models/portfolio.model';
import { PortfolioImports } from '../models/portfolio-imports';

const PORTFOLIO_API = '[Portfolio/API]';

export const findAllPortfolios = createAction(`${PORTFOLIO_API} Find all portfolios`);
export const findAllPortfoliosSuccess = createAction(
  `${PORTFOLIO_API} Find all portfolios success`,
  props<{ portfolios: PortfolioMetadata[] }>()
);

export const createPortfolio = createAction(`${PORTFOLIO_API} Create portfolio`, props<{ portfolio: CreatePortfolio }>());
export const createPortfolioSuccess = createAction(`${PORTFOLIO_API} Create portfolio success`, props<{ portfolio: Portfolio }>());

export const findPortfolio = createAction(`${PORTFOLIO_API} Find portfolio`, props<{ id: string }>());
export const findPortfolioSuccess = createAction(`${PORTFOLIO_API} Find portfolio success`, props<{ portfolio: Portfolio }>());

export const deletePortfolio = createAction(`${PORTFOLIO_API} Delete portfolio`, props<{ id: string }>());
export const deletePortfolioSuccess = createAction(`${PORTFOLIO_API} Delete portfolio success`);

export const downloadPortfolioExport = createAction(`${PORTFOLIO_API} Download portfolio export`, props<{
  id: string,
  versionHash: string
}>());
export const downloadPortfolioExportSuccess = createAction(`${PORTFOLIO_API} Download portfolio export success`);

export const importPortfolioScenarios = createAction(
  `${PORTFOLIO_API} Import portfolio scenarios`,
  props<{ id: string, imports: PortfolioImports }>()
);
export const importPortfolioScenariosSuccess = createAction(
  `${PORTFOLIO_API} Import portfolio scenarios success`,
  props<{ portfolio: Portfolio }>()
);

export const deletePortfolioScenarios = createAction(
  `${PORTFOLIO_API} Delete portfolio scenarios`,
  props<{ id: string, scenariosIds: string[] }>()
);
export const deletePortfolioScenariosSuccess = createAction(
  `${PORTFOLIO_API} Delete portfolio scenarios success`,
  props<{ portfolio: Portfolio }>()
);

export const portfolioError = createAction(`${PORTFOLIO_API} Error`, props<{ error: string }>());

