import { Injectable } from '@angular/core';
import { EntityReferenceDto } from '../../../api/dtos/__generated__/entityReferenceDto';
import { EntityReference } from '../models/entity-reference.model';
import { AbstractMapper } from '../../abstract-mapper';

@Injectable({
  providedIn: 'root',
})
export class EntityReferenceMapperService extends AbstractMapper<EntityReferenceDto, EntityReference> {

  constructor() {
    super();
  }

  from(dto: EntityReferenceDto): EntityReference | undefined {
    if (!dto) {
      return;
    }
    const { id, name } = dto;
    return { id, name } as EntityReference;
  }
}
