import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { downstreamError, updateDownstream, updateDownstreamSuccess, } from './downstream.actions';
import { DownstreamDataService } from '../services/downstream.data-service';
import { NotificationService } from '../../../core/notification.service';
import { Downstream } from '../models/downstream';

@Injectable()
export class DownstreamEffects {

  updateDownstream$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDownstream),
      switchMap((action: { id: string, downstream: Partial<Downstream> }) => this.downstreamDataService.update(action.downstream)
        .pipe(
          map((downstream: Downstream) => updateDownstreamSuccess({ downstream })),
          catchError(() => {
            this.notificationService.warn('Could not save latest downstream changes');
            return of(downstreamError({ message: 'Something went wrong when updating downstream' }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly downstreamDataService: DownstreamDataService,
    private readonly store: Store<AppState>,
    private readonly notificationService: NotificationService,
  ) {
  }
}
