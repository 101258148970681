import { createAction, props } from '@ngrx/store';
import { Packaging } from '../../../models/packaging.models';

export const addTertiaryPalletizationPackaging = createAction(
  '[Tertiary Palletization Packaging/API] Add Tertiary Palletization Packaging',
  props<{ scenarioId: string }>(),
);

export const addTertiaryPalletizationPackagingSuccess = createAction(
  '[Tertiary Palletization Packaging/API] Add Tertiary Palletization Packaging Success',
  props<{ packaging: Packaging }>(),
);

export const getTertiaryPalletizationPackagingSuccess = createAction(
  '[Tertiary Palletization Packaging/API] Get Tertiary Palletization Packaging Success',
  props<{ packaging: Packaging[] }>(),
);

export const updateTertiaryPalletizationPackaging = createAction(
  '[Tertiary Palletization Packaging/API] Update Tertiary Palletization Packaging',
  props<{ packaging: Packaging }>(),
);

export const updateTertiaryPalletizationPackagingSuccess = createAction(
  '[Tertiary Palletization Packaging/API] Update Tertiary Palletization Packaging Success',
  props<{ packaging: Packaging }>(),
);

export const deleteTertiaryPalletizationPackaging = createAction(
  '[Tertiary Palletization Packaging/API] Delete Tertiary Palletization Packaging',
  props<{ id: string, scenarioId: string }>(),
);

export const deleteTertiaryPalletizationPackagingSuccess = createAction(
  '[Tertiary Palletization Packaging/API] Delete Tertiary Palletization Packaging Success',
  props<{ id: string }>(),
);

export const tertiaryPalletizationPackagingError = createAction(
  '[Tertiary Palletization Packaging/API] Tertiary Palletization Packaging Error',
  props<{ message: string }>(),
);

export const deleteAll = createAction(
  '[Tertiary Palletization Packaging/API] Delete All Tertiary Palletization Packaging Of Scenario',
  props<{ scenarioId: string }>(),
);

export const deleteAllSuccess = createAction(
  '[Tertiary Palletization Packaging/API] Delete All Tertiary Palletization Packaging Of Scenario Success',
  props<{ scenarioId: string }>(),
);
