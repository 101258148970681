import { createAction, props } from '@ngrx/store';
import { DistributionMarket, Transport, UpdateDistributionMarket } from '../models/distribution-market';

const PORTFOLIO_API = '[Distribution Market]';

export const findAllDistributionMarkets = createAction(
  `${PORTFOLIO_API} Find all distribution markets`,
  props<{ scenarioId: string }>()
);
export const findAllDistributionMarketsSuccess = createAction(
  `${PORTFOLIO_API} Find all distribution markets success`,
  props<{ distributionMarkets: DistributionMarket[] }>()
);

export const createDistributionMarket = createAction(
  `${PORTFOLIO_API} Create distribution market`,
  props<{ scenarioId: string, distributionMarket: UpdateDistributionMarket }>()
);
export const createDistributionMarketSuccess = createAction(`${PORTFOLIO_API} Create distribution market success`);

export const updateDistributionMarket = createAction(
  `${PORTFOLIO_API} Update distribution market`,
  props<{ id: string, distributionMarket: UpdateDistributionMarket }>()
);
export const updateDistributionMarketSuccess = createAction(`${PORTFOLIO_API} Update distribution market success`);

export const deleteDistributionMarket = createAction(`${PORTFOLIO_API} Delete distribution market`, props<{ id: string }>());
export const deleteDistributionMarketSuccess = createAction(`${PORTFOLIO_API} Delete distribution market success`);

export const updateDistributionMarketTransports = createAction(
  `${PORTFOLIO_API} Update distribution market transports`,
  props<{ id: string, transports: Transport[] }>()
);
export const updateDistributionMarketTransportsSuccess = createAction(
  `${PORTFOLIO_API} Update distribution market transports success`
);

export const distributionMarketError = createAction(`${PORTFOLIO_API} Error`, props<{ error: string }>());

