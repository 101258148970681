import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './delete-confirmation-modal.component.html',
  styleUrls: [ './delete-confirmation-modal.component.scss' ],
})
export class DeleteConfirmationModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public readonly data: { type?: string, name?: string }) { }

  ngOnInit(): void {
  }

}
