import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Collaborator} from '../../collaborator/collaborator.model';
import {HttpClient} from '@angular/common/http';
import {CollaboratorMapperService} from '../../collaborator/collaborator-mapper.service';
import {Observable} from 'rxjs';
import {CollaboratorListDto} from '../../api/dtos/__generated__/collaboratorListDto';
import {catchError, map} from 'rxjs/operators';
import {CollaboratorDto} from '../../api/dtos/__generated__/collaboratorDto';
import {NotificationService} from '../../core/notification.service';

@Injectable({
  providedIn: 'root',
})
export class UserManagementDataService {

  COLLABORATOR_URL = `${environment.apiUrl}/collaborators`;

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: CollaboratorMapperService,
    private readonly notificationService: NotificationService,
  ) {
  }

  getAll(): Observable<Collaborator[]> {
    return this.http.get(`${this.COLLABORATOR_URL}?includeDisabled=true`).pipe(
      map((dto: CollaboratorListDto) => this.mapper.fromList(dto.collaborators)),
      catchError(err => {
        this.notificationService.warn('Could not retrieve user list');
        throw err;
      }),
    );
  }

  update(collaborator: Collaborator): Observable<Collaborator> {
    const updateDto = this.mapper.toUpdateDto(collaborator);
    return this.http.put(`${this.COLLABORATOR_URL}/${collaborator.id}`, updateDto).pipe(
      map((dto: CollaboratorDto) => this.mapper.from(dto)),
      catchError(err => {
        this.notificationService.warn(`Could not update ${collaborator.firstname} ${collaborator.lastname}`);
        throw err;
      }),
    );
  }
}
