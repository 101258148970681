import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePageHeaderComponent } from './home-page-header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { ProfileButtonModule } from '../../../modeling/components/profile-button/profile-button.module';
import { SharedModule } from '../../../../shared/shared.module';
import { MatMenuModule } from '@angular/material/menu';
import { TenantModule } from '../../../../tenant/tenant.module';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [
    HomePageHeaderComponent,
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    ProfileButtonModule,
    SharedModule,
    MatMenuModule,
    TenantModule,
    MatButtonModule,
  ],
  exports: [
    HomePageHeaderComponent,
  ],
})
export class HomePageHeaderModule {}
