import { Component, OnInit } from '@angular/core';
import { TenantLogoService } from '../tenant-logo.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-eqopack-brand',
  templateUrl: './eqopack-brand.component.html',
  styleUrls: [ './eqopack-brand.component.scss' ],
})
export class EqopackBrandComponent implements OnInit {

  image$: Observable<string>;

  constructor(private readonly tenantLogoService: TenantLogoService) { }

  ngOnInit(): void {
    this.image$ = this.tenantLogoService.logo;
  }
}
