import { Injectable } from '@angular/core';
import { Project, ProjectSearchParams, SortDirection } from '../models/project.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Page } from '../../../shared/page.model';
import { ComponentTransport, PackagingComponent } from '../models/component.models';
import { FinishingProcess } from '../models/finishing-process.models';
import { Material, MaterialTransport } from '../models/material.models';
import { Packaging } from '../models/packaging.models';
import { Scenario } from '../models/scenario.model';
import { ProjectCompleteDto } from '../../../api/dtos/__generated__/projectCompleteDto';
import { ProjectMapperService } from './project-mapper.service';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { ProjectMetadataDto } from '../../../api/dtos/__generated__/projectMetadataDto';
import { ProjectCreationRequestDto } from '../../../api/dtos/__generated__/projectCreationRequestDto';
import { PageOfProjectMetadataDto } from '../../../api/dtos/__generated__/pageOfProjectMetadataDto';
import { Downstream } from '../../../packaging/downstream/models/downstream';
import { ProjectFilterDto } from '../../../api/dtos/__generated__/projectFilterDto';
import { UpdateProjectScenarioDto } from '../../../api/dtos/__generated__/updateProjectScenarioDto';
import { WashingProcess } from '../models/washing-process.models';

@Injectable({
  providedIn: 'root',
})
export class ProjectDataService {

  PROJECTS_URL = `${environment.apiUrl}/projects`;
  PROJECT_FILTERS_URL = `${environment.apiUrl}/project-filters`;
  SORTED_BY = 'lastOpenedAt';
  SORT_DIRECTION = SortDirection.DESC;

  constructor(
    private readonly http: HttpClient,
    private readonly projectMapperService: ProjectMapperService,
  ) {
  }

  get(params: ProjectSearchParams = {}): Observable<{ page: Page<Project> }> {
    const queryParams: any = {
      sortedBy: this.SORTED_BY,
      sortDirection: this.SORT_DIRECTION,
    };
    if (params.page !== undefined) { queryParams.page = params.page; }
    if (params.search !== undefined) { queryParams.search = params.search; }
    if (params.size !== undefined) { queryParams.size = params.size; }
    if (params.sortDirection !== undefined) { queryParams.sortDirection = params.sortDirection; }
    if (params.sortedBy !== undefined) { queryParams.sortedBy = params.sortedBy; }
    if (params.brand !== undefined) { queryParams.brand = params.brand; }
    if (params.owner !== undefined) { queryParams.owner = params.owner; }
    return this.http.get(this.PROJECTS_URL, { params: queryParams }).pipe(
      map((pageDto: PageOfProjectMetadataDto) => {
        const page: Page<Project> = {
          ...pageDto,
          content: this.projectMapperService.mapProjectListFromPageOfProjectDto(pageDto),
        };
        return { page };
      }),
    );
  }

  findProjectFilters(): Observable<ProjectFilterDto> {
    return this.http.get(this.PROJECT_FILTERS_URL);
  }

  getById(id: string): Observable<{
    project: Project,
    components: PackagingComponent[],
    componentTransports: ComponentTransport[],
    finishingProcesses: FinishingProcess[],
    washingProcesses: WashingProcess[],
    materials: Material[],
    materialTransports: MaterialTransport[],
    primaryPackaging: Packaging[],
    secondaryPackaging: Packaging[],
    tertiaryPackaging: Packaging[],
    tertiaryPalletizationPackaging: Packaging[],
    scenarios: Scenario[],
    downstreams: Downstream[],
  }> {
    return this.http.get(`${this.PROJECTS_URL}/${id}`, {}).pipe(
      map(this.extractFromProjectCompleteDto),
    );
  }

  update(update: Project): Observable<Project> {
    const dto: ProjectMetadataDto = this.projectMapperService.mapProjectMetadataDtoFromProject(update);
    return this.http.put(`${this.PROJECTS_URL}/${update.id}`, dto).pipe(
      map((complete: ProjectCompleteDto) => this.projectMapperService.mapProjectFromProjectMetadataDto(complete)),
    );
  }

  delete(id: string): Observable<string> {
    return this.http.delete(`${this.PROJECTS_URL}/${id}`).pipe(
      map(() => id),
    );
  }

  updateProjectScenarios(projectId: string, scenarios: UpdateProjectScenarioDto[]): Observable<unknown> {
    return this.http.put(`${this.PROJECTS_URL}/${projectId}/scenarios`, { scenarios });
  }

  duplicate(id: string): Observable<{
    project: Project,
    components: PackagingComponent[],
    finishingProcesses: FinishingProcess[],
    materials: Material[],
    materialTransports: MaterialTransport[],
    primaryPackaging: Packaging[],
    secondaryPackaging: Packaging[],
    tertiaryPackaging: Packaging[],
    tertiaryPalletizationPackaging: Packaging[],
    scenarios: Scenario[],
    downstreams: Downstream[],
  }> {
    return this.http.post(`${this.PROJECTS_URL}/${id}/duplicate`, {}).pipe(
      map(this.extractFromProjectCompleteDto),
    );
  }

  create(project: Project): Observable<Project> {
    const projectCreationRequestDto: ProjectCreationRequestDto = this.projectMapperService.mapProjectCreationRequestDtoFromProject(project);
    return this.http.post(this.PROJECTS_URL, projectCreationRequestDto)
      .pipe(
        map((projectDto: ProjectCompleteDto) => this.projectMapperService.mapProjectFromProjectMetadataDto(projectDto)),
      );
  }

  private extractFromProjectCompleteDto = (projectCompleteDto: ProjectCompleteDto): {
    project: Project,
    components: PackagingComponent[],
    componentTransports: ComponentTransport[],
    finishingProcesses: FinishingProcess[],
    washingProcesses: WashingProcess[],
    materials: Material[],
    materialTransports: MaterialTransport[],
    primaryPackaging: Packaging[],
    secondaryPackaging: Packaging[],
    tertiaryPackaging: Packaging[],
    tertiaryPalletizationPackaging: Packaging[],
    scenarios: Scenario[],
    downstreams: Downstream[],
  } => {
    const project: Project = this.projectMapperService.mapProjectFromProjectMetadataDto(projectCompleteDto);
    const scenarios: Scenario[] = this.projectMapperService.mapScenarioListFromProjectDto(projectCompleteDto);
    const components: PackagingComponent[] = this.projectMapperService.mapPackagingComponentListFromProjectDto(projectCompleteDto);
    const componentTransports: ComponentTransport[] = this.projectMapperService.mapComponentTransportListFromProjectDto(projectCompleteDto);
    const finishingProcesses: FinishingProcess[] = this.projectMapperService.mapFinishingProcessListFromProjectDto(projectCompleteDto);
    const washingProcesses: WashingProcess[] = this.projectMapperService.mapWashingProcessListFromProjectDto(projectCompleteDto);
    const materials: Material[] = this.projectMapperService.mapMaterialListFromProjectDto(projectCompleteDto);
    const materialTransports: MaterialTransport[] = this.projectMapperService.mapMaterialTransportListFromProjectDto(projectCompleteDto);
    const primaryPackaging: Packaging[] = this.projectMapperService.mapPrimaryPackagingFromProjectDto(projectCompleteDto);
    const secondaryPackaging: Packaging[] = this.projectMapperService.mapSecondaryPackagingFromProjectDto(projectCompleteDto);
    const tertiaryPackaging: Packaging[] = this.projectMapperService.mapTertiaryPackagingFromProjectDto(projectCompleteDto);
    // tslint:disable-next-line:max-line-length
    const tertiaryPalletizationPackaging: Packaging[] = this.projectMapperService.mapTertiaryPalletizationPackagingFromProjectDto(projectCompleteDto);
    const downstreams: Downstream[] = this.projectMapperService.mapDownstreamListFromProjectDto(projectCompleteDto);
    // tslint:disable-next-line:max-line-length
    return {
      project,
      scenarios,
      components,
      componentTransports,
      finishingProcesses,
      washingProcesses,
      materials,
      materialTransports,
      primaryPackaging,
      secondaryPackaging,
      tertiaryPackaging,
      tertiaryPalletizationPackaging,
      downstreams,
    };
  }
}
