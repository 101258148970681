import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../../core/notification.service';
import { catchError, map, Observable } from 'rxjs';
import { Portfolio } from '../models/portfolio.model';
import { PortfolioMapperService } from '../mappers/portfolio-mapper.service';
import { environment } from '../../../environments/environment';
import { PortfoliosDto } from '../../api/dtos/__generated__/portfoliosDto';
import { PortfolioImports } from '../models/portfolio-imports';
import { PortfolioDto } from '../../api/dtos/__generated__/portfolioDto';
import { PortfolioImportsMapperService } from '../mappers/portfolio-imports-mapper.service';
import { PortfolioImportScenariosDto } from '../../api/dtos/__generated__/portfolioImportScenariosDto';
import { PortfolioScenariosSelectionDto } from '../../api/dtos/__generated__/portfolioScenariosSelectionDto';
import { PortfolioMetadata } from '../models/portfolio-metadata.model';
import { PortfolioMetadataMapperService } from '../mappers/portfolio-metadata-mapper.service';
import { CreatePortfolio } from '../models/create-portfolio.model';
import { CreatePortfolioDto } from '../../api/dtos/__generated__/createPortfolioDto';
import { CreatePortfolioMapperService } from '../mappers/create-portfolio-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class PortfolioDataService {
  private readonly PORTFOLIOS = 'portfolios';

  constructor(
    private readonly http: HttpClient,
    private readonly notificationService: NotificationService,
    private readonly portfolioMapperService: PortfolioMapperService,
    private readonly createPortfolioMapperService: CreatePortfolioMapperService,
    private readonly portfolioImportsMapperService: PortfolioImportsMapperService,
    private readonly portfolioMetadataMapperService: PortfolioMetadataMapperService,
  ) {
  }

  findAll(): Observable<PortfolioMetadata[]> {
    return this.http.get(`${environment.apiUrl}/${this.PORTFOLIOS}`).pipe(
      map((dto: PortfoliosDto) => this.portfolioMetadataMapperService.fromList(dto.results)),
      catchError(err => {
        this.notificationService.warn('Could not retrieve portfolios');
        throw err;
      }),
    );
  }

  create(createPortfolio: CreatePortfolio): Observable<Portfolio> {
    const body: CreatePortfolioDto = this.createPortfolioMapperService.to(createPortfolio);
    return this.http.post(`${environment.apiUrl}/${this.PORTFOLIOS}`, body).pipe(
      map((dto: PortfolioDto) => this.portfolioMapperService.from(dto)),
      catchError(err => {
        this.notificationService.warn('Could not create portfolio');
        throw err;
      }),
    );
  }

  findById(id: string): Observable<Portfolio> {
    return this.http.get(`${environment.apiUrl}/${this.PORTFOLIOS}/${id}`).pipe(
      map((dto: PortfolioDto) => this.portfolioMapperService.from(dto)),
      catchError(err => {
        this.notificationService.warn(`Could not fetch portfolio ${id}`);
        throw err;
      }),
    );
  }

  delete(id: string): Observable<boolean> {
    return this.http.delete(`${environment.apiUrl}/${this.PORTFOLIOS}/${id}`).pipe(
      map(() => true),
      catchError(err => {
        this.notificationService.warn(`Could not delete portfolio ${id}`);
        throw err;
      }),
    );
  }

  downloadExport(id: string, versionHash: string): Observable<string> {
    return this.http.get(`${environment.apiUrl}/${this.PORTFOLIOS}/${id}/export?versionHash=${versionHash}`, {
      responseType: 'blob',
    }).pipe(
      map((response: Blob) => {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        return window.URL.createObjectURL(blob);
      })
    );
  }

  importScenarios(id: string, imports: PortfolioImports): Observable<Portfolio> {
    const body: PortfolioImportScenariosDto = this.portfolioImportsMapperService.to(imports);
    return this.http.post(`${environment.apiUrl}/${this.PORTFOLIOS}/${id}/scenarios`, body).pipe(
      map((dto: PortfolioDto) => this.portfolioMapperService.from(dto)),
      catchError(err => {
        this.notificationService.warn(`Could not import scenarios into portfolio ${id}`);
        throw err;
      }),
    );
  }

  deleteScenarios(id: string, portfolioScenarioIds: string[]): Observable<Portfolio> {
    const body: PortfolioScenariosSelectionDto = {
      portfolioScenarioIds: Array.from(portfolioScenarioIds, (v) => ({ id: v }))
    };
    return this.http.delete(`${environment.apiUrl}/portfolios/${id}/scenarios`, { body }).pipe(
      map((dto: PortfolioDto) => this.portfolioMapperService.from(dto)),
      catchError(err => {
        this.notificationService.warn(`Could not remove scenarios from portfolio ${id}`);
        throw err;
      }),
    );
  }
}
