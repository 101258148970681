import { createAction, props } from '@ngrx/store';
import { EndOfLife } from '../models/end-of-life';

const ACTION = '[End Of Life]';

export const findAllEndOfLives = createAction(`${ACTION} Find all end of life`, props<{materialId: string}>());
export const findAllEndOfLivesSuccess = createAction(
  `${ACTION} Find all end of life success`,
  props<{ endOfLives: EndOfLife[] }>()
);

export const updateEndOfLife = createAction(
  `${ACTION} Update end of life`,
  props<{ id: string, endOfLife: EndOfLife }>()
);
export const updateEndOfLifeSuccess = createAction(`${ACTION} Update end of life success`,
  props<{ endOfLife: EndOfLife }>());

export const endOfLifeError = createAction(`${ACTION} Error`, props<{ error: string }>());

