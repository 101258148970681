<mat-card>
  <mat-card-title>
    <mat-icon id="title-icon" svgIcon="materials"></mat-icon>
    <h2 data-testid="material-table-title" i18n>Materials</h2>
  </mat-card-title>
  <mat-card-content>
    <mat-table *ngIf="dataSource?.data?.length > 0" [dataSource]="dataSource" data-testid="materials-table">

      <ng-container matColumnDef="material">
        <mat-header-cell *matHeaderCellDef class="material-column" i18n>Material</mat-header-cell>
        <mat-cell (click)="materialClick.emit(material)" *matCellDef="let material" class="material-column">
          <ng-container>{{material?.materialType?.value}}</ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="mass">
        <mat-header-cell *matHeaderCellDef class="mass-column" i18n>Mass [g]</mat-header-cell>
        <mat-cell (click)="materialClick.emit(material)" *matCellDef="let material"
                  class="mass-column"> {{material?.mass | mass}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="surface">
        <mat-header-cell *matHeaderCellDef class="mass-column" i18n>Surface [m<sup>2</sup>]</mat-header-cell>
        <mat-cell
          (click)="materialClick.emit(material)"
          *matCellDef="let material"
          class="mass-column"><span>{{material?.mass | surface}}</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="grammage">
        <mat-header-cell *matHeaderCellDef class="mass-column" i18n>Grammage [m<sup>2</sup>]</mat-header-cell>
        <mat-cell
          (click)="materialClick.emit(material)"
          *matCellDef="let material"
          class="mass-column">{{material?.mass | grammage}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="conversionProcess">
        <mat-header-cell *matHeaderCellDef class="conversion-process-column" i18n>Conversion process</mat-header-cell>
        <mat-cell (click)="materialClick.emit(material)" *matCellDef="let material" class="conversion-process-column">
          <span *ngIf="material.conversionProcess">{{material?.conversionProcess?.conversionProcessType?.value}}</span>
        </mat-cell>
      </ng-container>


      <ng-container matColumnDef="duplicate">
        <mat-header-cell *matHeaderCellDef class="delete-column"></mat-header-cell>
        <mat-cell *matCellDef="let material" class="delete-column">
          <button (click)="duplicateMaterial.emit(material)" *ngIf="isLockedByMe" color="warn" mat-icon-button>
            <mat-icon>content_copy</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef class="delete-column"></mat-header-cell>
        <mat-cell *matCellDef="let material" class="delete-column">
          <button (click)="deleteMaterial.emit(material)" *ngIf="isLockedByMe" color="warn" mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></mat-row>
    </mat-table>
  </mat-card-content>
  <mat-card-actions class="card-actions">
    <app-action-button
      data-testid="add-material-button"
      (click)="addMaterial.emit()"
      [disabled]="!isLockedByMe"
      i18n
    >Add a material</app-action-button>
  </mat-card-actions>
</mat-card>
