import { NgModule } from '@angular/core';
import { EndOfLifeStoreModule } from './end-of-life/store/end-of-life.store.module';
import { EndOfLifeModule } from './end-of-life/end-of-life.module';
import { DistributionMarketModule } from './distribution-market/distribution-market.module';
import { DistributionMarketStoreModule } from './distribution-market/store/distribution-market-store.module';
import { DownstreamModule } from './downstream/downstream.module';
import { DownstreamStoreModule } from './downstream/store/downstream-store.module';

@NgModule({
  imports: [
    DistributionMarketModule,
    DistributionMarketStoreModule,
    EndOfLifeModule,
    EndOfLifeStoreModule,
    DownstreamModule,
    DownstreamStoreModule
  ],
})
export class PackagingModule {
}
