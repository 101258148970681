import { MaterialType } from '../../../store/reference-data/models/reference-data.model';
import { ConversionProcess } from './conversion-process.model';
import { DistributionCountry } from './scenario.model';
import { TransportType } from './transport.models';

export interface Material {
  readonly id?: string;
  readonly componentId?: string;
  readonly mass?: Mass;
  readonly materialType?: MaterialType;
  readonly conversionProcess?: ConversionProcess;
  readonly recycledContent?: number;
  readonly recyclable?: boolean;
  readonly compostable?: boolean;
  readonly recyclingRateType?: RecyclingRateType;
  readonly recyclingRate?: number;
  readonly defaultRecyclingRate?: number;
}

export interface EndOfLife {
  readonly recyclable?: boolean;
  readonly compostable?: boolean;
  readonly recyclingRateType?: RecyclingRateType;
  readonly recyclingRate?: number;
  readonly defaultRecyclingRate?: number;
  readonly distributionCountry?: DistributionCountry;
}

export interface Mass {
  massType?: MassType;
  mass?: number;
  surface?: number;
  grammage?: number;
}

export type RecyclingRateType = 'USE_DEFAULT' | 'CUSTOM';

export const RecyclingRateType = {
  DEFAULT: 'USE_DEFAULT' as RecyclingRateType,
  CUSTOM: 'CUSTOM' as RecyclingRateType,
};

export type MassType = 'EXPLICIT_MASS' | 'SURFACE_TIMES_GRAMMAGE';

export const MassType = {
  EXPLICIT_MASS: 'EXPLICIT_MASS' as MassType,
  SURFACE_TIMES_GRAMMAGE: 'SURFACE_TIMES_GRAMMAGE' as MassType,
};

export interface MaterialTransport {
  readonly id?: string;
  readonly materialId?: string;
  readonly transportType?: TransportType;
  readonly distance?: number;
}
