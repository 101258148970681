<h2 mat-dialog-title>Delete {{data?.type}}</h2>
<mat-dialog-content class="warning-content">
  <mat-icon class="warning-icon" color="warn">warning</mat-icon>
  <div class="warning-text" data-testid="warning-text">
    <span i18n>Do you really want to delete</span>
    <span *ngIf="data?.type"> {{data?.type}}</span>
    <span *ngIf="data?.name" class="name"> {{data?.name}}</span>?
    <br/>
    <span i18n>You cannot undo this action.</span>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button i18n mat-dialog-close mat-stroked-button type="button">Cancel</button>
  <button [mat-dialog-close]="true"
          class="border-primary"
          color="primary"
          i18n
          mat-stroked-button
          type="submit">
    Confirm
  </button>
</mat-dialog-actions>
