<div class="portfolio-table">
  <div class="portfolio-actions-group">
    <h2 class="portfolio-title" data-testid="portfolio-table-title" i18n>Portfolios</h2>
    <div class="portfolio-actions">
      <app-search-input (filter)="handleChangeSearch($event)"></app-search-input>
      <app-action-button (click)="openNewPortfolioDialog()" data-testid="new-portfolio-button" size="large">
        <span i18n>New portfolio</span>
      </app-action-button>
    </div>
  </div>

  <div>
    <mat-card>
      <mat-table
        *ngIf="dataSource"
        [dataSource]="dataSource"
        [trackBy]="trackBy"
        data-testid="mat-table"
        matSort
        matSortActive="publishedAt"
        matSortDirection="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Name</mat-header-cell>
          <mat-cell *matCellDef="let portfolio" (click)="onRowClick(portfolio.id)">{{portfolio.name}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <mat-header-cell *matHeaderCellDef i18n>Created</mat-header-cell>
          <mat-cell *matCellDef="let portfolio"
                    (click)="onRowClick(portfolio.id)">{{portfolio.createdAt | date:'shortDate'}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="background-accent"></mat-header-row>
        <mat-row
          *matRowDef="let portfolio; columns: displayedColumns"
          id="scenario-{{portfolio.id}}"
          matRipple
        ></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[10, 20, 50]" class="table-footer"></mat-paginator>
    </mat-card>
  </div>
</div>
