import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBox, faChartPie, faShareAlt } from '@fortawesome/free-solid-svg-icons';


@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
  ],
  exports: [
    FontAwesomeModule
  ],
})
export class FontAwesomeIconsModuleModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faShareAlt, faChartPie, faBox);
  }
}
